import {
  Box,
  Skeleton,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Badge,
  Fab,
} from "@mui/material";
import {
  DEFAULT_SNACKBAR_PROPS,
  ROUTE_NAME,
  RoutePath,
} from "../../../utils/constant";
import { useLocation, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import isEmpty from "lodash.isempty";
import MarkdownRenderer from "../../../components/MarkdownRenderer/MarkdownRenderer";
import dayjs, { Dayjs } from "dayjs";
import QuantitySelector from "../../../components/QuantityInput/QuantityInput";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { COLOR } from "../../../utils/color";
import PhoneIcon from "../../../assets/svg/PhoneIcon";
import TextInput from "../../../components/Forms/TextInput/TextInput";
import MailIcon from "../../../assets/svg/MailIcon";
import UserIcon from "../../../assets/svg/UserIcon";
import {
  useGetCheckoutDetailById,
  useGetPublicProductScheduleByIdV2,
} from "../../../query/queries";
import { PublicScheduleDetailWithQty } from "../../../types/globalTypes";
import {
  formatCurrency,
  formatPhoneNumber,
  stripFormatting,
} from "../../../utils/helper";
import {
  useCreateNewTransaction,
  useGetPublicCompanyDetail,
} from "../../../query/mutations";
import { isAxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { errorLogger } from "../../../utils/logger";
import { useDynamicPage } from "../../NavigateOnValidCompanyName";
import {
  receipentSchema,
  receipentWARequiredSchema,
} from "../../../joiSchema/payment";
import Joi, { ValidationErrorItem } from "joi";
import {
  ProductDetailType,
  ScheduleProductDetail,
} from "../../../api/request.types";
import CalendarPicker from "../../../components/CalendarPIcker/CalendarPicker";
import OpacityButton from "../../../components/OpacityButton/OpacityButton";
import { FaCaretLeft, FaShoppingCart } from "react-icons/fa";
import { IoMdShare } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "../../../redux/reducers";
import {
  resetPublicTransaction,
  setPublicTransaction,
} from "../../../redux/reducers/public";
import useNavigateDynamicPage from "../../../hooks/useNavigateDynamicPage";
import DynamicPageContainer from "../../../components/Layout/DynamicPageContainer/DynamicPageContainer";
import { getTransactionTax, QRIS_TAX } from "../../../utils/transaction";
import TeamMemberSelector from "../components/TeamMemberSelector/TeamMemberSelector";
import { TIME_FORMAT } from "../../../utils/constant";
import useDebouncedFunction from "../../../hooks/useDebouncedFunction";
import CheckoutCartModal from "../components/Modal/CheckoutCartModal";

type PaymentReceipentType = {
  name: string;
  email: string;
  phone: string;
  notes: string;
};

const DEFAULT_PAYMENT_RECEIPENT_DATA = {
  name: "",
  email: "",
  phone: "",
  notes: "",
};

const D2Checkout = () => {
  const [cartOpen, setCartOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      setCartOpen(true);
    }
  };
  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };
  const FONT_COLOR = "black";
  const dispatch = useDispatch();
  const navigate = useNavigateDynamicPage();
  const { subdomain } = useDynamicPage();

  const publicReducer = useSelector(
    (state: RootReducerState) => state.publicReducer
  );
  const { data } = useDynamicPage();
  const { productId } = useParams<{ productId: string }>();
  let { state } = useLocation();
  const getCompanyV2Mutation = useGetPublicCompanyDetail();
  const [productDetailData, setProductDetailData] = useState<{
    data: ProductDetailType;
    selectedDate?: string;
  }>(state);
  const [productDetailDataLoading, setProductDetailDataLoading] =
    useState<boolean>(true);
  const selectedProductData = useCallback(async () => {
    if (!state && !productDetailData) {
      const companyData = await getCompanyV2Mutation.mutateAsync({
        companyDomainName: subdomain || "",
      });
      companyData.data?.data?.productDetails.forEach(
        (product: ProductDetailType) => {
          if (product.productID === productId) {
            setProductDetailData({
              data: product,
              selectedDate: dayjs().format(TIME_FORMAT.YYYYMMDD),
            });
            setProductDetailDataLoading(false);
          }
        }
      );
      return null;
    }
    setProductDetailData(
      state as {
        data: ProductDetailType;
        selectedDate?: string;
      }
    );
    setProductDetailDataLoading(false);
  }, [getCompanyV2Mutation, productId, state, subdomain]);

  useEffect(() => {
    if (productDetailDataLoading) {
      selectedProductData();
    }
  }, [productDetailDataLoading]);

  const [selectedDate, setSelectedDate] = useState<Dayjs>(
    state?.selectedDate ? dayjs(state?.selectedDate) : dayjs()
  );
  const [scheduleDetailsWithQuantities, setScheduleDetailsWithQuantities] =
    useState<{
      [date: string]: Array<
        PublicScheduleDetailWithQty & {
          teamMemberId: string;
          teamMemberName: string;
        }
      >;
    }>({});

  const selectedSchedules = Object.entries(scheduleDetailsWithQuantities)
    .map(([date, details]) =>
      details
        .filter((detail) => detail.qty > 0)
        .map((detail) => ({
          startTime: dayjs(detail.startTime, "HH.mm").format(TIME_FORMAT.HHmm),
          endTime: dayjs(detail.endTime, "HH.mm").format(TIME_FORMAT.HHmm),
          date: date,
          qty: detail.qty,
        }))
    )
    .flat();

  const [paymentReceipent, setPaymentRecipient] =
    useState<PaymentReceipentType>(DEFAULT_PAYMENT_RECEIPENT_DATA);
  const [errors, setErrors] = useState<
    Record<keyof PaymentReceipentType, string>
  >({
    name: "",
    email: "",
    notes: "",
    phone: "",
  });
  const checkoutDetailByProductIdQuery = useGetCheckoutDetailById(
    productId || ""
  );

  const checkoutData = useMemo(() => {
    return checkoutDetailByProductIdQuery.data?.data?.data;
  }, [checkoutDetailByProductIdQuery.data?.data?.data]);

  const isWhatsAppRequired = useMemo(() => {
    return (
      checkoutData?.collectField.find((data) => data.value === "No Whatsapp")
        ?.required ?? false
    );
  }, [checkoutData]);
  const incompletePaymentReceipent =
    Object.values(errors).some((error) => (error || "").trim() !== "") ||
    isEmpty(paymentReceipent.email) ||
    isEmpty(paymentReceipent.name) ||
    (isWhatsAppRequired && isEmpty(paymentReceipent.phone));

  const createNewTransactionMutation = useCreateNewTransaction();

  const productScheduleByIdQuery = useGetPublicProductScheduleByIdV2(
    productId || "",
    (selectedDate || dayjs()).format(TIME_FORMAT.YYYYMMDD) ||
      dayjs()?.format(TIME_FORMAT.YYYYMMDD)
  );

  const productScheduleData = useMemo(() => {
    return productScheduleByIdQuery.data?.data.data || { scheduleDetails: [] };
  }, [productScheduleByIdQuery.data]);

  const validateError = () => {
    const dynamicSchema = isWhatsAppRequired
      ? receipentWARequiredSchema
      : receipentSchema;
    const { error } = dynamicSchema.validate(paymentReceipent, {
      abortEarly: false,
    });
    if (error) {
      const errors = error.details.reduce(
        (acc: any, err: ValidationErrorItem) => {
          return { ...acc, [err.path[0]]: err.message };
        },
        {}
      );
      setErrors((prevErrors) => ({ ...prevErrors, ...errors }));
      return true;
    }
    return false;
  };
  const handleBlur = useCallback(
    (field: keyof PaymentReceipentType) => () => {
      const { error } = Joi.object({
        [field]: receipentSchema.extract(field),
      }).validate({ [field]: paymentReceipent[field] });
      if (error) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: error.message,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: undefined,
        }));
      }
    },
    [paymentReceipent]
  );

  const handleOnChange = (key: keyof PaymentReceipentType) => (e: any) => {
    switch (key) {
      case "phone":
        let phoneNumber = e?.target?.value || "";
        phoneNumber = stripFormatting(phoneNumber);
        setPaymentRecipient((prevData) => ({
          ...prevData,
          [key]: phoneNumber,
        }));
        return;
      case "email":
      case "name":
      case "notes":
        setPaymentRecipient((prevData) => ({
          ...prevData,
          [key]: e.target.value,
        }));
        return;
    }
  };

  const handleSubmit = async () => {
    try {
      const productDetail: ScheduleProductDetail[] = [];

      Object.entries(scheduleDetailsWithQuantities).forEach(
        ([date, details]) => {
          details
            .filter((detail) => detail.qty > 0)
            .forEach((detail) => {
              const existingProduct = productDetail.find(
                (pd) => pd.teamMemberID === detail.teamMemberId
              );

              const scheduleEntry = {
                startTime: dayjs(
                  detail.startTime.replace(".", ":"),
                  TIME_FORMAT.HHmm
                ).format("HH.mm"),
                endTime: dayjs(
                  detail.endTime.replace(".", ":"),
                  TIME_FORMAT.HHmm
                ).format("HH.mm"),
                date: dayjs(date, TIME_FORMAT.DDMMYYYY).format(
                  TIME_FORMAT.YYYYMMDD
                ),
                qty: detail.qty,
                price: detail.price,
                remainingQuota: detail.remainingQuota,
              };

              if (existingProduct) {
                existingProduct.scheduleDetails.push(scheduleEntry);
              } else {
                productDetail.push({
                  productID: productId || "",
                  productName: productDetailData?.data?.name || "",
                  teamMemberID: detail.teamMemberId,
                  scheduleDetails: [scheduleEntry],
                });
              }
            });
        }
      );

      const dataTransaction = {
        companyID: data.companyID,
        date: dayjs().format(TIME_FORMAT.YYYYMMDD),
        productDetail,
        location: "",
        total: priceDetail?.total || 0,
        productPrice: priceDetail?.amount || 0,
        adminPrice: priceDetail?.adminFee || 0,
        fullName: paymentReceipent.name,
        email: paymentReceipent.email,
        phoneNumber: paymentReceipent.phone
          ? `+62${paymentReceipent.phone}`
          : "",
        notes: "",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        teamMemberIDs: productDetail.map((pd) => pd.teamMemberID),
      };
      const apiResponse = await createNewTransactionMutation.mutateAsync(
        dataTransaction
      );
      const responseData = apiResponse.data.data;

      dispatch(
        setPublicTransaction({
          snapToken: responseData.snapToken,
          transactionId: responseData.transactionID,
          timestamp: dayjs().valueOf(),
        })
      );

      navigate(
        `${RoutePath[ROUTE_NAME.DYNAMIC_INVOICE]}/${
          responseData.transactionID
        }`,
        {
          replace: true,
        }
      );
    } catch (error) {
      // Handle error responses
      if (isAxiosError(error) && error.response?.status === 409) {
        const errorData = error.response.data?.data;
        if (Array.isArray(errorData)) {
          errorData.forEach((product) => {
            const { startTime, endTime, date } = product.scheduleDetails;
            enqueueSnackbar({
              ...DEFAULT_SNACKBAR_PROPS,
              variant: "error",
              message: `Kuota pada tanggal ${date} (${startTime}-${endTime}) tidak mencukupi`,
            });
          });
        } else {
          enqueueSnackbar({
            ...DEFAULT_SNACKBAR_PROPS,
            variant: "error",
            message:
              "Telah terjadi pembaharuan terhadap harga dan jadwal yang telah kamu pilih. Silakan merefresh halaman ini",
          });
        }
      } else {
        errorLogger(error);
        enqueueSnackbar({
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "error",
          message: "Terjadi kesalahan, mohon coba dalam beberapa saat lagi",
        });
      }
    }
  };

  const debouncedSubmit = useDebouncedFunction(handleSubmit, 300);
  useEffect(() => {
    if (
      publicReducer.transaction.timestamp !== -1 &&
      dayjs().valueOf() - publicReducer.transaction.timestamp > 15 * 60 * 1000
    ) {
      dispatch(resetPublicTransaction());
    } else if (publicReducer.transaction.transactionId) {
      const navigateTo = `${RoutePath[ROUTE_NAME.DYNAMIC_INVOICE]}/${
        publicReducer.transaction.transactionId
      }`;
      navigate(navigateTo, { replace: true });
    }
  }, [
    navigate,
    publicReducer.transaction.transactionId,
    publicReducer.transaction.timestamp,
    dispatch,
  ]);

  useEffect(() => {
    if (
      productScheduleByIdQuery.isFetched &&
      !productScheduleByIdQuery.isLoading
    ) {
      const selectedDateKey = selectedDate.format(TIME_FORMAT.DDMMYYYY);
      const fetchedScheduleDetails = productScheduleData.scheduleDetails;
      if (!scheduleDetailsWithQuantities[selectedDateKey]) {
        const initializedScheduleDetails = fetchedScheduleDetails.map(
          (detail) => ({
            ...detail,
            qty: 0,
            teamMemberId: "",
            teamMemberName: "",
          })
        );

        setScheduleDetailsWithQuantities((prevState) => {
          const result = {
            ...prevState,
            [selectedDateKey]: initializedScheduleDetails,
          };
          return result;
        });
      }
    }
  }, [
    productScheduleByIdQuery.isFetched,
    productScheduleByIdQuery.isLoading,
    productScheduleData.scheduleDetails,
    scheduleDetailsWithQuantities,
    selectedDate,
  ]);

  useEffect(() => {
    // DISINI KENA KOSONG NAVIGATE BACK
    if (
      (!productDetailDataLoading && !productDetailData) ||
      isEmpty(productId)
    ) {
      navigate(-1);
    } else {
      if (productDetailData?.selectedDate) {
        const selectedDateDayjs = dayjs(
          productDetailData.selectedDate,
          TIME_FORMAT.YYYYMMDD
        );
        setSelectedDate(selectedDateDayjs);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, productDetailData, productDetailDataLoading]);

  const handleQuantityChange = useCallback(
    (date: string, index: number, qty: number) => {
      setScheduleDetailsWithQuantities((prev) => {
        return {
          ...prev,
          [date]: prev[date].map((schedule, i) =>
            i === index ? { ...schedule, qty } : schedule
          ),
        };
      });
    },
    []
  );

  const handleTeamMemberChange = useCallback(
    (
      date: string,
      index: number,
      teamMemberId: string,
      teamMemberName: string
    ) => {
      setScheduleDetailsWithQuantities((prev) => {
        return {
          ...prev,
          [date]: (prev[date] || []).map((schedule, i) =>
            i === index
              ? {
                  ...schedule,
                  teamMemberId:
                    schedule.teamMemberId === teamMemberId ? "" : teamMemberId,
                  teamMemberName:
                    schedule.teamMemberName === teamMemberName
                      ? ""
                      : teamMemberName,
                }
              : schedule
          ),
        };
      });
    },
    [setScheduleDetailsWithQuantities]
  );

  const priceDetail = getTransactionTax(
    Object.values(scheduleDetailsWithQuantities).reduce(
      (sum, details) =>
        sum +
        details.reduce(
          (detailSum, detail) => detailSum + detail.price * detail.qty,
          0
        ),
      0
    ),
    QRIS_TAX,
    false
  );
  const daysContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (daysContainerRef.current) {
      const selectedDayElement = daysContainerRef.current.querySelector(
        `[data-day="${selectedDate.format(TIME_FORMAT.YYYYMMDD)}"]`
      );
      if (selectedDayElement) {
        selectedDayElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }
  }, [selectedDate]);

  const _renderLoadingSchedules = () => {
    return new Array(5).fill("").map((_, idx) => {
      return (
        <Skeleton
          width="100%"
          height="60px"
          key={idx}
          sx={{
            ":-webkit-transform": "none",
            transform: "none",
            mt: 1,
          }}
        />
      );
    });
  };
  const _renderSchedules = useCallback(() => {
    if (productScheduleData.scheduleDetails.length === 0) {
      return <Typography>Tidak Ada jadwal hari ini</Typography>;
    }
    return (
      <Box display="flex" flexDirection="column" gap={1}>
        {productScheduleData.scheduleDetails.map((detail, index) => {
          const formattedDate = selectedDate.format(TIME_FORMAT.DDMMYYYY);
          const scheduleDetail: PublicScheduleDetailWithQty =
            scheduleDetailsWithQuantities[formattedDate]?.find(
              (d) => d.startTime === detail.startTime
            ) || { ...detail, qty: 0 };

          const startTime = dayjs(detail.startTime, "HH.mm")
            .set("date", selectedDate.date())
            .set("month", selectedDate.month())
            .set("year", selectedDate.year());
          const endTime = dayjs(detail.endTime, "HH.mm")
            .set("date", selectedDate.date())
            .set("month", selectedDate.month())
            .set("year", selectedDate.year());

          const currentTime = dayjs();
          const timeDifference = endTime.diff(currentTime, "minute");
          const timePassed = timeDifference < 0;
          const disabled = scheduleDetail.remainingQuota === 0 || timePassed;
          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                pointerEvents: disabled ? "none" : "auto",
                opacity: disabled ? 0.5 : 1,
              }}
            >
              <Box display="flex" flexDirection="column">
                <Typography fontSize={16} fontWeight={600}>
                  {`${startTime.format("HH.mm")} - ${endTime.format("HH.mm")}`}
                  <Typography
                    fontSize={12}
                    fontWeight={400}
                    color={COLOR.danger400}
                    variant="caption"
                    ml="4px"
                  >
                    (Sisa {detail.remainingQuota})
                  </Typography>
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={600}
                  color={COLOR.neutral500}
                >
                  {formatCurrency(detail.price, "IDR")}
                </Typography>
              </Box>
              <QuantitySelector
                value={scheduleDetail.qty}
                onChange={(qty) =>
                  handleQuantityChange(
                    selectedDate.format(TIME_FORMAT.DDMMYYYY),
                    index,
                    qty
                  )
                }
                min={0}
                max={detail.remainingQuota}
                maxDigits={2}
                // primaryColor={FONT_COLOR}
                disabled={disabled}
              />
            </Box>
          );
        })}
      </Box>
    );
  }, [
    handleQuantityChange,
    productScheduleData.scheduleDetails,
    scheduleDetailsWithQuantities,
    selectedDate,
  ]);

  const cartScheduleData = Object.entries(scheduleDetailsWithQuantities)
    .map(([date, schedules]) => ({
      date,
      schedules: schedules
        .map((schedule, index) => ({
          ...schedule,
          index,
        }))
        .filter((schedule) => schedule.qty > 0),
    }))
    .filter(({ schedules }) => schedules.length > 0);

  const isScheduleEmpty = (
    scheduleDetails: typeof scheduleDetailsWithQuantities
  ) => {
    if (!scheduleDetails || Object.keys(scheduleDetails).length === 0) {
      return true;
    }

    return Object.values(scheduleDetails).every(
      (arr) => arr.length === 0 || arr.every((detail) => detail.qty === 0)
    );
  };

  const steps = useMemo(() => {
    let steps: Array<{ label: string; optional: any; content: any }> = [
      {
        label: "Title",
        optional: null,
        content: (
          <Box px={3}>
            <Typography fontSize={24} fontWeight={600} py={2}>
              {checkoutData?.checkoutTitle || "-"}
            </Typography>
            <Box display="flex" flexDirection="column" flex={1} width="100%">
              <MarkdownRenderer
                markdown={
                  checkoutData?.checkoutDesc
                    .split("\n")
                    .map((line) => line.trimStart())
                    .join("\n") || ""
                }
                customStyles={{
                  h1: {
                    color: "blue",
                    fontWeight: "bold",
                    whiteSpace: "pre-wrap",
                  },
                  ul: {
                    color: "gray",
                    listStyleType: "disc",
                    whiteSpace: "pre-wrap",
                  },
                  li: { marginBottom: "10px", whiteSpace: "pre-wrap" },
                  p: {
                    whiteSpace: "pre-wrap",
                  },
                  pre: {
                    whiteSpace: "pre-wrap",
                  },
                }}
              />
            </Box>
          </Box>
        ),
      },
      {
        label: "Informasi Pembeli",
        optional: "",
        content: (
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            py={2}
            gap={2}
          >
            <TextInput
              title="Nama Lengkap"
              required
              startEndorment={<UserIcon color={COLOR.neutral400} />}
              textInputProps={{
                placeholder: "cth: John Doe",
                value: paymentReceipent.name,
                onChange: handleOnChange("name"),
                onBlur: handleBlur("name"),
              }}
              helper={{
                color: COLOR.danger500,
                text: errors["name"],
              }}
            />
            <TextInput
              title="Alamat Email"
              required
              startEndorment={<MailIcon color={COLOR.neutral400} />}
              textInputProps={{
                placeholder: "cth: johndoe@email.com",
                value: paymentReceipent.email,
                onChange: handleOnChange("email"),
                onBlur: handleBlur("email"),
              }}
              helper={{
                color: COLOR.danger500,
                text: errors["email"],
              }}
            />
            <TextInput
              title="No Whatsapp"
              required={isWhatsAppRequired}
              startEndorment={
                <>
                  <PhoneIcon color={COLOR.neutral400} />
                  <Typography color={COLOR.neutral400} ml={"4px"}>
                    +62
                  </Typography>
                </>
              }
              additionalPrefix={
                !isWhatsAppRequired && (
                  <Typography
                    variant="caption"
                    fontWeight={400}
                    color={COLOR.neutral400}
                  >
                    (Optional)
                  </Typography>
                )
              }
              textInputProps={{
                placeholder: "cth: 818239183912",
                value: formatPhoneNumber(paymentReceipent.phone),
                onChange: handleOnChange("phone"),
                onBlur: handleBlur("phone"),
                pattern: "[0-9]*",
                inputMode: "numeric",
              }}
              helper={{
                color: COLOR.danger500,
                text: errors["phone"],
              }}
            />
          </Box>
        ),
      },
      {
        label: "Pilih Jadwal",
        optional: null,
        content: (
          <Box display="flex" flexDirection="column" gap={1}>
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              width="100%"
              alignItems="center"
            >
              <CalendarPicker
                baseColor={FONT_COLOR}
                primaryColor={data.color}
                minDate={dayjs()}
                maxDate={dayjs().add(
                  checkoutData?.reservationPeriod === undefined
                    ? 60
                    : checkoutData?.reservationPeriod,
                  "days"
                )}
                value={selectedDate}
                onChange={(date) => {
                  if (date) {
                    setSelectedDate(date);
                  }
                }}
              />
            </Box>

            <Box pt={2}>
              {productScheduleByIdQuery.isLoading
                ? _renderLoadingSchedules()
                : _renderSchedules()}
            </Box>
          </Box>
        ),
      },
      {
        label: "Request Tim Member (Optional)",
        optional: (
          <Typography variant="caption">
            lewati jika tidak ada preferensi. (dapat diubah oleh admin){" "}
          </Typography>
        ),
        content: (
          <Box display="flex" flexDirection="column" gap={3}>
            {productScheduleByIdQuery.isLoading && "loading...."}
            {cartScheduleData.map(({ date, schedules }) => {
              return (
                <Box key={date} display="flex" flexDirection="column" gap={1}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    📅{" "}
                    {dayjs(date, TIME_FORMAT.DDMMYYYY).format(
                      TIME_FORMAT.dddDDMMMYYYY
                    )}
                  </Typography>
                  {schedules.map((schedule) => {
                    const startTime = dayjs(
                      schedule.startTime,
                      TIME_FORMAT.HHmm
                    ).format(TIME_FORMAT.HHmm);
                    const endTime = dayjs(
                      schedule.endTime,
                      TIME_FORMAT.HHmm
                    ).format(TIME_FORMAT.HHmm);
                    return (
                      <Box
                        key={schedule.startTime + schedule.endTime}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography fontWeight={600}>
                          {startTime} - {endTime}{" "}
                          <Typography variant="caption">
                            (dapat diubah oleh admin)
                          </Typography>
                        </Typography>
                        <TeamMemberSelector
                          companyID={data.companyID}
                          productID={productId || ""}
                          selectedId={
                            scheduleDetailsWithQuantities[date]?.[
                              schedule.index
                            ]?.teamMemberId || ""
                          }
                          onClickTeamMember={(member) => {
                            handleTeamMemberChange(
                              date,
                              schedule.index,
                              member.teamMemberID,
                              member.name
                            );
                          }}
                        />
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          </Box>
        ),
      },
    ];
    if (checkoutData?.title) {
      steps[0].label = checkoutData?.title;
    }
    if (selectedSchedules.length === 0) {
      steps = steps.filter((step) => step?.label !== "Request Tim Member");
    }
    return steps;
  }, [
    _renderSchedules,
    cartScheduleData,
    checkoutData?.checkoutDesc,
    checkoutData?.checkoutTitle,
    checkoutData?.reservationPeriod,
    checkoutData?.title,
    data.color,
    data.companyID,
    errors,
    handleBlur,
    handleTeamMemberChange,
    isWhatsAppRequired,
    paymentReceipent.email,
    paymentReceipent.name,
    paymentReceipent.phone,
    productId,
    productScheduleByIdQuery.isLoading,
    scheduleDetailsWithQuantities,
    selectedDate,
    selectedSchedules.length,
  ]);
  const _renderCartModal = () => {
    return (
      <CheckoutCartModal
        modalProps={{ open: cartOpen, onClose: () => setCartOpen(false) }}
        cartScheduleData={cartScheduleData}
        onQuantityChange={handleQuantityChange}
        onConfirm={() => {
          const hasError = validateError();
          setActiveStep(steps.length - 1);
          setCartOpen(false);
          if (hasError) return;
          debouncedSubmit();
        }}
        onBack={() => {
          setCartOpen(false);
        }}
      />
    );
  };
  return (
    <DynamicPageContainer backgroundColor={data.color}>
      {_renderCartModal()}
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          color: FONT_COLOR,
          backgroundColor: "white",
        }}
      >
        <Box
          sx={{ width: "100%", aspectRatio: "16 / 9", position: "relative" }}
        >
          <img
            className="img-default"
            style={{ objectFit: "cover" }}
            src={checkoutData?.checkoutImg || "https://picsum.photos/200/300"}
            alt="product-img"
          />
          <OpacityButton
            onClick={() => {
              navigate(-1);
            }}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              top: "20px",
              position: "absolute",
              left: "20px",
              backgroundColor: data.color,
              color: FONT_COLOR,
            }}
          >
            <FaCaretLeft size={16} style={{ paddingRight: "2px" }} />
          </OpacityButton>
          <OpacityButton
            onClick={() => {
              navigator.clipboard.writeText(window.location.href);
              enqueueSnackbar({
                ...DEFAULT_SNACKBAR_PROPS,
                variant: "success",
                message: `Link siap dibagikan`,
                autoHideDuration: 1000,
              });
            }}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              top: "20px",
              position: "absolute",
              right: "20px",
              backgroundColor: data.color,
              color: FONT_COLOR,
            }}
          >
            <IoMdShare size={16} style={{ paddingRight: "2px" }} />
          </OpacityButton>
        </Box>

        <Box display="flex" flexDirection="column" px={2} pt={2} pb={5}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel optional={step.optional}>{step.label}</StepLabel>
                <StepContent>
                  {step.content}
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    flexWrap="wrap"
                    pt={2}
                  >
                    <CustomButton
                      variant="outlined"
                      disabled={
                        index === 0 || createNewTransactionMutation.isLoading
                      }
                      onClick={handleBack}
                      sx={{ width: "fit-content" }}
                    >
                      Kembali
                    </CustomButton>
                    <CustomButton
                      sx={{ width: "fit-content" }}
                      onClick={(e) => {
                        handleNext();
                      }}
                      disabled={
                        createNewTransactionMutation.isLoading ||
                        (step.label === "Informasi Pembeli" &&
                          incompletePaymentReceipent) ||
                        (step.label === "Pilih Jadwal" &&
                          (productScheduleData.scheduleDetails.length === 0 ||
                            isScheduleEmpty(scheduleDetailsWithQuantities)))
                      }
                    >
                      {index === steps.length - 1
                        ? checkoutData?.checkoutCTABtn || "Beli sekarang"
                        : "Lanjut"}
                    </CustomButton>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
    </DynamicPageContainer>
  );
};

export default D2Checkout;
