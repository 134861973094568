import { forwardRef, useEffect, useState } from "react";
import {
  BottomSheet,
  BottomSheetProps,
  BottomSheetRef,
} from "react-spring-bottom-sheet";
import { Box, SxProps } from "@mui/material";
import OpacityButton from "../OpacityButton/OpacityButton";
import { Theme } from "@emotion/react";
import { IoMdClose } from "react-icons/io";

export interface ActionBottomSheetProps {
  bottomSheetProps?: Omit<BottomSheetProps, "children" | "open" | "ref">;
  open: boolean;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  onClose?: () => void;
}
const ActionBottomSheet = forwardRef<BottomSheetRef, ActionBottomSheetProps>(
  ({ bottomSheetProps, ...props }, ref) => {
    const withCloseButton = props.onClose !== undefined;
    const [unmount, setUnmount] = useState(false);

    useEffect(() => {
      if (props.open) {
        setUnmount(false);
      }
    }, [props.open])
    if (unmount) {
      return null;
    }
    return (
      <BottomSheet ref={ref} open={props.open} {...bottomSheetProps} onSpringCancel={() => setUnmount(true)}>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          height="100%"
          pb={12}
          sx={props.sx}
          role="dialog"
          aria-modal="true"
        >
          {withCloseButton && (
            <OpacityButton
              component="span"
              sx={{
                borderRadius: 2,
                display: 'flex',
                alignSelf: 'flex-end',
                width: 'fit-content',
                aspectRatio: 1,
                p: 1,
                marginRight: 2,
              }}
              onClick={props.onClose}
            >
              <IoMdClose size={18} />
            </OpacityButton>
          )}
          {props.children}
        </Box>
      </BottomSheet>
    );
  }
);

export default ActionBottomSheet;
