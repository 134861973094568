import React, { useEffect, useMemo, useState } from "react";
import { COLOR } from "../../utils/color";
import ModalTemplate, { ModalTemplateProps } from "./ModalTemplate";
import { Box, Typography } from "@mui/material";
import CustomButton from "../CustomButton/CustomButton";
import SingleSelectRawOption from "../Forms/SingleSelect/SingleSelectRawOption";
import InputTitle from "../Forms/InputTitle/InputTitle";
import { formatNumber, getStatusTextPropsByStatus } from "../../utils/helper";
import NumberInput from "../Forms/NumberInput/NumberInput";
import MultiCheckboxSelect from "../Forms/MultiCheckboxSelect/MultiCheckboxSelect";
import { useSelector } from "react-redux";
import { RootReducerState } from "../../redux/reducers";
import { useGetProductReservationCompactList } from "../../query/queries";
import { PAYMENT_STATUS } from "../../types/globalTypes";

export type SaleFilterData = {
  status: PAYMENT_STATUS | "all";
  startAmount: string;
  endAmount: string;
  items: Array<string>;
};
interface SaleFilterModalProps {
  modalProps: Omit<ModalTemplateProps, "children">;
  onApply: (filter: SaleFilterData) => void;
  initialData: SaleFilterData;
}

const SaleFilterModal: React.FC<SaleFilterModalProps> = ({
  modalProps,
  onApply,
  initialData,
}) => {
  const DEFAULT_FILTER: SaleFilterData = {
    status: "all",
    startAmount: "",
    endAmount: "",
    items: [],
  };
  const [saleFilterData, setSaleFilterData] =
    useState<SaleFilterData>(DEFAULT_FILTER);

  const [
    selectedProductReservationListOptions,
    setSelectedProductReservationListOptions,
  ] = useState<Array<{ label: string; value: string }>>([]);

  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );
  const companyId = userReducer.data.companyId;

  const productReservationListQuery = useGetProductReservationCompactList(
    companyId || ""
  );

  const [productReservationListOptions, productReservationListMap] =
    useMemo(() => {
      const productReservationMap: { [id: string]: string } = {};
      const productReservationArr: Array<{ label: string; value: string }> = [];
      (productReservationListQuery.data?.data?.data || []).forEach((item) => {
        productReservationMap[item.productID] = item.productName;
        productReservationArr.push({
          label: item.productName,
          value: item.productID,
        });
      });
      return [productReservationArr, productReservationMap];
    }, [productReservationListQuery.data?.data]);

  const handleOnApply = () => {
    onApply(saleFilterData);
  };

  const handleResetFilter = () => {
    setSaleFilterData(DEFAULT_FILTER);
  };
  useEffect(() => {
    if (modalProps.open) {
      setSaleFilterData((prev) => ({
        ...prev,
        ...initialData,
        items: [...initialData.items],
      }));
    }
  }, [modalProps.open, initialData]);

  return (
    <ModalTemplate
      desktopWidth="500px"
      desktopHeight="80vh"
      {...modalProps}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...modalProps.sx,
      }}
      header={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
            flex: 1,
            borderBottom: `1px solid ${COLOR.neutral300}`,
            gap: 1,
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Filter Pencarian
          </Typography>
        </Box>
      }
      footer={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
            p: 2,
            borderTop: `1px solid ${COLOR.neutral300}`,
          }}
        >
          <CustomButton variant="text-secondary" onClick={handleResetFilter}>
            Reset
          </CustomButton>
          <CustomButton onClick={handleOnApply}>Terapkan</CustomButton>
        </Box>
      }
    >
      <Box px={2} pt={2} py={3}>
        <InputTitle title="Status" />
        <SingleSelectRawOption
          optionProps={{
            value: saleFilterData.status,
            onChange: (selectedOption) => {
              setSaleFilterData((prev) => ({
                ...prev,
                status: selectedOption as SaleFilterData["status"],
              }));
            },
            options: [
              ...Object.entries(PAYMENT_STATUS)
                .filter((status) =>
                  [
                    PAYMENT_STATUS.PAID,
                    PAYMENT_STATUS.PARTIAL_PAID,
                    PAYMENT_STATUS.UNPAID,
                  ].includes(status[1])
                )
                .map(([key, value]) => ({
                  label: getStatusTextPropsByStatus(value as PAYMENT_STATUS)
                    .children as string,
                  value: value as PAYMENT_STATUS,
                })),
              { label: "Semua", value: "all" },
            ],
          }}
        />

        <NumberInput
          title="Jumlah (Dari)"
          startEndorment={"IDR"}
          formatFunction={(value) => formatNumber(Number(value))}
          numberInputProps={{
            value: String(saleFilterData.startAmount),
            onChange: (e) => {
              setSaleFilterData((prev) => ({
                ...prev,
                startAmount: e.target.value,
              }));
            },
          }}
        />
        <NumberInput
          title="Jumlah (Hingga)"
          startEndorment={"IDR"}
          formatFunction={(value) => formatNumber(Number(value))}
          numberInputProps={{
            value: String(saleFilterData.endAmount),
            onChange: (e) => {
              setSaleFilterData((prev) => ({
                ...prev,
                endAmount: e.target.value,
              }));
            },
          }}
        />

        <MultiCheckboxSelect
          title="Produk/Jasa"
          options={productReservationListOptions}
          selectInputProps={{
            value: saleFilterData.items,
            onChange: (items) => {
              setSaleFilterData((prev) => {
                return {
                  ...prev,
                  items: items as unknown as string[],
                };
              });
            },
            placeholder: "Pilih Jenis Produk/Jasa",
          }}
          helper={{ text: "Kamu bisa memilih lebih dari satu." }}
        />
        {/* <FormButton
          title="Produk/Jasa"
          endEndorment={<span className="arrow-down" />}
          FormButtonProps={{
            onClick: () => { },
          }}
        >
          Pilih Jenis Produk/Jasa
        </FormButton> */}
      </Box>
    </ModalTemplate>
  );
};

export default SaleFilterModal;
