import { useRef, useEffect, useCallback } from "react";
import { debounce } from "lodash";

/**
 * A reusable debounce hook that prevents a function from being called too frequently.
 * @param fn The function to be debounced.
 * @param delay The debounce delay in milliseconds.
 * @returns A debounced version of the function.
 */
const useDebouncedFunction = <T extends (...args: any[]) => void>(
  fn: T,
  delay: number
) => {
  const fnRef = useRef(fn);
  const debouncedFn = useRef(debounce((...args: Parameters<T>) => fnRef.current(...args), delay));

  useEffect(() => {
    fnRef.current = fn;
  }, [fn]);

  useEffect(() => {
    const currentDebouncedFn = debouncedFn.current;
    return () => {
      currentDebouncedFn.cancel();
    };
  }, [delay]);

  return useCallback((...args: Parameters<T>) => {
    debouncedFn.current(...args);
  }, []);
};

export default useDebouncedFunction;
