import { Navigate, Outlet } from "react-router-dom";
import useTokenCheck from "../hooks/useTokenCheck";

interface RouteWrapperPropsI {
  to: string;
}
const RouteWrapper = (props: RouteWrapperPropsI) => {
  const [isLoggedIn] = useTokenCheck();
  if (!isLoggedIn) {
    return <Outlet />;
  }

  return <Navigate to={props.to} />;
};
export default RouteWrapper;
