import React, { useState } from "react";
import { FormBaseProps } from "../../../types/globalTypes";
import InputTitle from "../InputTitle/InputTitle";
import HelperText from "../HelperText/HelperText";
import { textAreaContainnerStyle, textAreaDisabledContainerStyle, textAreaErrorContainerStyle, textAreaFocusedContainerStyle } from "../form.styles";
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';
import { Box, SxProps } from "@mui/material";
import { Theme } from "@emotion/react";

interface TextAreaPropsI extends FormBaseProps {
  textAreaProps?: TextareaAutosizeProps;
  disabled?: boolean;
  error?: boolean;
  sx?: SxProps<Theme>;
  additionalPrefix?: React.ReactNode;
}

const TextArea: React.FC<TextAreaPropsI> = (props) => {
  const { title, required, helper, disabled = false, error = false, textAreaProps, sx } = props;

  const [isFocused, setIsFocused] = useState<boolean>(false);
  return (
    <Box sx={{ ...sx, display: 'flex', flexDirection: 'column' }}>
      <InputTitle
        title={title}
        required={required}
        textProps={{
          sx: { mb: 1 }
        }}
        prefix={props?.additionalPrefix}
      />
      <TextareaAutosize
        minRows={3}
        maxRows={5}
        {...textAreaProps}
        onFocus={(e) => {
          setIsFocused(true);
          textAreaProps?.onFocus?.(e);
        }}
        onBlur={(e) => {
          setIsFocused(false);
          textAreaProps?.onBlur?.(e);
        }}
        style={({
          ...textAreaContainnerStyle,
          ...(disabled ? textAreaDisabledContainerStyle : {}),
          ...(isFocused ? textAreaFocusedContainerStyle : {}),
          ...(error ? textAreaErrorContainerStyle : {}),
          ...textAreaProps?.style
        }) as any}
      />
      <HelperText {...helper} />
    </Box>
  );
};

export default TextArea;
