import React, {
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  Box,
  Switch,
  Tab,
  Tabs,
  Typography,
  CircularProgress,
} from "@mui/material";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_SNACKBAR_PROPS,
  DURATION_PERIOD,
  FONT_COLOR,
  PRODUCT_TYPE,
  ROUTE_NAME,
  RoutePath,
  TAB_ENUM,
  THUMB_STYLE,
  TIMEZONE_SHORTLABEL,
  TIMEZONES,
  UNIT_TEXT,
} from "../../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "../../../redux/reducers";
import CustomMDXEditor from "../../../components/CustomTextEditor/CustomMarkdownEditor";
import NumberedTitle from "../../../components/NumberedTitle/NumberedTitle";
import BoxStyle from "../../../components/BoxStyle/BoxStyle";
import {
  convertProductScheduleToManagedSchedule,
  convertSchedulesToList,
  fileToBase64,
  formatNumber,
  generateTimeOptions,
} from "../../../utils/helper";
import { COLOR } from "../../../utils/color";
import TextInput from "../../../components/Forms/TextInput/TextInput";
import {
  ProductScheduleData,
  DEFAULT_PRODUCT_DATA,
  initialState,
  setData,
  resetProductScheduleData,
  setScheduleData,
} from "../../../redux/reducers/product";
import { FieldContainer, PreviewContainer } from "./Product.styles";
import { enqueueSnackbar } from "notistack";
import { openDialog } from "../../../redux/reducers/confirmationDialog";
import SingleImageUploader from "../../../components/SingleImageUploader/SingleImageUploader";
import ImageCropDialog from "../../../components/ImageCropDialog/imageCropDialog";
import NumberInput from "../../../components/Forms/NumberInput/NumberInput";
import PhoneIcon from "../../../assets/svg/PhoneIcon";
import { MdFormatColorText } from "react-icons/md";
import { FiMail } from "react-icons/fi";
import SingleSelect from "../../../components/Forms/SingleSelect/SingleSelect";
import InputTitle from "../../../components/Forms/InputTitle/InputTitle";
import HelperText from "../../../components/Forms/HelperText/HelperText";
import ScheduleTable from "../../../components/Tables/ScheduleTables";
import { LuPencil } from "react-icons/lu";
import {
  useGetCompanyByIdV2,
  useGetProductReservationByIdV2,
  useGetResourceCompact,
  useGetTeamMembers,
} from "../../../query/queries";
import ThumbnailButton from "../../../components/ThumbnailButton/ThumbnailButton";
import dayjs from "dayjs";
import {
  ProductTabType,
  SCHEDULE_TYPE,
  CurrencyCodeType,
  Schedule,
  TimeSlot,
} from "../../../types/globalTypes";
import ScheduleBottomSheet from "../../../components/BottomSheets/ScheduleBottomSheet";
import { compressImage } from "../../../utils/image";
import {
  useCreateProductReservation,
  useDeleteProductById,
  useDeleteResource,
  useUpdateProductReservationV2,
  useUploadToStorageMutation,
} from "../../../query/mutations";
import { errorLogger } from "../../../utils/logger";
import ProductFooter from "../../../components/Footer/ProductFooter";
import { BiCalendar, BiSolidMessageDetail } from "react-icons/bi";
import { RxButton } from "react-icons/rx";
import { ManageSchedulesType } from "../../../context/ScheduleContext";
import { CheckboxButton } from "../../../components/ChecboxButton/CheckboxButton";
import {
  GetTeamMembers,
  ProductReservation,
  TeamMemberStatus,
} from "../../../api/request.types";
import { DAY } from "../../../utils/date";
import ServiceResourcesCheckbox from "../../../components/ServiceResourceCheckbox/ServiceResourceCheckbox";
import { isAxiosError } from "axios";

enum IMG_CROP_KEY {
  THUMB = "thumb",
  CHECKOUT = "checkout",
}

type ImgCropStateType = {
  img?: string;
  open: boolean;
  aspectRatio: { width: number; height: number };
};
interface ScheduleProductPropsI {
  productType: PRODUCT_TYPE;
  productId: string;
  tabs: Array<ProductTabType>;
  tabValue: TAB_ENUM;
  onChangeTab: (_: React.SyntheticEvent, newValue: TAB_ENUM) => void;
}
const ScheduleProduct = (props: ScheduleProductPropsI) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getTeamMemberQuery = useGetTeamMembers({
    status: TeamMemberStatus.ACTIVE,
  });
  const activeTeamMembers: GetTeamMembers[] = useMemo(() => {
    if (
      !getTeamMemberQuery.isLoading &&
      getTeamMemberQuery.isFetched &&
      getTeamMemberQuery.data
    ) {
      const activeMembers = getTeamMemberQuery.data?.data;
      return activeMembers;
    }
    return [];
  }, [
    getTeamMemberQuery.data,
    getTeamMemberQuery.isFetched,
    getTeamMemberQuery.isLoading,
  ]);

  const handleToggle = (id: string) => {
    if (
      productScheduleData.teamMemberIDs.length === 1 &&
      productScheduleData.teamMemberIDs.includes(id)
    ) {
      return;
    }
    dispatch(
      setData({
        teamMemberIDs: productScheduleData.teamMemberIDs.includes(id)
          ? productScheduleData.teamMemberIDs.filter(
              (memberId) => memberId !== id
            )
          : [...productScheduleData.teamMemberIDs, id],
      })
    );
  };

  const [imageCropState, setImageCropState] = useState<
    Record<IMG_CROP_KEY, ImgCropStateType>
  >({
    [IMG_CROP_KEY.THUMB]: {
      img: undefined,
      open: false,
      aspectRatio: { width: 1, height: 1 },
    },
    [IMG_CROP_KEY.CHECKOUT]: {
      img: undefined,
      open: false,
      aspectRatio: { width: 16, height: 9 },
    },
  });

  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );
  const companyId = userReducer.data.companyId;
  const companyV2Query = useGetCompanyByIdV2(companyId || "");
  const companyV2Detail = useMemo(() => {
    return companyV2Query.data?.data?.data;
  }, [companyV2Query.data?.data?.data]);

  const getResourceCompactQuery = useGetResourceCompact();

  const productV2Query = useGetProductReservationByIdV2(props.productId || "");
  const productDetail = useMemo(() => {
    const defaultData: ProductReservation = {
      productID: "",
      companyID: "",
      thumbStyle: THUMB_STYLE.BUTTON,
      thumbImg: "",
      title: "",
      subtitle: "",
      buttonText: "",
      checkoutImg: "",
      checkoutTitle: "",
      checkoutDesc: "",
      checkoutBottomTitle: "",
      checkoutCTABtn: "",
      price: 0,
      currency: "IDR",
      discount: 0,
      collectField: [
        {
          type: "text",
          value: "Nama Lengkap",
          required: true,
        },
        {
          type: "email",
          value: "Alamat Email",
          required: true,
        },
        {
          type: "phone",
          value: "No Whatsapp",
          required: false,
        },
      ],
      scheduleDetails: {
        [DAY.SUNDAY]: [],
        [DAY.MONDAY]: [],
        [DAY.TUESDAY]: [],
        [DAY.WEDNESDAY]: [],
        [DAY.THURSDAY]: [],
        [DAY.FRIDAY]: [],
        [DAY.SATURDAY]: [],
      },
      scheduleType: SCHEDULE_TYPE.DEFAULT,
      timezone: "Asia/Jakarta",
      scheduleDuration: 0,
      scheduleDurationPeriod: DURATION_PERIOD.MINUTES,
      preventScheduleBefore: 0,
      quota: 0,
      allowBreakBefore: false,
      allowBreakAfter: false,
      allowReschedule: false,
      preventRescheduleBefore: 0,
      breakBefore: 0,
      breakAfter: 0,
      reservationPeriod: 0,
      status: "draft",
      createdBy: "",
      updatedBy: "",
      teamMemberIDs: [],
      resourceIDs: [],
    };
    if (productV2Query.isLoading || !productV2Query.data?.data?.data) {
      return defaultData;
    }
    const productReservationData = productV2Query.data?.data?.data;
    return productReservationData || defaultData;
  }, [productV2Query.data?.data?.data, productV2Query.isLoading]);
  const deleteProductByIdMutation = useDeleteProductById();
  const deleteResourceMutation = useDeleteResource();

  const [sheetState, setSheetState] = useState<{ schedule: boolean }>({
    schedule: false,
  });
  const [isResourceActive, setIsResourceActive] = useState(
    !!productDetail.resourceIDs?.length
  );
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { productScheduleData } = useSelector(
    (state: RootReducerState) => state.productReducer
  );

  const uploadToStorageMutation = useUploadToStorageMutation();
  const createProductReservationMutation = useCreateProductReservation();
  const updateProductReservationMutation = useUpdateProductReservationV2();

  const productReservationQuery = useGetProductReservationByIdV2(
    props.productId
  );
  const productReservation = useMemo(() => {
    return productReservationQuery.data?.data?.data;
  }, [productReservationQuery.data?.data?.data]);

  useEffect(() => {
    if (!productV2Query.isLoading && productV2Query.isFetched) {
      setIsResourceActive(!!productDetail.resourceIDs?.length);
    }
  }, [
    productDetail.resourceIDs,
    productV2Query.isFetched,
    productV2Query.isLoading,
  ]);

  const handleCropClose = (key: IMG_CROP_KEY) => () => {
    setImageCropState((prev) => ({
      ...prev,
      [key]: { ...prev[key], open: false },
    }));
  };

  const handleCropComplete =
    (key: IMG_CROP_KEY) => async (croppedImg: string) => {
      try {
        if (!companyId) return;
        const blob = await fetch(croppedImg).then((res) => res.blob());
        const croppedFile = new File(
          [blob],
          `product-${dayjs().toISOString()}.jpg`,
          { type: blob.type }
        );
        const response = await uploadToStorageMutation.mutateAsync({
          companyId,
          image: croppedFile,
          usage: "product",
        });
        const imgUrl = response.data.data;
        switch (key) {
          case IMG_CROP_KEY.THUMB:
            dispatch(setData({ thumbImg: imgUrl[0] }));
            break;
          case IMG_CROP_KEY.CHECKOUT:
            dispatch(setData({ checkoutImg: imgUrl[0] }));
            break;
        }
        handleCropClose(key)();
      } catch (error) {
        errorLogger(error);
      }
    };

  const handleCreateProductReservation = async (status: "active" | "draft") => {
    await createProductReservationMutation.mutateAsync({
      companyID: companyId || "",
      thumbStyle: productScheduleData.thumbStyle,
      thumbImg: productScheduleData.thumbImg,
      title:
        productScheduleData.thumbStyle === THUMB_STYLE.BUTTON
          ? productScheduleData.buttonText
          : productScheduleData.title,
      subtitle: productScheduleData.subtitle,
      buttonText: productScheduleData.buttonText,
      checkoutImg: productScheduleData.checkoutImg,
      checkoutTitle: productScheduleData.checkoutTitle,
      checkoutDesc: productScheduleData.checkoutDesc,
      checkoutBottomTitle: productScheduleData.checkoutBottomTitle,
      checkoutCTABtn: productScheduleData.checkoutCTABtn,
      price: productScheduleData.price,
      currency: productScheduleData.currency,
      discount: productScheduleData.discount,
      collectField: productScheduleData.collectField,
      scheduleDetails: productScheduleData.schedules,
      scheduleType: productScheduleData.scheduleType,
      timezone: productScheduleData.timezone,
      scheduleDuration: productScheduleData.scheduleDuration,
      scheduleDurationPeriod: productScheduleData.scheduleDurationPeriod,
      preventScheduleBefore: productScheduleData.preventScheduleBefore,
      quota: productScheduleData.quota,
      allowBreakBefore: productScheduleData.allowBreakBefore,
      allowBreakAfter: productScheduleData.allowBreakAfter,
      breakBefore: productScheduleData.breakBefore,
      breakAfter: productScheduleData.breakAfter,
      reservationPeriod: productScheduleData.reservationPeriod,
      status: status,
      allowReschedule: productScheduleData.allowReschedule,
      preventRescheduleBefore: productScheduleData.preventRescheduleBefore,
      teamMemberIDs: productScheduleData.teamMemberIDs,
      resourceIDs: isResourceActive ? productScheduleData.resourceIDs : [],
    });
    dispatch(resetProductScheduleData());
    navigate(RoutePath[ROUTE_NAME.BUSINESS], { replace: true });
  };

  const handleUpdateProductReservation = async (status: "active" | "draft") => {
    await updateProductReservationMutation.mutateAsync({
      productId: props.productId,
      thumbStyle: productScheduleData.thumbStyle,
      thumbImg: productScheduleData.thumbImg,
      title:
        productScheduleData.thumbStyle === THUMB_STYLE.BUTTON
          ? productScheduleData.buttonText
          : productScheduleData.title,
      subtitle: productScheduleData.subtitle,
      buttonText: productScheduleData.buttonText,
      checkoutImg: productScheduleData.checkoutImg,
      checkoutTitle: productScheduleData.checkoutTitle,
      checkoutDesc: productScheduleData.checkoutDesc,
      checkoutBottomTitle: productScheduleData.checkoutBottomTitle,
      checkoutCTABtn: productScheduleData.checkoutCTABtn,
      price: productScheduleData.price,
      currency: productScheduleData.currency,
      discount: productScheduleData.discount,
      collectField: productScheduleData.collectField,
      scheduleDetails: productScheduleData.schedules,
      scheduleType: productScheduleData.scheduleType,
      timezone: productScheduleData.timezone,
      scheduleDuration: productScheduleData.scheduleDuration,
      scheduleDurationPeriod: productScheduleData.scheduleDurationPeriod,
      preventScheduleBefore: productScheduleData.preventScheduleBefore,
      quota: productScheduleData.quota,
      allowBreakBefore: productScheduleData.allowBreakBefore,
      allowBreakAfter: productScheduleData.allowBreakAfter,
      breakBefore: productScheduleData.breakBefore,
      breakAfter: productScheduleData.breakAfter,
      reservationPeriod: productScheduleData.reservationPeriod,
      status: status,
      allowReschedule: productScheduleData.allowReschedule,
      preventRescheduleBefore: productScheduleData.preventRescheduleBefore,
      teamMemberIDs: productScheduleData.teamMemberIDs,
      resourceIDs: isResourceActive ? productScheduleData.resourceIDs : [],
    });
    dispatch(resetProductScheduleData());
    navigate(RoutePath[ROUTE_NAME.BUSINESS], { replace: true });
  };
  const initializeData = useCallback(() => {
    if (props.productId === "new") {
      dispatch(
        setData({
          ...DEFAULT_PRODUCT_DATA,
        })
      );
    } else {
      dispatch(
        setData({
          ...DEFAULT_PRODUCT_DATA,
          ...productDetail,
          schedules:
            productDetail?.scheduleDetails || DEFAULT_PRODUCT_DATA.schedules,
          allowReschedule: productDetail?.allowReschedule || false,
          preventRescheduleBefore: productDetail?.preventRescheduleBefore || 24,
        })
      );
    }
  }, [dispatch, productDetail, props.productId]);
  useEffect(initializeData, [initializeData]);

  const handleDeleteProduct = async () => {
    try {
      await deleteProductByIdMutation.mutateAsync({
        productId: props.productId,
        type: props.productType,
      });
      enqueueSnackbar(
        `Product "${productScheduleData?.title || ""}" deleted successfully`,
        {
          variant: "success",
          ...DEFAULT_SNACKBAR_PROPS,
        }
      );
      await companyV2Query.refetch();
      navigate(RoutePath[ROUTE_NAME.BUSINESS], { replace: true });
    } catch (error) {
      errorLogger(error);
      enqueueSnackbar(
        "Gagal menghapus product. mohon coba beberapa saat lagi.",
        {
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "error",
        }
      );
    }
  };

  const handleToggleResource = () => {
    const newIsResourceActive = !isResourceActive;
    setIsResourceActive(newIsResourceActive);
    // dispatch(
    //   setData({
    //     resourceIDs: newIsResourceActive
    //       ? productScheduleData.resourceIDs || []
    //       : [],
    //   })
    // );
  };
  const updateScheduleOnPriceChange = useCallback(() => {
    const price =
      productScheduleData?.discount > 0
        ? productScheduleData?.discount || productScheduleData.price
        : productScheduleData.price;
    const newSchedules = Object.keys(productScheduleData.schedules).reduce(
      (newSchedule, day) => {
        const existingSchedule = productScheduleData.schedules[day];
        newSchedule[day] = existingSchedule.map((schedule) => ({
          ...schedule,
          price,
        }));
        return newSchedule;
      },
      {} as Schedule
    );
    dispatch(setScheduleData(newSchedules));
  }, [productScheduleData?.discount, productScheduleData.price, dispatch]);

  useEffect(updateScheduleOnPriceChange, [updateScheduleOnPriceChange]);

  const handleOnChangeImg =
    (key: IMG_CROP_KEY) =>
    async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.target instanceof HTMLInputElement && e.target.files) {
        const file = e.target.files[0];
        if (file) {
          try {
            const compressedImg = await compressImage(file);
            const base64Image = await fileToBase64(compressedImg);

            setImageCropState((prev) => ({
              ...prev,
              [key]: {
                ...prev[key],
                open: true,
                img: base64Image,
              },
            }));
          } catch (error) {
            enqueueSnackbar("Error Preview Image", {
              ...DEFAULT_SNACKBAR_PROPS,
              variant: "error",
            });
          }
        }
      }
    };

  const renderTabContent = (tabValue: TAB_ENUM) => {
    if (productV2Query.isLoading)
      return (
        <Box py={2} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" />
        </Box>
      );
    switch (tabValue) {
      case TAB_ENUM.THUMB:
        return (
          <Box py={2} display="flex" flexDirection="column" gap={2}>
            <Box sx={FieldContainer}>
              <NumberedTitle num={1} text="Pilih Gaya" />
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={2}
                mt={2}
                mb={5}
              >
                <BoxStyle
                  selected={
                    productScheduleData.thumbStyle === THUMB_STYLE.BUTTON
                  }
                  onClick={() => {
                    dispatch(
                      setData({
                        thumbStyle: THUMB_STYLE.BUTTON,
                      })
                    );
                  }}
                >
                  <RxButton size={20} />
                  <Typography>Button</Typography>
                </BoxStyle>
                <BoxStyle
                  selected={
                    productScheduleData.thumbStyle === THUMB_STYLE.CALLOUT
                  }
                  onClick={() => {
                    dispatch(
                      setData({
                        thumbStyle: THUMB_STYLE.CALLOUT,
                      })
                    );
                  }}
                >
                  <BiSolidMessageDetail size={20} />
                  <Typography>Callout</Typography>
                </BoxStyle>
                <BoxStyle
                  selected={
                    productScheduleData.thumbStyle === THUMB_STYLE.PREVIEW
                  }
                  onClick={() => {
                    dispatch(
                      setData({
                        thumbStyle: THUMB_STYLE.PREVIEW,
                      })
                    );
                  }}
                >
                  <BiCalendar size={20} />
                  <Typography>Preview</Typography>
                </BoxStyle>
              </Box>
            </Box>

            <Box sx={FieldContainer}>
              <NumberedTitle num={2} text="Pilih Gambar" />
              <Box mb={5}>
                <SingleImageUploader
                  fileInputRef={fileInputRef}
                  img={productScheduleData.thumbImg}
                  onChange={handleOnChangeImg(IMG_CROP_KEY.THUMB)}
                  onClickRemove={() => {
                    dispatch(
                      setData({
                        thumbImg: initialState.productScheduleData.thumbImg,
                      })
                    );
                  }}
                />
              </Box>
            </Box>
            <Box sx={FieldContainer}>
              <NumberedTitle num={3} text="Tambah Text" />
              <Box display="flex" flexDirection="column" gap={2}>
                {productScheduleData.thumbStyle !== THUMB_STYLE.BUTTON && (
                  <Box display="flex" flexDirection="column">
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Typography fontWeight={600}>Title</Typography>
                      <Typography color={COLOR.neutral400} variant="caption">
                        {productScheduleData.title.length}/50
                      </Typography>
                    </Box>
                    <TextInput
                      textInputProps={{
                        value: productScheduleData.title,
                        onChange: (e) => {
                          if (e.target.value.length > 50) return;
                          dispatch(
                            setData({
                              title: e.target.value,
                            })
                          );
                        },
                      }}
                    />
                  </Box>
                )}

                {productScheduleData.thumbStyle !== THUMB_STYLE.BUTTON && (
                  <Box display="flex" flexDirection="column">
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Typography fontWeight={600}>Subtitle</Typography>
                      <Typography color={COLOR.neutral400} variant="caption">
                        {productScheduleData.subtitle.length}/100
                      </Typography>
                    </Box>
                    <TextInput
                      textInputProps={{
                        value: productScheduleData.subtitle,
                        onChange: (e) => {
                          if (e.target.value.length > 100) return;
                          dispatch(
                            setData({
                              subtitle: e.target.value,
                            })
                          );
                        },
                      }}
                    />
                  </Box>
                )}

                <Box display="flex" flexDirection="column">
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography fontWeight={600}>Button</Typography>
                    <Typography color={COLOR.neutral400} variant="caption">
                      {productScheduleData.buttonText.length}/30
                    </Typography>
                  </Box>
                  <TextInput
                    textInputProps={{
                      value: productScheduleData.buttonText,
                      onChange: (e) => {
                        if (e.target.value.length > 30) return;
                        dispatch(
                          setData({
                            buttonText: e.target.value,
                          })
                        );
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              pt={1}
              pb={2}
              px={2}
            >
              <Typography pb={1} fontWeight={600} variant="h5">
                Preview
              </Typography>
              <Box
                sx={{
                  ...PreviewContainer,
                  backgroundColor: companyV2Detail?.color || COLOR.primary500,
                }}
              >
                <ThumbnailButton
                  type={productScheduleData.thumbStyle}
                  primaryColor={companyV2Detail?.color || COLOR.primary500}
                  baseColor={FONT_COLOR}
                  data={{
                    img: productScheduleData.thumbImg,
                    title: productScheduleData.title,
                    subtitle: productScheduleData.subtitle,
                    buttonText: productScheduleData.buttonText,
                    price: productScheduleData.price,
                    discount: productScheduleData.discount,
                    currency: companyV2Detail?.currency || "IDR",
                    selectedDate: dayjs(),
                  }}
                />
              </Box>
            </Box>
            <ImageCropDialog
              imageUrl={imageCropState[IMG_CROP_KEY.THUMB]?.img}
              open={!!imageCropState[IMG_CROP_KEY.THUMB]?.open}
              onClose={handleCropClose(IMG_CROP_KEY.THUMB)}
              aspectRatio={imageCropState[IMG_CROP_KEY.THUMB].aspectRatio}
              onCropComplete={handleCropComplete(IMG_CROP_KEY.THUMB)}
            />
          </Box>
        );
      case TAB_ENUM.CHECKOUT:
        return (
          <Box pt={2} pb={5}>
            <Box display="flex" flexDirection="column" gap={2} mb={5}>
              <Box sx={FieldContainer}>
                <NumberedTitle num={1} text="Pilih Gambar" />
                <SingleImageUploader
                  fileInputRef={fileInputRef}
                  img={productScheduleData.checkoutImg}
                  onChange={handleOnChangeImg(IMG_CROP_KEY.CHECKOUT)}
                  onClickRemove={() => {
                    dispatch(
                      setData({
                        checkoutImg:
                          initialState.productScheduleData.checkoutImg,
                      })
                    );
                  }}
                />
              </Box>
            </Box>
            <Box sx={FieldContainer} mb={5}>
              <NumberedTitle num={2} text="Tulis Deskripsi" />
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography fontWeight={600}>Description Title*</Typography>
                <Typography color={COLOR.neutral400} variant="caption">
                  {productScheduleData.checkoutTitle.length}/100
                </Typography>
              </Box>
              <TextInput
                textInputProps={{
                  value: productScheduleData.checkoutTitle,
                  onChange: (e) => {
                    if (e.target.value.length > 100) return;
                    dispatch(
                      setData({
                        checkoutTitle: e.target.value,
                      })
                    );
                  },
                }}
              />
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography fontWeight={600}>Description Body*</Typography>
                <CustomMDXEditor
                  markdown={productScheduleData.checkoutDesc}
                  onChange={(value) => {
                    dispatch(
                      setData({
                        checkoutDesc: value,
                      })
                    );
                  }}
                  companyId={companyId || ""}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={2} mb={5}>
              <Box sx={FieldContainer}>
                <NumberedTitle num={3} text="Buat Harga" />
                <Box display="flex" flexDirection="column" flex={1}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography fontWeight={600}>
                      Harga Awal ({productScheduleData.currency}) *
                    </Typography>
                  </Box>
                  <NumberInput
                    required
                    sx={{ flex: 1 }}
                    startEndorment={"Rp"}
                    formatFunction={(value) => {
                      return formatNumber(Number(value || 0));
                    }}
                    numberInputProps={{
                      value: String(productScheduleData.price),
                      onChange: (e) => {
                        dispatch(
                          setData({
                            price: Number(e.target.value),
                          })
                        );
                      },
                    }}
                  />
                </Box>
                <Box display="flex" flexDirection="column" flex={1}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography fontWeight={600}>
                      Harga Setelah Potong ({productScheduleData.currency})
                    </Typography>
                  </Box>
                  <NumberInput
                    required
                    sx={{ flex: 1 }}
                    startEndorment={"Rp"}
                    formatFunction={(value) => {
                      return formatNumber(Number(value || 0));
                    }}
                    numberInputProps={{
                      value: String(productScheduleData.discount),
                      onChange: (e) => {
                        dispatch(
                          setData({
                            discount: Number(e.target.value),
                          })
                        );
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>

            <Box display="flex" flexDirection="column" gap={2} mb={5}>
              <Box sx={FieldContainer}>
                <NumberedTitle num={4} text="Kumpulan Data" />
                {productScheduleData.collectField.map((fieldProps, index) => {
                  const { type } = fieldProps;

                  switch (type) {
                    case "text":
                    case "email":
                      return (
                        <TextInput
                          key={index}
                          disabled={index < 2}
                          startEndorment={
                            type === "email" ? (
                              <FiMail />
                            ) : (
                              <MdFormatColorText />
                            )
                          }
                          textInputProps={{
                            value: fieldProps.value,
                            onChange: (e) => {
                              const updatedFields = [
                                ...productScheduleData.collectField,
                              ];
                              updatedFields[index] = {
                                ...updatedFields[index],
                                value: e.target.value,
                              };
                              dispatch(
                                setData({
                                  collectField:
                                    updatedFields as ProductScheduleData["collectField"],
                                })
                              );
                            },
                          }}
                        />
                      );
                    case "phone":
                      return (
                        <>
                          <TextInput
                            key={index}
                            disabled={index < 2 || fieldProps.required}
                            endEndorment={
                              <Switch
                                value={fieldProps.required}
                                onChange={() => {
                                  const updatedFields = [
                                    ...productScheduleData.collectField,
                                  ];
                                  updatedFields[index] = {
                                    ...updatedFields[index],
                                    required: !updatedFields[index].required,
                                  };
                                  dispatch(
                                    setData({
                                      collectField:
                                        updatedFields as ProductScheduleData["collectField"],
                                    })
                                  );
                                }}
                              />
                            }
                            startEndorment={
                              <PhoneIcon color={COLOR.neutral400} />
                            }
                            textInputProps={{
                              value: fieldProps.value,
                              onChange: (e) => {
                                const value: string = e.target.value;
                                if (value.length >= 30) return;
                                const updatedFields = [
                                  ...productScheduleData.collectField,
                                ];
                                updatedFields[index] = {
                                  ...updatedFields[index],
                                  value,
                                };
                                dispatch(
                                  setData({
                                    collectField:
                                      updatedFields as ProductScheduleData["collectField"],
                                  })
                                );
                              },
                            }}
                          />
                        </>
                      );
                    default:
                      return null;
                  }
                })}

                {/* <CustomButton variant="text" startEndorment={<FaPlus />}>
                  Kumpulan Data
                </CustomButton> */}
              </Box>
            </Box>
            <ImageCropDialog
              imageUrl={imageCropState[IMG_CROP_KEY.CHECKOUT]?.img}
              open={!!imageCropState[IMG_CROP_KEY.CHECKOUT]?.open}
              onClose={handleCropClose(IMG_CROP_KEY.CHECKOUT)}
              aspectRatio={imageCropState[IMG_CROP_KEY.CHECKOUT].aspectRatio}
              onCropComplete={handleCropComplete(IMG_CROP_KEY.CHECKOUT)}
            />
          </Box>
        );
      case TAB_ENUM.AVAILABILITY:
        const scheduleList = convertSchedulesToList(
          productScheduleData.schedules
        );
        return (
          <Box py={2} display="flex" flexDirection="column" gap={2}>
            <Box sx={FieldContainer}>
              <NumberedTitle num={1} text="Atur Pegaturan" />
              <SingleSelect
                optionProps={{
                  options: Object.values(SCHEDULE_TYPE).map((e) => ({
                    label: e,
                    value: e,
                  })),
                  value: productScheduleData.scheduleType,
                  onChange: (optionValue) => {
                    if (optionValue) {
                      dispatch(
                        setData({
                          scheduleType: optionValue as SCHEDULE_TYPE,
                        })
                      );
                    }
                  },
                }}
              />
              <Box display="flex" flexDirection="row" gap={2}>
                <SingleSelect
                  title="Zona Waktu"
                  disabled
                  sx={{ flex: 1 }}
                  optionProps={{
                    value: productScheduleData.timezone,
                    onChange: (selectedValue?: string) => {
                      if (selectedValue) {
                        dispatch(
                          setData({
                            timezone: selectedValue,
                          })
                        );
                      }
                    },
                    options: TIMEZONES.map((e) => ({
                      label: TIMEZONE_SHORTLABEL[e],
                      value: e,
                    })),
                  }}
                />
                <NumberInput
                  title="Durasi"
                  required
                  sx={{ flex: 1 }}
                  endEndorment={
                    <Typography
                      component="span"
                      width="100%"
                      whiteSpace="nowrap"
                      textTransform="lowercase"
                      color={COLOR.neutral500}
                    >
                      / {UNIT_TEXT[DURATION_PERIOD.MINUTES]}
                    </Typography>
                  }
                  numberInputProps={{
                    value: String(productScheduleData.scheduleDuration),
                    onChange: (e) => {
                      dispatch(
                        setData({
                          scheduleDuration: Number(e.target.value),
                        })
                      );
                    },
                  }}
                />
              </Box>
              <NumberInput
                title="Cegah penjadwalan X jam dari waktu sekarang"
                required
                sx={{ flex: 1 }}
                endEndorment={
                  <Typography
                    component="span"
                    width="100%"
                    whiteSpace="nowrap"
                    textTransform="lowercase"
                    color={COLOR.neutral500}
                  >
                    / {UNIT_TEXT[DURATION_PERIOD.HOURS]}
                  </Typography>
                }
                numberInputProps={{
                  value: String(productScheduleData.preventScheduleBefore),
                  onChange: (e) => {
                    dispatch(
                      setData({
                        preventScheduleBefore: Number(e.target.value),
                      })
                    );
                  },
                }}
              />
              <NumberInput
                title="Jumlah maksimal peserta"
                required
                endEndorment={
                  <Typography
                    component="span"
                    width="100%"
                    whiteSpace="nowrap"
                    textTransform="lowercase"
                    color={COLOR.neutral500}
                  >
                    / Peserta
                  </Typography>
                }
                numberInputProps={{
                  inputProps: {
                    inputMode: "numeric",
                  },
                  value: String(productScheduleData.quota),
                  onChange: (e) => {
                    dispatch(setData({ quota: Number(e.target.value) }));
                  },
                }}
                helper={{
                  text: "Berikan 1+ peserta untuk ikut bergabung dalam jadwal mu.",
                  color: COLOR.neutral500,
                }}
              />
              <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column">
                  <InputTitle title="Selisih sebelum meeting selanjutnya" />
                  <HelperText text="Buat selisih waktu untuk kamu bersiap-siap atau beres-beres untuk pertemuan selanjutnya" />
                </Box>

                <Box display="flex" flexDirection="row" gap={2}>
                  <Box display="flex" flexDirection="column" flex={1}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <InputTitle title="Sebelum Pertemuan" />
                      <Switch
                        checked={productScheduleData.allowBreakBefore}
                        onChange={() => {
                          dispatch(
                            setData({
                              allowBreakBefore:
                                !productScheduleData.allowBreakBefore,
                            })
                          );
                        }}
                      />
                    </Box>
                    <SingleSelect
                      optionProps={{
                        options: generateTimeOptions([
                          15, 45, 60, 90, 120, 150, 180,
                        ]),
                        value: String(productScheduleData.breakBefore),
                        onChange: (selectedOption?: string) => {
                          dispatch(
                            setData({
                              breakBefore: Number(selectedOption),
                            })
                          );
                        },
                      }}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" flex={1}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <InputTitle title="Setelah Pertemuan" />
                      <Switch
                        checked={productScheduleData.allowBreakAfter}
                        onChange={() => {
                          dispatch(
                            setData({
                              allowBreakAfter:
                                !productScheduleData.allowBreakAfter,
                            })
                          );
                        }}
                      />
                    </Box>
                    <SingleSelect
                      optionProps={{
                        options: generateTimeOptions([
                          15, 45, 60, 90, 120, 150, 180,
                        ]),
                        value: String(productScheduleData.breakAfter),
                        onChange: (selectedOption?: string) => {
                          dispatch(
                            setData({
                              breakAfter: Number(selectedOption),
                            })
                          );
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <NumberInput
                title="Jadwal dalam jangka waktu"
                required
                endEndorment={
                  <Typography
                    component="span"
                    width="100%"
                    whiteSpace="nowrap"
                    textTransform="lowercase"
                    color={COLOR.neutral500}
                  >
                    / {UNIT_TEXT[DURATION_PERIOD.DAYS]}
                  </Typography>
                }
                numberInputProps={{
                  inputProps: {
                    inputMode: "numeric",
                  },
                  value: String(productScheduleData.reservationPeriod),
                  onChange: (e) => {
                    dispatch(
                      setData({
                        reservationPeriod: Number(e.target.value),
                      })
                    );
                  },
                }}
              />
              Jadwal anda akan aktif sampai:{" "}
              {dayjs()
                .add(productScheduleData.reservationPeriod, "day")
                .format("DD MMM YYYY")}
              <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column">
                  <InputTitle title="Konfigurasi Penjadwalan Ulang" />
                  <HelperText text="Atur selisih waktu yang dibutuhkan untuk penjadwalan ulang" />
                </Box>

                <Box display="flex" flexDirection="column" gap={2}>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <InputTitle title="Izinkan Penjadwalan Ulang" />
                    <Switch
                      checked={productScheduleData.allowReschedule}
                      onChange={() => {
                        dispatch(
                          setData({
                            allowReschedule:
                              !productScheduleData.allowReschedule,
                          })
                        );
                      }}
                    />
                  </Box>
                  <NumberInput
                    required
                    endEndorment={
                      <Typography
                        component="span"
                        width="100%"
                        whiteSpace="nowrap"
                        textTransform="lowercase"
                        color={COLOR.neutral500}
                      >
                        / {UNIT_TEXT[DURATION_PERIOD.HOURS]}
                      </Typography>
                    }
                    numberInputProps={{
                      inputProps: {
                        inputMode: "numeric",
                      },
                      value: String(
                        productScheduleData.preventRescheduleBefore
                      ),
                      onChange: (e) => {
                        dispatch(
                          setData({
                            preventRescheduleBefore: Number(e.target.value),
                          })
                        );
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={FieldContainer}>
              <NumberedTitle num={2} text="Atur Jadwal Kosong-Mu" />
              <Box display="flex" flexDirection="column">
                <InputTitle
                  title="Jadwal Kosong-Mu"
                  required
                  textProps={{
                    sx: {
                      mb: 1,
                    },
                  }}
                />
                <ScheduleTable schedule={scheduleList} />
                <CustomButton
                  variant="dashed"
                  sx={{ mt: 2 }}
                  startEndorment={<LuPencil size={18} />}
                  onClick={() => {
                    setSheetState((prev) => ({ ...prev, schedule: true }));
                  }}
                >
                  <Typography
                    component="span"
                    fontWeight="inherit"
                    fontSize="inherit"
                  >
                    Atur Jadwal
                  </Typography>
                </CustomButton>
              </Box>
            </Box>

            <Box sx={FieldContainer}>
              <NumberedTitle num={3} text="Anggota tim yang dibutuhkan" />
              <Typography variant="caption" color={COLOR.neutral500}>
                Pilih anggota tim mana yang akan melakukan layanan ini
              </Typography>

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {activeTeamMembers.map((member, idx) => {
                  const isChecked = productScheduleData.teamMemberIDs.includes(
                    member.teamMemberID
                  );

                  return (
                    <CheckboxButton
                      key={`${member.teamMemberID}${idx}`}
                      checked={isChecked}
                      onToggle={() => {
                        handleToggle(member.teamMemberID);
                      }}
                      buttonSx={{ px: 2, py: 1 }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 0.5,
                          alignItems: "flex-start",
                          textAlign: "left",
                        }}
                      >
                        <Typography variant="body2">{member.name}</Typography>
                      </Box>
                    </CheckboxButton>
                  );
                })}
              </Box>
            </Box>
            <Box sx={FieldContainer}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <NumberedTitle
                  num={4}
                  text={`Sumber Daya yang Dibutuhkan (${
                    isResourceActive ? "Aktif" : "Non Aktif"
                  })`}
                />
                <Switch
                  checked={isResourceActive}
                  onChange={handleToggleResource}
                />
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="caption" color={COLOR.neutral500}>
                  Memerlukan sumber daya tertentu saat menjalankan layanan ini
                </Typography>
              </Box>
              <ServiceResourcesCheckbox
                isDisabled={!isResourceActive}
                selectedResources={productScheduleData.resourceIDs || []}
                onChange={(newSelected: string[]) => {
                  dispatch(setData({ resourceIDs: newSelected }));
                }}
                onDelete={(resource) => {
                  dispatch(
                    openDialog({
                      title: "Apakah anda yakin?",
                      message: `Apakah anda yakin untuk menghapus ${resource.resourceName} ini?`,
                      primaryBtn: {
                        text: "Tidak",
                      },
                      secondaryBtn: {
                        text: "Yakin",
                        onClick: async () => {
                          try {
                            await deleteResourceMutation.mutateAsync({
                              companyID: companyId || "",
                              resourceID: resource.resourceID,
                            });
                            enqueueSnackbar(
                              `Resource "${resource.resourceName}" berhasil dihapus`,
                              {
                                variant: "success",
                                ...DEFAULT_SNACKBAR_PROPS,
                              }
                            );
                            await getResourceCompactQuery.refetch();
                            await productReservationQuery.refetch();
                          } catch (error) {
                            if (
                              isAxiosError(error) &&
                              error.response?.status === 409
                            ) {
                              const msg = error.response?.data?.message || "";
                              let toastMsg =
                                "Telah terjadi kesalahan. Silahkan coba lagi.";
                              switch (msg) {
                                case "future-schedule-exists":
                                  toastMsg =
                                    "Sumber Daya ini memiliki jadwal yang akan datang. Silahkan membatalkan jadwal atau mengganti pemilihan sumber daya terlebih dahulu";
                                  break;
                                default:
                                  break;
                              }
                              enqueueSnackbar(toastMsg, {
                                variant: "error",
                                ...DEFAULT_SNACKBAR_PROPS,
                                autoHideDuration: 3000,
                              });
                            }
                          }
                        },
                      },
                    })
                  );
                }}
              />
            </Box>
            <ScheduleBottomSheet
              isCustomPrice={true}
              price={
                productScheduleData.discount > 0
                  ? productScheduleData.discount
                  : productScheduleData.price
              }
              schedules={convertProductScheduleToManagedSchedule(
                productScheduleData.schedules
              )}
              open={sheetState.schedule}
              onApply={(newSchedules: ManageSchedulesType) => {
                const updatedSchedules: ProductScheduleData["schedules"] = {
                  ...productScheduleData.schedules,
                };
                const scheduleKeys = Object.keys(newSchedules);
                scheduleKeys.forEach((scheduleDay) => {
                  const daySchedules = newSchedules[scheduleDay];
                  const schedules: TimeSlot[] = daySchedules.map(
                    (eachSchedule) => ({
                      startTime: eachSchedule.time.startTime,
                      endTime: eachSchedule.time.endTime,
                      price: eachSchedule.price,
                    })
                  );

                  updatedSchedules[scheduleDay] = schedules;
                });
                dispatch(
                  setData({
                    schedules: { ...updatedSchedules },
                  })
                );
                setSheetState((prev) => ({ ...prev, schedule: false }));
              }}
              onCancel={() => {
                setSheetState((prev) => ({ ...prev, schedule: false }));
              }}
            />
          </Box>
        );
      default:
        return null;
    }
  };
  return (
    <Box>
      <Tabs
        value={props.tabValue}
        onChange={props.onChangeTab}
        sx={{
          "& .MuiTabs-scroller": {
            overflow: "scroll !important",
          },
        }}
      >
        {props.tabs.map((tab) => (
          <Tab
            key={tab.id}
            label={tab.title}
            value={tab.id}
            icon={tab.icon}
            iconPosition="start"
          />
        ))}
      </Tabs>
      {renderTabContent(props.tabValue)}
      <ProductFooter
        onClickTrash={() => {
          dispatch(
            openDialog({
              title: "Apakah anda yakin?",
              message: "Apakah anda yakin untuk menghapus produk ini?",
              primaryBtn: {
                text: "Tidak",
                onClick: () => {},
              },
              secondaryBtn: {
                text: "Ya, Saya Yakin",
                onClick: () => handleDeleteProduct(),
              },
            })
          );
        }}
        onClickDraft={() =>
          props.productId !== "new"
            ? handleUpdateProductReservation("draft")
            : handleCreateProductReservation("draft")
        }
        onClickPublish={() =>
          props.productId !== "new"
            ? handleUpdateProductReservation("active")
            : handleCreateProductReservation("active")
        }
        productId={props.productId}
        productStatus={productDetail?.status || "active"}
      />
    </Box>
  );
};

export default ScheduleProduct;
