import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { ROUTE_NAME, RoutePath } from '../utils/constant';
import useTokenCheck from '../hooks/useTokenCheck';
import { useLocation } from 'react-router-dom';

interface BottomNavContextProps {
  selectedRouteName: ROUTE_NAME;
  setRouteName: React.Dispatch<React.SetStateAction<ROUTE_NAME>>;
}

const BottomNavContext = createContext<BottomNavContextProps | undefined>(undefined);

export const useBottomNav = () => {
  const context = useContext(BottomNavContext);
  if (!context) {
    throw new Error('useBottomNav must be used within a BottomNavProvider');
  }
  return context;
};

export const BottomNavProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedRouteName, setRouteName] = useState<ROUTE_NAME>(ROUTE_NAME.SALES);
  const [isLoggedIn] = useTokenCheck();

  const location = useLocation();
  const getActiveRoute = useCallback(() => {
    const currPath = location.pathname;
    for (const routeName in ROUTE_NAME) {
      const routePath = RoutePath[routeName as ROUTE_NAME];
      if (currPath === routePath) {
        setRouteName(routeName as ROUTE_NAME)
        return routeName;
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isLoggedIn) {
      getActiveRoute();
    }
  }, [getActiveRoute, isLoggedIn, location.pathname]);


  return (
    <BottomNavContext.Provider value={{ selectedRouteName, setRouteName }}>
      {children}
    </BottomNavContext.Provider>
  );
};