import React from "react";
import TeamProfileMember from "./TeamProfileMember";
import { Box } from "@mui/material";
import { useCreateTeamMember } from "../../query/mutations";
import { CreateTeamMemberPayload } from "../../api/request.types";

const TeamProfileMemberAdd: React.FC = () => {
  const createTeamMember = useCreateTeamMember();

  const onSubmit = async (data: CreateTeamMemberPayload) => {
    await createTeamMember.mutateAsync(data);
  };

  return (
    <Box>
      <TeamProfileMember mode="add" onSubmit={onSubmit} />
    </Box>
  );
};

export default TeamProfileMemberAdd;
