import { Box, Checkbox, Typography } from "@mui/material";
import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import SubMenuHeader from "../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft, FaTrash } from "react-icons/fa";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { COLOR } from "../../utils/color";
import TextArea from "../../components/Forms/TextArea/TextArea";
import SingleSelect from "../../components/Forms/SingleSelect/SingleSelect";
import { INTERVAL_BY_5_MINUTES } from "../../components/BottomSheets/ShiftScheduleBottomSheet";
import FormButton from "../../components/Forms/FormButton/FormButton";
import dayjs from "dayjs";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useElementHeight } from "../../hooks/useElementHeight";
import { MobileDatePicker } from "@mui/x-date-pickers";
import OpacityButton from "../../components/OpacityButton/OpacityButton";
import {
  BpCheckedIcon,
  BpIcon,
} from "../../components/CheckboxIcon/CheckboxIcon";
import { ScheduledTimeOffFooter } from "./TeamScheduledTimeOff.styles";
import {
  CreateTeamMemberTimeOffPayload,
  TimeOffType,
} from "../../api/request.types";
import { useDeleteTeamMemberTimeOff } from "../../query/mutations";
import { enqueueSnackbar } from "notistack";
import { DEFAULT_SNACKBAR_PROPS } from "../../utils/constant";

export interface TimeOffForm {
  teamMemberID: string;
  startDate: Date; // by default follow params, if param not set default to today
  endDate: Date; // must be at least from startDate (by default startDate)
  startTime: string; // default 10:00
  endTime: string; // default 20:00
  timeOffType: TimeOffType;
  timeOffReason: string; // default '
  timeOffApproval: boolean; // default false
}

export interface TimeOffErrors {
  teamMember?: string;
  type?: string;
  startDate?: string;
  startTime?: string;
  endTime?: string;
}

const MOCK_TEAM_MEMBERS = [
  { id: "user_1", name: "John Doe" },
  { id: "user_2", name: "Jane Smith" },
  { id: "user_3", name: "Mike Johnson" },
  { id: "ke6_ke", name: "ke6 ke" },
];

const INITIAL_FORM: TimeOffForm = {
  teamMemberID: "",
  timeOffType: TimeOffType.ANNUAL_LEAVE,
  startDate: new Date(),
  endDate: new Date(),
  startTime: "10:00",
  endTime: "20:00",
  timeOffReason: "",
  timeOffApproval: false,
};

enum PickerType {
  START_DATE = "startDate",
  REPEAT_UNTIL = "repeatUntil",
}

interface TeamScheduledTimeOffProps {
  initialValues?: TimeOffForm;
  onSubmit: (form: CreateTeamMemberTimeOffPayload) => void;
}

const TeamScheduledTimeOff = (props: TeamScheduledTimeOffProps) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { timeOffID } = useParams();
  const startDate: string = state?.startDate;
  const userId: string = state?.teamMemberID;

  const footerRef = useRef<HTMLDivElement>(null);
  const footerHeight = useElementHeight(footerRef);

  const [isRepeatChecked, setIsRepeatChecked] = useState(false);
  const [teamMemberOptions, setTeamMemberOptions] = useState<
    { label: string; value: string }[]
  >([{ label: "Pilih member", value: "" }]);
  const [form, setForm] = useState<TimeOffForm>({
    ...INITIAL_FORM,
    teamMemberID: userId,
    startDate: startDate
      ? dayjs(startDate, "YYYY-MM-DD").toDate()
      : dayjs().toDate(),
    endDate: startDate
      ? dayjs(startDate, "YYYY-MM-DD").toDate()
      : dayjs().toDate(),
  });
  const [errors, setErrors] = useState<TimeOffErrors>({});
  const [openPicker, setOpenPicker] = useState<PickerType | null>(null);
  const deleteTeamMemberTimeOff = useDeleteTeamMemberTimeOff();

  const leaveTypes = useMemo(
    () => [
      { label: "Cuti Tahunan", value: TimeOffType.ANNUAL_LEAVE },
      { label: "Cuti Sakit", value: TimeOffType.SICK_LEAVE },
      { label: "Pelatihan", value: TimeOffType.TRAINING },
      { label: "Alasan Ketidakhadiran Lainnya", value: TimeOffType.OTHER },
    ],
    []
  );

  const handleChange = useCallback(
    (field: keyof TimeOffForm) => (value: any) => {
      const newForm = { ...form, [field]: value };

      if (
        (field === "startTime" || field === "endTime") &&
        newForm.startTime &&
        newForm.endTime
      ) {
        const startDate = dayjs(`2000-01-01 ${newForm.startTime}`);
        const endDate = dayjs(`2000-01-01 ${newForm.endTime}`);

        if (endDate.isSame(startDate) || endDate.isBefore(startDate)) {
          setErrors((prev) => ({
            ...prev,
            endTime: "Waktu selesai harus setelah waktu mulai",
          }));
          return;
        } else {
          setErrors((prev) => ({
            ...prev,
            endTime: undefined,
          }));
        }
      }

      setForm(newForm);

      if (errors[field as keyof TimeOffErrors]) {
        setErrors((prev) => ({
          ...prev,
          [field]: undefined,
        }));
      }
    },
    [form, errors]
  );

  const handleSubmit = async () => {
    try {
      const payload: CreateTeamMemberTimeOffPayload = {
        ...form,
        teamMemberID: state.teamMemberID,
        startDate: dayjs(form.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(form.endDate).format("YYYY-MM-DD"),
      };
      props.onSubmit(payload);
      enqueueSnackbar({
        ...DEFAULT_SNACKBAR_PROPS,
        variant: "success",
        message: `Berhasil ${
          timeOffID ? "mengubah" : "menambah"
        } waktu istirahat/libur ${state.teamMemberName}`,
      });
      navigate(-1);
    } catch (e) {
      enqueueSnackbar({
        ...DEFAULT_SNACKBAR_PROPS,
        variant: "error",
        message: `Gagal ${
          timeOffID ? "mengubah" : "menambah"
        } waktu istirahat/libur ${state.teamMemberName}`,
      });
    }
  };

  const handleOnSubmit = useCallback(() => {
    const newErrors: TimeOffErrors = {};

    if (!form.teamMemberID) {
      newErrors.teamMember = "Team member is required";
    }
    if (!form.timeOffType) {
      newErrors.type = "Type is required";
    }
    if (!form.startDate) {
      newErrors.startDate = "Start date is required";
    }
    if (!form.startTime) {
      newErrors.startTime = "Start time is required";
    }
    if (!form.endTime) {
      newErrors.endTime = "End time is required";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      handleSubmit();
    }
  }, [form]);

  const handleDelete = async () => {
    try {
      if (!timeOffID) {
        setForm({
          ...INITIAL_FORM,
          teamMemberID: userId,
          startDate: startDate
            ? dayjs(startDate, "YYYY-MM-DD").toDate()
            : dayjs().toDate(),
        });
        return;
      }

      await deleteTeamMemberTimeOff.mutateAsync(timeOffID);
      navigate(-1);
      enqueueSnackbar({
        ...DEFAULT_SNACKBAR_PROPS,
        variant: "success",
        message: `Berhasil menghapus waktu istirahat/libur ${state.teamMemberName}`,
      });
    } catch (e) {
      enqueueSnackbar({
        ...DEFAULT_SNACKBAR_PROPS,
        variant: "error",
        message: `Gagal menghapus waktu istirahat/libur ${state.teamMemberName}`,
      });
    }
  };

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 500));

        const formattedMembers = MOCK_TEAM_MEMBERS.map((member) => ({
          label: member.name,
          value: member.id,
        }));

        setTeamMemberOptions([...formattedMembers]);
      } catch (error) {
        console.error("Error fetching team members:", error);
      }
    };

    fetchTeamMembers();
  }, [userId]);

  useEffect(() => {
    if (props.initialValues) {
      setIsRepeatChecked(
        props.initialValues.startDate !== props.initialValues.endDate
      );
      setForm(props.initialValues);
    }
  }, [props.initialValues]);

  return (
    <Box>
      <SubMenuHeader
        leftNav={{
          icon: <FaArrowLeft />,
          onClick: () => {
            navigate(-1);
          },
        }}
        text={`${timeOffID ? "Ubah" : "Tambah"} Waktu Istirahat/Libur`}
      />

      <Box
        sx={{
          p: 2,
          pb: `${footerHeight}px`,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 1 }}>
          <Typography fontWeight={500}>Nama Anggota</Typography>
          <Typography variant="body1">{state.teamMemberName}</Typography>
        </Box>
        {/* <SingleSelect
          title="Anggota Tim"
          optionProps={{
            value: form.teamMemberID,
            onChange: handleChange("teamMemberID"),
            options: teamMemberOptions,
            placeholder: "Pilih member",
          }}
          error={!!errors.teamMember}
          helper={
            errors.teamMember
              ? {
                  color: COLOR.danger500,
                  text: errors.teamMember,
                }
              : undefined
          }
        /> */}

        <SingleSelect
          title="Tipe"
          optionProps={{
            value: form.timeOffType,
            onChange: handleChange("timeOffType"),
            options: leaveTypes,
          }}
          error={!!errors.type}
          helper={
            errors.type
              ? {
                  color: COLOR.danger500,
                  text: errors.type,
                }
              : undefined
          }
        />
        <FormButton
          title="Tanggal Mulai"
          endEndorment={<span className="arrow-down" />}
          FormButtonProps={{
            onClick: () => setOpenPicker(PickerType.START_DATE),
          }}
          error={!!errors.startDate}
          helper={
            errors.startDate
              ? {
                  color: COLOR.danger500,
                  text: errors.startDate,
                }
              : undefined
          }
        >
          {dayjs(form.startDate).format("ddd, DD MMM YYYY")}
        </FormButton>

        <Box sx={{ display: "flex", gap: 2 }}>
          <SingleSelect
            title="Jam Mulai"
            sx={{ flex: 1 }}
            optionProps={{
              value: form.startTime,
              onChange: handleChange("startTime"),
              options: INTERVAL_BY_5_MINUTES,
            }}
            error={!!errors.startTime}
            helper={
              errors.startTime
                ? {
                    color: COLOR.danger500,
                    text: errors.startTime,
                  }
                : undefined
            }
          />
          <SingleSelect
            title="Jam Selesai"
            sx={{ flex: 1 }}
            optionProps={{
              value: form.endTime,
              onChange: handleChange("endTime"),
              options: INTERVAL_BY_5_MINUTES,
            }}
            error={!!errors.endTime}
            helper={
              errors.endTime
                ? {
                    color: COLOR.danger500,
                    text: errors.endTime,
                  }
                : undefined
            }
          />
        </Box>

        <OpacityButton
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
          disableOpacity={true}
          onClick={() => {
            if (isRepeatChecked) {
              handleChange("endDate")(dayjs(form.startDate));
            } else {
              handleChange("endDate")(
                dayjs(form.startDate).add(1, "day").toDate()
              );
            }
            setIsRepeatChecked(!isRepeatChecked);
          }}
        >
          <Checkbox
            inputProps={{
              "aria-label": "Custom Location",
            }}
            checked={isRepeatChecked}
            disableRipple
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            sx={{
              p: 0,
              mr: 1,
              pointerEvents: "none",
            }}
          />
          <Typography>Repeat</Typography>
        </OpacityButton>

        {isRepeatChecked && (
          <FormButton
            title="Repeat until"
            endEndorment={<span className="arrow-down" />}
            FormButtonProps={{
              onClick: () => setOpenPicker(PickerType.REPEAT_UNTIL),
            }}
          >
            {dayjs(form.endDate).format("ddd, DD MMM YYYY")}
          </FormButton>
        )}
        <TextArea
          title="Description"
          textAreaProps={{
            value: form.timeOffReason,
            onChange: (e) => handleChange("timeOffReason")(e.target.value),
            placeholder: "Add description or note (optional)",
          }}
          helper={{
            text: `${form.timeOffReason?.length}/100`,
            color: COLOR.neutral500,
            otherProps: {
              sx: {
                textAlign: "right",
              },
            },
          }}
        />

        <OpacityButton
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          disableOpacity={true}
          onClick={() => {
            handleChange("timeOffApproval")(!form.timeOffApproval);
          }}
        >
          <Checkbox
            inputProps={{
              "aria-label": "Custom Location",
            }}
            checked={form.timeOffApproval}
            disableRipple
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            sx={{
              p: 0,
              mr: 1,
              pointerEvents: "none",
            }}
          />
          <Typography>Disetujui</Typography>
        </OpacityButton>

        <Box sx={{ color: COLOR.neutral500, fontSize: "12px", pb: 5 }}>
          Online Booking tidak dapat dilakukan selama waktu istirahat/libur
          (time off).
        </Box>
      </Box>

      <Box ref={footerRef} sx={ScheduledTimeOffFooter}>
        <CustomButton
          variant="icon"
          sx={{
            backgroundColor: COLOR.danger50,
            color: COLOR.danger500,
            "&:hover": {
              backgroundColor: COLOR.danger100,
            },
          }}
          onClick={handleDelete}
        >
          <FaTrash size={18} />
        </CustomButton>
        <CustomButton onClick={handleOnSubmit}>
          <Typography>Save</Typography>
        </CustomButton>
      </Box>
      <MobileDatePicker
        sx={{ display: "none" }}
        open={openPicker === PickerType.START_DATE}
        onClose={() => setOpenPicker(null)}
        label="Start date"
        value={dayjs(form.startDate)}
        onChange={(date) => {
          if (date) {
            handleChange("startDate")(date.toDate());
          }
          setOpenPicker(null);
        }}
        maxDate={dayjs(form.endDate)}
      />
      <MobileDatePicker
        sx={{ display: "none" }}
        open={openPicker === PickerType.REPEAT_UNTIL}
        onClose={() => setOpenPicker(null)}
        label="Repeat until"
        value={dayjs(form.endDate)}
        onChange={(date) => {
          if (date) {
            handleChange("endDate")(date.toDate());
          }
          setOpenPicker(null);
        }}
        minDate={dayjs(form.startDate)}
      />
    </Box>
  );
};

export default TeamScheduledTimeOff;
