import { Box, IconButton, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/id';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { COLOR } from '../../utils/color';

export interface WeeklyCalendarProps {
  selectedRange: { start: Date | null; end: Date | null };
  onRangeChange: (range: { start: Date | null; end: Date | null }) => void;
  locale?: 'en' | 'id';
  currentMonth?: Date;
  onMonthChange?: (date: Date) => void;
}

const WeeklyCalendar: FC<WeeklyCalendarProps> = ({
  selectedRange,
  onRangeChange,
  currentMonth,
  onMonthChange,
}) => {

  const dCurrentMonth = currentMonth ? dayjs(currentMonth) : dayjs();
  const dSelectedRange = {
    start: selectedRange.start ? dayjs(selectedRange.start) : null,
    end: selectedRange.end ? dayjs(selectedRange.end) : null
  };

  const weeks = useCallback(() => {
    const start = dCurrentMonth.startOf('month').startOf('week');
    const end = dCurrentMonth.endOf('month').endOf('week');
    const days = [];
    let day = start;

    while (day.isBefore(end)) {
      days.push(day);
      day = day.add(1, 'day');
    }

    const weeks = [];
    for (let i = 0; i < days.length; i += 7) {
      weeks.push(days.slice(i, i + 7));
    }

    return weeks;
  }, [dCurrentMonth]);

  const handlePrevMonth = () => {
    if (onMonthChange) {
      onMonthChange(dCurrentMonth.subtract(1, 'month').toDate());
    }
  };

  const handleNextMonth = () => {
    if (onMonthChange) {
      onMonthChange(dCurrentMonth.add(1, 'month').toDate());
    }
  };

  const handleDateClick = (date: dayjs.Dayjs) => {
    const weekStart = date.startOf('week');
    const weekEnd = date.endOf('week');
    onRangeChange({
      start: weekStart.toDate(),
      end: weekEnd.toDate()
    });
  };

  const isToday = (date: dayjs.Dayjs) => {
    return date.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD');
  };

  const isSelected = (date: dayjs.Dayjs) => {
    if (!dSelectedRange.start || !dSelectedRange.end) return false;
    return (
      date.format('YYYY-MM-DD') === dSelectedRange.start.format('YYYY-MM-DD') ||
      date.format('YYYY-MM-DD') === dSelectedRange.end.format('YYYY-MM-DD')
    );
  };

  const isDifferentMonth = (date: dayjs.Dayjs) => {
    return date.month() !== dCurrentMonth.month();
  };

  const isSelectedWeek = (date: dayjs.Dayjs) => {
    if (!dSelectedRange.start || !dSelectedRange.end) return false;
    return date.isAfter(dSelectedRange.start.subtract(1, 'day')) && 
           date.isBefore(dSelectedRange.end);
  };

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <IconButton onClick={handlePrevMonth}>
          <KeyboardArrowLeft />
        </IconButton>
        <Typography variant="h6">
          {dCurrentMonth.format('MMMM YYYY')}
        </Typography>
        <IconButton onClick={handleNextMonth}>
          <KeyboardArrowRight />
        </IconButton>
      </Box>
      <Box display="grid" gridTemplateColumns="repeat(7, 1fr)" mb={1}>
        {Array.from({ length: 7 }, (_, i) => (
          <Typography 
            key={i} 
            align="center"
            color="text.secondary"
            sx={{ fontWeight: 'bold' }}
          >
            {dayjs().day(i + 1 === 7 ? 0 : i + 1).format('ddd')}
          </Typography>
        ))}
      </Box>

      {weeks().map((week, weekIndex) => (
        <Box 
          key={weekIndex} 
          display="grid" 
          gridTemplateColumns="repeat(7, 1fr)"
        >
          {week.map((date, dateIndex) => (
            <Box
              key={dateIndex}
              onClick={() => handleDateClick(date)}
              sx={{
                position: 'relative',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                '& .MuiTypography-root': {
                  width: '32px',
                  height: '32px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  backgroundColor: isSelected(date)
                    ? '#1A1A1A'
                    : isSelectedWeek(date)
                      ? '#F5F5F5'
                      : 'transparent',
                  color: isSelected(date)
                    ? '#fff'
                    : isDifferentMonth(date)
                      ? '#CCCCCC'
                      : '#1A1A1A',
                  border: isToday(date)
                    ? '1px solid #1A1A1A'
                    : 'none',
                  '&:hover': {
                    backgroundColor: isSelected(date)
                      ? '#1A1A1A'
                      : '#F5F5F5'
                  }
                }
              }}
            >
              <Typography>
                {date.date()}
              </Typography>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default WeeklyCalendar;
