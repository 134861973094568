import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
const GroupSchedule = React.lazy(
  () => import("../../components/GroupSchedule/GroupSchedule")
);

const MainDashboard = () => {
  const { mode } = useParams();
  return (
    <Suspense fallback={<div />}>
      <GroupSchedule showChooseAppointment={mode === "book"} />
    </Suspense>
  );
};

export default MainDashboard;
