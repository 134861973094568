import { Box, Typography } from '@mui/material';
import React from 'react';
import { CART_PAYMENT_METHOD, PAYMENT_METHOD_ICON, PAYMENT_METHOD_TITLE } from '../../utils/constant';
import { COLOR } from '../../utils/color';
import OpacityButton from '../OpacityButton/OpacityButton';

export interface PaymentMethodPropsI {
  onClick: (method: CART_PAYMENT_METHOD) => void;
  allowedMethod?: Array<CART_PAYMENT_METHOD>;
}

const PaymentMethod = ({ onClick, allowedMethod = Object.keys(CART_PAYMENT_METHOD) as CART_PAYMENT_METHOD[] }: PaymentMethodPropsI) => {
  const paymentMethodKeys = Object.values(CART_PAYMENT_METHOD);
  const allowedMethodKeys = paymentMethodKeys.filter((key) => allowedMethod.includes(key));

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        justifyContent: 'center',
      }}
    >
      {allowedMethodKeys.map((key, idx) => {
        const Icon = PAYMENT_METHOD_ICON[key];
        return (
          <OpacityButton
            key={`${key}-${idx}`}
            onClick={() => onClick(key)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
              p: 2,
              flexBasis: 'calc((100% / 2) - 16px)',
              minWidth: 120,
              flexGrow: 1, 
              aspectRatio: '3/2',
              border: `1px solid ${COLOR.neutral300}`,
              borderRadius: 2,
              '&:hover, &:active': {
                borderWidth: '2px',
                borderColor: COLOR.primary500,
              },
            }}
          >
            <Icon size={24} />
            <Typography>{PAYMENT_METHOD_TITLE[key]}</Typography>
          </OpacityButton>
        );
      })}
    </Box>
  );
};

export default PaymentMethod;
