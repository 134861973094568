import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Modal,
  ModalProps,
  Typography,
  Button,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { PublicScheduleDetailWithQty } from "../../../../types/globalTypes";
import QuantitySelector from "../../../../components/QuantityInput/QuantityInput";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import { COLOR } from "../../../../utils/color";
import { formatCurrency } from "../../../../utils/helper";
import { getTransactionTax, QRIS_TAX } from "../../../../utils/transaction";
import dayjs from "dayjs";
import { TIME_FORMAT } from "../../../../utils/constant";
import isEmpty from "lodash.isempty";

interface CheckoutCartModalPropsI {
  modalProps: Pick<ModalProps, "keepMounted" | "onClose"> & { open: boolean };
  cartScheduleData: Array<{
    date: string;
    schedules: (PublicScheduleDetailWithQty & {
      teamMemberId: string;
      teamMemberName: string;
    })[];
  }>;
  disabledOnConfirm?: boolean;
  onQuantityChange: (date: string, index: number, qty: number) => void;
  onConfirm: () => void;
  onBack: () => void;
}


const CheckoutCartModal = ({
  modalProps,
  cartScheduleData,
  onQuantityChange,
  disabledOnConfirm = false,
  onConfirm,
  onBack,
}: CheckoutCartModalPropsI) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const hasValidQuantity = cartScheduleData.some(({ schedules }) =>
    schedules.some((schedule) => schedule.qty > 0)
  );
  const totalItems = cartScheduleData.reduce(
    (sum, { schedules }) => sum + schedules.reduce((detailSum, detail) => detailSum + detail.qty, 0),
    0
  );
  const priceDetail = getTransactionTax(
    cartScheduleData.reduce(
      (sum, { schedules }) =>
        sum + schedules.reduce((detailSum, detail) => detailSum + detail.price * detail.qty, 0),
      0
    ),
    QRIS_TAX,
    false
  );


  return (
    <Modal
      {...modalProps}
      open={modalProps.open}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{
        backgroundColor: "white",
        width: isMobile ? '100%' : "50vw",
        height: isMobile ? '100%' : "80vh",
        borderRadius: isMobile ? 0 : 2,
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            overflowY: 'auto',
            px: 2,
            pt: 3,
          }}
        >
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            🛒 Keranjang
          </Typography>
          <Divider />
          {cartScheduleData.length === 0 ? (
            <Typography variant="body1" textAlign="center" color="textSecondary" mt={3}>
              Keranjang kosong 😔
            </Typography>
          ) : (
            cartScheduleData.map(({ date, schedules }) => (
              <Box key={date} sx={{ mt: 2 }}>
                <Typography variant="subtitle1" fontWeight="bold" color="primary">
                  📅 {dayjs(date, TIME_FORMAT.DDMMYYYY).format(TIME_FORMAT.dddDDMMMYYYY)}
                </Typography>
                <Box>
                  {schedules.map((schedule, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <ListItemText
                          primary={`⏰ ${schedule.startTime} - ${schedule.endTime}`}
                          secondary={
                            <>
                              💰 Price: {schedule.price > 0 ? `${formatCurrency(schedule.price, 'IDR')}` : "Free"} <br />
                              {!isEmpty(schedule.teamMemberName) && (<>👥 Request (if available): {schedule.teamMemberName} <br /> </>)}
                            </>
                          }
                        />
                        <QuantitySelector
                          value={schedule.qty}
                          onChange={(qty: number) => onQuantityChange(date, index, qty)}
                          min={0}
                          max={schedule.remainingQuota}
                          maxDigits={2}
                        />
                      </ListItem>
                      {index < schedules.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            ))
          )}
        </Box>

        <Box sx={{ px: 2, pt: 1, pb: 3, borderTop: `1px solid ${COLOR.neutral200}` }}>
          {hasValidQuantity && (
            <Box sx={{ textAlign: "right" }}>
              <Box
                display="flex"
                flexDirection="row"
                flexGrow={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography fontSize={14} fontWeight={400}>
                  Harga Produk
                  <Typography
                    variant="caption"
                    fontSize={12}
                    fontWeight={400}
                    color={COLOR.danger400}
                  >
                    ({totalItems} item{totalItems !== 1 ? "s" : ""})
                  </Typography>
                </Typography>
                <Typography fontSize={14} fontWeight={400}>
                  {formatCurrency(priceDetail.amount, "IDR")}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                flexGrow={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography fontSize={14} fontWeight={400}>
                  Biaya Admin{" "}
                </Typography>
                <Typography fontSize={14} fontWeight={400}>
                  {formatCurrency(priceDetail.adminFee, "IDR")}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                flexGrow={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography fontSize={16} fontWeight={600}>
                  Total{" "}
                  <Typography
                    variant="caption"
                    fontSize={12}
                    fontWeight={400}
                    color={COLOR.danger400}
                  >
                    ({totalItems} item{totalItems !== 1 ? "s" : ""})
                  </Typography>
                </Typography>
                <Typography fontSize={20} fontWeight={700}>
                  {formatCurrency(priceDetail.total, "IDR")}
                </Typography>
              </Box>
            </Box>
          )}

          <Box display="flex" flexDirection="row" justifyContent="center" gap={1}>
            <CustomButton startEndorment="❌" variant="outlined" onClick={onBack}>
              Kembali
            </CustomButton>
            <CustomButton
              startEndorment="✅"
              onClick={onConfirm}
              disabled={!hasValidQuantity || disabledOnConfirm}
            >
              Lanjut Bayar
            </CustomButton>
          </Box>
        </Box>
      </Box>


    </Modal>
  );
};

export default CheckoutCartModal;
