import { IconType } from "react-icons";
import {
  FlattenedScheduleEventByEntity,
  GetScheduleEventResponseType,
  GetTeamMemberShiftsByDateResponse,
  PublicScheduleDetail,
  ScheduleEventStatus,
} from "../api/request.types";
import { COLOR } from "../utils/color";
import {
  DURATION_PERIOD,
  ROUTE_NAME,
  RoutePathType,
  TAB_ENUM,
} from "../utils/constant";
import { TypographyProps } from "@mui/material";

export type ValueOf<T> = T[keyof T];
export interface FormBaseProps {
  title?: string;
  required?: boolean;
  helper?: {
    text?: string;
    color?: string;
    otherProps?: TypographyProps;
  };
}

export type SideNavItem = {
  routeName: ROUTE_NAME;
  routePath: ValueOf<RoutePathType>;
  label: string;
  icon: IconType;
};

export interface IconSVGProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

export interface TimeSlot {
  startTime: string; // Format: "HH.mm A"
  endTime: string; // Format: "HH.mm A"
  price: number;
}
// DAY , Timeslot
export type Schedule = Record<string, TimeSlot[]>;

export type SchedulePriceType = {
  time: {
    startTime: string;
    endTime: string;
    disabled: boolean;
  };
  price: number;
};

export type ProductData = {
  images: {
    base64: string;
    file: File | null;
  }[];
  name: string;
  price: number;
  duration: number;
  durationUnit: DURATION_PERIOD | string;
  quota: number;
  isCustomPrice: boolean;
  schedules: Schedule;
  description: string;
  location: {
    lat: number;
    long: number;
    search: string;
  };
  locationDetail: string;
  allowReschedule: boolean;
};

export type EditProductData = {
  images: {
    base64: string;
    file: File | null;
    url: string | null;
  }[];
  name: string;
  price: number;
  duration: number;
  durationUnit: DURATION_PERIOD | string;
  quota: number;
  isCustomPrice: boolean;
  schedules: Schedule;
  description: string;
  location: {
    lat: number;
    long: number;
    search: string;
    isCustomLocation: boolean;
  };
  locationDetail: string;
  allowReschedule: boolean;
};

export interface DefaultTemplatePropsI {
  data: ProductData;
}

export type GoogleMapSrcOptions = {
  address?: string;
  zipCode?: string;
  latitude?: number;
  longitude?: number;
};

export enum PAYMENT_STATUS {
  PAID = "paid",
  REQUEST_REFUND = "request_refund",
  REFUNDED = "refunded",
  REFUND_CANCELLED = "refund_cancelled",
  CANCELLED = "cancelled",
  UNPAID = "unpaid",
  PENDING = "pending",
  PARTIAL_REFUND = "partial_refund",
  PARTIAL_PAID = "partial_paid",
}

export enum TRANSACTION_STATUS {
  COMPLETE = "complete", // Selesai
  ON_PROGRESS = "on-progress", // Sedang berjalan
  COMING_SOON = "coming-soon", // Akan Datang
  CANCELED = "cancelled", // Batal
  CREATED = "created", // Baru dibuat tp blm bayar
}
export enum TRANSACTION_TYPE {
  ONLINE_BOOKING = "online_booking",
  OFFLINE_BOOKING = "offline_booking",
  SALE_ONLY = "sale_only",
}

export enum SCHEDULE_TYPE {
  DEFAULT = "default",
}

export type CurrencyCodeType = "IDR" | "USD" | "EUR" | "GBP" | "JPY" | "AUD";

export type PublicScheduleDetailWithQty = PublicScheduleDetail & {
  qty: number;
};

export type IMAGE_USAGE = "product";

export type ProductTabType = {
  id: TAB_ENUM;
  title: string;
  icon: React.ReactElement;
};

export enum BankAccountStatus {
  UNVERIFIED = "UNVERIFIED",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  INVALID_ACCOUNT_NUMBER = "INVALID_ACCOUNT_NUMBER",
  SUSPECTED_ACCOUNT = "SUSPECTED_ACCOUNT",
  BLACK_LISTED = "BLACK_LISTED",
  FAILED = "FAILED",
  CLOSED = "CLOSED",
}

export const bankAccountStatusMap = {
  [BankAccountStatus.UNVERIFIED]: "Belum Diverifikasi",
  [BankAccountStatus.PENDING]: "Pending",
  [BankAccountStatus.SUCCESS]: "Terverifikasi",
  [BankAccountStatus.INVALID_ACCOUNT_NUMBER]: "Rekening Tidak Valid",
  [BankAccountStatus.SUSPECTED_ACCOUNT]: "Rekening Tidak Valid",
  [BankAccountStatus.BLACK_LISTED]: "Rekening Tidak Valid",
  [BankAccountStatus.FAILED]: "Rekening Tidak Valid",
  [BankAccountStatus.CLOSED]: "Rekening Tidak Valid",
};

export const bankAccountColorMap = {
  [BankAccountStatus.UNVERIFIED]: COLOR.danger500,
  [BankAccountStatus.PENDING]: COLOR.neutral500,
  [BankAccountStatus.SUCCESS]: COLOR.success500,
  [BankAccountStatus.INVALID_ACCOUNT_NUMBER]: COLOR.danger500,
  [BankAccountStatus.SUSPECTED_ACCOUNT]: COLOR.danger500,
  [BankAccountStatus.BLACK_LISTED]: COLOR.danger500,
  [BankAccountStatus.FAILED]: COLOR.danger500,
  [BankAccountStatus.CLOSED]: COLOR.danger500,
};

export enum BankCode {
  HARDA = "harda",
  ANZ = "anz",
  ACEH = "aceh",
  ALADIN = "aladin",
  AMAR = "amar",
  ANTARDAERAH = "antardaerah",
  ARTHA = "artha",
  BENGKULU = "bengkulu",
  DAERAH_ISTIMEWA = "daerah_istimewa",
  DAERAH_ISTIMEWA_SYR = "daerah_istimewa_syr",
  BTPN_SYR = "btpn_syr",
  BUKOPIN_SYR = "bukopin_syr",
  BUMI_ARTA = "bumi_arta",
  CAPITAL = "capital",
  BCA = "bca",
  CCB = "ccb",
  CNB = "cnb",
  DANAMON = "danamon",
  DINAR = "dinar",
  DKI = "dki",
  DKI_SYR = "dki_syr",
  GANESHA = "ganesha",
  AGRIS = "agris",
  INA_PERDANA = "ina_perdana",
  INDEX_SELINDO = "index_selindo",
  ARTOS_SYR = "artos_syr",
  JAMBI = "jambi",
  JAMBI_SYR = "jambi_syr",
  JASA_JAKARTA = "jasa_jakarta",
  JAWA_TENGAH = "jawa_tengah",
  JAWA_TENGAH_SYR = "jawa_tengah_syr",
  JAWA_TIMUR = "jawa_timur",
  JAWA_TIMUR_SYR = "jawa_timur_syr",
  KALIMANTAN_BARAT = "kalimantan_barat",
  KALIMANTAN_BARAT_SYR = "kalimantan_barat_syr",
  KALIMANTAN_SELATAN = "kalimantan_selatan",
  KALIMANTAN_SELATAN_SYR = "kalimantan_selatan_syr",
  KALIMANTAN_TENGAH = "kalimantan_tengah",
  KALIMANTAN_TIMUR_SYR = "kalimantan_timur_syr",
  KALIMANTAN_TIMUR = "kalimantan_timur",
  LAMPUNG = "lampung",
  MALUKU = "maluku",
  MANDIRI = "mandiri",
  MANTAP = "mantap",
  MASPION = "maspion",
  MAYAPADA = "mayapada",
  MAYORA = "mayora",
  MEGA = "mega",
  MEGA_SYR = "mega_syr",
  MESTIKA_DHARMA = "mestika_dharma",
  MIZUHO = "mizuho",
  MAS = "mas",
  MUTIARA = "mutiara",
  SUMATERA_BARAT = "sumatera_barat",
  SUMATERA_BARAT_SYR = "sumatera_barat_syr",
  NUSA_TENGGARA_BARAT = "nusa_tenggara_barat",
  NUSA_TENGGARA_TIMUR = "nusa_tenggara_timur",
  NUSANTARA_PARAHYANGAN = "nusantara_parahyangan",
  OCBC = "ocbc",
  OCBC_SYR = "ocbc_syr",
  AMERICA_NA = "america_na",
  BOC = "boc",
  INDIA = "india",
  TOKYO = "tokyo",
  PAPUA = "papua",
  PRIMA = "prima",
  BRI = "bri",
  RIAU_DAN_KEPRI = "riau_dan_kepri",
  SAHABAT_SAMPOERNA = "sahabat_sampoerna",
  SHINHAN = "shinhan",
  SINARMAS = "sinarmas",
  SINARMAS_SYR = "sinarmas_syr",
  SULSELBAR = "sulselbar",
  SULSELBAR_SYR = "sulselbar_syr",
  SULAWESI = "sulawesi",
  SULAWESI_TENGGARA = "sulawesi_tenggara",
  SULUT = "sulut",
  SUMSEL_DAN_BABEL = "sumsel_dan_babel",
  SUMSEL_DAN_BABEL_SYR = "sumsel_dan_babel_syr",
  SUMUT = "sumut",
  SUMUT_SYR = "sumut_syr",
  RESONA_PERDANIA = "resona_perdania",
  VICTORIA_INTERNASIONAL = "victoria_internasional",
  VICTORIA_SYR = "victoria_syr",
  WOORI = "woori",
  BCA_SYR = "bca_syr",
  BJB = "bjb",
  BJB_SYR = "bjb_syr",
  ROYAL = "royal",
  BNI = "bni",
  BNP_PARIBAS = "bnp_paribas",
  BALI = "bali",
  BANTEN = "banten",
  EKA = "eka",
  AGRONIAGA = "agroniaga",
  BSM = "bsm",
  BTN = "btn",
  BTN_SYR = "btn_syr",
  TABUNGAN_PENSIUNAN_NASIONAL = "tabungan_pensiunan_nasional",
  CIMB = "cimb",
  CITIBANK = "citibank",
  CHINATRUST = "chinatrust",
  DBS = "dbs",
  HSBC = "hsbc",
  ICBC = "icbc",
  ARTOS = "artos",
  HANA = "hana",
  BII = "bii",
  BII_SYR = "bii_syr",
  MNC_INTERNASIONAL = "mnc_internasional",
  MUAMALAT = "muamalat",
  YUDHA_BAKTI = "yudha_bakti",
  NATIONALNOBU = "nationalnobu",
  PANIN = "panin",
  PANIN_SYR = "panin_syr",
  PERMATA = "permata",
  PERMATA_SYR = "permata_syr",
  QNB_KESAWAN = "qnb_kesawan",
  RABOBANK = "rabobank",
  SBI_INDONESIA = "sbi_indonesia",
  KESEJAHTERAAN_EKONOMI = "kesejahteraan_ekonomi",
  STANDARD_CHARTERED = "standard_chartered",
  SUPER_BANK = "super_bank",
  UOB = "uob",
  BUKOPIN = "bukopin",
  DANA = "dana",
  GOPAY = "gopay",
  LINKAJA = "linkaja",
  OVO = "ovo",
  SHOPEEPAY = "shopeepay",
}

export const bankCodeMap = {
  [BankCode.HARDA]: "Allo Bank/Bank Harda Internasional",
  [BankCode.ANZ]: "ANZ Indonesia",
  [BankCode.ACEH]: "Bank Aceh Syariah",
  [BankCode.ALADIN]: "Bank Aladin Syariah",
  [BankCode.AMAR]: "Bank Amar Indonesia",
  [BankCode.ANTARDAERAH]: "Bank Antardaerah",
  [BankCode.ARTHA]: "Bank Artha Graha Internasional",
  [BankCode.BENGKULU]: "Bank Bengkulu",
  [BankCode.DAERAH_ISTIMEWA]: "Bank BPD DIY",
  [BankCode.DAERAH_ISTIMEWA_SYR]: "Bank BPD DIY Syariah",
  [BankCode.BTPN_SYR]: "Bank BTPN Syariah",
  [BankCode.BUKOPIN_SYR]: "Bank Bukopin Syariah",
  [BankCode.BUMI_ARTA]: "Bank Bumi Arta",
  [BankCode.CAPITAL]: "Bank Capital Indonesia",
  [BankCode.BCA]: "Bank Central Asia",
  [BankCode.CCB]: "Bank China Construction Bank Indonesia",
  [BankCode.CNB]: "Bank CNB (Centratama Nasional Bank)",
  [BankCode.DANAMON]: "Bank Danamon & Danamon Syariah",
  [BankCode.DINAR]: "Bank Dinar Indonesia",
  [BankCode.DKI]: "Bank DKI",
  [BankCode.DKI_SYR]: "Bank DKI Syariah",
  [BankCode.GANESHA]: "Bank Ganesha",
  [BankCode.AGRIS]: "Bank IBK Indonesia",
  [BankCode.INA_PERDANA]: "Bank Ina Perdana",
  [BankCode.INDEX_SELINDO]: "Bank Index Selindo",
  [BankCode.ARTOS_SYR]: "Bank Jago Syariah",
  [BankCode.JAMBI]: "Bank Jambi",
  [BankCode.JAMBI_SYR]: "Bank Jambi Syariah",
  [BankCode.JASA_JAKARTA]: "Bank Jasa Jakarta",
  [BankCode.JAWA_TENGAH]: "Bank Jateng",
  [BankCode.JAWA_TENGAH_SYR]: "Bank Jateng Syariah",
  [BankCode.JAWA_TIMUR]: "Bank Jatim",
  [BankCode.JAWA_TIMUR_SYR]: "Bank Jatim Syariah",
  [BankCode.KALIMANTAN_BARAT]: "Bank Kalbar",
  [BankCode.KALIMANTAN_BARAT_SYR]: "Bank Kalbar Syariah",
  [BankCode.KALIMANTAN_SELATAN]: "Bank Kalsel",
  [BankCode.KALIMANTAN_SELATAN_SYR]: "Bank Kalsel Syariah",
  [BankCode.KALIMANTAN_TENGAH]: "Bank Kalteng",
  [BankCode.KALIMANTAN_TIMUR_SYR]: "Bank Kaltim Syariah",
  [BankCode.KALIMANTAN_TIMUR]: "Bank Kaltimtara",
  [BankCode.LAMPUNG]: "Bank Lampung",
  [BankCode.MALUKU]: "Bank Maluku",
  [BankCode.MANDIRI]: "Bank Mandiri",
  [BankCode.MANTAP]: "Bank MANTAP (Mandiri Taspen)",
  [BankCode.MASPION]: "Bank Maspion Indonesia",
  [BankCode.MAYAPADA]: "Bank Mayapada",
  [BankCode.MAYORA]: "Bank Mayora Indonesia",
  [BankCode.MEGA]: "Bank Mega",
  [BankCode.MEGA_SYR]: "Bank Mega Syariah",
  [BankCode.MESTIKA_DHARMA]: "Bank Mestika Dharma",
  [BankCode.MIZUHO]: "Bank Mizuho Indonesia",
  [BankCode.MAS]: "Bank Multi Arta Sentosa (Bank MAS)",
  [BankCode.MUTIARA]: "Bank Mutiara",
  [BankCode.SUMATERA_BARAT]: "Bank Nagari",
  [BankCode.SUMATERA_BARAT_SYR]: "Bank Nagari Syariah",
  [BankCode.NUSA_TENGGARA_BARAT]: "Bank NTB Syariah",
  [BankCode.NUSA_TENGGARA_TIMUR]: "Bank NTT",
  [BankCode.NUSANTARA_PARAHYANGAN]: "Bank Nusantara Parahyangan",
  [BankCode.OCBC]: "Bank OCBC NISP",
  [BankCode.OCBC_SYR]: "Bank OCBC NISP Syariah",
  [BankCode.AMERICA_NA]: "Bank of America NA",
  [BankCode.BOC]: "Bank of China (Hong Kong) Limited",
  [BankCode.INDIA]: "Bank of India Indonesia",
  [BankCode.TOKYO]: "Bank of Tokyo Mitsubishi UFJ",
  [BankCode.PAPUA]: "Bank Papua",
  [BankCode.PRIMA]: "Bank Prima Master",
  [BankCode.BRI]: "Bank Rakyat Indonesia",
  [BankCode.RIAU_DAN_KEPRI]: "Bank Riau Kepri",
  [BankCode.SAHABAT_SAMPOERNA]: "Bank Sahabat Sampoerna",
  [BankCode.SHINHAN]: "Bank Shinhan Indonesia",
  [BankCode.SINARMAS]: "Bank Sinarmas",
  [BankCode.SINARMAS_SYR]: "Bank Sinarmas Syariah",
  [BankCode.SULSELBAR]: "Bank Sulselbar",
  [BankCode.SULSELBAR_SYR]: "Bank Sulselbar Syariah",
  [BankCode.SULAWESI]: "Bank Sulteng",
  [BankCode.SULAWESI_TENGGARA]: "Bank Sultra",
  [BankCode.SULUT]: "Bank SulutGo",
  [BankCode.SUMSEL_DAN_BABEL]: "Bank Sumsel Babel",
  [BankCode.SUMSEL_DAN_BABEL_SYR]: "Bank Sumsel Babel Syariah",
  [BankCode.SUMUT]: "Bank Sumut",
  [BankCode.SUMUT_SYR]: "Bank Sumut Syariah",
  [BankCode.RESONA_PERDANIA]: "Bank Resona Perdania",
  [BankCode.VICTORIA_INTERNASIONAL]: "Bank Victoria International",
  [BankCode.VICTORIA_SYR]: "Bank Victoria Syariah",
  [BankCode.WOORI]: "Bank Woori Saudara",
  [BankCode.BCA_SYR]: "BCA (Bank Central Asia) Syariah",
  [BankCode.BJB]: "BJB",
  [BankCode.BJB_SYR]: "BJB Syariah",
  [BankCode.ROYAL]: "Blu/BCA Digital",
  [BankCode.BNI]: "BNI (Bank Negara Indonesia)",
  [BankCode.BNP_PARIBAS]: "BNP Paribas Indonesia",
  [BankCode.BALI]: "BPD Bali",
  [BankCode.BANTEN]: "BPD Banten",
  [BankCode.EKA]: "BPR EKA (Bank Eka)",
  [BankCode.AGRONIAGA]: "BRI Agroniaga",
  [BankCode.BSM]: "BSI (Bank Syariah Indonesia)",
  [BankCode.BTN]: "BTN",
  [BankCode.BTN_SYR]: "BTN Syariah",
  [BankCode.TABUNGAN_PENSIUNAN_NASIONAL]: "BTPN",
  [BankCode.CIMB]: "CIMB Niaga & CIMB Niaga Syariah",
  [BankCode.CITIBANK]: "Citibank",
  [BankCode.CHINATRUST]: "CTBC (Chinatrust) Indonesia",
  [BankCode.DBS]: "DBS Indonesia",
  [BankCode.HSBC]: "HSBC Indonesia",
  [BankCode.ICBC]: "ICBC Indonesia",
  [BankCode.ARTOS]: "Jago/Artos",
  [BankCode.HANA]: "LINE Bank/KEB Hana",
  [BankCode.BII]: "Maybank Indonesia",
  [BankCode.BII_SYR]: "Maybank Syariah",
  [BankCode.MNC_INTERNASIONAL]: "Motion/MNC Bank",
  [BankCode.MUAMALAT]: "Muamalat",
  [BankCode.YUDHA_BAKTI]: "Neo Commerce/Yudha Bhakti",
  [BankCode.NATIONALNOBU]: "Nobu (Nationalnobu) Bank",
  [BankCode.PANIN]: "Panin Bank",
  [BankCode.PANIN_SYR]: "Panin Dubai Syariah",
  [BankCode.PERMATA]: "Permata",
  [BankCode.PERMATA_SYR]: "Permata Syariah",
  [BankCode.QNB_KESAWAN]: "QNB Indonesia",
  [BankCode.RABOBANK]: "Rabobank International Indonesia",
  [BankCode.SBI_INDONESIA]: "SBI Indonesia",
  [BankCode.KESEJAHTERAAN_EKONOMI]: "Seabank/Bank BKE",
  [BankCode.STANDARD_CHARTERED]: "Standard Chartered Bank",
  [BankCode.SUPER_BANK]: "Superbank",
  [BankCode.UOB]: "TMRW/UOB",
  [BankCode.BUKOPIN]: "Wokee/Bukopin",
  [BankCode.DANA]: "Dana",
  [BankCode.GOPAY]: "GoPay",
  [BankCode.LINKAJA]: "LinkAja",
  [BankCode.OVO]: "OVO",
  [BankCode.SHOPEEPAY]: "ShopeePay",
};

export const bankCodeOptions = Object.entries(bankCodeMap).map(
  ([key, value]) => ({
    label: value,
    value: key,
  })
);

export enum DisbursementStatus {
  UNVERIFIED = "UNVERIFIED",
  PENDING = "PENDING",
  CANCELLED = "CANCELLED",
  DONE = "DONE",
}

export const disbursementStatusMap = {
  [DisbursementStatus.UNVERIFIED]: "Belum Diverifikasi",
  [DisbursementStatus.PENDING]: "Pending",
  [DisbursementStatus.CANCELLED]: "Gagal",
  [DisbursementStatus.DONE]: "Berhasil",
};

export const disbursementStatusColorMap = {
  [DisbursementStatus.UNVERIFIED]: COLOR.neutral500,
  [DisbursementStatus.PENDING]: COLOR.neutral500,
  [DisbursementStatus.CANCELLED]: COLOR.danger500,
  [DisbursementStatus.DONE]: COLOR.success500,
};

export enum FailedDisbursementReason {
  INACTIVE_ACCOUNT = "INACTIVE_ACCOUNT",
  NOT_REGISTERED_ACCOUNT = "NOT_REGISTERED_ACCOUNT",
  CANT_RECEIVE_TRANSFER = "CANT_RECEIVE_TRANSFER",
  INTERMITTENT_DISTURBANCE_ON_BENEFICIARY_BANK = "INTERMITTENT_DISTURBANCE_ON_BENEFICIARY_BANK",
  BENEFICIARY_ACCOUNT_NOT_VERIFIED = "BENEFICIARY_ACCOUNT_NOT_VERIFIED",
  INSUFFICIENT_BALANCE = "INSUFFICIENT_BALANCE",
  EXCEEDED_TRANSACTION_AMOUNT_LIMIT = "EXCEEDED_TRANSACTION_AMOUNT_LIMIT",
}

export enum DisbursementDirection {
  DOMESTIC_TRANSFER = "DOMESTIC_TRANSFER",
  DOMESTIC_SPECIAL_TRANSFER = "DOMESTIC_SPECIAL_TRANSFER",
  FOREIGN_INBOUND_SPECIAL_TRANSFER = "FOREIGN_INBOUND_SPECIAL_TRANSFER",
}

export type BankAccountData = {
  accountNumber: string;
  accountName: string;
  bankCode: BankCode | "";
  bankName: string;
  bankInquiryStatus: BankAccountStatus;
};

export type WithdrawData = {
  amount: string;
};

export interface DurationFormat {
  short: {
    hour: string;
    minute: string;
    second: string;
  };
  long: {
    hour: string;
    minute: string;
    second: string;
  };
}

export type FrequencyDetail = {
  qty: number;
  unit: "day" | "week" | "month";
  ends: "never" | "after";
  occurrences?: number;
  endDate?: Date;
};

export enum ServiceExtraType {
  PROCESSING_TIME = "processing_time",
  BLOCK_TIME = "block_time",
  NONE = "none",
}
export type ServiceCompact = {
  id: string;
  name: string;
};
export type TeamMemberType = {
  id: string;
  name: string;
  type: GetScheduleEventResponseType;
  shifts: {
    startTime: string;
    endTime: string;
  }[];
};

export type ServiceData = {
  id: string;
  name: string;
  price: number;
  startTime: string;
  durationInSeconds: number;
  teamMember: {
    id: string;
    name: string;
  };
  extra: {
    type: ServiceExtraType;
    numberOfMinutes: number;
  };
};

export type ClientData = {
  name: string;
  phoneNumber: string;
  email: string;
};

export type ScheduleEventPassedData = {
  teamMemberID: string;
  resourceID: string;
  eventID: string;
  date: string;
  hour: string;
  teamMemberShift: {
    [teamMemberID: string]: GetTeamMemberShiftsByDateResponse;
  };
  allEvents: FlattenedScheduleEventByEntity[];
};
export type AppointmentData = {
  id: string;
  date: Date;
  client: ClientData;
  time: string; // HH:mm
  frequencyDetail: FrequencyDetail;
  services: Array<ServiceData>;
  notes: string;
  status: ScheduleEventStatus;
  receivedBy: TeamMemberType;
};

export type SaleService = {
  id: string;
  name: string;
  price: number;
  durationInSeconds: number;
  qty: number;
  teamMember: TeamMemberType;
  resource: TeamMemberType;
};
export type SaleData = {
  id: string;
  client: ClientData;
  services: Array<SaleService>;
  paymentStatus: PAYMENT_STATUS;
  notes: string;
  receivedBy: TeamMemberType;
  paidAmount: number;
};

export type DateRangeType =
  | "custom"
  | "today"
  | "yesterday"
  | "last_7_days"
  | "last_30_days"
  | "last_90_days"
  | "last_month"
  | "week_to_date"
  | "month_to_date"
  | "quarter_to_date"
  | "year_to_date";
export type RangeTypeLabelKey = Exclude<DateRangeType, "custom">;
export type BlockTimeType = {
  id: string;
  emoji: string;
  title: string;
  durationInSeconds: number;
};

export type BlockTimeEventType = {
  eventID: string;
  date: string; // YYYY-MM-DD
  title: string;
  startTime: string;
  endTime: string;
  teamMember: TeamMemberType;
  description: string;
  blockTimeID: string;
};
