import React from "react";
import {
  useGetPublicTeamMembersCompact,
} from "../../../../query/queries";
import {
  GetPublicTeamMembersCompactResponse,
  GetTeamMembers,
  TeamMemberStatus,
} from "../../../../api/request.types";
import "./TeamMemberSelector.css";
interface TeamMemberSelectorProps {
  companyID: string;
  productID: string;
  selectedId: GetTeamMembers["teamMemberID"];
  onClickTeamMember?: (teamMember: GetPublicTeamMembersCompactResponse) => void;
}

const TeamMemberSelector: React.FC<TeamMemberSelectorProps> = ({
  companyID,
  selectedId,
  productID,
  onClickTeamMember,
}) => {
  const getActiveTeamMembers = useGetPublicTeamMembersCompact(companyID, {
    status: TeamMemberStatus.ACTIVE,
    productID,
  });

  if (getActiveTeamMembers.isLoading) {
    return <div className="loading">Loading...</div>;
  }

  const activeTeamMembers = getActiveTeamMembers.data?.data || [];

  return (
    <div>
      {activeTeamMembers.length === 0 ? (
        <p style={{ fontSize: "0.75rem" }}>Belum ada pilihan</p>
      ) : (
        <div className="team-member-grid">
          {activeTeamMembers.map((teamMember) => (
            <button
              key={teamMember.teamMemberID}
              className={`team-member ${
                selectedId === teamMember.teamMemberID ? "selected" : ""
              }`}
              onClick={() => onClickTeamMember?.(teamMember)}
              aria-pressed={selectedId === teamMember.teamMemberID}
            >
              {teamMember.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default TeamMemberSelector;
