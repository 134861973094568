import { Box, Skeleton, Typography } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useCallback, useEffect, useMemo, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { COLOR } from "../../utils/color";
import {
  useGetProductByTransaction,
} from "../../query/queries";
import { RootReducerState } from "../../redux/reducers";
import { useSelector } from "react-redux";
import SubMenuHeader from "../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft } from "react-icons/fa";
import { useQuery } from "../../hooks/useQuery";
import { useNavigate } from "react-router-dom";
import { DashboardBaseQuery } from "../../api/request.types";

const TopProduct = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const today = dayjs();

  const defaultStartDate = today.subtract(7, "day");
  const defaultEndDate = today;

  const queryStartDate = query.get("startDate");
  const queryEndDate = query.get("endDate");

  const startDate = queryStartDate ? dayjs(queryStartDate) : defaultStartDate;
  const endDate = queryEndDate ? dayjs(queryEndDate) : defaultEndDate;

  const [timeRange, setTimeRange] = useState<{
    startDate: Dayjs;
    endDate: Dayjs;
  }>({
    startDate,
    endDate,
  });

  const [openPicker, setOpenPicker] = useState<"start" | "end" | null>(null);

  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );

  const dashboardQuery: DashboardBaseQuery = useMemo(
    () => ({
      companyID: userReducer.data.companyId || "",
      endDate: timeRange.endDate.format("YYYY-MM-DD"),
      startDate: timeRange.startDate.format("YYYY-MM-DD"),
      sort: "desc",
    }),
    [timeRange]
  );

  const productByTransactionQuery = useGetProductByTransaction(dashboardQuery);
  const productByTransaction = useMemo(() => {
    return productByTransactionQuery.data?.data?.data;
  }, [productByTransactionQuery.data?.data?.data]);

  const handleDateChange = (newDate: Dayjs | null, type: "start" | "end") => {
    if (!newDate) return;

    setTimeRange((prev) => ({
      ...prev,
      [type === "start" ? "startDate" : "endDate"]: newDate,
    }));
    setOpenPicker(null);
  };

  const updateURLParams = useCallback(
    (newStartDate: Dayjs, newEndDate: Dayjs) => {
      const params = new URLSearchParams();
      params.set("startDate", newStartDate.format("YYYY-MM-DD"));
      params.set("endDate", newEndDate.format("YYYY-MM-DD"));

      navigate(`/admin/top-product?${params.toString()}`, { replace: true });
    },
    [navigate]
  );

  useEffect(() => {
    updateURLParams(timeRange.startDate, timeRange.endDate);
  }, [timeRange, updateURLParams]);

  const _renderLoading = (size: number) =>
    Array.from({ length: size }).map((_, idx) => (
      <Skeleton key={idx} height="68px" sx={{ transform: "none" }} />
    ));

  const _renderProductList = () =>
    productByTransaction?.map((product) => (
      <Box
        key={product.productID}
        display="flex"
        flexDirection="row"
        border={`1px solid ${COLOR.neutral300}`}
        justifyContent="space-between"
        alignItems="center"
        borderRadius={2}
        p={1}
      >
        <Box display="flex" flexDirection="column">
          <Typography
            fontWeight={500}
            color={COLOR.neutral500}
            variant="caption"
          >
            ID: {product.productID}
          </Typography>
          <Typography fontWeight={600}>{product.productName}</Typography>
          <Typography
            style={{
              display: "flex",
              flexDirection: "row",
            }}
            fontWeight={400}
          >
            {"Total Transaksi Berhasil:"}
            &nbsp;
            <Typography fontWeight={600}>{product.total}</Typography>
          </Typography>
        </Box>
      </Box>
    )) || [];

  return (
    <>
      <SubMenuHeader
        leftNav={{
          icon: <FaArrowLeft />,
        }}
        text={"Produk Terlaris"}
      />
      <Box p={2} display="flex" flexDirection="column" gap={1}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          width="100%"
          gap={1}
        >
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            onClick={() => setOpenPicker("start")}
          >
            <Typography variant="caption" fontWeight={600}>
              Transaksi Mulai
            </Typography>
            <Box
              width="100%"
              borderRadius={3}
              overflow="hidden"
              border={`1px solid ${COLOR.neutral300}`}
              px={1}
              py="12px"
            >
              <Typography variant="caption" fontSize={16}>
                {timeRange.startDate.format("DD MMM YYYY")}
              </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            onClick={() => setOpenPicker("end")}
          >
            <Typography variant="caption" fontWeight={600}>
              Transaksi Akhir
            </Typography>
            <Box
              width="100%"
              borderRadius={3}
              overflow="hidden"
              border={`1px solid ${COLOR.neutral300}`}
              px={1}
              py="12px"
            >
              <Typography variant="caption" fontSize={16}>
                {timeRange.endDate.format("DD MMM YYYY")}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Typography fontWeight={700} mt={3}>
          Produk Terlaris
        </Typography>
        <Box display="flex" flexDirection="column" gap={1} mt={1}>
          {productByTransactionQuery.isLoading
            ? _renderLoading(5)
            : _renderProductList()}
        </Box>

        <MobileDatePicker
          sx={{ display: "none" }}
          open={openPicker === "start"}
          onClose={() => setOpenPicker(null)}
          value={timeRange.startDate}
          onChange={(newDate) => handleDateChange(newDate, "start")}
          disableFuture
        />
        <MobileDatePicker
          sx={{ display: "none" }}
          open={openPicker === "end"}
          onClose={() => setOpenPicker(null)}
          value={timeRange.endDate}
          onChange={(newDate) => handleDateChange(newDate, "end")}
          disableFuture
        />
      </Box>
    </>
  );
};

export default TopProduct;
