import { Box, Divider, Typography } from "@mui/material";
import SubMenuHeader from "../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_SNACKBAR_PROPS,
  ROUTE_NAME,
  RouteLabel,
  RoutePath,
} from "../../utils/constant";
import { useEffect, useState } from "react";
import TextInput from "../../components/Forms/TextInput/TextInput";
import { MdOutlineMail } from "react-icons/md";
import { COLOR } from "../../utils/color";
import { addTeamMemberSchema } from "../../joiSchema/addTeamMember";
import Joi from "joi";
import PhoneIcon from "../../assets/svg/PhoneIcon";
import { formatPhoneNumber } from "../../utils/helper";
import CustomButton from "../../components/CustomButton/CustomButton";
import dayjs from "dayjs";
import SingleSelect from "../../components/Forms/SingleSelect/SingleSelect";
import TextArea from "../../components/Forms/TextArea/TextArea";
import DateYearInput from "../../components/Forms/DateYearInput/DateYearInput";
import { LuCalendarDays } from "react-icons/lu";

import {
  CreateTeamMemberPayload,
  EmploymentType,
  TeamMemberStatus,
} from "../../api/request.types";
import { enqueueSnackbar } from "notistack";

export type ProfileDataType = {
  customTeamMemberID: string;
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  phoneNumber: string;
  startDate: string | undefined;
  endDate: string | undefined;
  employmentType: EmploymentType | undefined;
  notes: string;
  status: TeamMemberStatus | undefined;
};

interface TeamProfileMemberProps {
  mode?: "add" | "edit";
  isLoading?: boolean;
  initialData?: ProfileDataType;
  onSubmit: (data: CreateTeamMemberPayload) => Promise<void>;
}

const TeamProfileMember: React.FC<TeamProfileMemberProps> = ({
  mode = "add",
  ...props
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [profileForm, setProfileForm] = useState<ProfileDataType>(
    props.initialData ?? {
      customTeamMemberID: "",
      firstName: "",
      lastName: "",
      email: "",
      jobTitle: "",
      phoneNumber: "",
      startDate: "",
      endDate: "",
      employmentType: EmploymentType.EMPLOYEE,
      notes: "",
      status: undefined,
    }
  );

  const [errors, setErrors] = useState<
    Record<keyof ProfileDataType, string | undefined>
  >({
    customTeamMemberID: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    jobTitle: undefined,
    phoneNumber: undefined,
    startDate: undefined,
    endDate: undefined,
    employmentType: undefined,
    notes: undefined,
    status: undefined,
  });

  const handleOnChangeProfileForm =
    (field: keyof ProfileDataType) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      handleChange(field, e.target.value);
    };
  const handleChange = (
    field: keyof ProfileDataType,
    value: string | undefined
  ) => {
    setProfileForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleBlur = (field: keyof ProfileDataType) => () => {
    const { error } = Joi.object({
      [field]: addTeamMemberSchema.extract(field),
    }).validate({ [field]: profileForm[field] });

    if (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: error.message,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const data: CreateTeamMemberPayload = {
        ...profileForm,
        status: profileForm.status ?? TeamMemberStatus.ACTIVE,
      };

      await props.onSubmit(data);
      enqueueSnackbar({
        ...DEFAULT_SNACKBAR_PROPS,
        variant: "success",
        message: `${data.firstName} ${data.lastName} successfully ${
          mode === "add" ? "created" : "updated"
        }!`,
      });

      navigate(RoutePath[ROUTE_NAME.TEAM_MEMBER], { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar({
        ...DEFAULT_SNACKBAR_PROPS,
        variant: "error",
        message: (error as Error).message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnSubmitSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { error } = addTeamMemberSchema.validate(profileForm, {
      abortEarly: false,
    });

    if (error) {
      const validationErrors: Record<keyof ProfileDataType, string> = {} as any;
      error.details.forEach((detail) => {
        const key = detail.path[0] as keyof ProfileDataType;
        validationErrors[key] = detail.message;
      });

      console.error({ validationErrors });
      setErrors(validationErrors);
      return;
    }

    handleSubmit();
  };

  useEffect(() => {
    if (props.initialData) {
      setProfileForm(props.initialData);
    }
  }, [props.initialData]);

  useEffect(() => {
    if (props.isLoading !== undefined) {
      setIsLoading(props.isLoading);
    }
  }, [props.isLoading]);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <SubMenuHeader
        leftNav={{
          icon: <FaArrowLeft />,
          onClick: () => {
            navigate(-1);
          },
        }}
        text={
          mode === "edit"
            ? RouteLabel[ROUTE_NAME.TEAM_EDIT_MEMBER]
            : RouteLabel[ROUTE_NAME.TEAM_ADD_MEMBER]
        }
      />
      <Box
        component="form"
        pt={2}
        pb={14}
        px={2}
        display="flex"
        flexDirection="column"
        gap={2}
        onSubmit={handleOnSubmitSave}
      >
        <Box>
          <Typography variant="h6" pb={0}>
            {mode === "edit"
              ? `Ubah Profil "${props.initialData?.firstName} ${props.initialData?.lastName}"`
              : "Profil Anggota"}
          </Typography>
          <Typography variant="caption" color={COLOR.neutral500}>
            kelola profil pribadi anggota tim Anda
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <TextInput
            title="Nama Depan"
            required
            sx={{ flex: 1 }}
            textInputProps={{
              placeholder: "John",
              value: profileForm.firstName,
              onChange: handleOnChangeProfileForm("firstName"),
              onBlur: handleBlur("firstName"),
            }}
            error={errors.firstName !== undefined}
            helper={{
              color: COLOR.danger500,
              text: errors.firstName,
            }}
            disabled={isLoading}
          />
          <TextInput
            title="Nama Belakang"
            sx={{ flex: 1 }}
            textInputProps={{
              placeholder: "Doe",
              value: profileForm.lastName,
              onChange: handleOnChangeProfileForm("lastName"),
              onBlur: handleBlur("lastName"),
            }}
            error={errors.lastName !== undefined}
            helper={{
              color: COLOR.danger500,
              text: errors.lastName,
            }}
            disabled={isLoading}
          />
        </Box>
        <TextInput
          title="Email"
          required
          startEndorment={<MdOutlineMail size={20} color={COLOR.neutral500} />}
          textInputProps={{
            placeholder: "address@email.com",
            value: profileForm.email,
            onChange: handleOnChangeProfileForm("email"),
            onBlur: handleBlur("email"),
          }}
          error={errors.email !== undefined}
          helper={{
            color: COLOR.danger500,
            text: errors.email,
          }}
          disabled={isLoading}
        />

        <TextInput
          title="No Telepon"
          startEndorment={
            <>
              <PhoneIcon color={COLOR.neutral400} />
              <Typography color={COLOR.neutral400} ml={"4px"}>
                +62
              </Typography>
            </>
          }
          textInputProps={{
            placeholder: "123123123",
            value: formatPhoneNumber(profileForm.phoneNumber),
            onChange: handleOnChangeProfileForm("phoneNumber"),
            onBlur: handleBlur("phoneNumber"),
            pattern: "[0-9-]*",
            inputMode: "numeric",
          }}
          helper={{
            color: COLOR.danger500,
            text: errors["phoneNumber"],
          }}
          error={Boolean(errors["phoneNumber"])}
          disabled={isLoading}
        />

        <TextInput
          title="Nama Jabatan"
          required
          sx={{ flex: 1 }}
          additionalPrefix={
            <Typography
              variant="caption"
              fontWeight={400}
              color={COLOR.neutral400}
            >
              (Terlihat oleh customer online)
            </Typography>
          }
          textInputProps={{
            placeholder: "Instruktur / Therapist / lain-lain",
            value: profileForm.jobTitle,
            onChange: handleOnChangeProfileForm("jobTitle"),
            onBlur: handleBlur("jobTitle"),
          }}
          error={errors.jobTitle !== undefined}
          helper={{
            color: COLOR.danger500,
            text: errors.jobTitle,
          }}
          disabled={isLoading}
        />
        <Divider sx={{ pt: 5 }} />

        <Box>
          <Typography variant="h6" pb={0}>
            Rincian Kerja
          </Typography>
          <Typography variant="caption" color={COLOR.neutral500}>
            Kelola tanggal mulai anggota tim Anda, dan detail pekerjaan
          </Typography>
        </Box>

        <DateYearInput
          title="Tanggal Mulai"
          startEndorment={
            <LuCalendarDays size="24px" color={COLOR.neutral400} />
          }
          value={
            profileForm.startDate && profileForm.startDate !== ""
              ? dayjs(profileForm.startDate)
              : undefined
          }
          placeholder="Pilih tanggal"
          onChange={(date) => {
            const formattedDate = date?.format("YYYY-MM-DD");
            handleChange("startDate", formattedDate);
            handleBlur("startDate")();
            handleBlur("endDate")();
          }}
          error={errors.startDate !== undefined}
          helper={{
            color: COLOR.danger500,
            text: errors.startDate,
          }}
          disabled={isLoading}
        />
        <DateYearInput
          title="Tanggal Akhir"
          startEndorment={
            <LuCalendarDays size="24px" color={COLOR.neutral400} />
          }
          value={profileForm.endDate ? dayjs(profileForm.endDate) : undefined}
          placeholder="Pilih tanggal"
          onChange={(date) => {
            const formattedDate = date?.format("YYYY-MM-DD");
            handleChange("endDate", formattedDate);
            handleBlur("startDate")();
            handleBlur("endDate")();
          }}
          error={errors.endDate !== undefined}
          helper={{
            color: COLOR.danger500,
            text: errors.endDate,
          }}
          disabled={isLoading}
        />
        <SingleSelect
          title="Jenis Pekerjaan"
          optionProps={{
            value: profileForm.employmentType,
            onChange: (selectedOption: string | undefined) => {
              handleChange("employmentType", selectedOption);
            },
            options: [
              {
                label: "Karyawan tetap (Employee)",
                value: EmploymentType.EMPLOYEE,
              },
              {
                label: "Wiraswasta (Self Employed)",
                value: EmploymentType.SELF_EMPLOYEE,
              },
            ],
            placeholder: "Pilih opsi",
          }}
          error={errors.employmentType !== undefined}
          helper={{
            color: COLOR.danger500,
            text: errors.employmentType,
          }}
          disabled={isLoading}
        />
        <TextInput
          title="ID Anggota Tim"
          sx={{ flex: 1 }}
          additionalPrefix={
            <Typography
              variant="caption"
              fontWeight={400}
              color={COLOR.neutral400}
            >
              (Terlihat oleh customer online)
            </Typography>
          }
          textInputProps={{
            placeholder: "ID-001",
            value: profileForm.customTeamMemberID,
            onChange: handleOnChangeProfileForm("customTeamMemberID"),
            onBlur: handleBlur("customTeamMemberID"),
          }}
          error={errors.customTeamMemberID !== undefined}
          helper={{
            color:
              errors.customTeamMemberID !== undefined
                ? COLOR.danger500
                : undefined,
            text:
              errors.customTeamMemberID ??
              "Kode unik yang digunakan untuk sistem eksternal seperti penggajian",
          }}
          disabled={isLoading}
        />

        <TextArea
          title="Catatan"
          textAreaProps={{
            placeholder:
              "Tambahkan catatan pribadi yang hanya dapat dilihat di daftar anggota tim",
            value: profileForm.notes,
            onChange: handleOnChangeProfileForm("notes"),
            onBlur: handleBlur("notes"),
          }}
          disabled={isLoading}
        />
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "white",
            boxShadow: 2,
            p: 2,
          }}
        >
          <CustomButton type="submit" disabled={isLoading}>
            Simpan
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default TeamProfileMember;
