import React, { useState } from "react";
import { FormBaseProps } from "../../../types/globalTypes";
import InputTitle from "../InputTitle/InputTitle";
import HelperText from "../HelperText/HelperText";
import { Box, BoxProps, SxProps, Typography } from "@mui/material";
import { Theme } from "@emotion/react";
import { ContainerStyle, DisabledContainerStyle, EndormentStyle, ErrorContainerStyle, valueSx } from "../form.styles";
import { MobileDatePicker, MobileDatePickerProps } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { COLOR } from "../../../utils/color";

interface DateYearInputPropsI extends FormBaseProps {
  startEndorment?: React.ReactNode;
  endEndorment?: React.ReactNode;
  DateYearInputProps?: BoxProps;
  disabled?: boolean;
  error?: boolean;
  value?: Dayjs | null;
  sx?: SxProps<Theme>;
  dateFormat?: string;
  datePickerProps?: MobileDatePickerProps<Dayjs>;
  placeholder?: string;
  onChange?: (date: Dayjs | null) => void;
}

const DateYearInput: React.FC<DateYearInputPropsI> = ({
  title,
  required,
  helper,
  disabled = false,
  error = false,
  value = null,
  DateYearInputProps,
  dateFormat = "DD MMM YYYY",
  sx,
  startEndorment,
  endEndorment,
  datePickerProps,
  placeholder = "",
  onChange = () => {},
}) => {
  const formattedValue = value ? dayjs(value).format(dateFormat) : placeholder;
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);

  const handleOpenDatePicker = () => {
    if (!disabled) {
      setDatePickerOpen(true);
    }
  };

  const handleDateChange = (date: Dayjs | null) => {
    onChange(date);
    setDatePickerOpen(false);
  };

  return (
    <Box sx={{ ...sx, display: "flex", flexDirection: "column" }}>
      <InputTitle
        title={title}
        required={required}
        textProps={{
          sx: { mb: 1 },
        }}
      />
      <Box
        sx={{
          ...ContainerStyle,
          ...({ pointer: 'cursor' }),
          ...(disabled ? DisabledContainerStyle : {}),
          ...(error ? ErrorContainerStyle : {}),
          ...DateYearInputProps?.sx,
        } as SxProps<Theme>}
        {...DateYearInputProps}
        onClick={handleOpenDatePicker}
      >
        {startEndorment && (
          <Box component="span" sx={{ ...EndormentStyle, mr: 1 }}>
            {startEndorment}
          </Box>
        )}
        <Typography sx={{
          ...valueSx,
          ...(disabled ? { color: COLOR.neutral300 } : {}),
        }} aria-label="Selected date">
          {formattedValue}
        </Typography>
        {endEndorment && (
          <Box component="span" sx={{ ...EndormentStyle, ml: 1 }}>
            {endEndorment}
          </Box>
        )}
      </Box>
      {helper && <HelperText {...helper} />}
      <MobileDatePicker
        sx={{ display: 'none' }}
        open={isDatePickerOpen}
        onClose={() => setDatePickerOpen(false)}
        value={value}
        onChange={handleDateChange}
        {...datePickerProps}
      />
    </Box>
  );
};

export default DateYearInput;
