import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import CustomButton from "../CustomButton/CustomButton";
import ActionBottomSheet, { ActionBottomSheetProps } from "../BottomSheets/ActionBottomSheet";
import SingleSelect from "../Forms/SingleSelect/SingleSelect";
import { TeamMemberFilters } from "../../pages/TeamMember/TeamMember";
import { TeamMemberStatus } from "../../api/request.types";

interface TeamMemberFilterBottomSheetProps {
  modalProps: ActionBottomSheetProps;
  initialData: TeamMemberFilters;
  onClear?: () => void;
  onApply?: (filter: TeamMemberFilters) => void;
}

const TeamMemberFilterBottomSheet: React.FC<TeamMemberFilterBottomSheetProps> = ({
  modalProps,
  initialData,
  onClear,
  onApply,
}) => {
  const [filter, setFilter]= useState<TeamMemberFilters>(initialData);
  const onChangeFilter = (filter: Partial<TeamMemberFilters>) => {
    setFilter((prevFilters) => {
      return { ...prevFilters, ...filter };
    });
  };

  return (
    <ActionBottomSheet
      {...modalProps}
    >
      <Box display="flex" flexDirection="column" p={2} gap={1}>
        <Typography variant="h6" pb={3}>
          Filter
        </Typography>
        <SingleSelect
          title="Sort By"
          sx={{ flex: 1 }}
          optionProps={{
            placeholder: "Select sort by",
            value: filter?.sortDirections,
            onChange: (selectedValue?: string) => {
              if (selectedValue) {
                onChangeFilter({ sortDirections: selectedValue });
              }
            },
            options: [
              { label: "A-Z", value: "asc" },
              { label: "Z-A", value: "desc" },
            ],
          }}
        />
        <SingleSelect
          title="Status"
          sx={{ flex: 1 }}
          optionProps={{
            placeholder: "Select status",
            sx: { maxHeight: "120px" },
            value: filter?.status,
            onChange: (selectedValue?: string) => {
              if (selectedValue) {
                onChangeFilter({ status: selectedValue as TeamMemberStatus });
              }
            },
            options: [
              { label: "Semua Tim Member", value: TeamMemberStatus.ALL },
              { label: "Aktif", value: TeamMemberStatus.ACTIVE },
              {
                label: "Diarsip",
                value: TeamMemberStatus.INACTIVE,
              },
            ],
          }}
        />
        <Box display="flex" flexDirection="row" gap={1} pt={8}>
          <CustomButton
            variant="text-secondary"
            onClick={() => {
              onChangeFilter(({...initialData}));
              onClear?.();
            }}
          >
            Clear Filters
          </CustomButton>
          <CustomButton onClick={() => {
            onApply?.(filter)
          }}>Apply</CustomButton>
        </Box>
      </Box>
    </ActionBottomSheet>
  );
};

export default TeamMemberFilterBottomSheet;
