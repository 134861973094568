type envType = {
  ENV: string;
  APP_ENV: "staging" | "production" | "development";
  APP_NAME: string;
  GOOGLE_AUTH_SECRET: string;
  GOOGLE_AUTH_CLIENT_ID: string;
  BASE_URL: string;
  MIDTRANS_CLIENT_KEY: string;
  MIDTRANS_API_URL: string;
  APP_VERSION: string;
};
const ENV: envType = {
  ENV: process.env.ENV || "development",
  APP_ENV: (process.env.REACT_APP_ENV as envType["APP_ENV"]) || "development",
  APP_NAME: process.env.REACT_APP_NAME || "Neema Development",
  GOOGLE_AUTH_SECRET: process.env.REACT_APP_GOOGLE_AUTH_SECRET || "",
  GOOGLE_AUTH_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID || "",
  BASE_URL: process.env.REACT_APP_BASE_URL || "",
  MIDTRANS_CLIENT_KEY:
    process.env.MIDTRANS_CLIENT_KEY || "SB-Mid-client-GTCOJlLedX5kqkaW",
  MIDTRANS_API_URL:
    process.env.MIDTRANS_API_URL || "https://app.sandbox.midtrans.com",
  APP_VERSION: process.env.REACT_APP_VERSION || "v0.0.1",
};
console.log(ENV)
export default ENV;
