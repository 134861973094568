import { Box, Typography } from '@mui/material';
import React, { MouseEventHandler } from 'react';
import { MemberItemContainer, MemberItemContent, MemberItemIndicatorContainer, MemberTextContainer, ViewMoreButton } from './MemberItem.styles';
import { IoMdMore } from 'react-icons/io';
import OpacityButton from '../OpacityButton/OpacityButton';
import { getInitials } from '../../utils/helper';
import { COLOR } from '../../utils/color';
import ProfileInitial from '../ProfileInitial/ProfileInitial';


interface MemberItemPropsI {
  name: string;
  email: string;
  status: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onClickViewMore?: MouseEventHandler<HTMLDivElement>;
}
const MemberItem: React.FC<MemberItemPropsI> = (props) => {
  return (
    <OpacityButton sx={MemberItemContainer} onClick={props.onClick}>
      <Box sx={MemberItemContent}>
        <ProfileInitial name={props.name} />
        <Box sx={MemberTextContainer}>
          <Typography variant='body1'>{props.name}</Typography>
          <Typography variant='caption' color={COLOR.neutral500}>
            {props.status}
          </Typography>
          <Typography variant='body2' color={COLOR.neutral500}>
            {props.email}
          </Typography>
        </Box>
      </Box>
      <OpacityButton
        component="div"
        sx={ViewMoreButton}
        onClick={(e) => {
          e.stopPropagation();
          props?.onClickViewMore?.(e);
        }}>
        <IoMdMore size="24px" />
      </OpacityButton>
    </OpacityButton>
  );
};


export default MemberItem;