import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { COOKIE, ROUTE_NAME, RouteIcon, RouteLabel, RoutePath } from '../../utils/constant';
import { COLOR } from '../../utils/color';
import { useDispatch, useSelector } from 'react-redux';
import { useLogout } from '../../query/mutations';
import { RootReducerState } from '../../redux/reducers';
import { useFindUserById, useGetCompanyAccountByID } from '../../query/queries';
import Cookies from 'js-cookie';
import { clearAll } from '../../redux/actions';
import WithdrawCard from '../../components/WithdrawCard/WithdrawCard';
import { BankAccountStatus } from '../../types/globalTypes';
import CustomButton from '../../components/CustomButton/CustomButton';
import ENV from '../../utils/env';

interface MoreButtonPropsI {
  children?: React.ReactNode;
  to: string;
}
const MoreButton: React.FC<MoreButtonPropsI> = (props) => {
  return (
    <Box
      component={Link}
      to={props.to}
      sx={{
        borderRadius: 1,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        p: 2,
        gap: 1,
        border: `1px solid ${COLOR.neutral300}`,
        backgroundColor: 'white',
        color: COLOR.neutral900,
        textDecoration: 'none',
        '&:hover, &:active': {
          cursor: 'pointer',
        }
      }}>
      {props.children}
    </Box>
  )
};

const MoreButtonList = [
  {
    to: RoutePath[ROUTE_NAME.SCHEDULE],
    label: RouteLabel[ROUTE_NAME.SCHEDULE],
    Icon: RouteIcon[ROUTE_NAME.SCHEDULE],
  },
  {
    to: RoutePath[ROUTE_NAME.SCHEDULE_V2],
    label: RouteLabel[ROUTE_NAME.SCHEDULE_V2],
    Icon: RouteIcon[ROUTE_NAME.SCHEDULE_V2],
  },
  {
    to: RoutePath[ROUTE_NAME.SALES],
    label: RouteLabel[ROUTE_NAME.SALES],
    Icon: RouteIcon[ROUTE_NAME.SALES],
  },
  {
    to: RoutePath[ROUTE_NAME.TEAM],
    label: RouteLabel[ROUTE_NAME.TEAM],
    Icon: RouteIcon[ROUTE_NAME.TEAM],
  },
  {
    to: RoutePath[ROUTE_NAME.BUSINESS],
    label: RouteLabel[ROUTE_NAME.BUSINESS],
    Icon: RouteIcon[ROUTE_NAME.BUSINESS],
  },
  {
    to: RoutePath[ROUTE_NAME.DASHBOARD],
    label: RouteLabel[ROUTE_NAME.DASHBOARD],
    Icon: RouteIcon[ROUTE_NAME.DASHBOARD],
  },
  // {
  //   to: RoutePath[ROUTE_NAME.PERMISSION],
  //   label: RouteLabel[ROUTE_NAME.PERMISSION],
  //   Icon: RouteIcon[ROUTE_NAME.PERMISSION],
  // },
]

const MorePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutQuery = useLogout();
  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );
  const companyId = userReducer.data.companyId || "";

  const getUserProfileById = useFindUserById(userReducer.data.userId || "");
  const userProfile = getUserProfileById.data?.data?.data;

  const getCompanyAccountById = useGetCompanyAccountByID(companyId, {
    enabled: true,
  });
  const companyAccount = getCompanyAccountById.data?.data.data;

  const logoutUser = () => {
    if (Cookies.get(COOKIE.TOKEN)) {
      logoutQuery.mutate(undefined, {
        onSuccess: () => {
          dispatch(clearAll());
          Cookies.remove(COOKIE.TOKEN, { path: "/" });
          navigate(RoutePath.LOGIN, { replace: true });
        },
      });
    } else {
      navigate(RoutePath.LOGIN, { replace: true });
    }
  };

  return (
    <Box px={2} gap={2} display="flex" flexDirection="column" py={5}>
      <Box height="24px" display="flex" justifyContent="center">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/neema-b7c3b.appspot.com/o/public-web-assets%2FLogo.png?alt=media&token=3ddd51e9-0144-40a6-a29e-6e23f91cda8c"
          alt="neema-logo"
          className="img-default"
          style={{ width: "fit-content" }}
        />
      </Box>
      <Box
        p={1}
        sx={{
          borderRadius: 2,
          wordBreak: "break-all",
          border: `1px solid ${COLOR.neutral300}`,
          backgroundColor: 'white',
        }}
      >
        {getUserProfileById.isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography variant='body2'>Nama: </Typography>
              <Typography variant='body2'>{userProfile?.fullName}</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography variant='body2'>Email: </Typography>
              <Typography variant='body2'>{userProfile?.email}</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography variant='body2'>No Hp: </Typography>
              <Typography variant='body2'>
                {userProfile?.phoneNumber || "-"}
              </Typography>
            </Box>
          </>
        )}
      </Box>

      {getCompanyAccountById.isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <WithdrawCard
          amount={companyAccount?.availableAmount || 0}
          bankInquiryStatus={
            companyAccount?.bankInquiryStatus || BankAccountStatus.UNVERIFIED
          }
          onClickWithdraw={() => {
            navigate(RoutePath.WITHDRAW);
          }}
        />
      )}

      <Typography pt={2} variant='h6'>Navigasi Cepat</Typography>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2, pb: 5, }}>
        {MoreButtonList.map(button => {
          return (
            <MoreButton key={button.label} to={button.to}>
              <button.Icon size="18px" />
              <Typography variant='body2'>{button.label}</Typography>
            </MoreButton>
          )
        })}
      </Box>

      <Typography variant='caption' textAlign="center">
        {ENV.APP_VERSION} {ENV.APP_ENV !== 'production' && ENV.APP_ENV}
      </Typography>
      <CustomButton
        variant="outlined"
        onClick={() => {
          navigate(RoutePath[ROUTE_NAME.RESET_PASSWORD]);
        }}
      >
        Reset Password
      </CustomButton>
      <CustomButton
        disabled={logoutQuery.isLoading}
        onClick={() => {
          logoutUser();
        }}
      >
        {logoutQuery.isLoading ? "Loading..." : "Logout"}
      </CustomButton>

    </Box>
  )
};
export default MorePage;