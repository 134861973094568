import React from "react";
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import { COLOR } from "../../utils/color";

interface Option {
  label: string;
  value: string;
}

interface SwitchTabsPropsI {
  options: [Option, Option];
  value: Option["value"];
  handleChange: (value: Option["value"]) => void;
  bgPrimary?: string;
  bgSecondary?: string;
  textPrimary?: string;
  textSecondary?: string;
  fullWidth?: boolean;
}

const SwitchWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "bgSecondary" && prop !== "fullWidth",
})<{ bgSecondary?: string; fullWidth?: boolean }>(({ bgSecondary }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  width: "100%",
  height: "36px",
  backgroundColor: bgSecondary,
  borderRadius: "20px",
  overflow: "hidden",
  cursor: "pointer",
  border: `1px solid ${COLOR.neutral300}`,
}));

interface SwitchIndicatorProps {
  activeIndex: number;
  totalOptions: number;
  bgPrimary?: string;
}
const SwitchIndicator = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "activeIndex" && prop !== "totalOptions" && prop !== "bgPrimary",
})<SwitchIndicatorProps>(({ activeIndex, totalOptions, bgPrimary }) => ({
  position: "absolute",
  width: `${100 / totalOptions}%`,
  height: "100%",
  backgroundColor: bgPrimary,
  borderRadius: "20px",
  left: 0,
  transform: `translateX(${activeIndex * 100}%)`,
  transition: "all 0.3s ease",
  zIndex: 1,
}));

interface SwitchTabProps {
  active: boolean;
  textPrimary?: string;
  textSecondary?: string;
}
const SwitchTab = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "textPrimary" && prop !== "textSecondary",
})<SwitchTabProps>(({ active, textPrimary, textSecondary }) => ({
  zIndex: 2,
  color: active ? textPrimary : textSecondary,
  fontWeight: active ? "bold" : 500,
  fontSize: "14px",
  flex: 1,
  textAlign: "center",
  cursor: "pointer",
  userSelect: "none",
}));

const SwitchTabs: React.FC<SwitchTabsPropsI> = ({
  options,
  value,
  handleChange,
  bgPrimary = COLOR.primary500,
  bgSecondary = COLOR.neutral50,
  textPrimary = COLOR.neutral50,
  textSecondary = COLOR.primary500,
}) => {
  const activeIndex = options.findIndex((option) => option.value === value);

  return (
    <SwitchWrapper bgSecondary={bgSecondary}>
      <SwitchIndicator
        activeIndex={activeIndex}
        totalOptions={options.length}
        bgPrimary={bgPrimary}
      />
      {options.map((option) => (
        <SwitchTab
          key={option.value}
          active={option.value === value}
          textPrimary={textPrimary}
          textSecondary={textSecondary}
          onClick={() => handleChange(option.value)}
        >
          {option.label}
        </SwitchTab>
      ))}
    </SwitchWrapper>
  );
};

export default SwitchTabs;
