import { Box, Skeleton, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import SubMenuHeader from '../../components/Layout/SubMenuHeader/SubMenuHeader';
import { FaArrowLeft, FaWallet } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ROUTE_NAME, RouteLabel } from '../../utils/constant';
import DateRangePicker from '../../components/Forms/DateRangePicker/DateRangePicker';
import { COLOR } from '../../utils/color';
import { formatCurrency } from '../../utils/helper';
import { DashboardBaseQuery } from '../../api/request.types';
import { useGetCompanyByIdV2, useGetProductByTransaction, useGetTotalTransactionPerDate, useGetTransactionByStatus, useGetTransactionOverview } from '../../query/queries';
import dayjs, { Dayjs } from 'dayjs';
import { RootReducerState } from '../../redux/reducers';
import { useSelector } from 'react-redux';
import { BarChart } from '@mui/x-charts';
import { PieChart } from "@mui/x-charts";

const SaleReportPage = () => {
  const navigate = useNavigate();

  const userReducer = useSelector((state: RootReducerState) => state.userReducer);
  const companyId = userReducer.data.companyId || "";
  const [dateRange, setDateRange] = useState<{
    startDate: Dayjs;
    endDate: Dayjs;
  }>({ startDate: dayjs().startOf("weeks"), endDate: dayjs().endOf("weeks") });

  const getCompanyById = useGetCompanyByIdV2(companyId, { enabled: true });
  const companyDetail = getCompanyById.data?.data.data;

  const dashboardQuery: DashboardBaseQuery = useMemo(
    () => ({
      startDate: dateRange.startDate.format("YYYY-MM-DD"),
      endDate: dateRange.endDate.format("YYYY-MM-DD"),
      companyID: companyId,
      sort: "desc",
    }),
    [companyId, dateRange.endDate, dateRange.startDate]
  );

  const totalTransactionPerDateQuery = useGetTotalTransactionPerDate(dashboardQuery);
  const totalTransactionPerDate = useMemo(() => {
    return totalTransactionPerDateQuery.data?.data?.data;
  }, [totalTransactionPerDateQuery.data?.data?.data]);

  const totalTransactionByStatusQuery = useGetTransactionByStatus(dashboardQuery);
  const totalTransactionByStatus = useMemo(() => {
    return totalTransactionByStatusQuery.data?.data?.data;
  }, [totalTransactionByStatusQuery.data?.data?.data]);

  const transactionOverviewQuery = useGetTransactionOverview(dashboardQuery);
  const transactionOverview = useMemo(() => {
    return transactionOverviewQuery.data?.data?.data;
  }, [transactionOverviewQuery.data?.data?.data]);

  const productByTransactionQuery = useGetProductByTransaction(dashboardQuery);
  const productByTransaction = useMemo(() => {
    return productByTransactionQuery.data?.data?.data;
  }, [productByTransactionQuery.data?.data?.data]);

  const dataset = () => {
    const dateData = [];
    for (
      let startDate = dayjs(dashboardQuery.startDate);
      startDate.isSame(dashboardQuery.endDate) ||
      startDate.isBefore(dashboardQuery.endDate);
      startDate = startDate.add(1, "days")
    ) {
      dateData.push({
        day: startDate.format("YYYY-MM-DD"),
        transactionCount: 0,
      });
    }
    return dateData;
  };

  const datasetPie = [
    { label: "Berhasil (0)", value: 0, color: COLOR.success500 },
    { label: "Gagal (0)", value: 0, color: COLOR.danger500 },
    { label: "Pending (0)", value: 0, color: COLOR.neutral500 },
  ];

  const _renderLoading = (size: number) =>
    Array.from({ length: size }).map((_, idx) => (
      <Skeleton key={idx} height="68px" sx={{ transform: "none" }} />
    ));

  const _renderProductList = () => {
    return (
      <Box display="flex" gap={1} flexDirection="column">
        {productByTransaction?.map((product) => (
          <Box
            key={product.productID}
            display="flex"
            flexDirection="row"
            border={`1px solid ${COLOR.neutral300}`}
            justifyContent="space-between"
            alignItems="center"
            borderRadius={2}
            p={1}
          >
            <Box display="flex" flexDirection="column">
              <Typography
                fontWeight={500}
                color={COLOR.neutral500}
                variant="caption"
              >
                ID: {product.productID}
              </Typography>
              <Typography fontWeight={600}>{product.productName}</Typography>
              <Typography
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
                fontWeight={400}
              >
                {"Total Transaksi Berhasil:"}
                &nbsp;
                <Typography fontWeight={600}>{product.total}</Typography>
              </Typography>
            </Box>
          </Box>
        )) || []}
      </Box>
    )
  }



  const _renderSuccessTransaction = () => {
    return (
      <BarChart
        sx={{
          width: "100%",
          ".MuiBarLabel-root": {
            fill: "white",
          },
        }}
        height={300}
        dataset={
          totalTransactionPerDateQuery.isLoading || !totalTransactionPerDate
            ? dataset()
            : totalTransactionPerDate.map((data) => ({
              day: data.date,
              transactionCount: data.total,
            }))
        }
        xAxis={[{ scaleType: "band", dataKey: "day" }]}
        borderRadius={10}
        series={[
          {
            dataKey: "transactionCount",
            color: COLOR.primary500,
          },
        ]}
        barLabel="value"
      />
    )
  }
  const _renderTransactionByStatus = () => {
    return (
      <PieChart
        series={[
          {
            data: totalTransactionByStatus?.[0]
              ? [
                {
                  label: `Berhasil (${totalTransactionByStatus[0]?.success || 0
                    })`,
                  value: totalTransactionByStatus[0]?.success || 0,
                  color: COLOR.success500,
                },
                {
                  label: `Pending (${totalTransactionByStatus[0]?.pending || 0
                    })`,
                  value: totalTransactionByStatus[0]?.pending || 0,
                  color: COLOR.neutral500,
                },
                {
                  label: `Gagal (${totalTransactionByStatus[0]?.failed || 0
                    })`,
                  value: totalTransactionByStatus[0]?.failed || 0,
                  color: COLOR.danger500,
                },
              ]
              : datasetPie,
            highlightScope: { fade: "global", highlight: "item" },
            faded: {
              innerRadius: 30,
              additionalRadius: -30,
              color: "gray",
            },
            innerRadius: 45,
          },
        ]}
        slotProps={{
          legend: {
            itemMarkHeight: 10,
            itemMarkWidth: 10,
            labelStyle: {
              fontSize: 14,
            },
          },
        }}
        height={200}
      />
    )
  }
  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <SubMenuHeader
        leftNav={{
          icon: <FaArrowLeft />,
          onClick: () => {
            navigate(-1);
          },
        }}
        text={RouteLabel[ROUTE_NAME.SALE_REPORT]}
      />
      <Box sx={{ p: 2, pb: 5, backgroundColor: 'white', display: 'flex', flex: 1, flexDirection: 'column' }}>
        <Box my={3}>
          <Typography variant="body1" fontWeight={600} mb={1}>
            Analisa Bisnis
          </Typography>
          <Box my={1}>
            <DateRangePicker
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              onChangeStartDate={(date) => {
                setDateRange((prev) => ({
                  ...prev,
                  startDate: date,
                }));
              }}
              onChangeEndDate={(date) => {
                setDateRange((prev) => ({
                  ...prev,
                  endDate: date,
                }));
              }}
            />
          </Box>
          <Box display="flex" flexDirection="row" gap={1}>
            <Box
              p={1}
              border={`1px solid ${COLOR.neutral200}`}
              borderRadius={2}
              flex={1}
            >
              <Typography
                fontWeight={500}
                fontSize={12}
                color={COLOR.neutral500}
                display="flex"
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
                gap={2}
              >
                Total Penghasilan <FaWallet />
              </Typography>
              <Typography fontWeight={700} fontSize={16}>
                {formatCurrency(
                  transactionOverview?.totalTransactionAmount || 0,
                  companyDetail?.currency || "IDR"
                )}
              </Typography>
            </Box>
          </Box>

          <Box sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            justifyContent: "center",
            mt: 2
          }}>
            <Box
              sx={{
                flex: {
                  xs: "1 1 100%",
                  sm: "1 1 calc(50% - 8px)",
                },
                border: `1px solid ${COLOR.neutral200}`,
                borderRadius: 2
              }}
            >
              <Typography my={2} fontWeight={600} textAlign="center">
                Jumlah Transaksi Berhasil
              </Typography>
              {_renderSuccessTransaction()}
            </Box>
            <Box
              sx={{
                flex: {
                  xs: "1 1 100%",
                  sm: "1 1 calc(50% - 8px)",
                },
                border: `1px solid ${COLOR.neutral200}`,
                borderRadius: 2
              }}
            >
              <Typography my={2} fontWeight={600} textAlign="center">
                Jumlah Transaksi Berdasarkan Status
              </Typography>
              {_renderTransactionByStatus()}
            </Box>

            <Box
              sx={{
                flex: {
                  xs: "1 1 100%",
                  sm: "1 1 calc(50% - 8px)",
                },
              }}
            >
              <Typography my={2} fontWeight={600}>
                Produk Terlaris
              </Typography>
              {productByTransactionQuery.isLoading
                ? _renderLoading(5)
                : _renderProductList()}
            </Box>
          </Box>
        </Box>

      </Box>
    </Box>
  )
};

export default SaleReportPage;