import { Box } from '@mui/material';
import React from 'react';
import MenuNextButton, { MenuNextButtonPropsI } from '../MenuNextButton/MenuNextButton';
import { COLOR } from '../../utils/color';


interface MenuListButtonPropsI {
  buttons: Array<MenuNextButtonPropsI>
}
const MenuListButton = (props: MenuListButtonPropsI) => {
  const { buttons } = props;
  return (
    <Box sx={{ border: `1px solid ${COLOR.neutral300}`, backgroundColor: 'white', p: 1, borderRadius: 2, display: 'flex', flexDirection: 'column', gap: 1, }}>
      {buttons.map((buttonProps, index) => (
        <MenuNextButton
          key={index}
          {...buttonProps} />
      ))}
    </Box>
  )
};

export default MenuListButton;