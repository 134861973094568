import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { COLOR } from "../../utils/color";


export const MemberItemContainer: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: 1,
  p: 1,
  borderRadius: 2,
  alignItems: 'center',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: COLOR.neutral100,
  },
};

export const MemberItemIndicatorContainer: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '64px',
  aspectRatio: 1,
  backgroundColor: COLOR.primary100,
  borderRadius: '50%',
  border: `2px solid ${COLOR.primary200}`
};

export const MemberItemContent: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 2,
  flex: 1,
};
export const MemberTextContainer: SxProps<Theme> = {
  display: 'flex', flexDirection: 'column', textAlign: 'left'
}

export const ViewMoreButton: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'fit-content',
  height: 'fit-content',
  p: 1,
  minWidth: '40px',
  minHeight: '40px',
  borderRadius: 2,
  "&:hover": {
    cursor: 'pointer',
    backgroundColor: COLOR.neutral200,
  }
};