import { Checkbox, SxProps, Theme } from '@mui/material';
import OpacityButton from '../OpacityButton/OpacityButton';
import { BpCheckedIcon, BpIcon } from '../CheckboxIcon/CheckboxIcon';
import { ReactNode } from 'react';

interface CheckboxButtonProps {
  checked?: boolean;
  disabled?: boolean;
  onToggle?: () => void;
  children: ReactNode;
  checkboxSx?: SxProps<Theme>;
  buttonSx?: SxProps<Theme>;
  checkedIcon?: ReactNode;
  uncheckedIcon?: ReactNode;
}

export const CheckboxButton = ({
  checked = false,
  disabled = false,
  onToggle,
  children,
  checkboxSx,
  buttonSx,
  checkedIcon = <BpCheckedIcon />,
  uncheckedIcon = <BpIcon />,
}: CheckboxButtonProps) => {
  return (
    <OpacityButton disabled={disabled} onClick={onToggle} sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      ...buttonSx,
    }}>
      <Checkbox
        checked={checked}
        disableRipple
        checkedIcon={checkedIcon}
        icon={uncheckedIcon}
        sx={{ p: 0, mr: 1, pointerEvents: "none", ...checkboxSx }}
      />
      {children}
    </OpacityButton>
  );
};
