import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { COLOR } from "../../utils/color";

export const FilterBtn: SxProps<Theme> = {
  aspectRatio: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: 1,
  borderRadius: 2,
  minWidth: '30px',
  minHeight: '30px',
  border: `1px solid ${COLOR.neutral300}`,
  backgroundColor:'white',
};
