import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

export const buttonContainer: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  p: 1,
  width: '100%',
  alignItems: 'center',
};
export const buttonItemContainer: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  gap: 2,
  width: '100%',
  textAlign: 'left',
  alignItems: 'center',
};

export const buttonText: SxProps<Theme> = {
  flex: 1,
}