import React, { useMemo } from "react";
import { ServiceData, ServiceExtraType } from "../../types/globalTypes";
import {
  formatCurrency,
  formatDurationBySecond,
  generateWarningMessage,
} from "../../utils/helper";
import { COLOR } from "../../utils/color";
import { Box, Typography } from "@mui/material";
import OpacityButton from "../OpacityButton/OpacityButton";
import { ScheduleEventProductDetail } from "../../api/request.types";
import WarningIcon from "../../assets/svg/WarningIcon";
import { DOT_SEPARATOR, TIME_FORMAT } from "../../utils/constant";
import dayjs from "dayjs";

type ScheduleEventProductDetailSubset = Omit<
  Pick<
    ScheduleEventProductDetail,
    | "productName"
    | "duration"
    | "teamMemberName"
    | "price"
    | "startTime"
    | "endTime"
    | "resourceName"
  >,
  "startTime" | "endTime"
> &
  Partial<Pick<ScheduleEventProductDetail, "startTime" | "endTime">>;

export enum ServiceItemWarningType {
  OUTSIDE_SHIFT = "outside_shift",
  BLOCK_TIME = "block_time",
  EXISTING_BOOKING = "existing_booking",
  RESOURCE_UNAVAILABLE = "resource_unavailable",
  RESOURCE_NOT_SUITABLE = "resource_not_suitable",
}
export interface ServiceItemWarning {
  type: ServiceItemWarningType;
  time: string;
  name: string;
}
interface ServiceItemProps {
  service: ScheduleEventProductDetailSubset;
  startDate?: string;
  onClick?: () => void;
  showDetail?: boolean;
  disabled?: boolean;
  warningText?: ServiceItemWarning[];
}
const ServiceItem = (props: ServiceItemProps) => {
  const {
    service,
    onClick,
    disabled,
    showDetail = true,
    startDate,
    warningText,
  } = props;

  const descriptionText = useMemo(() => {
    const descriptionText = [];
    if (showDetail) {
      descriptionText.push(formatDurationBySecond(service.duration * 60));
    }
    if (
      showDetail &&
      startDate !== undefined &&
      service.startTime !== undefined &&
      service.endTime !== undefined
    ) {
      const eventStart = dayjs(startDate)
        .startOf("days")
        .add(service.startTime, "minutes")
        .format(TIME_FORMAT.HHmm);
      const eventEnd = dayjs(startDate)
        .startOf("days")
        .add(service.endTime, "minutes")
        .format(TIME_FORMAT.HHmm);
      descriptionText.unshift(`${eventStart} - ${eventEnd}`);
    }
    if (service.teamMemberName) {
      descriptionText.push(service.teamMemberName);
    }
    if (service.resourceName) {
      descriptionText.push(service.resourceName);
    }
    const joinedDescription = descriptionText.join(DOT_SEPARATOR);

    return joinedDescription;
  }, [
    service.duration,
    service.startTime,
    service.endTime,
    service.teamMemberName,
    service.resourceName,
    startDate,
  ]);

  // const extraText =
  //   service.extraTime !== null
  //     ? [service.extraTimeType, formatDurationBySecond(service.extraTime)].join(
  //         separator
  //       )
  //     : "";
  return (
    <OpacityButton
      disabled={disabled}
      onClick={onClick}
      sx={{
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        textAlign: "left",
        px: 1,
        py: 2,
        ":hover": {
          backgroundColor: COLOR.neutral200,
          "& .indicator": {
            width: "5px",
          },
        },
      }}
    >
      <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
        <Box
          className="indicator"
          sx={{
            width: "4px",
            backgroundColor: COLOR.primary300,
            borderRadius: 1,
            flexShrink: 0,
            transition: "all 0.2s ease",
          }}
        />

        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
            py: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                flexWrap: "wrap",
              }}
            >
              <Typography variant="body1" fontWeight={500}>
                {service.productName}
              </Typography>
              {showDetail && (
                <Typography variant="body2" color={COLOR.neutral500}>
                  {descriptionText}
                </Typography>
              )}
              {warningText?.map((warning) => (
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: 0.5,
                  }}
                >
                  <WarningIcon size={18} color={COLOR.warning500} />
                  <Typography variant="body2" color={COLOR.warning500}>
                    {generateWarningMessage(warning)}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Typography variant="body1" fontWeight={500}>
              {formatCurrency(service.price, "IDR")}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* {service.extraTimeType !== ServiceExtraType.NONE && (
        <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
          <Box
            className="indicator"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              width: "4px",
              gap: "2px",
              my: "2px",
              borderRadius: 1,
              transition: "all 0.2s ease",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flex: 1,
                width: "100%",
                borderRadius: "50%",
                backgroundColor: COLOR.primary300,
              }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                flex: 1,
                width: "100%",
                borderRadius: "50%",
                backgroundColor: COLOR.primary300,
              }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                flex: 1,
                width: "100%",
                borderRadius: "50%",
                backgroundColor: COLOR.primary300,
              }}
            ></Box>
          </Box>

          <Typography variant="body2" color={COLOR.neutral500}>
            {extraText}
          </Typography>
        </Box>
      )} */}
    </OpacityButton>
  );
};

export default ServiceItem;
