import { useNavigate } from "react-router-dom";
import OpacityButton from "../OpacityButton/OpacityButton";
import ModalTemplate, { ModalTemplateProps } from "./ModalTemplate";
import { Box, Typography } from "@mui/material";
import { ROUTE_NAME, RouteIcon, RoutePath } from "../../utils/constant";
import MenuListButton from "../MenuListButton/MenuListButton";
import { FaRegCalendarPlus } from "react-icons/fa";
import { LuCalendarPlus } from "react-icons/lu";

interface ActionBottomModalPropsI {
  modalProps: Omit<ModalTemplateProps, "children">;
  onClickBookSchedule: () => void;
  onClickNewSale: () => void;

}
const ActionBottomModal = ({
  modalProps,
  onClickBookSchedule,
  onClickNewSale,
}: ActionBottomModalPropsI) => {
  return (
    <ModalTemplate
      desktopWidth='400px'
      desktopHeight='50vh'
      {...modalProps}
      withCloseButton
    >
      <Box sx={{ px: 2, pb: 5 }}>
        <Typography variant='h6' pb={1}>Menu Cepat</Typography>
        <MenuListButton
          buttons={[
            {
              Icon: LuCalendarPlus,
              title: "Booking Jadwal",
              onClick: onClickBookSchedule,
            },
            {
              Icon: RouteIcon[ROUTE_NAME.SALES],
              title: "Penjualan Baru",
              onClick: onClickNewSale
            }
          ]}
        />
      </Box>
    </ModalTemplate>
  );
};

export default ActionBottomModal;
