import {
  Box,
  Container,
  Paper,
  SxProps,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  actionButtonStyles,
  NavContainer,
  NavItemContainer,
  SidebarItem,
} from "./PageContainer.styles";
import {
  CART_PAYMENT_METHOD,
  ROUTE_NAME,
  RouteIcon,
  RouteLabel,
  RoutePath,
} from "../../../utils/constant";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useBottomNav } from "../../../context/BottomNavContext";
import { Theme } from "@emotion/react";
import { FiPlus } from "react-icons/fi";
import { COLOR } from "../../../utils/color";
import ActionBottomModal from "../../Modal/ActionBottomModal";
import CreateNewSaleModal from "../../Modal/CreateNewSaleModal";
import { PAYMENT_STATUS, TRANSACTION_TYPE } from "../../../types/globalTypes";
import ChoosePaymentMethod from "../../Modal/ChoosePaymentMethodModal";
import { PaymentAmountData } from "../../Modal/InputAmountModal";
import { useElementHeight } from "../../../hooks/useElementHeight";
import {
  useConfirmTransactionAdhoc,
  useCreateTransactionAdhoc,
} from "../../../query/mutations";
import { APICreateNewTransactionAdhocPayload } from "../../../api/request.types";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RootReducerState } from "../../../redux/reducers";
import { GetScheduleEventResponseType } from "../../../api/request.types";

interface PageContainerPropsI {
  children?: React.ReactNode;
}

interface NavItemPropsI {
  label: string;
  routeName: string;
  routePath: string;
  icon: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
}

const NavItem: React.FC<NavItemPropsI> = (props) => {
  const { isActive = false } = props;
  return (
    <Tooltip
      title={<Typography variant="body1">{props.label}</Typography>}
      key={props.routeName}
      placement="top"
      arrow
      disableInteractive
    >
      <Box
        component={Link}
        to={props.routePath}
        sx={
          {
            ...SidebarItem(isActive),
          } as SxProps<Theme>
        }
        onClick={props.onClick}
      >
        {props.icon}
      </Box>
    </Tooltip>
  );
};

interface ActionButtonProps {
  onClick?: () => void;
}

const ActionButton: React.FC<ActionButtonProps> = ({ onClick }) => (
  <Box sx={actionButtonStyles} onClick={onClick}>
    <FiPlus size="28px" />
  </Box>
);

const BottomNav = React.forwardRef<HTMLDivElement, { onClick?: () => void }>(
  (props, ref) => {
    const { selectedRouteName, setRouteName } = useBottomNav();
    const location = useLocation();
    const internalRef = useRef<HTMLDivElement>(null);
    const height = useElementHeight(internalRef);
    React.useImperativeHandle(ref, () => internalRef.current as HTMLDivElement);

    const isActiveRoute = (routeName: ROUTE_NAME, currentPath: string) => {
      const basePath = RoutePath[routeName];
      if (!basePath) return false;
      return currentPath.startsWith(basePath);
    };
    const leftNavItems = [
      {
        label: RouteLabel[ROUTE_NAME.SCHEDULE_V2],
        routeName: ROUTE_NAME.SCHEDULE_V2,
        routePath: RoutePath[ROUTE_NAME.SCHEDULE_V2],
        icon: RouteIcon[ROUTE_NAME.SCHEDULE_V2],
      },
      {
        label: RouteLabel[ROUTE_NAME.DASHBOARD],
        routeName: ROUTE_NAME.DASHBOARD,
        routePath: RoutePath[ROUTE_NAME.DASHBOARD],
        icon: RouteIcon[ROUTE_NAME.DASHBOARD],
      },
    ];

    const rightNavItems = [
      {
        label: RouteLabel[ROUTE_NAME.BUSINESS],
        routeName: ROUTE_NAME.BUSINESS,
        routePath: RoutePath[ROUTE_NAME.BUSINESS],
        icon: RouteIcon[ROUTE_NAME.BUSINESS],
      },
      {
        label: RouteLabel[ROUTE_NAME.MORE],
        routeName: ROUTE_NAME.MORE,
        routePath: RoutePath[ROUTE_NAME.MORE],
        icon: RouteIcon[ROUTE_NAME.MORE],
      },
    ];

    return (
      <>
        <Paper
          ref={internalRef}
          sx={NavContainer}
          elevation={3}
          component="div"
        >
          <Container sx={NavItemContainer} maxWidth="sm">
            {leftNavItems.map((item) => (
              <NavItem
                key={item.routeName}
                label={item.label}
                routeName={item.routeName}
                routePath={item.routePath}
                icon={<item.icon size="24px" />}
                isActive={isActiveRoute(item.routeName, location.pathname)}
                onClick={() => setRouteName(item.routeName)}
              />
            ))}

            <ActionButton onClick={props.onClick} />
            {rightNavItems.map((item) => (
              <NavItem
                key={item.routeName}
                label={item.label}
                routeName={item.routeName}
                routePath={item.routePath}
                icon={<item.icon size="24px" />}
                isActive={isActiveRoute(item.routeName, location.pathname)}
                onClick={() => setRouteName(item.routeName)}
              />
            ))}
          </Container>
        </Paper>
      </>
    );
  }
);

export interface PageContainerOutletContextI {
  footerHeight: number;
}

const PageContainer: React.FC<PageContainerPropsI> = (props) => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );
  const companyID = userReducer.data.companyId;

  const [modalState, setModalState] = useState<{
    actionModal: boolean;
    newSaleModal: object | null;
    saleModal: { transactionID: string } | null;
    choosePaymentMethod:
      | (PaymentAmountData & {
          totalAmountToBePaid: number;
          transactionID: string;
        })
      | null;
  }>({
    actionModal: false,
    newSaleModal: null,
    choosePaymentMethod: null,
    saleModal: null,
  });
  const [bottomNavHeight, setBottomNavHeight] = useState<number>(0);

  const createTransactionAdhocMutations = useCreateTransactionAdhoc();
  const confirmTransactionAdhocMutations = useConfirmTransactionAdhoc();

  const handleActionClick = () => {
    setModalState((prev) => ({ ...prev, actionModal: !prev.actionModal }));
  };

  const handleDismissActionModal = () => {
    setModalState((prev) => ({ ...prev, actionModal: false }));
  };
  const handleDismissNewSaleModal = () => {
    setModalState((prev) => ({
      ...prev,
      newSaleModal: null,
      actionModal: false,
    }));
  };

  useEffect(() => {
    const updateHeight = () => {
      if (bottomRef.current) {
        const height = bottomRef.current.clientHeight;
        setBottomNavHeight(height);
      }
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: `calc(100% - ${bottomNavHeight}px)`,
          overflow: "auto",
          backgroundColor: COLOR.bg1,
        }}
      >
        <Outlet context={{ footerHeight: bottomNavHeight }} />
      </Box>

      <BottomNav ref={bottomRef} onClick={handleActionClick} />

      <ActionBottomModal
        modalProps={{
          open: modalState.actionModal,
          onDismiss: handleDismissActionModal,
          onClose: handleDismissActionModal,
        }}
        onClickBookSchedule={() => {
          navigate(`${RoutePath[ROUTE_NAME.SCHEDULE_V2]}/book`);
          handleDismissActionModal();
        }}
        onClickNewSale={() => {
          handleDismissActionModal();
          setModalState((prev) => ({
            ...prev,
            newSaleModal: {
              id: "",
              client: {
                name: "",
                phoneNumber: "",
                email: "",
              },
              services: [],
              paymentStatus: PAYMENT_STATUS.UNPAID,
              notes: "",
              receivedBy: {
                id: "",
                name: "",
                startTime: "",
                endTime: "",
              },
            },
          }));
        }}
      />
      <CreateNewSaleModal
        key={modalState.newSaleModal ? "new-sale-modal" : null}
        modalProps={{
          open: modalState.newSaleModal !== null,
          onClose: handleDismissNewSaleModal,
          onDismiss: handleDismissNewSaleModal,
        }}
        initialData={{
          id: "",
          client: {
            name: "",
            phoneNumber: "",
            email: "",
          },
          services: [],
          paymentStatus: PAYMENT_STATUS.UNPAID,
          notes: "",
          receivedBy: {
            id: "",
            name: "",
            type: GetScheduleEventResponseType.TEAM_MEMBER,
            shifts: [],
          },
          paidAmount: 0,
        }}
        onContinueToPayment={async (data) => {
          const totalAmount = data.services.reduce(
            (curr, service) => curr + service.price,
            0
          );
          const paymentMethodData = {
            amount: 0,
            method: CART_PAYMENT_METHOD.CASH,
            totalAmountToBePaid: totalAmount - data.paidAmount,
            receivedBy: {
              id: userReducer.data.userId || "",
              name: userReducer.data.name || "",
            },
          };
          let transactionID = data.id || "";
          if (!transactionID) {
            let totalPrice = 0;
            const allTeamMemberIDs = [];
            const allProductDetail = data.services.map((service) => {
              allTeamMemberIDs.push(service.teamMember.id);
              totalPrice += service.price * service.qty;
              return {
                productID: service.id,
                productName: service.name,
                teamMemberID: service.teamMember.id,
                scheduleDetails: [
                  {
                    startTime: -1,
                    endTime: -1,
                    date: dayjs().format("YYYY-MM-DD"),
                    qty: service.qty,
                    price: service.price,
                    total: service.price * service.qty,
                  },
                ],
              };
            });

            const createTransactionAdhocPayload: APICreateNewTransactionAdhocPayload =
              {
                companyID: companyID || "",
                date: dayjs().format("YYYY-MM-DD"),
                location: "",
                timezone: "",
                total: totalAmount,
                productDetail: allProductDetail,
                fullName: data.client.name,
                email: data.client.email,
                teamMemberIDs: data.services.map(
                  (service) => service.teamMember.id
                ),
                phoneNumber: data.client.phoneNumber,
                notes: data.notes,
                productPrice: totalPrice,
                adminPrice: 0,
                transactionType: TRANSACTION_TYPE.SALE_ONLY,
                paymentDetail: [],
              };

            const response = await createTransactionAdhocMutations.mutateAsync({
              payload: createTransactionAdhocPayload,
            });
            transactionID = response.data.data.transactionID;
          }
          setModalState((prev) => ({
            ...prev,
            choosePaymentMethod: { ...paymentMethodData, transactionID },
            newSaleModal: null,
          }));
        }}
        onViewSale={() => {
          setModalState((prev) => ({
            ...prev,
            saleModal: null,
          }));
        }}
      />

      <ChoosePaymentMethod
        allowedMethod={[CART_PAYMENT_METHOD.CASH, CART_PAYMENT_METHOD.OTHER]}
        data={{
          amount: modalState.choosePaymentMethod?.amount || 0,
          method:
            modalState.choosePaymentMethod?.method || CART_PAYMENT_METHOD.CASH,
          receivedBy: {
            id: "",
            name: "",
            ...modalState.choosePaymentMethod?.receivedBy,
          },
        }}
        modalProps={{
          open: modalState.choosePaymentMethod !== null,
          onClose: () =>
            setModalState((prev) => ({ ...prev, choosePaymentMethod: null })),
        }}
        onFullyPaid={async (paymentDetails: Array<PaymentAmountData>) => {
          await confirmTransactionAdhocMutations.mutateAsync({
            transactionID: modalState.choosePaymentMethod?.transactionID || "",
            payload: {
              details: paymentDetails.map((detail) => ({
                paidAmount: detail.amount,
                paidWith: detail.method,
                receivedBy: detail.receivedBy.id.toString(),
                receivedByName: detail.receivedBy.name,
              })),
            },
          });
          setModalState((prev) => ({ ...prev, choosePaymentMethod: null }));
          navigate(RoutePath[ROUTE_NAME.SALE_LIST], { replace: true });
        }}
        onPartlyPaid={async (paymentDetails: Array<PaymentAmountData>) => {
          await confirmTransactionAdhocMutations.mutateAsync({
            transactionID: modalState.choosePaymentMethod?.transactionID || "",
            payload: {
              details: paymentDetails.map((detail) => ({
                paidAmount: detail.amount,
                paidWith: detail.method,
                receivedBy: detail.receivedBy.id.toString(),
                receivedByName: detail.receivedBy.name,
              })),
            },
          });
          setModalState((prev) => ({ ...prev, choosePaymentMethod: null }));
          navigate(RoutePath[ROUTE_NAME.SALE_LIST], { replace: true });
        }}
        totalAmountToBePaid={
          modalState.choosePaymentMethod?.totalAmountToBePaid || 0
        }
      />
    </Box>
  );
};

export default PageContainer;
