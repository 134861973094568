import { Box, BoxProps } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import { Transition } from 'react-transition-group';

export interface AccordionProps {
  open?: boolean;
  onToggle?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  animationDuration?: number;
  children: React.ReactNode;
  sx?: BoxProps['sx'];
}

const defaultStyle = (duration: number) => ({
  transition: `max-height ${duration}ms ease-in-out`,
  maxHeight: 0,
  overflow: 'hidden',
});

export const AccordionItem: React.FC<AccordionProps> = ({
  open = false,
  onToggle,
  animationDuration = 300,
  children,
  sx,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const nodeRefs = useRef<any[]>([]);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [open, children]);

  const transitionStyles: { [key: string]: React.CSSProperties } = {
    entering: { maxHeight: `${contentHeight || 0}px` },
    entered: { maxHeight: `${contentHeight || 0}px` },
    exiting: { maxHeight: 0 },
    exited: { maxHeight: 0 },
  };
  return (
    <Box sx={sx}>
      {React.Children.map((children), (child, index) => {
        if (React.isValidElement(child)) {
          if (child.type === AccordionTitle) {
            return React.cloneElement(child, { onToggle } as AccordionTitleProps);
          }
          if (child.type === AccordionContent) {
            nodeRefs.current[index] = nodeRefs.current[index] || React.createRef();
            return (
              <Transition in={open} timeout={animationDuration} nodeRef={nodeRefs.current[index]}>
                {(state) => (
                  <Box sx={{ ...defaultStyle(animationDuration), ...transitionStyles[state] }}>
                    <Box ref={contentRef}>{child}</Box>
                  </Box>
                )}
              </Transition>
            );
          }
        }
        return child;
      })}
    </Box>
  );
};

interface AccordionTitleProps extends BoxProps {
  onToggle?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

interface AccordionContentProps extends BoxProps {
  children?: React.ReactNode;
}

export const AccordionTitle: React.FC<AccordionTitleProps> = ({ children, onToggle, ...otherProps }) => (
  <Box
    {...otherProps}
    onClick={(event) => {
      event.preventDefault();
      onToggle?.(event);
      otherProps?.onClick?.(event);
    }}
    role="button"
    sx={{ 
      cursor: 'pointer',
      ...otherProps.sx 
    }}
  >
    {children}
  </Box>
);

export const AccordionContent: React.FC<AccordionContentProps> = ({ children, ...otherProps }) => (
  <Box {...otherProps}>
    {children}
  </Box>
);
