export const handlePrint = async (elementID: string): Promise<void> => {
  const invoiceElement = document.getElementById(elementID);

  if (!invoiceElement) {
    console.error("Invoice element not found!");
    return;
  }

  const printWindow = window.open("", "_blank");

  if (!printWindow) {
    console.error("Failed to open print window");
    return;
  }

  const styles = Array.from(document.styleSheets)
    .map((sheet) => {
      try {
        const rules = sheet.cssRules || sheet.rules;
        return Array.from(rules).map((rule) => rule.cssText).join("\n");
      } catch (e) {
        return "";
      }
    })
    .join("\n");

  const paperWidth = 58;

  printWindow.document.write(`
    <html>
      <head>
        <title>Print Invoice</title>
        <style>
          ${styles}

          @page { size: ${paperWidth}mm auto; margin: 0; }

          body {
            font-size: 12px;
            line-height: 1.3;
            margin: 0;
            padding: 0;
            width: ${paperWidth - 7}mm;
            color: #000; /* Force black text */
            word-wrap: break-word !important;
            overflow-wrap: break-word !important;
          }

          .print-container {
            max-width: ${paperWidth - 7}mm;
            margin: auto;
            background: white;
          }

          h6, p, span, strong, b {
            font-size: 12px !important;
            font-weight: normal;
            color: #000 !important;
          }

          * {
            box-sizing: border-box;
            -webkit-print-color-adjust: exact;
          }
        </style>
      </head>
      <body>
        <div class="print-container">
          ${invoiceElement.outerHTML}
        </div>
        <script>
          window.onload = function () {
            window.print();
            window.onafterprint = function () { window.close(); };
          };
        </script>
      </body>
    </html>
  `);

  printWindow.document.close();
};
