import React from 'react';
import { Box, Typography, SxProps } from '@mui/material';
import InputTitle from '../InputTitle/InputTitle';
import HelperText from '../HelperText/HelperText';
import { FormBaseProps } from '../../../types/globalTypes';
import { ContainerStyle, DisabledContainerStyle, ErrorContainerStyle, InputStyle } from '../form.styles';
import { Theme } from '@emotion/react';

export interface Option {
  value: string;
  label: string;
}

interface SingleSelectRawOptionPropsI extends FormBaseProps {
  disabled?: boolean;
  error?: boolean;
  startEndorment?: React.ReactNode;
  endEndorment?: React.ReactNode;
  sx?: SxProps<Theme>;
  optionProps: {
    value?: Option['value'];
    onChange: (selectedOption: Option['value']) => void;
    options: Array<Option>;
    defaultValue?: Option['value'];
    placeholder?: string;
    disabledValues?: Array<Option['value']>;
    sx?: SxProps<Theme>;
  };
}

const SingleSelectRawOption: React.FC<SingleSelectRawOptionPropsI> = (props) => {
  const { value, onChange, options, placeholder, disabledValues = [] } = props.optionProps;

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, ...(props?.sx || {}) as SxProps<Theme>}}>
      <InputTitle
        title={props.title}
        required={props.required}
        textProps={{ sx: { mb: 1 } }}
      />
      <Box
        sx={({
          ...ContainerStyle,
          ...(props.disabled ? DisabledContainerStyle : {}),
          ...(props.error ? ErrorContainerStyle : {}),
        }) as SxProps<Theme>}
      >
        {props.startEndorment && (
          <Box component="span" sx={{ mr: 1 }}>
            {props.startEndorment}
          </Box>
        )}

        <select
          value={value || ''}
          onChange={handleChange}
          disabled={props.disabled}
          style={{
            ...InputStyle,
            cursor: props.disabled ? 'not-allowed' : 'pointer',
            backgroundColor: 'transparent',
            border: 'none',
            outline: 'none',
            width: '100%',
            appearance: 'none',
          }}
        >
          {placeholder && (
            <option value="" disabled hidden>
              {placeholder}
            </option>
          )}
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              disabled={disabledValues.includes(option.value)}
            >
              {option.label}
            </option>
          ))}
        </select>

        {props.endEndorment && (
          <Box component="span" sx={{ ml: 1 }}>
            {props.endEndorment}
          </Box>
        )}
      </Box>
      <HelperText {...props.helper} />
    </Box>
  );
};

export default SingleSelectRawOption;
