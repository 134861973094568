import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CurrencyCodeType,
  EditProductData,
  ProductData,
  Schedule,
  SCHEDULE_TYPE,
} from "../../types/globalTypes";
import { DAY } from "../../utils/date";
import { DURATION_PERIOD, THUMB_STYLE, TIMEZONES } from "../../utils/constant";

export type InputNode = {
  title: string;
  value: string;
  required: boolean;
  minLength: number;
  maxLength: number;
  type: "default" | "md";
};

export type ThumbData = {
  thumbStyle: THUMB_STYLE;
  thumbImg: string;
  title: string;
  subtitle: string;
  buttonText: string;
};

export type CheckoutData = {
  checkoutImg: string;
  checkoutTitle: string;
  checkoutDesc: string;
  checkoutBottomTitle: string;
  checkoutCTABtn: string;
};
export type ProductLinkData = { link: string } & ThumbData;

export type ProductScheduleData = {
  price: number;
  discount: number;
  currency: CurrencyCodeType;
  collectField: [FieldNode, FieldNode, ...FieldNode[]];
  schedules: Schedule;
  scheduleType: SCHEDULE_TYPE;
  timezone: string;
  scheduleDuration: number;
  scheduleDurationPeriod: DURATION_PERIOD;
  preventScheduleBefore: number;
  quota: number;
  allowBreakBefore: boolean;
  allowBreakAfter: boolean;
  breakBefore: number;
  breakAfter: number;
  reservationPeriod: number;
  status: "draft" | "active";
  allowReschedule: boolean;
  preventRescheduleBefore: number;
  teamMemberIDs: string[];
  resourceIDs: string[];
} & ThumbData &
  CheckoutData;

export const DEFAULT_THUMB_FIELD: Array<InputNode> = [
  {
    title: "Title",
    value: "Buat Jadwal Temu",
    required: false,
    minLength: 0,
    maxLength: 50,
    type: "default",
  },
  {
    title: "Subtitle",
    value: "Reservasi jadwal temu dengan saya sekarang!",
    required: false,
    minLength: 0,
    maxLength: 100,
    type: "default",
  },
  {
    title: "Button*",
    value: "Reservasi sekarang!",
    required: true,
    minLength: 0,
    maxLength: 30,
    type: "default",
  },
];

export const DEFAULT_CHECKOUT_FIELD: Array<InputNode> = [
  {
    ...DEFAULT_THUMB_FIELD[1],
    title: "Description Title*",
    required: true,
  },
  {
    title: "Description Body *",
    value: `Reservasi jadwal temu dengan saya sekarang!`,
    required: true,
    minLength: 0,
    maxLength: 30,
    type: "md",
  },
  {
    ...DEFAULT_THUMB_FIELD[2],
    title: "Bottom Title *",
    required: true,
  },
  {
    ...DEFAULT_THUMB_FIELD[2],
    title: "Call To Action *",
    required: true,
  },
];

export type FieldNode = {
  type: "text" | "phone" | "email";
  value: string;
  required: boolean;
};
export const DEFAULT_COLLECT_FIELD: [FieldNode, FieldNode, ...FieldNode[]] = [
  {
    type: "text",
    value: "Nama Lengkap",
    required: true,
  },
  {
    type: "email",
    value: "Alamat Email",
    required: true,
  },
  {
    type: "phone",
    value: "No Whatsapp",
    required: false,
  },
];

export const DEFAULT_PRODUCT_DATA: ProductScheduleData = {
  thumbStyle: THUMB_STYLE.BUTTON,
  thumbImg:
    "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Calendar_icon_%282020%29.svg/768px-Google_Calendar_icon_%282020%29.svg.png",
  title: "Buat Jadwal Temu",
  subtitle: "Reservasi jadwal temu dengan saya sekarang!",
  buttonText: "Reservasi sekarang!",

  checkoutImg:
    "https://images.unsplash.com/photo-1457369804613-52c61a468e7d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  checkoutTitle: "Buat Jadwal Temu",
  checkoutDesc: `
    Demo reservasi dengan memilih jadwal yang tersedia.&#x20;

    Sebagai *<u>Customer</u>* ini kamu bisa :

    1. Pilih **"Tanggal"** yang ingin di reservasikan
    2. Pilih **"Jam Reservasi"** berdasarkan ketersediaan.
    3. Pilih **"Pembayaran"** dan lakukan pembayaran.
    4. Penjadwalan berhasil dilakukan, anda bisa melihat bukti pembyaran melalui "email" yang dimasukkan
`,
  checkoutBottomTitle: "Jadwalkan!",
  checkoutCTABtn: "Reservasi Sekarang",
  price: 10000,
  currency: "IDR",
  discount: 0,
  collectField: DEFAULT_COLLECT_FIELD,
  teamMemberIDs: [],
  resourceIDs: [],
  schedules: {
    [DAY.SUNDAY]: [],
    [DAY.MONDAY]: [{ startTime: "08:00", endTime: "18:00", price: 0 }],
    [DAY.TUESDAY]: [{ startTime: "08:00", endTime: "18:00", price: 0 }],
    [DAY.WEDNESDAY]: [{ startTime: "08:00", endTime: "18:00", price: 0 }],
    [DAY.THURSDAY]: [{ startTime: "08:00", endTime: "18:00", price: 0 }],
    [DAY.FRIDAY]: [{ startTime: "08:00", endTime: "18:00", price: 0 }],
    [DAY.SATURDAY]: [],
  },
  scheduleType: SCHEDULE_TYPE.DEFAULT,
  timezone: TIMEZONES[0],
  scheduleDuration: 30,
  scheduleDurationPeriod: DURATION_PERIOD.MINUTES,
  preventScheduleBefore: 1,
  quota: 1,
  allowBreakBefore: false,
  allowBreakAfter: false,
  breakBefore: 15,
  breakAfter: 15,
  reservationPeriod: 60,
  status: "draft",
  allowReschedule: false,
  preventRescheduleBefore: 24,
};

export const DEFAULT_PRODUCT_LINK_DATA: ProductLinkData = {
  thumbStyle: THUMB_STYLE.BUTTON,
  thumbImg:
    "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Calendar_icon_%282020%29.svg/768px-Google_Calendar_icon_%282020%29.svg.png",
  title: "Buat Jadwal Temu",
  subtitle: "Reservasi jadwal temu dengan saya sekarang!",
  buttonText: "Reservasi sekarang!",
  link: "",
};

export type ProductReducerState = {
  productScheduleData: ProductScheduleData;
  productLinkData: ProductLinkData;
  data: ProductData;
  edit: EditProductData;
};

export const initialState: ProductReducerState = {
  productScheduleData: { ...DEFAULT_PRODUCT_DATA },
  productLinkData: { ...DEFAULT_PRODUCT_LINK_DATA },
  data: {
    images: new Array(5).fill({
      file: null,
      base64: "",
    }),
    name: "",
    price: 0,
    duration: 0,
    durationUnit: DURATION_PERIOD.MINUTES,
    quota: 0,
    isCustomPrice: true,
    schedules: {
      [DAY.SUNDAY]: [],
      [DAY.MONDAY]: [{ startTime: "08:00", endTime: "18:00", price: 0 }],
      [DAY.TUESDAY]: [{ startTime: "08:00", endTime: "18:00", price: 0 }],
      [DAY.WEDNESDAY]: [{ startTime: "08:00", endTime: "18:00", price: 0 }],
      [DAY.THURSDAY]: [{ startTime: "08:00", endTime: "18:00", price: 0 }],
      [DAY.FRIDAY]: [{ startTime: "08:00", endTime: "18:00", price: 0 }],
      [DAY.SATURDAY]: [],
    },
    description: "",
    location: {
      lat: 0,
      long: 0,
      search: "",
    },
    locationDetail: "",
    allowReschedule: false,
  },
  edit: {
    images: new Array(5).fill({
      file: null,
      base64: "",
      url: null,
    }),
    name: "",
    price: 0,
    duration: 0,
    durationUnit: DURATION_PERIOD.MINUTES,
    quota: 0,
    isCustomPrice: true,
    schedules: {
      [DAY.SUNDAY]: [],
      [DAY.MONDAY]: [{ startTime: "08:00", endTime: "18:00", price: 0 }],
      [DAY.TUESDAY]: [{ startTime: "08:00", endTime: "18:00", price: 0 }],
      [DAY.WEDNESDAY]: [{ startTime: "08:00", endTime: "18:00", price: 0 }],
      [DAY.THURSDAY]: [{ startTime: "08:00", endTime: "18:00", price: 0 }],
      [DAY.FRIDAY]: [{ startTime: "08:00", endTime: "18:00", price: 0 }],
      [DAY.SATURDAY]: [],
    },
    description: "",
    location: {
      lat: 0,
      long: 0,
      search: "",
      isCustomLocation: false,
    },
    locationDetail: "",
    allowReschedule: false,
  },
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<Partial<ProductScheduleData>>) => {
      return {
        ...state,
        productScheduleData: {
          ...state.productScheduleData,
          ...action.payload,
        },
      };
    },
    setLinkData: (state, action: PayloadAction<Partial<ProductLinkData>>) => {
      return {
        ...state,
        productLinkData: {
          ...state.productLinkData,
          ...action.payload,
        },
      };
    },
    setScheduleData: (
      state,
      action: PayloadAction<ProductData["schedules"]>
    ) => {
      return {
        ...state,
        productScheduleData: {
          ...state.productScheduleData,
          schedules: {
            [DAY.SUNDAY]: [...(action.payload?.[DAY.SUNDAY] || [])],
            [DAY.MONDAY]: [...(action.payload?.[DAY.MONDAY] || [])],
            [DAY.TUESDAY]: [...(action.payload?.[DAY.TUESDAY] || [])],
            [DAY.WEDNESDAY]: [...(action.payload?.[DAY.WEDNESDAY] || [])],
            [DAY.THURSDAY]: [...(action.payload?.[DAY.THURSDAY] || [])],
            [DAY.FRIDAY]: [...(action.payload?.[DAY.FRIDAY] || [])],
            [DAY.SATURDAY]: [...(action.payload?.[DAY.SATURDAY] || [])],
          },
        },
      };
    },
    resetProductScheduleData: (state) => {
      return {
        ...state,
        productScheduleData: {
          ...initialState.productScheduleData,
        },
      };
    },
    resetProductLinkData: (state) => {
      return {
        ...state,
        productLinkData: {
          ...initialState.productLinkData,
        },
      };
    },
    setProductData: (state, action: PayloadAction<Partial<ProductData>>) => {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          schedules: {
            ...state.data.schedules,
            ...action.payload.schedules,
          },
          location: {
            ...state.data.location,
            ...action.payload.location,
          },
        },
      };
    },
    resetProductData: (state) => {
      return {
        ...state,
        data: {
          ...initialState.data,
        },
      };
    },
    setScheduleEditData: (
      state,
      action: PayloadAction<EditProductData["schedules"]>
    ) => {
      return {
        ...state,
        edit: {
          ...state.edit,
          schedules: {
            [DAY.SUNDAY]: [...(action.payload?.[DAY.SUNDAY] || [])],
            [DAY.MONDAY]: [...(action.payload?.[DAY.MONDAY] || [])],
            [DAY.TUESDAY]: [...(action.payload?.[DAY.TUESDAY] || [])],
            [DAY.WEDNESDAY]: [...(action.payload?.[DAY.WEDNESDAY] || [])],
            [DAY.THURSDAY]: [...(action.payload?.[DAY.THURSDAY] || [])],
            [DAY.FRIDAY]: [...(action.payload?.[DAY.FRIDAY] || [])],
            [DAY.SATURDAY]: [...(action.payload?.[DAY.SATURDAY] || [])],
          },
        },
      };
    },
    setProductEditData: (
      state,
      action: PayloadAction<Partial<EditProductData>>
    ) => {
      return {
        ...state,
        edit: {
          ...state.edit,
          ...action.payload,
          location: {
            ...state.edit.location,
            ...action.payload.location,
          },
        },
      };
    },
    resetProductEditData: (state) => {
      return {
        ...state,
        edit: {
          ...initialState.edit,
        },
      };
    },
  },
});

export const {
  setData,
  setLinkData,
  setProductData,
  resetProductData,
  setScheduleData,
  setScheduleEditData,
  setProductEditData,
  resetProductEditData,
  resetProductScheduleData,
  resetProductLinkData,
} = productSlice.actions;
export default productSlice.reducer;
