import { Box, Typography } from '@mui/material';
import React from 'react';
import { COLOR } from '../../utils/color';
import { CELL_MIN_WIDTH } from '../GroupSchedule/config';

interface ThumbnailSectionPropsI {
  primaryColor?: string;
  baseColor?: string;
  title: string;
}
const ThumbnailSection = (props: ThumbnailSectionPropsI) => {
  const {
    primaryColor = COLOR.primary500,
    baseColor = "black",
    title = "Judul"
  } = props;
  return (
    <Box sx={{
      color: baseColor,
      backgroundColor: primaryColor,
      width: 'fit-content',
      px: 2,
      py: 1,
      minWidth: `${CELL_MIN_WIDTH}px`,
      minHeight: '45px',
      textAlign: 'center',
      borderRadius: 7,
      boxShadow: '0px 1px 18px -2px rgba(0,0,0,0.86)',
      WebkitBoxShadow: '0px 1px 18px -2px rgba(0,0,0,0.86)',
      MozBoxShadow: '0px 1px 18px -2px rgba(0,0,0,0.86)',
    }}>
      <Typography variant='caption' fontWeight={600} fontSize={18}>
        {title}
      </Typography>
    </Box>
  );
};

export default ThumbnailSection;
