import { Box, Pagination, Skeleton, Typography } from "@mui/material";
import SubMenuHeader from "../../components/Layout/SubMenuHeader/SubMenuHeader";
import {
  FaArrowLeft,
  FaDownload,
  FaFilter,
  FaPlusCircle,
  FaRegCalendarAlt,
} from "react-icons/fa";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import {
  CART_PAYMENT_METHOD,
  DEFAULT_SNACKBAR_PROPS,
  ROUTE_NAME,
  RouteLabel,
  RoutePath,
  TIME_FORMAT,
} from "../../utils/constant";
import BasicTable, { Column } from "../../components/Tables/BasicTable";
import OpacityButton from "../../components/OpacityButton/OpacityButton";
import { formatCurrency, getStatusTextPropsByStatus } from "../../utils/helper";
import { useMemo, useState } from "react";
import dayjs from "dayjs";
import SearchBar from "../../components/SearchBar/SearchBar";
import { COLOR } from "../../utils/color";
import DateRangePickerModal from "../../components/Modal/DateRangePickerModal";
import SaleFilterModal, {
  SaleFilterData,
} from "../../components/Modal/SaleFilterModal";
import { useGetTransactionByQueryV2 } from "../../query/queries";
import {
  APICreateNewTransactionAdhocPayload,
  GetScheduleEventResponseType,
  TransactionDataV2,
  TransactionFilterV2,
} from "../../api/request.types";
import {
  PAYMENT_STATUS,
  SaleData,
  TRANSACTION_TYPE,
} from "../../types/globalTypes";
import { useSelector } from "react-redux";
import { RootReducerState } from "../../redux/reducers";
import CreateNewSaleModal from "../../components/Modal/CreateNewSaleModal";
import {
  useConfirmTransactionAdhoc,
  useCreateTransactionAdhoc,
  useDownloadCSVTransaction,
  useDownloadCSVTransactionV2,
} from "../../query/mutations";
import SaleModal from "../../components/Modal/SaleModal";
import ChoosePaymentMethod from "../../components/Modal/ChoosePaymentMethodModal";
import { PaymentAmountData } from "../../components/Modal/InputAmountModal";
import { errorLogger } from "../../utils/logger";
import CustomButton from "../../components/CustomButton/CustomButton";
import { PageContainerOutletContextI } from "../../components/Layout/PageContainer/PageContainer";
import { enqueueSnackbar } from "notistack";

type DateRangeType =
  | "custom"
  | "today"
  | "yesterday"
  | "last_7_days"
  | "last_30_days"
  | "last_90_days"
  | "last_month"
  | "week_to_date"
  | "month_to_date"
  | "quarter_to_date"
  | "year_to_date";

type SaleFilterDateType = {
  startDate: string;
  endDate: string;
  type: DateRangeType;
};

const SaleFilter = (props: {
  filter: TransactionFilterV2;
  setFilter: React.Dispatch<React.SetStateAction<TransactionFilterV2>>;
  onAddNewSale: () => void;
  onClickDownloadCSV: () => void;
  disabled?: boolean;
}) => {
  const { filter, setFilter, onAddNewSale, onClickDownloadCSV, disabled } =
    props;
  const { footerHeight } = useOutletContext<PageContainerOutletContextI>();

  const [modalState, setModalState] = useState<{
    datePicker: boolean;
    filter: boolean;
  }>({ datePicker: false, filter: false });

  const [date, setDate] = useState<SaleFilterDateType>({
    startDate: dayjs(filter.startDate, TIME_FORMAT.YYYYMMDD).format(
      TIME_FORMAT.YYYYMMDD
    ),
    endDate: dayjs(filter.endDate, TIME_FORMAT.YYYYMMDD).format(
      TIME_FORMAT.YYYYMMDD
    ),
    type: "today",
  });

  const _getDateStr = () => {
    type DateRangeType =
      | "custom"
      | "today"
      | "yesterday"
      | "last_7_days"
      | "last_30_days"
      | "last_90_days"
      | "last_month"
      | "week_to_date"
      | "month_to_date"
      | "quarter_to_date"
      | "year_to_date";
    const DateRangeLabel: Record<Exclude<DateRangeType, "custom">, string> = {
      today: "Today",
      yesterday: "Yesterday",
      last_7_days: "Last 7 Days",
      last_30_days: "Last 30 Days",
      last_90_days: "Last 90 Days",
      last_month: "Last Month",
      week_to_date: "Week to Date",
      month_to_date: "Month to Date",
      quarter_to_date: "Quarter to Date",
      year_to_date: "Year to Date",
    };

    if (date.type === "custom") {
      if (date.startDate === date.endDate) {
        return dayjs(date.startDate).format(TIME_FORMAT.DDMMMYYYY);
      }
      return `${dayjs(date.startDate).format(TIME_FORMAT.DDMMMYYYY)} - ${dayjs(
        date.endDate
      ).format(TIME_FORMAT.DDMMMYYYY)}`;
    }

    return (
      DateRangeLabel[date.type] || date.type.replace("_", " ").toUpperCase()
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={1}
      width="100%"
      px={2}
      mb={3}
      sx={{
        overflowX: "auto",
        pb: 1,
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        gap={1}
        width="100%"
      >
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          <SearchBar
            value={filter.query}
            onChange={(e) => {
              setFilter((prev) => ({ ...prev, query: e.target.value }));
            }}
            placeholder="Sale #ID / Nama Customer"
            disabled={disabled}
          />
          <CustomButton
            variant="text-secondary"
            onClick={() => {
              setModalState((prev) => ({ ...prev, datePicker: true }));
            }}
            disabled={disabled}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              textWrap: "nowrap",
              minWidth: "auto",
              width: "fit-content",
            }}
          >
            <FaRegCalendarAlt size={16} />
            {_getDateStr()}
          </CustomButton>

          <CustomButton
            variant="icon"
            disabled={disabled}
            onClick={() => {
              setModalState((prev) => ({ ...prev, filter: true }));
            }}
            sx={{
              position: "fixed",
              bottom: `${footerHeight + 20}px`,
              right: 24,
              backgroundColor: COLOR.neutral50,
              color: COLOR.primary500,
              border: `1px solid ${COLOR.primary500}`,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <FaFilter size={16} />
          </CustomButton>

          <CustomButton
            variant="text-secondary"
            disabled={disabled}
            onClick={onAddNewSale}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              textWrap: "nowrap",
              minWidth: "auto",
              width: "fit-content",
            }}
          >
            <FaPlusCircle size={18} />
            Penjualan
          </CustomButton>

          <CustomButton
            variant="text-secondary"
            disabled={disabled}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              textWrap: "nowrap",
              minWidth: "auto",
              width: "fit-content",
            }}
            onClick={onClickDownloadCSV}
          >
            <FaDownload size={18} />
            CSV
          </CustomButton>
          <DateRangePickerModal
            modalProps={{
              open: modalState.datePicker,
              onClose: () =>
                setModalState((prev) => ({ ...prev, datePicker: false })),
            }}
            startDate={dayjs(date.startDate, TIME_FORMAT.YYYYMMDD)}
            endDate={dayjs(date.endDate, TIME_FORMAT.YYYYMMDD)}
            onApply={(date) => {
              const newDate = {
                startDate: date.startDate.format(TIME_FORMAT.YYYYMMDD),
                endDate: date.endDate.format(TIME_FORMAT.YYYYMMDD),
                type: date.range,
              };
              setDate(newDate);
              setFilter((prev) => ({
                ...prev,
                startDate: newDate.startDate,
                endDate: newDate.endDate,
              }));
              setModalState((prev) => ({ ...prev, datePicker: false }));
            }}
          />
        </Box>
      </Box>
      <SaleFilterModal
        modalProps={{
          open: modalState.filter,
          onClose: () => setModalState((prev) => ({ ...prev, filter: false })),
        }}
        initialData={{
          startAmount: filter.fromAmount?.toString() || "",
          endAmount: filter.toAmount?.toString() || "",
          items:
            (Array.isArray(filter.includingItems)
              ? filter.includingItems
              : filter.includingItems
              ? [filter.includingItems]
              : []) || [],
          status: (filter.status as PAYMENT_STATUS) || "all",
        }}
        onApply={(filter) => {
          setFilter((prev) => ({
            ...prev,
            includingItems: filter.items,
            fromAmount:
              filter.startAmount.length === 0
                ? undefined
                : Number(filter.startAmount),
            toAmount:
              filter.endAmount.length === 0
                ? undefined
                : Number(filter.endAmount),
            status: filter.status as PAYMENT_STATUS,
          }));
          setModalState((prev) => ({ ...prev, filter: false }));
        }}
      />
    </Box>
  );
};

const SaleListPage = () => {
  const navigate = useNavigate();
  const { transactionId } = useParams<{ transactionId: string }>();
  const downloadTransactionCSVV2Mutation = useDownloadCSVTransactionV2();

  const DEFAULT_FILTER_VALUE: TransactionFilterV2 = {
    companyID: "",
    query: "",
    status: "all",
    fromAmount: undefined,
    toAmount: undefined,
    includingItems: [],
    endDate: dayjs().format(TIME_FORMAT.YYYYMMDD),
    startDate: dayjs().subtract(7, "days").format(TIME_FORMAT.YYYYMMDD),
    limit: 15,
    page: 1,
  };

  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );
  const companyID = userReducer.data.companyId;

  const [modalState, setModalState] = useState<{
    newSaleModal: SaleData | null;
    choosePaymentMethod:
      | (PaymentAmountData & {
          totalAmountToBePaid: number;
          transactionID: string;
        })
      | null;
  }>({ newSaleModal: null, choosePaymentMethod: null });

  const [filter, setFilter] =
    useState<TransactionFilterV2>(DEFAULT_FILTER_VALUE);
  const transactionByQuertV2Queries = useGetTransactionByQueryV2(filter || "");
  const transactionPaginatedData = useMemo(() => {
    return transactionByQuertV2Queries.data?.data || { docs: [] };
  }, [transactionByQuertV2Queries.data?.data]);

  const createTransactionAdhocMutations = useCreateTransactionAdhoc();
  const confirmTransactionAdhocMutations = useConfirmTransactionAdhoc();

  const isLoading = transactionByQuertV2Queries.isLoading;
  const salesColumns: Column<TransactionDataV2>[] = [
    {
      key: "id",
      label: "#ID",
      customStyles: { minWidth: 50 },
      // renderHeader: (children) => {
      //   return <OpacityButton onClick={(e) => { console.log('ehe') }}>{children}</OpacityButton>
      // },
    },
    { key: "client", label: "Customer" },
    {
      key: "status",
      label: "Status",
    },
    { key: "saleDate", label: "Tanggal", customStyles: { minWidth: 200 } },
    {
      key: "grossTotal",
      label: "Amount",
      customStyles: { minWidth: 200 },
    },
  ];
  const handleCloseSaleModal = async () => {
    try {
      await transactionByQuertV2Queries.refetch();
    } catch (error) {
      errorLogger(error);
    } finally {
      navigate(RoutePath[ROUTE_NAME.SALE_LIST], { replace: true });
    }
  };
  const handleDismissNewSaleModal = () => {
    setModalState((prev) => ({
      ...prev,
      newSaleModal: null,
    }));
  };
  const handleDownloadCSV = async () => {
    try {
      const response = await downloadTransactionCSVV2Mutation.mutateAsync({
        query: filter,
      });
      if (response.data.data) {
        window.open(response.data.data, "_blank");
      } else {
        enqueueSnackbar({
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "error",
          message: "Error Download CSV",
        });
      }
    } catch (err) {
      enqueueSnackbar({
        ...DEFAULT_SNACKBAR_PROPS,
        variant: "error",
        message: "Error Download CSV",
      });
    }
  };
  const onAddNewSale = () => {
    setModalState((prev) => ({
      ...prev,
      newSaleModal: {
        mode: "create",
        id: "",
        client: {
          name: "",
          phoneNumber: "",
          email: "",
        },
        services: [],
        paymentStatus: PAYMENT_STATUS.UNPAID,
        notes: "",
        receivedBy: {
          id: "",
          name: "",
          startTime: "",
          type: GetScheduleEventResponseType.TEAM_MEMBER,
          endTime: "",
          shifts: [],
        },
        paidAmount: 0,
      },
    }));
  };

  const _renderSkeleton = () => (
    <Box display="flex" flexDirection="column" gap={1}>
      {Array.from({ length: 10 }).map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          width={"100%"}
          height={50}
        />
      ))}
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        backgroundColor: "white",
      }}
    >
      <SubMenuHeader
        leftNav={{
          icon: <FaArrowLeft />,
          onClick: () => {
            navigate(-1);
          },
        }}
        text={RouteLabel[ROUTE_NAME.SALE_LIST]}
      />
      <Box sx={{ pt: 3, pb: 5 }}>
        <SaleFilter
          filter={filter}
          setFilter={setFilter}
          onAddNewSale={onAddNewSale}
          onClickDownloadCSV={handleDownloadCSV}
          disabled={downloadTransactionCSVV2Mutation.isLoading}
        />
        <CreateNewSaleModal
          mode={modalState.newSaleModal?.id ? "edit" : "create"}
          modalProps={{
            open: modalState.newSaleModal !== null,
            onClose: handleDismissNewSaleModal,
            onDismiss: handleDismissNewSaleModal,
          }}
          initialData={{
            id: modalState.newSaleModal?.id || "",
            client: {
              name: modalState.newSaleModal?.client?.name || "",
              phoneNumber: modalState.newSaleModal?.client?.phoneNumber || "",
              email: modalState.newSaleModal?.client?.email || "",
            },
            services: modalState.newSaleModal?.services || [],
            paymentStatus:
              modalState.newSaleModal?.paymentStatus || PAYMENT_STATUS.UNPAID,
            notes: modalState.newSaleModal?.notes || "",
            receivedBy: {
              id: userReducer.data.userId || "",
              name: userReducer.data.name || "",
              type: GetScheduleEventResponseType.TEAM_MEMBER,
              shifts: [],
            },
            paidAmount: modalState.newSaleModal?.paidAmount || 0,
          }}
          onContinueToPayment={async (data) => {
            const totalAmount = data.services.reduce(
              (curr, service) => curr + service.price,
              0
            );
            const paymentMethodData = {
              amount: 0,
              method: CART_PAYMENT_METHOD.CASH,
              totalAmountToBePaid: totalAmount - data.paidAmount,
              receivedBy: {
                id: userReducer.data.userId || "",
                name: userReducer.data.name || "",
              },
            };

            let transactionID = data.id || "";
            if (!transactionID) {
              let totalPrice = 0;
              const allTeamMemberIDs = [];
              const allProductDetail = data.services.map((service) => {
                allTeamMemberIDs.push(service.teamMember.id);
                totalPrice += service.price * service.qty;
                return {
                  productID: service.id,
                  productName: service.name,
                  teamMemberID: service.teamMember.id,
                  scheduleDetails: [
                    {
                      startTime: -1,
                      endTime: -1,
                      date: dayjs().format("YYYY-MM-DD"),
                      qty: service.qty,
                      price: service.price,
                      total: service.price * service.qty,
                    },
                  ],
                };
              });

              const createTransactionAdhocPayload: APICreateNewTransactionAdhocPayload =
                {
                  companyID: companyID || "",
                  date: dayjs().format("YYYY-MM-DD"),
                  location: "",
                  timezone: "",
                  total: totalAmount,
                  productDetail: allProductDetail,
                  fullName: data.client.name,
                  email: data.client.email,
                  teamMemberIDs: data.services.map(
                    (service) => service.teamMember.id
                  ),
                  phoneNumber: data.client.phoneNumber,
                  notes: data.notes,
                  productPrice: totalPrice,
                  adminPrice: 0,
                  transactionType: TRANSACTION_TYPE.SALE_ONLY,
                  paymentDetail: [],
                };

              const response =
                await createTransactionAdhocMutations.mutateAsync({
                  payload: createTransactionAdhocPayload,
                });
              transactionID = response.data.data.transactionID;
            }
            setModalState((prev) => ({
              ...prev,
              choosePaymentMethod: { ...paymentMethodData, transactionID },
              newSaleModal: null,
            }));
          }}
          onViewSale={() => {
            setModalState((prev) => ({
              ...prev,
              saleModal: {
                ...prev.newSaleModal,
                transactionID: prev.newSaleModal?.id || "",
              },
            }));
          }}
        />
        <ChoosePaymentMethod
          allowedMethod={[CART_PAYMENT_METHOD.CASH, CART_PAYMENT_METHOD.OTHER]}
          data={{
            amount: modalState.choosePaymentMethod?.amount || 0,
            method:
              modalState.choosePaymentMethod?.method ||
              CART_PAYMENT_METHOD.CASH,
            receivedBy: {
              id: "",
              name: "",
              ...modalState.choosePaymentMethod?.receivedBy,
            },
          }}
          modalProps={{
            open: modalState.choosePaymentMethod !== null,
            onClose: () =>
              setModalState((prev) => ({ ...prev, choosePaymentMethod: null })),
          }}
          onFullyPaid={async (paymentDetails: Array<PaymentAmountData>) => {
            await confirmTransactionAdhocMutations.mutateAsync({
              transactionID:
                modalState.choosePaymentMethod?.transactionID || "",
              payload: {
                details: paymentDetails.map((detail) => ({
                  paidAmount: detail.amount,
                  paidWith: detail.method,
                  receivedBy: detail.receivedBy.id.toString(),
                  receivedByName: detail.receivedBy.name,
                })),
              },
            });
            setModalState((prev) => ({ ...prev, choosePaymentMethod: null }));
            transactionByQuertV2Queries.refetch();
          }}
          onPartlyPaid={async (paymentDetails: Array<PaymentAmountData>) => {
            await confirmTransactionAdhocMutations.mutateAsync({
              transactionID:
                modalState.choosePaymentMethod?.transactionID || "",
              payload: {
                details: paymentDetails.map((detail) => ({
                  paidAmount: detail.amount,
                  paidWith: detail.method,
                  receivedBy: detail.receivedBy.id.toString(),
                  receivedByName: detail.receivedBy.name,
                })),
              },
            });
            setModalState((prev) => ({ ...prev, choosePaymentMethod: null }));
            transactionByQuertV2Queries.refetch();
          }}
          totalAmountToBePaid={
            modalState.choosePaymentMethod?.totalAmountToBePaid || 0
          }
        />
        <SaleModal
          modalProps={{
            open: !!transactionId,
            onClose: () => handleCloseSaleModal(),
          }}
          onCloseAll={handleCloseSaleModal}
          transactionID={transactionId || ""}
        />

        <Box px={2}>
          {isLoading ? (
            _renderSkeleton()
          ) : (
            <BasicTable
              data={transactionPaginatedData.docs}
              columns={salesColumns}
              stickyColumnCount={1}
              maxHeight={`calc(100vh - 320px)`}
              renderCell={(row, key) => {
                if (key === "saleDate") {
                  return (
                    <Typography sx={{ fontStyle: "italic" }}>
                      {dayjs(row.saleDate)
                        .tz("Asia/Jakarta")
                        .format("DD MMM YYYY HH:mm")}
                    </Typography>
                  );
                }
                if (key === "status") {
                  return (
                    <OpacityButton
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Typography
                        sx={{
                          color:
                            row.status === PAYMENT_STATUS.PAID
                              ? "green"
                              : "orange",
                          fontWeight: "bold",
                        }}
                      >
                        {
                          getStatusTextPropsByStatus(
                            row.status as PAYMENT_STATUS
                          ).children
                        }
                      </Typography>
                    </OpacityButton>
                  );
                }
                if (key === "grossTotal") {
                  return (
                    <Typography sx={{ fontWeight: "bold" }}>
                      {formatCurrency(row.grossTotal, "IDR")}
                    </Typography>
                  );
                }
                return row[key];
              }}
              onRowClick={(row) => {
                navigate(`${RoutePath[ROUTE_NAME.SALE_LIST]}/${row.id}`); // Push transactionId to URL
              }}
            />
          )}

          {!isLoading && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                pt: 2,
              }}
            >
              <Pagination
                count={transactionPaginatedData.totalPages || 10}
                page={filter.page}
                onChange={(_, value: number) => {
                  setFilter((prevFilter) => ({ ...prevFilter, page: value }));
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SaleListPage;
