import React, { useRef, useState, useEffect } from "react";
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper, SxProps, Theme, Breakpoint, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { COLOR } from "../../utils/color";
import { ZINDEX } from "../../utils/constant";

type ColumnData<T> = {
  key: keyof T;
  label: string;
  width?: number;
};

export interface Column<T> extends ColumnData<T> {
  renderHeader?: (children?: React.ReactNode) => React.ReactNode;
  customStyles?: React.CSSProperties;
  onClick?: (data: ColumnData<T>) => void;
}

type MaxHeight = Partial<Record<Breakpoint, number | string>> | string | number;


interface SalesTableProps<T> {
  data: T[];
  columns: Column<T>[];
  maxHeight?: MaxHeight;
  stickyColumnCount?: number;
  renderCell?: (row: T, key: keyof T) => React.ReactNode;
  onCellClick?: (row: T | null, key: keyof T) => void;
  onRowClick?: (row: T) => void;
  onHeaderCellClick?: (key: keyof T) => void;
  customRowStyles?: (row: T, rowIndex: number) => React.CSSProperties;
  tableSx?: SxProps<Theme>;
}

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) =>
    prop !== "isStickyColumn" && prop !== "isStickyHeader" && prop !== "left",
})<{
  isStickyColumn?: boolean;
  isStickyHeader?: boolean;
  left?: number;
}>(({ isStickyColumn, isStickyHeader, left }) => ({
  position: isStickyColumn || isStickyHeader ? "sticky" : "static",
  left: isStickyColumn ? left : "auto",
  top: isStickyHeader ? 0 : "auto",
  zIndex: (isStickyHeader && isStickyColumn) ? ZINDEX.TABLE_HEADER_HIGH : isStickyHeader ? ZINDEX.TABLE_HEADER_MID : isStickyColumn ? ZINDEX.TABLE_HEADER_LOW : "initial",
  font: 'inherit',
  background: 'inherit',
}));

function BasicTable<T extends Record<string, any>>({
  data,
  columns,
  stickyColumnCount = 0,
  maxHeight,
  renderCell,
  onCellClick,
  onRowClick,
  onHeaderCellClick,
  customRowStyles,
  tableSx,
}: SalesTableProps<T>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number | null>(null);
  const theme = useTheme();
  useEffect(() => {
    if (containerRef.current) {
      setContentHeight(containerRef.current.scrollHeight);
    }
  }, [data]);

  return (
    <TableContainer
      ref={containerRef}
      sx={{
        ...tableSx,
        border: `1px solid ${COLOR.neutral300}`,
        ...(typeof maxHeight === "number" || typeof maxHeight === "string"
          ? {
            maxHeight,
            overflowY:
              contentHeight && typeof maxHeight === "number" && contentHeight < maxHeight
                ? "visible"
                : "auto",
          }
          : {}),
        ...(typeof maxHeight === "object" &&
          Object.entries(maxHeight).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [theme.breakpoints.up(key as Breakpoint)]: {
                maxHeight: value,
                overflowY:
                  contentHeight && value
                    ? contentHeight < (typeof value === "number" ? value : parseInt(value, 10))
                      ? "visible"
                      : "auto"
                    : "auto",
              },
            }),
            {}
          )),
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: "white",
              fontWeight: 700,
            }}>
            {columns.map((col, index) => (
              <StyledTableCell
                key={String(col.key)}
                isStickyColumn={maxHeight ? index < stickyColumnCount : false}
                isStickyHeader={Boolean(maxHeight)}
                left={index < stickyColumnCount ? index * 120 : undefined}
                onClick={() => onHeaderCellClick?.(col.key)}
                sx={{ ...((col.customStyles || {}) as React.CSSProperties) }}
              >
                {col.renderHeader ? col.renderHeader(col.label) : col.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              sx={{
                cursor: onRowClick ? "pointer" : "default",
                backgroundColor: "white",
                "&:hover": {
                  backgroundColor: COLOR.neutral100,
                },
                ...customRowStyles?.(row, rowIndex),
              }}
              onClick={() => onRowClick?.(row)}
            >
              {columns.map((col, colIndex) => (
                <StyledTableCell
                  key={String(col.key)}
                  isStickyColumn={maxHeight ? colIndex < stickyColumnCount : false}
                  left={colIndex < stickyColumnCount ? colIndex * 120 : undefined}
                  onClick={() => onCellClick?.(row, col.key)}
                  sx={{
                    py: {
                      xs: 0.5,
                      sm: 0.5,
                      md: 1,
                      lg: 1,
                      xl: 1,
                    },
                    px: {
                      xs: 2,
                      sm: 2,
                      md: 2,
                      lg: 2,
                      xl: 2,
                    },
                    ...((col.customStyles || {}) as React.CSSProperties)
                  }}
                >
                  {renderCell ? renderCell(row, col.key) : row[col.key]}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default BasicTable;
