import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export const generateImageData = async (
  invoiceRef: React.RefObject<HTMLDivElement>
): Promise<string | null> => {
  try {
    if (invoiceRef.current) {
      const canvas = await html2canvas(invoiceRef.current);
      return canvas.toDataURL("image/jpeg");
    }
    return null;
  } catch (error) {
    console.error("Error generating image data:", error);
    throw new Error("Failed to generate image data.");
  }
};

export const downloadPDFHelper = async (
  imgData: string,
  invoiceRef: React.RefObject<HTMLDivElement>
) => {
  try {
    if (!invoiceRef.current) {
      throw new Error("Invoice reference is not available.");
    }

    const canvas = await html2canvas(invoiceRef.current);
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;

    const doc = new jsPDF({
      unit: "px",
      format: [imgWidth, imgHeight],
    });

    const aspectRatio = imgWidth / imgHeight;
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    let pdfWidth = pageWidth - 20;
    let pdfHeight = pdfWidth / aspectRatio;

    if (pdfHeight > pageHeight - 20) {
      pdfHeight = pageHeight - 20;
      pdfWidth = pdfHeight * aspectRatio;
    }

    doc.addImage(imgData, "JPEG", 10, 10, pdfWidth, pdfHeight);
    doc.save("invoice.pdf");
  } catch (error) {
    console.error("Error generating PDF:", error);
    throw new Error("Failed to generate PDF.");
  }
};

export const downloadJPGHelper = (imgData: string) => {
  const link = document.createElement("a");
  link.href = imgData;
  link.download = "invoice.jpg";
  link.click();
};
