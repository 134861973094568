import { Box } from '@mui/material';
import React from 'react';
import { FormContainer, PageBackground } from './AuthContainer.styles';

interface AuthContainerPropsI {
  children?: React.ReactNode;
}
const AuthContainer: React.FC<AuthContainerPropsI> = (props) => {
  return (
    <Box sx={PageBackground}>
      <Box sx={FormContainer}>
        {props.children}
      </Box>
    </Box>
  )
};

export default AuthContainer;