import { useMemo, useEffect, useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "../../../redux/reducers";
import { COLOR } from "../../../utils/color";
import TextInput from "../../../components/Forms/TextInput/TextInput";
import { useGetCompanyAccountByID } from "../../../query/queries";
import { useResendWithdraw, useVerifyWithdraw } from "../../../query/mutations";
import { useNavigate } from "react-router-dom";
import { ContainerStyle } from "../../../styles/global.styles";
import AppContainer from "../../../components/Layout/AppContainer/AppContainer";
import SubMenuHeader from "../../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft } from "react-icons/fa";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { formatCountdown } from "../../../utils/time";
import { enqueueSnackbar } from "notistack";
import { DEFAULT_SNACKBAR_PROPS } from "../../../utils/constant";
import { isAxiosError } from "axios";
import { openDialog } from "../../../redux/reducers/confirmationDialog";

const WithdrawOTP = () => {
  const [otp, setOtp] = useState("");
  const [countdown, setCountdown] = useState(15 * 60); // 30 seconds countdown
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );

  const resendWithdraw = useResendWithdraw();
  const verifyWithdraw = useVerifyWithdraw();

  const companyId = userReducer.data.companyId;
  const companyAccountQuery: ReturnType<typeof useGetCompanyAccountByID> =
    useGetCompanyAccountByID(companyId || "");
  const companyAccount = useMemo(() => {
    return companyAccountQuery.data?.data?.data;
  }, [companyAccountQuery.data?.data?.data]);

  useEffect(() => {
    if (companyAccountQuery.isFetched && companyAccount) {
      const difference =
        (companyAccount.pendingWithdraws?.verificationTokenExpiry || 0) -
        Date.now();
      if (difference > 0) {
        setCountdown(Math.ceil(difference / 1000));
      } else {
        setIsResendDisabled(false);
        setCountdown(0);
        companyAccountQuery.refetch();
      }
    }
  }, [companyAccount, companyAccountQuery, companyAccountQuery.isFetched]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (isResendDisabled && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsResendDisabled(false);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [countdown, isResendDisabled]);

  const handleResend = useCallback(async () => {
    try {
      await resendWithdraw.mutateAsync({
        companyId: companyId || "",
      });
      enqueueSnackbar(`Email Kode OTP telah dikirimkan kembali`, {
        variant: "success",
        ...DEFAULT_SNACKBAR_PROPS,
      });

      setIsResendDisabled(true); // Disable resend button again
      setCountdown(15 * 60); // Reset countdown
      await companyAccountQuery.refetch();
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 400) {
        const msg = error.response?.data?.message || "";
        let toastMsg = "Telah terjadi kesalahan. Silahkan coba lagi.";
        switch (msg) {
          case "token-still-active":
            toastMsg = "Kode OTP masih aktif. Silahkan coba lagi.";
            break;
          case "verified-withdraw-request":
            toastMsg = "Permintaan Pencairan sudah terverifikasi.";
            break;
          default:
            break;
        }
        enqueueSnackbar(toastMsg, {
          variant: "error",
          ...DEFAULT_SNACKBAR_PROPS,
        });
      }
    }
  }, [companyAccountQuery, companyId, resendWithdraw]);

  const handleOTPSubmit = async () => {
    try {
      await verifyWithdraw.mutateAsync({
        companyId: companyId || "",
        token: otp,
      });
      enqueueSnackbar(`Permintaan Verifikasi Permintaan Pencairan Berhasil`, {
        variant: "success",
        ...DEFAULT_SNACKBAR_PROPS,
      });
      navigate(-1);
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 400) {
        const msg = error.response?.data?.message || "";
        let toastMsg = "Telah terjadi kesalahan. Silahkan coba lagi.";
        switch (msg) {
          case "invalid-token":
            toastMsg = "Kode OTP tidak valid. Silahkan coba lagi.";
            break;
          case "expired-token":
            toastMsg = "Kode OTP sudah kadaluarsa. Silahkan coba lagi.";
            break;
          case "verified-withdraw-request":
            toastMsg = "Permintaan Pencairan sudah terverifikasi.";
            break;
          default:
            break;
        }
        enqueueSnackbar(toastMsg, {
          variant: "error",
          ...DEFAULT_SNACKBAR_PROPS,
        });
      }
    }
  };

  return (
    <AppContainer>
      <Box sx={ContainerStyle}>
        <SubMenuHeader
          leftNav={{
            icon: <FaArrowLeft />,
            onClick: () => {
              dispatch(
                openDialog({
                  title: "Konfirmasi",
                  message:
                    "Anda yakin ingin kembali? Kode OTP yang sudah dikirimkan akan kadaluarsa.",
                  secondaryBtn: {
                    onClick: async () => {
                      navigate(-1);
                    },
                  },
                })
              );
            },
          }}
          text={"Verifikasi Pencairan"}
        />
        <Box py={4} width={"80%"} display="flex" flexDirection="column" gap={3}>
          <Typography variant="h6" textAlign="center" color={COLOR.neutral700}>
            Masukkan Kode OTP
          </Typography>
          <TextInput
            title="Kode OTP"
            required
            textInputProps={{
              type: "text",
              inputMode: "numeric",
              maxLength: 6,
              placeholder: "Masukkan 6 digit kode OTP",
              value: otp,
              onChange: (e) => setOtp(e.target.value),
            }}
            // helper={{
            //   text: error,
            //   color: error ? COLOR.danger500 : undefined,
            // }}
          />
          <Typography
            variant="body2"
            textAlign="center"
            color={isResendDisabled ? COLOR.neutral500 : COLOR.primary500}
            onClick={!isResendDisabled ? handleResend : undefined}
            style={{ cursor: isResendDisabled ? "default" : "pointer" }}
          >
            {isResendDisabled
              ? `Kirim ulang dalam ${formatCountdown(countdown)}`
              : "Kirim ulang kode"}
          </Typography>
          <CustomButton
            disabled={!otp || otp.length < 6}
            onClick={handleOTPSubmit}
            sx={{ width: "100%" }}
          >
            <Typography
              variant="body1"
              fontWeight={500}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              gap={2}
            >
              Verifikasi
            </Typography>
          </CustomButton>
        </Box>
      </Box>
    </AppContainer>
  );
};

export default WithdrawOTP;
