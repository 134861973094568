import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import { RoutePath } from "../utils/constant";
import isEmpty from "lodash.isempty";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import {
  PageTemplateType,
  PublicCompanyWebsiteResponse,
} from "../api/request.types";
import { useGetDataByDomainNameMutation } from "../query/mutations";
import useTokenCheck from "../hooks/useTokenCheck";

interface DynamicPageContextType {
  data: PublicCompanyWebsiteResponse;
  subdomain: string;
  loading: boolean;
}

const DynamicPageContext = createContext<DynamicPageContextType | undefined>(
  undefined
);

const NavigateOnValidCompanyNameRoute = () => {
  const params = useParams<{ companyDomainName: string }>();
  const location = useLocation();
  const invalidCompanyName = isEmpty(params.companyDomainName);
  const isAdminPage = params.companyDomainName === "admin";
  const getDataByDomainNameMutation = useGetDataByDomainNameMutation();
  const [isLoggedIn] = useTokenCheck();
  const [hasFetched, setHasFetched] = useState(false);

  const { companyDomainName: subdomain = "" } = params;

  const domainNameData = useMemo(() => ({
    companyWebsiteID: "",
    companyID: "",
    companyDomainName: "",
    logoPath: "",
    logoSignedURL: "",
    template: "Type1" as PageTemplateType,
    color: "#D5D5D5",
    name: "",
    description: "",
    products: [],
    openingHours: "",
    ...getDataByDomainNameMutation?.data?.data?.data,
  }), [getDataByDomainNameMutation?.data?.data?.data]);

  const fetchData = useCallback(async () => {
    if (params.companyDomainName && !isAdminPage && !hasFetched) {
      try {
        await getDataByDomainNameMutation.mutateAsync(params.companyDomainName);
      } catch (error) {
        if (isLoggedIn) {
          return <Navigate to={RoutePath.SCHEDULE_V2} replace />;
        } else {
          return <Navigate to={RoutePath.DYNAMIC_NOT_FOUND} replace />;
        }
      } finally {
        setHasFetched(true);
      }
    }
  }, [getDataByDomainNameMutation, hasFetched, isAdminPage, isLoggedIn, params.companyDomainName]);

  useEffect(() => {
    if (!getDataByDomainNameMutation.isLoading) {
      fetchData();
    }
  }, [fetchData, getDataByDomainNameMutation.isLoading]);

  useEffect(() => {
    setHasFetched(false);
  }, [params.companyDomainName]);
  if (invalidCompanyName) {
    return <Navigate to={RoutePath.DYNAMIC_NOT_FOUND} state={{ from: location }} replace />;
  }

  if (isAdminPage) {
    return <Navigate to={RoutePath.LOGIN} state={{ from: location }} replace />;
  }

  if (getDataByDomainNameMutation.isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (getDataByDomainNameMutation.isError) {
    return <Navigate to={RoutePath.DYNAMIC_NOT_FOUND} state={{ from: location }} replace />;
  }

  return (
    <DynamicPageContext.Provider
      value={{
        subdomain,
        data: domainNameData,
        loading: getDataByDomainNameMutation.isLoading,
      }}
    >
      <Outlet />
    </DynamicPageContext.Provider>
  );
};

export const useDynamicPage = (): DynamicPageContextType => {
  const context = useContext(DynamicPageContext);
  if (context === undefined) {
    throw new Error("useDynamicPage must be used within a DataProvider");
  }
  return context;
};

export default NavigateOnValidCompanyNameRoute;