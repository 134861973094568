import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  WithdrawData,
} from "../../types/globalTypes";

export type WithdrawReducerState = {
  withdrawData: WithdrawData;
};

export const initialState: WithdrawReducerState = {
  withdrawData: {
    amount: "",
  },
};

const withdrawSlice = createSlice({
  name: "withdraw",
  initialState,
  reducers: {
    setWithdrawData: (state, action: PayloadAction<Partial<WithdrawData>>) => {
      return {
        ...state,
        withdrawData: {
          ...state.withdrawData,
          ...action.payload,
        },
      };
    },
    resetWithdrawData: (state) => {
      return {
        ...state,
        withdrawData: {
          ...initialState.withdrawData,
        },
      };
    },
  },
});

export const { setWithdrawData, resetWithdrawData } = withdrawSlice.actions;
export default withdrawSlice.reducer;
