import React, { useEffect, useMemo, useState } from "react";
import ActionBottomSheet from "./ActionBottomSheet";
import CustomButton from "../CustomButton/CustomButton";
import dayjs from "dayjs";
import { Box, Divider, Typography } from "@mui/material";
import { BottomSheetProps } from "react-spring-bottom-sheet";
import SingleSelect from "../Forms/SingleSelect/SingleSelect";
import { FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import { COLOR } from "../../utils/color";
import {
  calculateTotalHours,
  formatDuration,
  generateIntervalOptions,
} from "../../utils/helper";
import OpacityButton from "../OpacityButton/OpacityButton";
import isEmpty from "lodash.isempty";
import { enqueueSnackbar } from "notistack";
import { DEFAULT_SNACKBAR_PROPS } from "../../utils/constant";
import { TimeRange } from "../../pages/TeamScheduledShifts/TeamScheduledShifts";

export const INTERVAL_BY_5_MINUTES = generateIntervalOptions(5);
export const DEFAULT_START_TIME_INDEX = INTERVAL_BY_5_MINUTES.findIndex(
  (option) => option.value === "09:00"
);
export const DEFAULT_END_TIME_INDEX = INTERVAL_BY_5_MINUTES.findIndex(
  (option) => option.value === "19:00"
);

export const DEFAULT_START_TIME =
  INTERVAL_BY_5_MINUTES[DEFAULT_START_TIME_INDEX].value;
export const DEFAULT_END_TIME =
  INTERVAL_BY_5_MINUTES[DEFAULT_END_TIME_INDEX].value;
const DEFAULT_DURATION = 10;

export type ShiftSchedule = {
  id: string;
  name: string;
  date: Date;
  shifts: TimeRange[];
};

export interface ShiftScheduleBottomSheetPropsI {
  isLoading: boolean;
  open: boolean;
  onDismiss: BottomSheetProps["onDismiss"];
  onApply: (schedules: ShiftSchedule) => void;
  onDelete: (id: string, date: string) => void;
  data: ShiftSchedule | null;
  header?: React.ReactNode;
  additionalContent?: React.ReactNode;
}

interface ShiftComponentProps {
  isLoading: boolean;
  startTime: string;
  endTime: string;
  onChangeStartTime: (value?: string) => void;
  onChangeEndTime: (value?: string) => void;
  onClickDelete: () => void;
  withTitle?: boolean;
  error?: boolean;
  helperText?: string;
  disableDelete?: boolean;
}

const getDuration = (startTime: string, endTime: string) => {
  const start = dayjs(`2024-01-01 ${startTime}`);
  const end = dayjs(`2024-01-01 ${endTime}`);

  return end.diff(start, "minute");
};

const ShiftComponent: React.FC<ShiftComponentProps> = ({
  isLoading,
  startTime = INTERVAL_BY_5_MINUTES[DEFAULT_START_TIME_INDEX].value,
  endTime = INTERVAL_BY_5_MINUTES[DEFAULT_END_TIME_INDEX].value,
  onChangeStartTime,
  onChangeEndTime,
  onClickDelete,
  withTitle = false,
  error = false,
  helperText,
  disableDelete = false,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        mb: 2,
        alignItems: "flex-end",
      }}
    >
      <SingleSelect
        disabled={isLoading}
        sx={{ flex: 1 }}
        title={withTitle ? "Jam Mulai" : undefined}
        optionProps={{
          value: startTime,
          onChange: (newValue: string | undefined) =>
            onChangeStartTime(newValue),
          options: INTERVAL_BY_5_MINUTES,
        }}
        error={error}
        helper={
          error
            ? {
                color: COLOR.danger500,
                text: helperText || "Error pada jadwal shift",
              }
            : undefined
        }
      />
      <SingleSelect
        disabled={isLoading}
        sx={{ flex: 1 }}
        title={withTitle ? "Jam Selesai" : undefined}
        optionProps={{
          value: endTime,
          onChange: (newValue: string | undefined) => onChangeEndTime(newValue),
          options: INTERVAL_BY_5_MINUTES,
        }}
        error={error}
        helper={
          error
            ? {
                color: COLOR.danger500,
                text: helperText || "Error pada jadwal shift",
              }
            : undefined
        }
      />
      <CustomButton
        variant="icon"
        onClick={onClickDelete}
        disabled={disableDelete || isLoading}
      >
        <FaTrashAlt size={20} />
      </CustomButton>
    </Box>
  );
};

type ValidationError = {
  index: number;
  message: string;
};

const validateShiftSchedules = (
  schedules: Array<TimeRange>
): ValidationError[] => {
  const errors: ValidationError[] = [];

  schedules.forEach((shift, index) => {
    const start = dayjs(`2024-01-01 ${shift.startTime}`);
    const end = dayjs(`2024-01-01 ${shift.endTime}`);

    if (start.isSame(end) || start.isAfter(end)) {
      errors.push({
        index,
        message: `Shift ${
          index + 1
        }: Jam mulai harus lebih awal dari jam selesai`,
      });
    }

    if (index > 0) {
      const prevShift = schedules[index - 1];
      const prevEnd = dayjs(`2024-01-01 ${prevShift.endTime}`);

      if (start.isBefore(prevEnd) || start.isSame(prevEnd)) {
        errors.push({
          index,
          message: `Shift ${index + 1}: Tumpang tindih dengan shift sebelumnya`,
        });
      }
    }
  });

  return errors;
};

const ShiftScheduleBottomSheet: React.FC<ShiftScheduleBottomSheetPropsI> = ({
  isLoading,
  open,
  onDismiss,
  onApply,
  onDelete,
  data,
  header,
  additionalContent,
}) => {
  const DEFAULT_DATA: ShiftSchedule = useMemo(
    () => ({
      id: "",
      name: "",
      date: dayjs().toDate(),
      shifts: [
        {
          startTime: DEFAULT_START_TIME,
          endTime: DEFAULT_END_TIME,
          duration: DEFAULT_DURATION,
        },
      ],
    }),
    []
  );
  const [currentShiftSchedule, setCurrentShiftSchedule] =
    useState<ShiftSchedule>(!isEmpty(data) ? data : DEFAULT_DATA);
  useEffect(() => {
    if (open) {
      setCurrentShiftSchedule(!isEmpty(data) ? data : DEFAULT_DATA);
    }
  }, [open, data, DEFAULT_DATA]);
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
    []
  );

  const getNextShiftTimes = (lastEndTime?: string): TimeRange => {
    if (!lastEndTime) {
      return {
        startTime: DEFAULT_START_TIME,
        endTime: DEFAULT_END_TIME,
        duration: DEFAULT_DURATION,
      };
    }

    const lastEndIndex = INTERVAL_BY_5_MINUTES.findIndex(
      (option) => option.value === lastEndTime
    );

    const nextStartIndex = Math.min(
      lastEndIndex + 24,
      INTERVAL_BY_5_MINUTES.length - 1
    );
    const nextEndIndex = Math.min(
      nextStartIndex + 12,
      INTERVAL_BY_5_MINUTES.length - 1
    );
    const startTime = INTERVAL_BY_5_MINUTES[nextStartIndex].value;
    const endTime = INTERVAL_BY_5_MINUTES[nextEndIndex].value;
    const duration = getDuration(startTime, endTime);

    return { startTime, endTime, duration };
  };

  const handleAddShift = () => {
    const lastShift =
      currentShiftSchedule.shifts[currentShiftSchedule.shifts.length - 1];
    const nextShiftTime = getNextShiftTimes(lastShift?.endTime);

    setCurrentShiftSchedule({
      ...currentShiftSchedule,
      shifts: [...currentShiftSchedule.shifts, nextShiftTime],
    });
  };

  const handleValidateAndUpdateSchedules = (newSchedules: Array<TimeRange>) => {
    setValidationErrors([]);

    const errors = validateShiftSchedules(newSchedules);
    if (errors.length > 0) {
      setValidationErrors((prevErrors) => [...prevErrors, ...errors]);
    }

    setCurrentShiftSchedule({
      ...currentShiftSchedule,
      shifts: newSchedules,
    });
  };

  const handleUpdateShift = (
    index: number,
    field: keyof TimeRange,
    value: string | undefined
  ) => {
    const updatedSchedules = [...currentShiftSchedule.shifts];
    updatedSchedules[index] = {
      ...updatedSchedules[index],
      [field]: value,
    };

    handleValidateAndUpdateSchedules(updatedSchedules);
  };

  const handleDeleteShift = (index: number) => {
    if (currentShiftSchedule.shifts.length <= 1) return;

    const updatedSchedules = currentShiftSchedule.shifts.filter(
      (_, i) => i !== index
    );
    handleValidateAndUpdateSchedules(updatedSchedules);
  };

  const handleApply = () => {
    const errors = validateShiftSchedules(currentShiftSchedule.shifts);
    setValidationErrors(errors);

    if (errors.length === 0) {
      onApply?.(currentShiftSchedule);
    } else {
      enqueueSnackbar({
        ...DEFAULT_SNACKBAR_PROPS,
        variant: "error",
        message: `Terdapat ${errors.length} kesalahan pada jadwal shift`,
      });
      const firstErrorShift = document.getElementById(
        `shift-${errors[0].index}`
      );
      firstErrorShift?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleDelete = () => {
    onDelete?.(
      currentShiftSchedule.id,
      currentShiftSchedule.date.toISOString()
    );
  };

  const getShiftError = (index: number) => {
    return validationErrors.find((error) => error.index === index);
  };

  return (
    <ActionBottomSheet
      open={open}
      bottomSheetProps={{
        onDismiss,
        footer: (
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            <CustomButton
              variant="outlined"
              disabled={isLoading}
              onClick={handleDelete}
            >
              Delete
            </CustomButton>
            <CustomButton disabled={isLoading} onClick={handleApply}>
              Apply
            </CustomButton>
          </Box>
        ),
      }}
      onClose={onDismiss}
      sx={{ pb: 0 }}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        sx={{
          minHeight: "80vh",
        }}
      >
        {header}
        {header && <Divider sx={{ my: 2 }} />}
        <Box sx={{ px: 2, pb: 15 }}>
          {currentShiftSchedule.shifts.map((shift, index) => {
            const error = getShiftError(index);
            return (
              <Box
                key={`shift-${index}`}
                sx={{
                  transition: "all 0.3s ease",
                  backgroundColor: error
                    ? "rgba(255, 0, 0, 0.05)"
                    : "transparent",
                  borderRadius: 1,
                  p: error ? 1 : 0,
                }}
              >
                <ShiftComponent
                  isLoading={isLoading}
                  withTitle={index === 0}
                  startTime={shift.startTime}
                  endTime={shift.endTime}
                  onChangeStartTime={(value) =>
                    handleUpdateShift(index, "startTime", value)
                  }
                  onChangeEndTime={(value) =>
                    handleUpdateShift(index, "endTime", value)
                  }
                  onClickDelete={() => handleDeleteShift(index)}
                  error={!!error}
                  helperText={error?.message}
                  disableDelete={currentShiftSchedule.shifts.length <= 1}
                />
              </Box>
            );
          })}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              mt: 2,
              mb: 2,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <OpacityButton onClick={handleAddShift} disabled={isLoading}>
              <Typography
                variant="body2"
                color={COLOR.primary500}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FaPlusCircle size={12} /> Tambah Shift
              </Typography>
            </OpacityButton>
            <Typography fontWeight={600} variant="caption">
              {`Total Waktu Kerja: ${formatDuration(
                calculateTotalHours(currentShiftSchedule.shifts)
              )}`}
            </Typography>
          </Box>
          {additionalContent}
        </Box>
      </Box>
    </ActionBottomSheet>
  );
};

export default ShiftScheduleBottomSheet;
