import { Box, BoxProps } from "@mui/material";
import React from "react";
import { COLOR } from "../../utils/color";

export interface OpacityButtonPropsI extends Omit<BoxProps, 'sx'> {
  children?: React.ReactNode;
  sx?: BoxProps['sx'];
  disabled?: boolean;
  disableOpacity?: boolean;
}

const OpacityButton = React.forwardRef<HTMLButtonElement, OpacityButtonPropsI>(
  (props, ref) => {
    const {
      children,
      disabled,
      sx,
      disableOpacity = false,
      ...boxProps
    } = props;

    return (
      <Box
        ref={ref}
        component="button"
        sx={{
          "&:active": {
            opacity: (disabled || disableOpacity) ? 1 : 0.8,
            backgroundColor: (disabled || disableOpacity)
              ? "transparent"
              : COLOR.neutral100,
          },
          backgroundColor: "transparent",
          padding: 0,
          border: "none",
          color: disabled ? COLOR.neutral500 : "inherit",
          cursor: "pointer",
          opacity: disabled ? 0.5 : 1,
          pointerEvents: disabled ? "none" : "auto",
          ...(sx || {}),
        }}
        {...boxProps}
        disabled={disabled}
      >
        {children}
      </Box>
    );
  }
);

export default OpacityButton;
