import React, { useState, useEffect } from 'react';
import ActionBottomSheet from './ActionBottomSheet';
import WeeklyCalendar from '../WeeklyCalendar/WeeklyCalendar';
import CustomButton from '../CustomButton/CustomButton';
import dayjs from 'dayjs';
import { WeeklyCalendarProps } from '../WeeklyCalendar/WeeklyCalendar';
import { Box } from '@mui/material';
import { BottomSheetProps } from 'react-spring-bottom-sheet';

interface WeeklyScheduleBottomSheetProps extends Omit<WeeklyCalendarProps, 'onChange' | 'onRangeChange'> {
  open: boolean;
  onDismiss: BottomSheetProps['onDismiss'];
  onApply?: (range: { start: Date | null, end: Date | null }) => void;
}

const WeeklyScheduleBottomSheet: React.FC<WeeklyScheduleBottomSheetProps> = ({
  open,
  onDismiss,
  onApply,
  ...props
}) => {

  const [selectedRange, setSelectedRange] = useState<{ start: Date | null, end: Date | null }>(props.selectedRange);

  useEffect(() => {
    if (open) {
      setSelectedRange(props.selectedRange);
    }
  }, [open, props.selectedRange]);

  const handleApply = () => {
    onApply?.(selectedRange);
    onDismiss?.();
  };

  return (
    <ActionBottomSheet open={open} bottomSheetProps={{ onDismiss }} onClose={onDismiss} sx={{pb: 0}}>
      <Box 
        display="flex" 
        flexDirection="column" 
        height="100%" 
        sx={{ 
          minHeight: '80vh',
        }}
      >
        <Box flex={1}>
          <WeeklyCalendar
            currentMonth={props.currentMonth}
            onMonthChange={props.onMonthChange}
            selectedRange={selectedRange}
            onRangeChange={(newRange) => {
              setSelectedRange({
                start: newRange.start,
                end: newRange.end
              });
            }}
          />
        </Box>
        <Box 
          sx={{
            p: 2,
            borderTop: '1px solid',
            borderColor: 'divider'
          }}
        >
          <CustomButton onClick={handleApply} fullWidth>
            Apply
          </CustomButton>
        </Box>
      </Box>
    </ActionBottomSheet>
  );
};

export default WeeklyScheduleBottomSheet;
