import React, { useMemo, useRef, useState } from "react";
import { Box, Typography, SxProps, Skeleton } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDynamicPage } from "../../NavigateOnValidCompanyName";
import AppContainer from "../../../components/Layout/AppContainer/AppContainer";
import { COLOR } from "../../../utils/color";
import {
  DEFAULT_SNACKBAR_PROPS,
  ROUTE_NAME,
  RoutePath,
  TIMEZONE_SHORTLABEL,
} from "../../../utils/constant";
import RowKeyValue from "../../../components/RowKeyValue/RowKeyValue";
import { Theme } from "@emotion/react";
import { BottomSheetRef } from "react-spring-bottom-sheet";
import {
  useGetPublicTransactionById,
  useGetProductReservationByIdV2,
  useGetPublicProductReservationByIdV2,
} from "../../../query/queries";
import {
  GetPublicTransactionByIdResponse,
  ScheduleDetailWithQty,
} from "../../../api/request.types";
import dayjs from "dayjs";
import { PAYMENT_STATUS, TRANSACTION_TYPE } from "../../../types/globalTypes";
import TemplateButton from "../../../components/TemplateButton/TemplateButton";
import useNavigateDynamicPage from "../../../hooks/useNavigateDynamicPage";
import { useSnackbar } from "notistack";
import { TIME_FORMAT } from "../../../utils/constant";

const DEFAULT_TRANSACTION_DETAIL: GetPublicTransactionByIdResponse = {
  transactionID: "",
  companyID: "",
  timezone: "Asia/Jakarta",
  companyName: "",
  companyLogo: "",
  date: "",
  fullName: "",
  email: "",
  phoneNumber: "",
  productDetail: [],
  location: "",
  adminPrice: 0,
  productPrice: 0,
  total: 0,
  notes: "",
  paymentStatus: PAYMENT_STATUS.PENDING,
  paidWith: "",
  snapToken: "",
  snapRedirectURL: "",
  refundReason: "",
  isManuallyAdded: false,
  transactionTimestamp: {
    paidAt: null,
    requestRefundAt: null,
    refundedAt: null,
    refundCancelledAt: null,
    cancelledAt: null,
  },
  linkID: 0,
  linkURL: "",
  allowRefund: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  transactionType: TRANSACTION_TYPE.ONLINE_BOOKING,
};

const DReschedule: React.FC = () => {
  const { data } = useDynamicPage();
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const { subdomain } = useDynamicPage();
  const navigate = useNavigateDynamicPage();
  const { enqueueSnackbar } = useSnackbar();

  const bottomSheetRef = useRef<BottomSheetRef>(null);
  const [openBotSheet, setOpenBotSheet] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const transactionByIdQuery = useGetPublicTransactionById(
    subdomain,
    invoiceId || ""
  );

  const transactionData: GetPublicTransactionByIdResponse = useMemo(() => {
    return transactionByIdQuery.data?.data.data || DEFAULT_TRANSACTION_DETAIL;
  }, [transactionByIdQuery.data]);

  const isLoading = useMemo(() => {
    return transactionByIdQuery.isLoading;
  }, [transactionByIdQuery.isLoading]);

  const productId = transactionData.productDetail[0]?.productID || "";
  const productReservationQuery =
    useGetPublicProductReservationByIdV2(productId);

  const productReservation = useMemo(() => {
    return productReservationQuery.data?.data?.data;
  }, [productReservationQuery.data?.data?.data]);

  const titleSx: SxProps<Theme> = {
    fontWeight: 600,
    fontSize: 16,
  };

  const handleSelectItem = (index: number) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(index)
        ? prevSelectedItems.filter((item) => item !== index)
        : [...prevSelectedItems, index]
    );
  };

  const handleItemClick = (schedule: ScheduleDetailWithQty) => {
    if (!productReservation) {
      enqueueSnackbar(
        "Penjadwalan ulang tidak diizinkan untuk produk ini atau sudah melewati batas waktu penjadwalan ulang",
        {
          variant: "error",
          ...DEFAULT_SNACKBAR_PROPS,
        }
      );
      return;
    }

    const now = dayjs();
    const scheduleTime = dayjs(
      schedule.date + " " + schedule.startTime,
      `${TIME_FORMAT.YYYYMMDD} ${TIME_FORMAT.HHmm.replace(":", ".")}`
    );
    const hoursDiff = scheduleTime.diff(now, "hour");

    if (!productReservation.allowReschedule) {
      enqueueSnackbar("Penjadwalan ulang tidak diizinkan untuk produk ini", {
        variant: "error",
        ...DEFAULT_SNACKBAR_PROPS,
      });
      return;
    }
    if (hoursDiff < productReservation.preventRescheduleBefore) {
      enqueueSnackbar(
        "Penjadwalan ulang sudah melewati batas waktu yang diizinkan",
        {
          variant: "error",
          ...DEFAULT_SNACKBAR_PROPS,
        }
      );
      return;
    }

    navigate(
      RoutePath[ROUTE_NAME.DYNAMIC_RESCHEDULE_DETAIL] +
        `/${invoiceId}/reschedule/detail`,
      {
        state: {
          schedule,
          productID: transactionData.productDetail[0]?.productID || "",
        },
      }
    );
  };

  const handleBackToHome = () => {
    navigate("/");
  };

  const _renderLoadingSkeleton = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          color: "black",
          justifyContent: "center",
        }}
      >
        <Skeleton variant="rounded" width="45%" />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          gap={2}
        >
          <Skeleton variant="rounded" width="35%" height={15} />
          <Skeleton variant="rounded" width="35%" height={15} />
        </Box>
      </Box>
    );
  };

  return (
    <AppContainer
      sx={{
        backgroundColor: data.color,
        padding: 2,
        height: "100%",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: 4,
          py: 2,
        }}
      >
        {isLoading ? (
          _renderLoadingSkeleton()
        ) : (
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: 4,
              py: 2,
            }}
          >
            <Typography px={2} sx={titleSx}>
              Pilih Produk yang Ingin Dijadwalkan Ulang
            </Typography>
            {transactionData.productDetail.map((product, productIndex) =>
              (product.scheduleDetails || []).map((schedule, key) => {
                const start = dayjs(schedule.startTime, TIME_FORMAT.HHmm);
                const end = dayjs(schedule.endTime, TIME_FORMAT.HHmm);
                const date = dayjs(schedule.date, TIME_FORMAT.YYYYMMDD);

                const now = dayjs();
                const scheduleTime = dayjs(
                  schedule.date + " " + schedule.startTime,
                  `${TIME_FORMAT.YYYYMMDD} ${TIME_FORMAT.HHmm.replace(":", ".")}`
                );
                const hoursDiff = scheduleTime.diff(now, "hour");
                const disallowReschedule =
                  !productReservation?.allowReschedule ||
                  hoursDiff < productReservation?.preventRescheduleBefore;

                return (
                  <Box
                    key={key}
                    display="flex"
                    alignItems="center"
                    px={2}
                    py={1}
                    onClick={() => handleItemClick(schedule)}
                    sx={{
                      cursor: disallowReschedule ? "not-allowed" : "pointer",
                      opacity: disallowReschedule ? 0.5 : 1,
                      backgroundColor: selectedItems.includes(key)
                        ? COLOR.primary100
                        : "transparent",
                      "&:hover": {
                        backgroundColor: COLOR.primary50,
                      },
                    }}
                  >
                    <RowKeyValue
                      title=""
                      value={`${date.format(TIME_FORMAT.DDMMMYYYY)} / ${start.format(
                        TIME_FORMAT.HHmm
                      )} - ${end.format(TIME_FORMAT.HHmm)} ${
                        TIMEZONE_SHORTLABEL?.[
                          transactionData.timezone || "Asia/Jakarta"
                        ]
                      } (${end.diff(start, "minute")} menit) - ${
                        schedule.qty
                      } item`}
                    />
                  </Box>
                );
              })
            )}
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="column" gap={2} pt={2}>
        <TemplateButton
          onClick={handleBackToHome}
          backgroundColor="white"
          color={COLOR.neutral900}
          buttonText="Kembali Ke Halaman Utama"
        />
      </Box>
    </AppContainer>
  );
};

export default DReschedule;
