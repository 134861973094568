import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BankAccountData,
  BankAccountStatus,
} from "../../types/globalTypes";

export type BankAccountReducerState = {
  bankAccountData: BankAccountData;
};

export const initialState: BankAccountReducerState = {
  bankAccountData: {
    accountNumber: "",
    accountName: "",
    bankCode: "",
    bankName: "",
    bankInquiryStatus: BankAccountStatus.UNVERIFIED,
  },
};

const bankAccountSlice = createSlice({
  name: "bankAccount",
  initialState,
  reducers: {
    setBankAccountData: (
      state,
      action: PayloadAction<Partial<BankAccountData>>
    ) => {
      return {
        ...state,
        bankAccountData: {
          ...state.bankAccountData,
          ...action.payload,
        },
      };
    },
    resetBankAccountData: (state) => {
      return {
        ...state,
        bankAccountData: {
          ...initialState.bankAccountData,
        },
      };
    },
  },
});

export const { setBankAccountData, resetBankAccountData } =
  bankAccountSlice.actions;
export default bankAccountSlice.reducer;
