import React, { useEffect, useState, forwardRef, useCallback } from "react";
import {
  BottomSheet,
  BottomSheetProps,
  BottomSheetRef,
} from "react-spring-bottom-sheet";
import SchedulePrice from "../SchedulePrice/SchedulerPrice";
import { ManageSchedulesType } from "../../context/ScheduleContext";
import { SchedulePriceType } from "../../types/globalTypes";
import { Box, Typography } from "@mui/material";
import CustomButton from "../CustomButton/CustomButton";
import { DAY } from "../../utils/date";
import { COLOR } from "../../utils/color";

interface ScheduleBottomSheetProps {
  bottomSheetProps?: Omit<BottomSheetProps, "children" | "open" | "ref">;
  schedules: ManageSchedulesType;
  isCustomPrice: boolean;
  price: number;
  open: boolean;
  onCancel?: () => void;
  onApply: (schedules: ManageSchedulesType) => void;
}

const ScheduleBottomSheet = forwardRef<
  BottomSheetRef,
  ScheduleBottomSheetProps
>(({ bottomSheetProps, schedules, ...props }, ref) => {
  const [initialSchedules, setInitialSchedules] =
    useState<ManageSchedulesType>(schedules);

  const handleSchedulesChange =
    (day: string) => (updatedSchedules: SchedulePriceType[]) => {
      setInitialSchedules((prev) => ({
        ...prev,
        [day]: [...updatedSchedules],
      }));
    };

  const resetState = useCallback(() => {
    setInitialSchedules({
      [DAY.SUNDAY]: [...schedules[DAY.SUNDAY]],
      [DAY.MONDAY]: [...schedules[DAY.MONDAY]],
      [DAY.TUESDAY]: [...schedules[DAY.TUESDAY]],
      [DAY.WEDNESDAY]: [...schedules[DAY.WEDNESDAY]],
      [DAY.THURSDAY]: [...schedules[DAY.THURSDAY]],
      [DAY.FRIDAY]: [...schedules[DAY.FRIDAY]],
      [DAY.SATURDAY]: [...schedules[DAY.SATURDAY]],
    });
  }, [schedules]);

  useEffect(() => {
    if (props.open) {
      resetState();
    }
  }, [props.open, resetState, schedules]);

  const handleCancel = () => {
    resetState();
    props.onCancel?.();
  };

  const handleApply = () => {
    props?.onApply(initialSchedules);
  };

  return (
    <BottomSheet
      ref={ref}
      open={props.open}
      blocking={false}
      snapPoints={({ maxHeight }) => [
        maxHeight - 45,
      ]}
      {...bottomSheetProps}
      style={{
        ...bottomSheetProps?.style,
      }}
      footer={
        <Box display="flex" justifyContent="space-between" gap={1}>
          <CustomButton variant="outlined" onClick={handleCancel}>
            Cancel
          </CustomButton>
          <CustomButton onClick={handleApply}>Apply</CustomButton>
        </Box>
      }
    >
      <Box px={2} pb={5} pt={3}>
        {Object.keys(initialSchedules).map((day) => {
          const daySchedules = initialSchedules[day];
          return (
            <SchedulePrice
              key={day}
              title={day}
              schedules={daySchedules}
              onSchedulesChange={handleSchedulesChange(day)}
              priceProps={{
                isCustomPrice: props.isCustomPrice,
                price: props.price,
              }}
            />
          );
        })}
      </Box>
    </BottomSheet>
  );
});

export default ScheduleBottomSheet;
