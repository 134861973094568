import React, { useEffect, useState, useCallback } from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Checkbox } from "@mui/material";
import { COLOR } from "../../utils/color";

interface Permission {
  category: string;
  name: string;
  levels: boolean[];
  editable: boolean;
}

const fetchPermissions = async (): Promise<Permission[]> => {
  // TODO: Replace with ReactQuery API Call Mutate Async or others that match with the behaviour
  return [
    { category: "Sales Page", name: "Sale Dashboard", levels: [false, true, true], editable: true },
    { category: "Sales Page", name: "View Page", levels: [false, true, true], editable: true },
    { category: "Sales Page", name: "Export Transaction", levels: [false, true, true], editable: true },
    { category: "Feature Access", name: "View Feature List", levels: [false, false, true], editable: false },
    { category: "Feature Access", name: "Update Feature Access", levels: [false, false, true], editable: false },
  ];
};

const updatePermission = async (name: string, levelIndex: number, value: boolean) => {
  // TODO: Replace with ReactQuery API Call Mutate Async or others that match with the behaviour, remember to debounce to prevent double same call can use useDebouncedFunction if needed
  console.log(`Updating ${name} at level ${levelIndex} to ${value}`);
};

const PermissionTable: React.FC = () => {
  const [permissions, setPermissions] = useState<Permission[]>([]);

  useEffect(() => {
    const loadPermissions = async () => {
      const data = await fetchPermissions();
      setPermissions(data);
    };
    loadPermissions();
  }, []);

  const handleCheckboxChange = useCallback(
    async (name: string, levelIndex: number, currentValue: boolean, editable: boolean) => {
      if (!editable) {
        console.log(`Permission '${name}' is not editable.`);
        return;
      }

      setPermissions((prevPermissions) =>
        prevPermissions.map((item) =>
          item.name === name
            ? { ...item, levels: item.levels.map((level, i) => (i === levelIndex ? !currentValue : level)) }
            : item
        )
      );

      await updatePermission(name, levelIndex, !currentValue);
    },
    []
  );

  const groupedPermissions = permissions.reduce((acc, permission) => {
    if (!acc[permission.category]) {
      acc[permission.category] = [];
    }
    acc[permission.category].push(permission);
    return acc;
  }, {} as Record<string, Permission[]>);

  return (
    <TableContainer component={Paper} style={{ maxHeight: "calc(100vh - 90px)", overflow: "auto" }}>
      <Table>
        <TableBody>
          {Object.entries(groupedPermissions).map(([category, items]) => (
            <React.Fragment key={category}>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", position: "sticky", left: 0, background: "white", minWidth: "150px", backgroundColor: COLOR.neutral200 }}>
                  {category}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center", py: "4px", minWidth: "80px", backgroundColor: COLOR.neutral200 }}>Lv 1</TableCell>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center", py: "4px", minWidth: "80px", backgroundColor: COLOR.neutral200 }}>Lv 2</TableCell>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center", py: "4px", minWidth: "80px", backgroundColor: COLOR.neutral200 }}>Lv 3</TableCell>
              </TableRow>
              {items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ position: "sticky", left: 0, background: "white", minWidth: "150px", backgroundColor: "white", zIndex: 1 }}>{item.name}</TableCell>
                  {item.levels.map((level, i) => (
                    <TableCell key={i} align="center" sx={{ py: "4px" }}>
                      <Checkbox
                        checked={level}
                        onChange={() => handleCheckboxChange(item.name, i, level, item.editable)}
                        disabled={!item.editable}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PermissionTable;
