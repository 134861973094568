import { debounce } from '@mui/material';
import { useState, useEffect, RefObject, useCallback } from 'react';

/**
 * Custom hook to get the height of a referenced element.
 * @param ref - The ref of the element to measure.
 * @returns The height of the element.
 */
export function useElementHeight(ref: RefObject<HTMLElement>): number {
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setHeight(ref.current.clientHeight);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return height;
}
export function useElementDimensions(ref: RefObject<HTMLElement>): [number, number] {
  const [dimensions, setDimensions] = useState<[number, number]>([0, 0]);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setDimensions([ref.current.clientWidth, ref.current.clientHeight]);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return dimensions;
}

export function useElementProperties(ref: RefObject<HTMLElement>) {
  const [properties, setProperties] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    offsetTop: 0,
  });

  useEffect(() => {
    const updateProperties = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        setProperties(prevProperties => ({
          ...prevProperties,
          width: rect.width,
          height: rect.height,
          top: rect.top,
          left: rect.left,
          right: rect.right,
          bottom: rect.bottom,
          offsetTop: ref.current?.offsetTop || 0,
        }));
      }
    };

    updateProperties();
    window.addEventListener('resize', updateProperties);
    window.addEventListener('scroll', updateProperties);

    return () => {
      window.removeEventListener('resize', updateProperties);
      window.removeEventListener('scroll', updateProperties);
    };
  }, [ref]);

  return properties;
}


interface ElementProperties {
  scrollHeight: number;
  offsetTop: number;
  offsetLeft: number;
  offsetRight: number;
  clientHeight: number;
  clientWidth: number;
  scrollWidth: number;
  offsetWidth: number;
  offsetBottom: number;
  scrollTop: number;
  scrollLeft: number;
  offsetHeight: number;
}


export const useElementCurrentProperties = (
  ref?: RefObject<HTMLElement> | undefined | null
): ElementProperties => {
  const [properties, setProperties] = useState<ElementProperties>({
    scrollHeight: 0,
    offsetTop: 0,
    offsetLeft: 0,
    offsetRight: 0,
    clientHeight: 0,
    clientWidth: 0,
    scrollWidth: 0,
    offsetWidth: 0,
    offsetBottom: 0,
    scrollTop: 0,
    scrollLeft: 0,
    offsetHeight: 0,
  });


  const updateProperties = useCallback(() => {
    try {
      if (!ref?.current) return;
      
      const element = ref.current;
      setProperties({
        scrollHeight: element.scrollHeight,
        offsetTop: element.offsetTop,
        offsetLeft: element.offsetLeft,
        offsetRight: element.offsetLeft + element.offsetWidth,
        clientHeight: element.clientHeight,
        clientWidth: element.clientWidth,
        scrollWidth: element.scrollWidth,
        offsetWidth: element.offsetWidth,
        offsetBottom: element.offsetTop + element.offsetHeight,
        scrollTop: element.scrollTop,
        scrollLeft: element.scrollLeft,
        offsetHeight: element.offsetHeight,
      });
    } catch (error) {
      console.error('Error updating properties:', error);
    }
  }, [ref]);

  useEffect(() => {
    const debouncedUpdate = debounce(updateProperties, 100);
    const observer = new ResizeObserver(debouncedUpdate);
    
    updateProperties();
    
    if (ref?.current) {
      observer.observe(ref.current);
    }

    window.addEventListener('scroll', debouncedUpdate, { passive: true });
    
    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', debouncedUpdate);
      debouncedUpdate.clear();
    };
  }, [ref, updateProperties]);

  return properties;
};