import { Box, BoxProps } from '@mui/material';
import { useRef } from 'react';
import { getInitials } from '../../utils/helper';
import { Typography } from '@mui/material';
import { COLOR } from '../../utils/color';
import { useElementProperties } from '../../hooks/useElementHeight';

interface ProfileInitialProps {
  name: string;
  size?: number | string;
  sx?: BoxProps['sx'];
  fontScale?: number;
}

const ProfileInitial = (props: ProfileInitialProps) => {
  const { size = '62px', fontScale = 0.35 } = props;
  const ref = useRef<HTMLDivElement>(null);
  const { width } = useElementProperties(ref);
  return (
    <Box 
      ref={ref} 
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '50px',
        aspectRatio: 1,
        backgroundColor: COLOR.primary100,
        borderRadius: '50%',
        outline: `2px solid ${COLOR.primary200}`,
        outlineOffset: '2px',
        margin: '2px',
        color: COLOR.primary500,
        width: size || '100%',
        ...props?.sx
      } as BoxProps['sx']}
    >
      <Typography fontWeight="bold" fontSize={width * fontScale}>{getInitials(props.name)}</Typography>
    </Box>
  )
}

export default ProfileInitial;
