import { Box, SxProps, Typography } from '@mui/material';
import React, { useState } from 'react';
import { formatCurrency } from '../../utils/helper';
import { COLOR } from '../../utils/color';
import OpacityButton from '../OpacityButton/OpacityButton';
import { RiDeleteBack2Line } from 'react-icons/ri';
import { Theme } from '@emotion/react';

export type KeyboardKeyType = number | 'del' | '000';

interface NumberKeyboardPropsI {
  onKeyPress?: (value: KeyboardKeyType) => void;
}
const NumberKeyboard = (props: NumberKeyboardPropsI) => {
  const handleKeyPress = (value: string) => {
    props?.onKeyPress?.(parseInt(value));
  };

  const buttonSx: SxProps<Theme> = {
    p: 2,
    minWidth: 80,
    borderRadius: 2,
    border: `1px solid ${COLOR.neutral300}`,
    fontWeight: 'bold',
    fontSize: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 1, }}>
      {'123456789'.split('').map(numStr => (
        <OpacityButton sx={buttonSx} key={numStr} onClick={() => handleKeyPress(numStr)}>{numStr}</OpacityButton>
      ))}
      <OpacityButton sx={buttonSx} onClick={() => props?.onKeyPress?.(0)}>0</OpacityButton>
      <OpacityButton sx={buttonSx} onClick={() => props?.onKeyPress?.('000')}>000</OpacityButton>
      <OpacityButton sx={buttonSx} onClick={() => props?.onKeyPress?.('del')}><RiDeleteBack2Line size={24} /></OpacityButton>
    </Box>
  );
};

export default NumberKeyboard;