import { SortDirection } from "@mui/material";
import { FieldNode } from "../redux/reducers/product";
import {
  BankAccountStatus,
  BankCode,
  CurrencyCodeType,
  DisbursementStatus,
  FailedDisbursementReason,
  IMAGE_USAGE,
  PAYMENT_STATUS,
  SCHEDULE_TYPE,
  Schedule,
  TRANSACTION_TYPE,
} from "../types/globalTypes";
import { DURATION_PERIOD, THUMB_STYLE } from "../utils/constant";
import { PAYMENT_STATUS_SCHEDULE } from "../components/Modal/ScheduleFilterModal";
import {
  TeamShift,
  TimeRange,
} from "../pages/TeamScheduledShifts/TeamScheduledShifts";

export enum ScheduleEventStatus {
  BOOKED = "booked",
  CONFIRMED = "confirmed",
  ARRIVED = "arrived",
  STARTED = "started",
  COMPLETED = "completed",
  NO_SHOW = "no_show",
  CANCELLED = "cancelled",
}

export const ScheduleEventStatusLabelMap = {
  [ScheduleEventStatus.BOOKED]: "Dipesan",
  [ScheduleEventStatus.CONFIRMED]: "Dikonfirmasi",
  [ScheduleEventStatus.ARRIVED]: "Telah Tiba",
  [ScheduleEventStatus.STARTED]: "Dimulai",
  [ScheduleEventStatus.COMPLETED]: "Selesai",
  [ScheduleEventStatus.NO_SHOW]: "Tidak Hadir",
  [ScheduleEventStatus.CANCELLED]: "Dibatalkan",
};

export enum ScheduleEventType {
  SINGLE_RESERVATION = "single_reservation",
  BLOCKED_TIME = "blocked_time",
}

export const ScheduleEventTypeLabelMap = {
  [ScheduleEventType.SINGLE_RESERVATION]: "Janji Temu Tunggal",
  [ScheduleEventType.BLOCKED_TIME]: "Waktu Terblokir",
};

export enum ScheduleEventPaymentStatus {
  UNPAID = "unpaid",
  PARTLY_PAID = "partly_paid",
  PAID = "paid",
}

export const ScheduleEventPaymentStatusLabelMap = {
  [ScheduleEventPaymentStatus.UNPAID]: "Belum Dibayar",
  [ScheduleEventPaymentStatus.PARTLY_PAID]: "Dibayar Sebagian",
  [ScheduleEventPaymentStatus.PAID]: "Sudah Dibayar",
};

export enum ScheduleEventActivityType {
  CREATE = "create",
  DATE = "date",
  STATUS = "status",
  PRODUCT = "product",
  SALE = "sale",
}

export interface ScheduleEventProductDetail {
  productID: string;
  productName: string;
  teamMemberID: string;
  teamMemberName: string;
  resourceID: string;
  resourceName: string;
  qty: number;
  duration: number;
  startTime: number;
  endTime: number;
  price: number;
  extraTimeType: string;
  extraTime: number;
  blockTimeID?: string;
  blockTimeName?: string;
  blockTimeEmoji?: string;
  isResourceActive?: boolean;
}

export interface CreateScheduleEventRequest {
  teamMemberIDs: string[];
  companyID: string;
  title: string;
  date: string;
  startDate: string;
  endDate: string;
  startTime: number;
  endTime: number;
  notes: string;
  productDetails: ScheduleEventProductDetail[];
  type: ScheduleEventType;
  paymentStatus: ScheduleEventPaymentStatus;
}

export interface CreateBlockTime {
  teamMemberIDs: string[];
  companyID: string;
  title: string;
  date: string;
  startDate: string;
  endDate: string;
  startTime: number;
  endTime: number;
  notes: string;
  blockTimeID: string;
}

export interface UpdateBlockTime {
  eventID: string;
  teamMemberIDs: string[];
  companyID: string;
  title: string;
  date: string;
  startDate: string;
  endDate: string;
  startTime: number;
  endTime: number;
  notes: string;
  blockTimeID: string;
  status: ScheduleEventStatus;
}

export interface UpdateScheduleEventRequest extends CreateScheduleEventRequest {
  eventID: string;
  status: ScheduleEventStatus;
}
export interface ConfirmTransactionScheduleEventRequest {
  paidAmount: number;
  paidWith: string;
  receivedBy: string;
  receivedByName: string;
}
export interface ConfirmTransactionScheduleEvenPayloadRequest {
  details: {
    paidAmount: number;
    paidWith: string;
    receivedBy: string;
    receivedByName: string;
  }[];
}

export interface APIUpdateTransactionRequest {
  transactionID?: string;
  companyID: string;
  eventID?: string;
  productDetail?: Array<{
    _id: string;
    productID: string;
    productName: string;
    teamMemberID: string;
    scheduleDetails: Array<{
      startTime: string;
      endTime: string;
      date: string;
      qty: number;
      price: number;
      duration: number;
      _id: string;
    }>;
  }>;
  paymentStatus?: PAYMENT_STATUS;
  receivedBy?: string;
}

export interface APICreateNewResourceRequest {
  resourceName: string;
  description: string;
  productIDs: string[];
  companyID: string;
}
export interface APIUpdateResourceRequest {
  resourceID: string;
  resourceName?: string;
  description?: string;
  productIDs?: string[];
  companyID: string;
}
export interface TeamMemberShiftByDateQuery {
  teamMemberIDs: string[];
  teamMemberQueryType: TeamMemberQueryType;
}

export interface ScheduleEventProduct {
  services: ScheduleEventProductDetail;
  notes: string;
  status: ScheduleEventStatus;
  paymentStatus: ScheduleEventPaymentStatus;
}
export interface FlattenedScheduleEvent {
  overlapOrder: number;
  eventID: string;
  overlapCount: number;
  startDate: string;
  endDate: string;
  title: string;
  isAvailable: boolean;
  type: ScheduleEventType;
  productDetails: ScheduleEventProduct;
  blockTimeID?: string;
  client: {
    email: string;
    name: string;
    phoneNumber: string;
  }
}

export interface ClientDetail {
  name: string;
  email: string;
  phoneNumber: string;
}

export interface ScheduleEvent {
  eventID: string;
  teamMemberIDs: string[];
  resourceIDs: string[];
  companyID: string;
  title: string;
  date: string;
  status: ScheduleEventStatus;
  startDate: string;
  endDate: string;
  startTime: number;
  endTime: number;
  notes: string;
  productDetails: ScheduleEventProductDetail[];
  allowOnlineBooking: boolean;
  isOnlineBooking: boolean;
  type: ScheduleEventType;
  paymentStatus: ScheduleEventPaymentStatus;
  paidAmount: number;
  transactionID: string;
  client: ClientDetail;
  createdBy: string;
  updatedBy: string;
}

export interface FlattenedScheduleEventByTeamMember {
  teamMemberID: string;
  teamMemberName: string;
  events: FlattenedScheduleEvent[];
}
export type APIGetScheduleEventsResponse = Response<
  FlattenedScheduleEventByTeamMember[]
>;

export enum GetScheduleEventResponseType {
  TEAM_MEMBER = "team-member",
  RESOURCE = "resource",
}

export interface FlattenedScheduleEventByEntity {
  id: string;
  name: string;
  type: GetScheduleEventResponseType;
  events: FlattenedScheduleEvent[];
}

export type APIGetScheduleEventsResponseV2 = Response<
  FlattenedScheduleEventByEntity[]
>;

export interface GetResourceCompact {
  companyID: string;
  companyName: string;
  resources: {
    resourceID: string;
    resourceName: string;
    isActive: boolean;
  }[];
}

export type APIGetResourceCompactResponse = Response<GetResourceCompact>;

export enum ResourceStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface Resource {
  resourceID: string;
  companyID: string;
  resourceName: string;
  description: string;
  productIDs: string[];
  status: ResourceStatus;
  createdBy: string;
  updatedBy: string;
}

export type APIGetResourceByIDResponse = Response<Resource | null>;
export type APICreateScheduleEventResponse = Response<string>;
export type APIUpdateScheduleEventResponse = Response<string>;
export type APICreateBlockTimeResponse = Response<string>;
export type APIUpdateBlockTimeResponse = Response<string>;
export type APIConfirmTransactionScheduleEventResponse = Response<string>;
export type APIUpdateTransactionResponse = Response<string>;
export type APICreateTransactionScheduleEventResponse = Response<string>;
export type APICreateTransactionAdhocResponse = Response<{
  transactionID: string;
}>;
export interface ShiftTimeCompact {
  startTime: string;
  endTime: string;
}
export interface GetTeamMemberShiftsByDateResponse {
  date: string;
  teamMemberID: string;
  teamMemberName: string;
  shifts: ShiftTimeCompact[];
}
export type APIGetTeamMemberShiftsByDateResponse = Response<
  GetTeamMemberShiftsByDateResponse[]
>;

export interface ScheduleEventActivity {
  eventID: string;
  transactionID: string;
  scheduleEventStatus: ScheduleEventStatus;
  amount: number;
  userName: string;
  userID: string;
  activityType: ScheduleEventActivityType;
  prevValue: string;
  newValue: string;
  createdAt: string;
  updatedAt: string;
}

export type APIGetScheduleEventTransactionActivitiesResponse = Response<
  ScheduleEventActivity[]
>;
export interface GetScheduleEventProductDetail {
  productID: string;
  productName: string;
  duration: number;
  price: number;
  teamMembers: {
    teamMemberID: string;
    name: string;
  }[];
  resources: {
    resourceID: string;
    name: string;
  }[];
}
export type APIGetScheduleEventProductsResponse = Response<
  GetScheduleEventProductDetail[]
>;

export interface GetTeamMemberByProductIDResponse {
  productID: string;
  productName: string;
  teamMembers: {
    teamMemberID: string;
    name: string;
  }[];
}
export type APIGetTeamMemberByProductID =
  Response<GetTeamMemberByProductIDResponse>;
export type APIGetScheduleEventByIDResponse = Response<ScheduleEvent>;
export type APIGetScheduleEventActivitiesResponse = Response<
  ScheduleEventActivity[]
>;
export type APIGetScheduleEventClientOptionsResponse = Response<{
  name: string;
  email: string;
  phoneNumber: string;
}>;

export type Response<T> = {
  message: string;
  data: T;
};
export type ErrorResponse = {
  message: string;
};
export type RegisterPayload = {
  email: string;
  password: string;
  fullName: string;
};
export type RegisterResponse = Response<string>;
export type LoginPayload = {
  email: string;
  password: string;
};

export type LoginResponse = Response<{
  fullName: string;
  token: string;
  email: string;
  userID: string;
}>;

export type LoginGooglePayload = {
  code: string;
  scope: string;
  authuser: string;
  prompt: string;
};
export type GoogleLoginResponse = LoginResponse;

export type APICompanyLocation = {
  mapLocation: string;
  completeAddress: string;
  useManualLocation: boolean;
  longitude: number;
  latitude: number;
  timezone: string;
};

export type APICreateCompanyBodyPayload = {
  name: string;
  disabled: boolean;
  description: string;
  currency: CurrencyCodeType;
  location: APICompanyLocation;
  companyDomainName: string;
  logoPath: string;
  template: string;
  color: string;
  socialMedia: SocialMediaType;
};

export type APIEditCompanyBodyPayload = Partial<APICreateCompanyBodyPayload>;

export type APICreateCompanyBodyPayloadV2 = APICreateCompanyBodyPayload & {
  socialMedia: SocialMediaType;
};

export type APIEditCompanyBodyPayloadV2 =
  Partial<APICreateCompanyBodyPayloadV2>;

export type ScheduleDetail = {
  startTime: string;
  endTime: string;
  price: number;
};

export type APICreateProductBodyPayload = {
  companyID: string; //required
  name: string; // min 5 char
  description: string; // min 5 char
  useSinglePrice: boolean; // required
  allowReschedule: boolean; // required
  duration: number; // in minute, required, min 0, max 1410 (12 jam 30menit)
  durationPeriod: DURATION_PERIOD; // required
  quota: number; // min 0, max 100
  price: number; // min 0, max 100.000.000
  photos: string[]; // required
  location: APICompanyLocation;
  scheduleDetails: Record<string, ScheduleDetail[]>;
  disabled: boolean;
  addOn: [];
  allowRefund: boolean;
};
export type APICreateProductResponse = Response<string>;
export type APIUploadCompanyLogoPayload = {
  companyId: string;
  file: File;
};

export type GetProductByIdResponse = {
  productID: string;
  companyID: string;
  name: string;
  description: string;
  price?: number;
  useSinglePrice: boolean;
  allowRefund: boolean;
  disabled: boolean;
  duration: number;
  durationPeriod: DURATION_PERIOD;
  quota: number;
  photos: Array<string>;
  photosSignedURL: Array<string>;
  location: APICompanyLocation;
  scheduleDetails: Record<string, ScheduleDetail[]>;
  addOn: [];
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
};
export type APIGetProductByIdResponse = Response<GetProductByIdResponse>;
export type APIUploadProductPhotosPayload = Array<File | string>;
export type APIUploadProductPhotosResponse = Response<Array<string>>;

export type APIDeleteProductById = string; // deleted product Id
export type APIDeleteProductByIdResponse = Response<APIDeleteProductById>;

export type UploadCompanyLogoResponse = Response<string>;
export type DownloadSignedUrlResponse = Response<string>;
export type CreateCompanyResponse = Response<string>;
export type UpdateCompanyResponse = Response<string>;
export type UpdateCompanyProductDetailResponse = Response<CompanyProduct>;

export type UserByIdResponse = {
  userID: string;
  email: string;
  fullName: string;
  password: string;
  phoneNumber: string;
  whatsAppNumber: string;
  status: string;
  registerProvider: string;
  role: string;
  notificationSource: string;
  createdAt: string;
  updatedAt: string;
};
export type FindUserByIdResponse = Response<UserByIdResponse>;

export type UserCompaniesType = {
  companyID: string;
  name: string;
  logoPath: string;
  logoSignedURL: string;
};
export type UserCompaniesResponse = Response<UserCompaniesType[]>;

export type APICompanyById = {
  _id: string;
  color: string;
  companyDomainName: string;
  companyID: string;
  userID: string;
  name: string;
  logoPath: string;
  logoSignedURL: string;
  template: string;
  disabled: boolean;
  description: string;
  currency: CurrencyCodeType;
  location: APICompanyLocation;
  createdAt: string;
  createdBy: string;
  updatedBy: string;
  updatedAt: string;
  __v: number;
};
export type CompanyByIdResponse = Response<APICompanyById>;

export interface ProductCompact {
  productID: string;
  thumbnailPhoto: string;
  thumbnailPhotoSignedURL: string;
  name: string;
  price: number;
  duration: number;
  durationPeriod: DURATION_PERIOD;
}
export type PageTemplateType = "Type1" | "Type2";

export interface PublicCompanyWebsiteResponse {
  companyWebsiteID: string;
  companyID: string;
  companyDomainName: string;
  logoPath: string;
  logoSignedURL: string;
  template: PageTemplateType;
  color: string;
  name: string;
  description: string;
  products: ProductCompact[];
  openingHours: string;
}

export type APIGetByDomainNameResponse = Response<PublicCompanyWebsiteResponse>;

export type APIGetDomainAvailabilityResponse = Response<boolean>;

export type GetPublicProductByIdResponse = {
  _id: string;
  name: string;
  description: string;
  duration: number;
  durationPeriod: string;
  location: APICompanyLocation;
  photos: string[];
  photosSignedURL: string[];
  price: number;
  productID: string;
};

export type APIGetPublicProductById = Response<GetPublicProductByIdResponse>;

export type PublicScheduleDetail = {
  startTime: string; // "HH.mm" format
  endTime: string; // "HH.mm" format
  date: string;
  price: number;
  remainingQuota: number;
  duration?: number;
};
export type GetPublicScheduleDetailByIdResponse = {
  date: string; // format "YYYY-MM-DD"
  scheduleDetails: Array<PublicScheduleDetail>;
};

export type APIGetPublicProductScheduleDetailByIdResponse =
  Response<GetPublicScheduleDetailByIdResponse>;

export type APICalculateTransactionPayload = {
  companyID: string;
  productDetail: Array<{
    productID: string;
    scheduleDetails: Array<
      PublicScheduleDetail & {
        date: string; // YYYY-MM-DD
      }
    >;
  }>;
};

export type APICreateNewTransactionPayload = CalculateTransactionResponse & {
  fullName: string;
  email: string;
  phoneNumber: string; // optionals
  notes: string;
};

export type APICreateNewTransactionScheduleEventPayload = {
  companyID: string;
  date: string; // YYYY-MM-DD
  location: string;
  timezone?: string;
  total?: number;
  productDetail?: Array<ScheduleProductDetail>;
  fullName: string;
  email: string;
  teamMemberIDs?: string[];
  phoneNumber: string;
  notes: string;
  productPrice?: number;
  adminPrice?: number;
  transactionType?: TRANSACTION_TYPE;
  paymentDetail?: ConfirmTransactionScheduleEventRequest[];
};

export type APICreateNewTransactionAdhocPayload = {
  companyID: string;
  date: string; // YYYY-MM-DD
  location: string;
  timezone?: string;
  total?: number;
  productDetail?: Array<ScheduleProductDetailAdhoc>;
  fullName: string;
  email: string;
  teamMemberIDs?: string[];
  phoneNumber: string;
  notes: string;
  productPrice?: number;
  adminPrice?: number;
  transactionType?: TRANSACTION_TYPE;
  paymentDetail?: ConfirmTransactionScheduleEventRequest[];
};

export type APIRefundTransactionPayload = {
  transactionID: string;
  refundReason: string;
};

export type ScheduleProductDetail = {
  productID: string;
  productName: string;
  teamMemberID?: string;
  teamMemberName?: string;
  scheduleDetails: Array<ScheduleDetailWithQty>;
};

export type ScheduleProductDetailAdhoc = {
  productID: string;
  productName: string;
  teamMemberID?: string;
  scheduleDetails: Array<ScheduleDetailWithQtyAdhoc>;
};

export type ScheduleDetailWithQtyAdhoc = {
  qty: number;
  startTime: number;
  endTime: number;
  date: string;
  price: number;
  duration?: number;
};
export type ScheduleDetailWithQty = {
  qty: number;
} & PublicScheduleDetail;

export type CalculateTransactionResponse = {
  companyID: string;
  date: string; // YYYY-MM-DD
  productDetail: Array<ScheduleProductDetail>;
  location: string;
  timezone?: string;
  total: number;
};

export type APICalculateTransactionResponse =
  Response<CalculateTransactionResponse>;

export interface TransactionTimestamp {
  paidAt: Date | null;
  requestRefundAt: Date | null;
  refundedAt: Date | null;
  refundCancelledAt: Date | null;
  cancelledAt: Date | null;
}

export type APIGetProductCompact = {
  productID: string;
  productName: string;
};

export type APIGetProductCompactListResponse = Response<APIGetProductCompact[]>;

export type ProductScheduleTransactionDetail = {
  fullName: string;
  email: string;
  phoneNumber: string;
  transactionID: string;
  qty: number;
};
export type ProductScheduleDetail = {
  startTime: string; // HH.mm
  endTime: string; // HH.mm
  remainingQuota: number;
  transactionDetails: Array<ProductScheduleTransactionDetail>;
};
export type APIGetScheduleDetailsByProductId = {
  productID: string;
  productName: string;
  date: string; // YYYY-MM-DD
  scheduleDetails: Array<ProductScheduleDetail>;
};
export type APIGetScheduleDetailsByProductIdResponse =
  Response<APIGetScheduleDetailsByProductId>;

export type CreateNewTransactionResponse = {
  transactionID: string;
  companyID: string;
  date: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  productDetail: Array<ScheduleProductDetail>;
  location: string;
  productPrice: number;
  adminPrice: number;
  total: number;
  notes: string;
  teamMemberIDs?: string[];
  paymentStatus: PAYMENT_STATUS;
  paidWith: string;
  transactionTimestamp: TransactionTimestamp;
  snapToken: string;
  snapRedirectURL: string;
  refundReason: string;
  isManuallyAdded: boolean;
  linkID: number;
  linkURL: string;
  createdAt: Date;
  updatedAt: Date;
};

export type APICreateNewTransactionResponse =
  Response<CreateNewTransactionResponse>;

export type APIRefundTransactionResponse =
  Response<CreateNewTransactionResponse>;

export type APICancelTransactionResponse =
  Response<CreateNewTransactionResponse>;

export type APIConfirmTransactionResponse =
  Response<CreateNewTransactionResponse>;

export type GetPublicTransactionByIdResponse = CreateNewTransactionResponse & {
  companyName: string;
  companyLogo: string;
  timezone: string;
  allowRefund: boolean;
  transactionType: TRANSACTION_TYPE;
};
export type APIGetPublicTransactionByIdResponse =
  Response<GetPublicTransactionByIdResponse>;

export type APIChangeProductQuotaPayload = {
  date: string; //YYYY-MM-DD
  startTime: string; //HH.mm
  endTime: string;
  quota: number;
  email?: string;
  fullName?: string;
  phoneNumber?: string;
};

export type ChangeProductQuotaResponse = any;
export type APIChangeProductQuotaResponse =
  Response<ChangeProductQuotaResponse>;

export type APIGetTransactionQueries = Partial<{
  companyID: string;
  status: string;
  startDate: string; // YYYY-MM-DD
  endDate: string; // YYYY-MM-DD
  productIDs: string;
}>;

export type APITransactionData = {
  transactionID: string;
  createdAt: string;
  productName: string;
  total: number;
  totalItems: number;
  timezone: string;
  currency: string;
  paymentStatus: string;
};
export type APIGetTransaction = Array<APITransactionData>;
export type APIGetTransactionResponse = Response<APIGetTransaction>;

export type APIGetTransactionById = {
  _id: string;
  transactionID: string;
  companyID: string;
  date: string; // YYYY-MM-DD
  fullName: string;
  email: string;
  phoneNumber: string;
  productDetail: Array<{
    _id: string;
    productID: string;
    productName: string;
    teamMemberID: string;
    teamMemberName: string;
    resourceID: string;
    resourceName: string;
    scheduleDetails: Array<{
      startTime: string;
      endTime: string;
      date: string;
      qty: number;
      price: number;
      duration: number;
      _id: string;
    }>;
  }>;
  location: string;
  total: number;
  adminPrice: number;
  productPrice: number;
  notes: string;
  paymentStatus: PAYMENT_STATUS;
  paidWith: string;
  snapToken: string;
  snapRedirectURL: string;
  refundReason: string;
  isManuallyAdded: boolean;
  transactionTimestamp: {
    paidAt: string;
    requestRefundAt: string | null;
    refundedAt: string | null;
    refundCancelledAt: string | null;
    cancelledAt: string | null;
    _id: string;
  };
  paidAmount?: number;
  teamMemberIDs?: string[];
  transactionType?: TRANSACTION_TYPE;
  paymentDetail?: ConfirmTransactionScheduleEventRequest[];
  isWalkIn?: boolean;
  eventID?: string;
  createdAt: string;
  updatedAt: string;
  companyLogo: string;
  timezone: string;
  receivedBy?: string;
};

export type APIGetTransactionByIdResponse = Response<APIGetTransactionById>;

export type APIUpdateProductByIdPayload = {
  companyID: string;
  name: string;
  description: string;
  useSinglePrice: boolean;
  allowReschedule: boolean;
  duration: number;
  durationPeriod: DURATION_PERIOD;
  quota: number;
  price: number;
  photos: Array<string>;
  location: APICompanyLocation;
  scheduleDetails: Record<string, ScheduleDetail[]>;
  disabled: boolean;
  addOn: [];
};

export type UpdateProductByIdResponse = string; // productId
export type APIUpdateProductByIdResponse = Response<UpdateProductByIdResponse>;

export type UploadStoragePayload = {
  companyId: string;
  usage: IMAGE_USAGE;
  image: File;
};
export type UploadStorageResponse = string;
export type APIUploadStorageResponse = Response<UploadStorageResponse>;

export type SocialMediaType = {
  tiktok: string;
  instagram: string;
  youtube: string;
  email: string;
  discord: string;
  twitter: string;
  twitch: string;
  facebook: string;
  snapchat: string;
  linkedin: string;
  pinterest: string;
  vimeo: string;
  etsy: string;
  link: string;
};
export type CompanyV2Payload = {
  name: string;
  disabled: boolean;
  description: string;
  currency: CurrencyCodeType;
  location: APICompanyLocation;
  companyDomainName: string;
  socialMedia: SocialMediaType;
  logoPath: string;
  template: PageTemplateType;
  color: string;
};

export enum PRODUCT_TYPE {
  RESERVATION = "reservation",
  LINK = "link",
  SECTION = "section",
}
export enum PRODUCT_STATUS {
  DRAFT = "draft",
  ACTIVE = "active",
}
export type ProductDetailType = {
  productID: string;
  type: PRODUCT_TYPE;
  name: string;
  status: PRODUCT_STATUS;
  subtitle: string;
  currency: string;
  thumbStyle: THUMB_STYLE;
  thumbImg: string;
  buttonText: string;
  link: string;
  price: number;
  discount: number;
};
export type UpdateCompanyV2Payload = Partial<CompanyV2Payload>;
export type UpdateCompanyV2Response = string;
export type APIUpdateCompanyV2Response = Response<UpdateCompanyResponse>;

export type GetCompanyV2Response = {
  companyID: string;
  userID: string;
  name: string;
  disabled: boolean;
  description: string;
  currency: CurrencyCodeType;
  location: APICompanyLocation;
  logoSignedURL: string;
  socialMedia: SocialMediaType;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  companyDomainName: string;
  template: PageTemplateType;
  color: string;
  productDetails: Array<ProductDetailType>;
};
export type APIGetCompanyV2Response = Response<GetCompanyV2Response>;

export interface CreateProductReservationRequest {
  companyID: string;
  thumbStyle: THUMB_STYLE;
  thumbImg: string;
  title: string;
  subtitle: string;
  buttonText: string;
  checkoutImg: string;
  checkoutTitle: string;
  checkoutDesc: string;
  checkoutBottomTitle: string;
  checkoutCTABtn: string;
  price: number;
  currency: string;
  discount: number;
  collectField: [FieldNode, FieldNode, ...FieldNode[]];
  scheduleDetails: Schedule;
  scheduleType: SCHEDULE_TYPE;
  timezone: string;
  scheduleDuration: number;
  scheduleDurationPeriod: DURATION_PERIOD;
  preventScheduleBefore: number;
  quota: number;
  allowBreakBefore: boolean;
  allowBreakAfter: boolean;
  allowReschedule: boolean;
  preventRescheduleBefore: number;
  breakBefore: number;
  breakAfter: number;
  reservationPeriod: number;
  status: "draft" | "active";
  teamMemberIDs: string[];
  resourceIDs: string[];
}
export type APICreateProductReservationResponse = Response<string>;

export interface CreateProductLinkRequest {
  companyID: string;
  thumbStyle: THUMB_STYLE;
  thumbImg: string;
  title: string;
  subtitle: string;
  buttonText: string;
  link: string;
  status: "draft" | "active";
}
export type APICreateProductLinkResponse = Response<string>;

export interface CreateProductSectionRequest {
  companyID: string;
  name: string;
}
export type APICreateProductSectionResponse = Response<string>;

export interface UpdateProductReservationRequest {
  companyID: string;
  thumbStyle: THUMB_STYLE;
  thumbImg: string;
  title: string;
  subtitle: string;
  buttonText: string;
  checkoutImg: string;
  checkoutTitle: string;
  checkoutDesc: string;
  checkoutBottomTitle: string;
  checkoutCTABtn: string;
  price: number;
  currency: string;
  discount: number;
  collectField: [FieldNode, FieldNode, ...FieldNode[]];
  scheduleDetails: Schedule;
  scheduleType: SCHEDULE_TYPE;
  timezone: string;
  scheduleDuration: number;
  scheduleDurationPeriod: DURATION_PERIOD;
  preventScheduleBefore: number;
  quota: number;
  allowBreakBefore: boolean;
  allowBreakAfter: boolean;
  allowReschedule: boolean;
  preventRescheduleBefore: number;
  breakBefore: number;
  breakAfter: number;
  reservationPeriod: number;
  status: "draft" | "active";
  teamMemberIDs: string[];
  resourceIDs: string[];
}
export type APIUpdateProductReservationResponse = Response<string>;

export interface UpdateProductLinkRequest {
  companyID: string;
  thumbStyle: THUMB_STYLE;
  thumbImg: string;
  title: string;
  subtitle: string;
  buttonText: string;
  link: string;
  status: "draft" | "active";
}
export type APIUpdateProductLinkResponse = Response<string>;

export interface UpdateProductSectionRequest {
  companyID: string;
  name: string;
}
export type APIUpdateProductSectionResponse = Response<string>;

export interface UpsertBankAccountRequest {
  accountNumber: string;
  bankCode: BankCode;
}
export type APIUpsertBankAccountResponse = Response<string>;
export interface VerifyBankAccountRequest {
  token: string;
}
export type APIVerifyBankAccountResponse = Response<string>;
export type APIResendBankAccountResponse = Response<string>;

export interface CreateWithdrawRequest {
  amount: number; // The amount of money to be disbursed
}
export type APICreateWithdrawResponse = Response<string>;

export interface VerifyWithdrawRequest {
  token: string;
}
export type APIVerifyWithdrawResponse = Response<string>;
export type APIResendVerificationEmailWithdrawResponse = Response<string>;

export interface CompanyProduct {
  companyWebsiteID: string;
  companyID: string;
  details: ProductDetailCompact[];
  createdBy: string;
  updatedBy: string;
}

export interface ProductDetailCompact {
  productID: string;
  type: PRODUCT_TYPE;
  name: string;
  status: PRODUCT_STATUS;
}

export type UpdateCompanyProductDetailBodyPayload = {
  details: ProductDetailCompact[];
};

export type APIUpdateCompanyProductDetailBodyPayload =
  Partial<UpdateCompanyProductDetailBodyPayload>;
export interface ProductReservation {
  productID: string;
  companyID: string;
  thumbStyle: THUMB_STYLE;
  thumbImg: string;
  title: string;
  subtitle: string;
  buttonText: string;
  checkoutImg: string;
  checkoutTitle: string;
  checkoutDesc: string;
  checkoutBottomTitle: string;
  checkoutCTABtn: string;
  price: number;
  currency: CurrencyCodeType;
  discount: number;
  collectField: [FieldNode, FieldNode, ...FieldNode[]];
  scheduleDetails: Schedule;
  scheduleType: SCHEDULE_TYPE;
  timezone: string;
  scheduleDuration: number;
  scheduleDurationPeriod: DURATION_PERIOD;
  preventScheduleBefore: number;
  quota: number;
  allowBreakBefore: boolean;
  allowBreakAfter: boolean;
  allowReschedule: boolean;
  preventRescheduleBefore: number;
  teamMemberIDs: Array<string>;
  breakBefore: number;
  breakAfter: number;
  reservationPeriod: number;
  status: "draft" | "active";
  createdBy: string;
  updatedBy: string;
  resourceIDs: Array<string>;
}

export type APIGetProductReservationByIdResponse = Response<ProductReservation>;

export interface ProductLink {
  productID: string;
  companyID: string;
  thumbStyle: THUMB_STYLE;
  thumbImg: string;
  title: string;
  subtitle: string;
  buttonText: string;
  link: string;
  status: "draft" | "active";
  createdBy: string;
  updatedBy: string;
}

export type APIGetProductLinkByIdResponse = Response<ProductLink>;

export type CheckoutDetailByProductId = {
  productID: string;
  companyID: string;
  thumbStyle: THUMB_STYLE;
  thumbImg: string;
  title: string;
  subtitle: string;
  buttonText: string;
  checkoutImg: string;
  checkoutTitle: string;
  checkoutDesc: string;
  checkoutBottomTitle: string;
  checkoutCTABtn: string;
  price: number;
  currency: CurrencyCodeType;
  discount: number;
  collectField: [FieldNode, FieldNode, ...FieldNode[]];
  scheduleDetails: Schedule;
  scheduleType: SCHEDULE_TYPE;
  timezone: string;
  scheduleDuration: number;
  scheduleDurationPeriod: DURATION_PERIOD;
  preventScheduleBefore: number;
  quota: number;
  allowBreakBefore: boolean;
  allowBreakAfter: boolean;
  allowReschedule: boolean;
  preventRescheduleBefore: number;
  breakBefore: number;
  breakAfter: number;
  reservationPeriod: number;
  status: "draft" | "active";
  createdBy: string;
  updatedBy: string;
};

export type APICheckoutDetailByProductIdResponse =
  Response<CheckoutDetailByProductId>;

export type APIRequestForgotPasswordResponse = Response<string>;

export interface ResetPasswordRequest {
  token: string;
  email: string;
  password: string;
}
export type APIResetPasswordResponse = Response<string>;
export interface ChangePasswordRequest {
  password: string;
  newPassword: string;
}
export type APIChangePasswordResponse = Response<string>;
export interface VerifyEmailRequest {
  email: string;
  token: string;
}
export type APIVerifyEmailResponse = Response<string>;
export type APIResendVerificationEmailResponse = Response<string>;
export type APIDownloadTransactionCSVResponse = Response<string>;

export type APIRegisterUserResponse = Response<string>;

export interface GetTotalTransactionPerDateResponse {
  date: string;
  day: number;
  total: number;
}
export interface GetTransactionStatusResponse {
  success: number;
  failed: number;
  pending: number;
}
export interface GetProductByTransactionResponse {
  productID: string;
  productName: string;
  total: number;
}
export interface GetTransactionOverviewResponse {
  totalTransactionAmount: number;
  convertionRate: number;
}

export interface GetTotalTransactionAmountAggregateResponse {
  total: number;
}

export type APITotalTransactionByDateResponse = Response<
  GetTotalTransactionPerDateResponse[]
>;

export type APITransactionByStatusResponse = Response<
  GetTransactionStatusResponse[]
>;

export type APIProductByTransactionResponse = Response<
  GetProductByTransactionResponse[]
>;

export type APITransactionOverviewResponse =
  Response<GetTransactionOverviewResponse>;

export interface DashboardBaseQuery {
  productID?: string[];
  companyID: string;
  startDate: string;
  endDate: string;
  sort: SortDirection;
}

export interface TransactionBaseQuery {
  productID?: string[];
  companyID: string;
  startDate: string;
  endDate: string;
  status?: PAYMENT_STATUS;
}

export interface UpcomingScheduleQuery {
  startDate: string;
  endDate?: string;
  productID?: string[];
  companyID: string;
  limit?: number;
  isUpcomingScheduleOnly?: boolean;
}

export enum UpcomingScheduleStatus {
  UPCOMING = "upcoming",
  IN_PROGRESS = "in-progress",
  ENDED = "ended",
}

export interface GetUpcomingScheduleResponse {
  transactionID: string;
  productID: string;
  productName: string;
  date: string;
  startTime: string;
  endTime: string;
  qty: number;
  companyID: string;
  fullName: string;
  email: string;
  notes: string;
  status: UpcomingScheduleStatus;
  timezone: string;
}

export type APIGetUpcomingSchedulesResponse = Response<
  GetUpcomingScheduleResponse[]
>;

export interface WithdrawDetail {
  withdrawID: string;
  amount: number;
  status: DisbursementStatus;
  createdAt: Date;
  verificationTokenExpiry: number;
  timezone: string;
}

export interface FindCompanyAccountResponse {
  companyID: string;
  totalAmount: number;
  availableAmount: number;
  bankAccountID: string;
  accountNumber: string;
  accountName: string;
  bankCode: BankCode | "";
  bankName: string;
  bankInquiryStatus: BankAccountStatus;
  pendingWithdraws: WithdrawDetail | null;
  verificationTokenExpiry: number;
  createdBy: string;
  updatedBy: string;
}
export type APIGetCompanyAccountResponse = Response<FindCompanyAccountResponse>;

export interface WithdrawTimestamp {
  failedAt: Date | null;
  successAt: Date | null;
}
export interface GetAllWithdrawsResponse {
  withdrawID: string;
  totalAmount: number;
  accountNumber: string;
  bankCode: BankCode;
  bankName: string;
  status: DisbursementStatus;
  reason?: FailedDisbursementReason;
  withdrawTimestamp: WithdrawTimestamp;
  fee: number;
  createdAt: Date;
  timezone: string;
}

export type APIGetWithdrawListResponse = Response<GetAllWithdrawsResponse[]>;

export interface GetPublicTeamMembersCompactResponse {
  teamMemberID: string;
  name: string;
}
export type APIGetPublicTeamMembersCompactResponse = Response<
  GetPublicTeamMembersCompactResponse[]
>;

export interface RescheduleDetail {
  date: string;
  startTime: string;
  endTime: string;
  quota: number;
}
export interface RescheduleProductRequest {
  transactionID: string;
  prevScheduleDetail: RescheduleDetail;
  newScheduleDetail: RescheduleDetail;
}

export type APIRescheduleProductResponse = Response<string>;

export enum EmploymentType {
  EMPLOYEE = "EMPLOYEE",
  SELF_EMPLOYEE = "SELF_EMPLOYEE",
}

export enum TeamMemberStatus {
  ALL = "ALL",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface TeamMemberProductDetail {
  productID: string;
  productName: string;
}

export interface GetTeamMembers {
  teamMemberID: string;
  name: string;
  email: string;
  jobTitle: string;
  phoneNumber: string;
  status: string;
}

export type GetProductTeamMembersResponse = {
  [productID: string]: string[];
};

export interface GetTeamMemberDetails {
  data: {
    teamMemberID: string;
    customTeamMemberID: string;
    firstName: string;
    lastName: string;
    email: string;
    jobTitle: string;
    phoneNumber: string;
    startDate: string;
    endDate: string;
    status: string;
    employmentType: EmploymentType;
    notes: string;
    productDetails: TeamMemberProductDetail[];
  };
}

export type APIGetTeamMembersResponse = Response<GetTeamMembers[]>;

export type APIGetTeamMemberDetailsResponse = Response<GetTeamMemberDetails>;

export interface TeamMemberData {
  customTeamMemberID: string;
  companyID: string;
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  phoneNumber: string;
  startDate: string;
  endDate: string;
  employmentType: EmploymentType;
  notes: string;
  status: TeamMemberStatus;
}

export interface CreateTeamMemberRequest extends Partial<TeamMemberData> {
  companyID: string;
}

export type CreateTeamMemberPayload = Partial<TeamMemberData>;

export type APICreateTeamMemberResponse = Response<string>;

export interface UpdateTeamMemberRequest extends Partial<TeamMemberData> {
  companyID: string;
}

export type UpdateTeamMemberPayload = Partial<TeamMemberData>;

export type APIUpdateTeamMemberResponse = Response<string>;

export type APIDeleteTeamMemberResponse = Response<string>;

export interface GetTeamMemberRequest {
  companyID: string;
  status?: TeamMemberStatus;
  productID?: string;
  search?: string;
  sortFields?: string;
  sortDirections: string;
}

export interface GetTeamMemberRegularShiftRequest {
  startDate: string; // YYYY-MM-DD
}

export interface GetTeamMemberShiftsRequest {
  startDate: string; // YYYY-MM-DD
  endDate: string; // YYYY-MM-DD
  teamMemberIDs?: string[];
}

export interface TeamMemberRegularShift {
  scheduleDay: string;
  startTime: string;
  endTime: string;
}

export type APIGetTeamMemberRegularShiftResponse = Response<
  TeamMemberRegularShift[]
>;

export interface ShiftTime {
  startTime: string;
  endTime: string;
  duration: number;
}

export enum TimeOffType {
  ANNUAL_LEAVE = "ANNUAL_LEAVE",
  SICK_LEAVE = "SICK_LEAVE",
  TRAINING = "TRAINING",
  OTHER = "OTHER",
}

export interface GetTeamMemberShiftsResponse {
  data: {
    teamMemberID: string;
    teamMemberName: string;
    shifts: Record<string, TimeRange[]>;
    shiftStartDate: string;
    shiftEndDate: string;
  };
}

export interface UpsertTeamMemberRegularShiftPayload {
  teamMemberID: string;
  shiftStartDate: string;
  shiftEndDate: string | null;
  shifts: { [day: string]: ShiftTime[] };
}

export type UpsertTeamMemberRegularShiftRequest =
  UpsertTeamMemberRegularShiftPayload & { companyID: string };

export type APIUpsertTeamMemberRegularShiftResponse = Response<string>;

export interface CreateTeamMemberTimeOffPayload {
  teamMemberID: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  timeOffType: TimeOffType;
  timeOffReason: string;
  timeOffApproval: boolean;
}

export type CreateTeamMemberTimeOffRequest = CreateTeamMemberTimeOffPayload & {
  companyID: string;
};

export type APICreateTeamMemberTimeOffResponse = Response<string>;

export type UpdateTeamMemberTimeOffRequest = CreateTeamMemberTimeOffPayload & {
  timeOffID: string;
};

export type APIUpdateTeamMemberTimeOffResponse = Response<string>;

export type APIDeleteTeamMemberTimeOffResponse = Response<string>;

export interface UpdateTeamMemberShiftPayload {
  shifts: ShiftTime[];
  date: string;
}

export interface UpdateTeamMemberShiftRequest {
  shifts: ShiftTime[];
  date: string;
  companyID: string;
}

export type APIUpdateTeamMemberShiftResponse = Response<string>;

export interface DeleteTeamMemberShiftRequest {
  date: string;
  startTime: string;
  endTime: string;
  companyID: string;
}

export type APIDeleteTeamMemberShiftResponse = Response<string>;

export interface DeleteAllTeamMemberShiftsRequest {
  fromDate: string;
}

export type APIDeleteAllTeamMemberShiftsResponse = Response<string>;

export type APIGetTeamMemberShiftsResponse = Response<TeamShift[]>;

export type GetPublicTeamMemberAvailabilityResponse = {
  overallAvailability: string[];
  scheduleAvailability: {
    date: string;
    startTime: string;
    endTime: string;
    teamMemberIDs: string[];
  }[];
};

export type APIGetPublicTeamMemberAvailabilityResponse =
  Response<GetPublicTeamMemberAvailabilityResponse>;

export enum TeamMemberQueryType {
  SCHEDULED = "scheduled",
  ALL_TEAM = "all_team",
  ALL_RESOURCE = "all_resource",
  ALL_TEAM_RESOURCE = "all_team_resource",
  CUSTOM = "custom",
}

export enum ScheduleEventChannel {
  ONLINE = "online",
  OFFLINE = "offline",
}
export const ScheduleEventChannelLabelMap = {
  [ScheduleEventChannel.ONLINE]: "Daring (Online)",
  [ScheduleEventChannel.OFFLINE]: "Walk-In (Offline)",
};

export enum ScheduleEventCreatedDateType {
  ANYTIME = "anytime",
  TODAY = "today",
  LAST_1_HOUR = "last_1_hour",
  LAST_2_HOURS = "last_2_hours",
  LAST_12_HOURS = "last_12_hours",
  LAST_24_HOURS = "last_24_hours",
  LAST_2_DAYS = "last_2_days",
  LAST_3_DAYS = "last_3_days",
  MORE_THAN_3_DAYS = "more_than_3_days",
}

export const ScheduleEventCreatedDateTypeLabelMap = {
  [ScheduleEventCreatedDateType.ANYTIME]: "Kapan Saja",
  [ScheduleEventCreatedDateType.TODAY]: "Hari Ini",
  [ScheduleEventCreatedDateType.LAST_1_HOUR]: "1 Jam Terakhir",
  [ScheduleEventCreatedDateType.LAST_2_HOURS]: "2 Jam Terakhir",
  [ScheduleEventCreatedDateType.LAST_12_HOURS]: "12 Jam Terakhir",
  [ScheduleEventCreatedDateType.LAST_24_HOURS]: "24 Jam Terakhir",
  [ScheduleEventCreatedDateType.LAST_2_DAYS]: "2 Hari Terakhir",
  [ScheduleEventCreatedDateType.LAST_3_DAYS]: "3 Hari Terakhir",
  [ScheduleEventCreatedDateType.MORE_THAN_3_DAYS]: "Lebih dari 3 Hari",
};

export const scheduleEventCreatedDateTypeSequence = [
  ScheduleEventCreatedDateType.ANYTIME,
  ScheduleEventCreatedDateType.MORE_THAN_3_DAYS,
  ScheduleEventCreatedDateType.LAST_3_DAYS,
  ScheduleEventCreatedDateType.LAST_2_DAYS,
  ScheduleEventCreatedDateType.LAST_24_HOURS,
  ScheduleEventCreatedDateType.LAST_12_HOURS,
  ScheduleEventCreatedDateType.LAST_2_HOURS,
  ScheduleEventCreatedDateType.LAST_1_HOUR,
  ScheduleEventCreatedDateType.TODAY,
];

export enum ScheduleEventTeamAssignmentStatus {
  ASSIGNED = "assigned",
  UNASSIGNED = "unassigned",
}

export interface ScheduleEventQuery {
  teamMemberQueryType: TeamMemberQueryType;
  companyID: string;
  teamMemberIDs: string[];
  resourceIDs: string[];
  productIDs: string[];
  date: string;
  scheduleEventStatus: ScheduleEventStatus[];
  scheduleEventTypes: ScheduleEventType[];
  scheduleEventChannels: ScheduleEventChannel[];
  paymentStatus: ScheduleEventPaymentStatus[];
  scheduleEventCreatedDateType: ScheduleEventCreatedDateType[];
  scheduleEventTeamAssignmentStatus: ScheduleEventTeamAssignmentStatus[];
}

export enum BlockTimeCompensation {
  PAID = "paid",
  UNPAID = "unpaid",
}
export interface BlockTime {
  blockTimeID: string;
  companyID: string;
  emoji: string;
  name: string;
  duration: number;
  compensation: BlockTimeCompensation;
  createdAt: Date;
  updatedAt: Date;
}

export interface GetTeamMemberAvailabilityByProductRequest {
  date: string;
  startTime: string;
  endTime: string;
  qty: number;
}

export interface APIGetTeamMemberAvailabilityByProductRequest {
  schedules: GetTeamMemberAvailabilityByProductRequest[];
}
export interface TransactionFilterV2 {
  companyID: string;
  query: string;
  status: PAYMENT_STATUS | "all";
  fromAmount?: number;
  toAmount?: number;
  includingItems?: Array<string> | string;
  startDate: string; // YYYY-MM-DD
  endDate: string; // YYYY-MM-DD
  limit: number;
  page: number;
}
export interface PaginationResult<T> {
  docs: T[];
  totalDocs?: number;
  limit?: number;
  page?: number;
  pages?: number;
  offset?: number;
  totalPages?: number;
}

export type TransactionDataV2 = {
  id: string;
  client: string;
  status: string;
  saleDate: string;
  grossTotal: number;
};
