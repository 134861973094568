import { StringifyOptions } from "querystring";
import API from "./config";
import endpoints from "./endpoints";
import {
  APICalculateTransactionPayload,
  APICalculateTransactionResponse,
  APICancelTransactionResponse,
  APIChangePasswordResponse,
  APICreateScheduleEventResponse,
  APIGetScheduleEventActivitiesResponse,
  APIGetScheduleEventClientOptionsResponse,
  APIGetScheduleEventProductsResponse,
  APIGetScheduleEventTransactionActivitiesResponse,
  APIGetScheduleEventsResponse,
  APIUpdateScheduleEventResponse,
  CreateScheduleEventRequest,
  UpdateScheduleEventRequest,
  APIChangeProductQuotaPayload,
  APIChangeProductQuotaResponse,
  APICheckoutDetailByProductIdResponse,
  APIConfirmTransactionResponse,
  APICreateCompanyBodyPayload,
  APICreateCompanyBodyPayloadV2,
  APICreateNewTransactionPayload,
  APICreateNewTransactionResponse,
  APICreateProductBodyPayload,
  APICreateProductResponse,
  APICreateWithdrawResponse,
  APIDeleteProductByIdResponse,
  APIDownloadTransactionCSVResponse,
  APIEditCompanyBodyPayload,
  APIEditCompanyBodyPayloadV2,
  APIGetByDomainNameResponse,
  APIGetCompanyAccountResponse,
  APIGetCompanyV2Response,
  APIGetDomainAvailabilityResponse,
  APIGetProductByIdResponse,
  APIGetProductCompactListResponse,
  APIGetProductLinkByIdResponse,
  APIGetProductReservationByIdResponse,
  APIGetPublicProductById,
  APIGetPublicProductScheduleDetailByIdResponse,
  APIGetPublicTransactionByIdResponse,
  APIGetScheduleDetailsByProductIdResponse,
  APIGetTransactionByIdResponse,
  APIGetTransactionQueries,
  APIGetTransactionResponse,
  APIGetUpcomingSchedulesResponse,
  APIGetWithdrawListResponse,
  APIProductByTransactionResponse,
  APIRefundTransactionPayload,
  APIRefundTransactionResponse,
  APIRequestForgotPasswordResponse,
  APIRescheduleProductResponse,
  APIResendBankAccountResponse,
  APIResendVerificationEmailResponse,
  APIResendVerificationEmailWithdrawResponse,
  APIResetPasswordResponse,
  APITotalTransactionByDateResponse,
  APITransactionByStatusResponse,
  APITransactionOverviewResponse,
  APIUpdateCompanyProductDetailBodyPayload,
  APIUpdateCompanyV2Response,
  APIUpdateProductByIdPayload,
  APIUpdateProductByIdResponse,
  APIUploadCompanyLogoPayload,
  APIUploadProductPhotosPayload,
  APIUploadProductPhotosResponse,
  APIUploadStorageResponse,
  APIUpsertBankAccountResponse,
  APIVerifyBankAccountResponse,
  APIVerifyEmailResponse,
  APIVerifyWithdrawResponse,
  APIUpsertTeamMemberRegularShiftResponse,
  APICreateTeamMemberTimeOffResponse,
  APIUpdateTeamMemberTimeOffResponse,
  APIDeleteTeamMemberTimeOffResponse,
  APIUpdateTeamMemberShiftResponse,
  APIDeleteTeamMemberShiftResponse,
  APIDeleteAllTeamMemberShiftsResponse,
  APIGetTeamMemberShiftsResponse,
  ChangePasswordRequest,
  CompanyByIdResponse,
  CreateCompanyResponse,
  CreateProductLinkRequest,
  CreateProductReservationRequest,
  CreateProductSectionRequest,
  CreateWithdrawRequest,
  DashboardBaseQuery,
  DownloadSignedUrlResponse,
  FindUserByIdResponse,
  GetTeamMemberRegularShiftRequest,
  GetTeamMemberShiftsRequest,
  GoogleLoginResponse,
  LoginGooglePayload,
  LoginPayload,
  LoginResponse,
  PRODUCT_TYPE,
  RegisterPayload,
  RegisterResponse,
  RescheduleProductRequest,
  ResetPasswordRequest,
  UpcomingScheduleQuery,
  UpdateCompanyProductDetailResponse,
  UpdateProductLinkRequest,
  UpdateProductReservationRequest,
  UpdateProductSectionRequest,
  UploadCompanyLogoResponse,
  UploadStoragePayload,
  UpsertBankAccountRequest,
  UserCompaniesResponse,
  VerifyBankAccountRequest,
  VerifyEmailRequest,
  VerifyWithdrawRequest,
  CreateTeamMemberRequest,
  GetTeamMemberDetails,
  GetTeamMemberRequest,
  GetTeamMembers,
  UpdateTeamMemberRequest,
  UpsertTeamMemberRegularShiftRequest,
  GetTeamMemberShiftsResponse,
  CreateTeamMemberTimeOffRequest,
  UpdateTeamMemberTimeOffRequest,
  UpdateTeamMemberShiftRequest,
  DeleteTeamMemberShiftRequest,
  DeleteAllTeamMemberShiftsRequest,
  ScheduleEventQuery,
  APIGetScheduleEventByIDResponse,
  APIGetTeamMemberByProductID,
  ConfirmTransactionScheduleEventRequest,
  APICreateTransactionScheduleEventResponse,
  APIConfirmTransactionScheduleEventResponse,
  APICreateNewTransactionScheduleEventPayload,
  TeamMemberShiftByDateQuery,
  APIGetTeamMemberShiftsByDateResponse,
  ConfirmTransactionScheduleEvenPayloadRequest,
  APIUpdateTransactionRequest,
  APIUpdateTransactionResponse,
  APIGetPublicTeamMembersCompactResponse,
  BlockTime,
  CreateBlockTime,
  APICreateBlockTimeResponse,
  UpdateBlockTime,
  APIUpdateBlockTimeResponse,
  APIGetPublicTeamMemberAvailabilityResponse,
  APIGetTeamMemberAvailabilityByProductRequest,
  APIGetScheduleEventsResponseV2,
  APIGetResourceCompactResponse,
  APIGetResourceByIDResponse,
  APICreateNewResourceRequest,
  Resource,
  APIUpdateResourceRequest,
  TransactionFilterV2,
  TransactionDataV2,
  PaginationResult,
  APICreateNewTransactionAdhocPayload,
  APICreateTransactionAdhocResponse,
} from "./request.types";
import { getQueryParams } from "./helpers/getQueryParams";

export const registerAPI = (data: RegisterPayload) => {
  return API<RegisterResponse>({
    method: "POST",
    url: endpoints.REGISTER,
    data,
  });
};
export const loginAPI = (data: LoginPayload) => {
  return API<LoginResponse>({
    method: "POST",
    url: endpoints.LOGIN,
    data,
  });
};

export const loginGoogleAPI = (data: LoginGooglePayload) => {
  return API<GoogleLoginResponse>({
    method: "POST",
    url: endpoints.LOGIN_GOOGLE,
    data,
  });
};

export const requestTokenAPI = () => {
  return API({
    method: "POST",
    url: endpoints.REFRESH_TOKEN,
  });
};

export const logoutAPI = () => {
  return API({
    method: "POST",
    url: endpoints.LOGOUT,
  });
};

export const findUserByIdAPI = (userId: string) => {
  return API<FindUserByIdResponse>({
    method: "GET",
    url: endpoints.BASE_URL + "/" + userId,
  });
};

export const createCompanyAPI = (data: APICreateCompanyBodyPayload) => {
  return API<CreateCompanyResponse>({
    method: "POST",
    url: `${endpoints.COMPANY}/`,
    data,
  });
};

export const createCompanyAPIV2 = (data: APICreateCompanyBodyPayloadV2) => {
  return API<CreateCompanyResponse>({
    method: "POST",
    url: `${endpoints.COMPANY_V2}/`,
    data,
  });
};
export const updateCompanyAPI = (
  companyId: string,
  data: APIEditCompanyBodyPayload
) => {
  return API<CreateCompanyResponse>({
    method: "PUT",
    url: `${endpoints.COMPANY}/${companyId}`,
    data,
  });
};

export const updateCompanyProductDetailAPI = (
  companyId: string,
  data: APIUpdateCompanyProductDetailBodyPayload
) => {
  return API<UpdateCompanyProductDetailResponse>({
    method: "PUT",
    url: `${endpoints.COMPANY_V2}/${companyId}/detail`,
    data,
  });
};

export const createProductAPI = (data: APICreateProductBodyPayload) => {
  return API<APICreateProductResponse>({
    method: "POST",
    url: endpoints.PRODUCT,
    data,
  });
};

export const getProductById = (productId: string) => {
  return API<APIGetProductByIdResponse>({
    method: "GET",
    url: `${endpoints.PRODUCT}/${productId}`,
  });
};

export const deleteProductById = (productId: string, type?: PRODUCT_TYPE) => {
  switch (type) {
    case PRODUCT_TYPE.LINK:
      return API<APIDeleteProductByIdResponse>({
        method: "DELETE",
        url: `${endpoints.PRODUCT_LINK}/${productId}`,
      });
    case PRODUCT_TYPE.RESERVATION:
      return API<APIDeleteProductByIdResponse>({
        method: "DELETE",
        url: `${endpoints.PRODUCT_RESERVATION}/${productId}`,
      });
    case PRODUCT_TYPE.SECTION:
      return API<APIDeleteProductByIdResponse>({
        method: "DELETE",
        url: `${endpoints.PRODUCT_SECTION}/${productId}`,
      });
    default:
      return API<APIDeleteProductByIdResponse>({
        method: "DELETE",
        url: `${endpoints.PRODUCT}/${productId}`,
      });
  }
};
export const uploadCompanyLogo = (data: APIUploadCompanyLogoPayload) => {
  const formData = new FormData();
  formData.append("file", data.file);
  return API<UploadCompanyLogoResponse>({
    method: "POST",
    url: endpoints.COMPANY_LOGO + `/${data.companyId}`,
    data: formData,
  });
};
export const downloadSignedUrl = (path: string) => {
  return API<DownloadSignedUrlResponse>({
    method: "GET",
    url: endpoints.STORAGE_DOWNLOAD,
    data: {
      filePath: path,
    },
  });
};

export const getCompanyById = (companyId: string) => {
  return API<CompanyByIdResponse>({
    method: "GET",
    url: endpoints.COMPANY + `/${companyId}`,
  });
};
export const getUserCompanies = () => {
  return API<UserCompaniesResponse>({
    method: "GET",
    url: endpoints.COMPANY,
  });
};

export const uploadProductPhotos = (
  data: APIUploadProductPhotosPayload,
  companyId: string,
  productId: string
) => {
  const formData = new FormData();
  data.forEach((file) => {
    formData.append("file", file);
  });
  return API<APIUploadProductPhotosResponse>({
    method: "POST",
    url: `${endpoints.PRODUCT_STORAGE_UPLOAD}/${companyId}/${productId}`,
    data: formData,
  });
};

export const createProduct = (data: APICreateProductBodyPayload) => {
  return API<APICreateProductResponse>({
    method: "POST",
    url: endpoints.PRODUCT,
    data,
  });
};

export const getDataByDomainName = (domainName: string) => {
  return API<APIGetByDomainNameResponse>({
    method: "GET",
    url: endpoints.COMPANY_BY_DOMAIN + `/${domainName}`,
  });
};

export const getDomainAvailabiity = (domainName: string) => {
  return API<APIGetDomainAvailabilityResponse>({
    method: "GET",
    url: endpoints.CHECK_DOMAIN_AVAILABILITY + `/${domainName}`,
  });
};

export const getPublicProductById = (productId: string) => {
  return API<APIGetPublicProductById>({
    method: "GET",
    url: endpoints.GET_PUBLIC_PRODUCT_BY_ID + `/${productId}`,
  });
};
export const getPublicReservationProductById = (productId: string) => {
  return API<APIGetPublicProductById>({
    method: "GET",
    url: endpoints.GET_PRODUCT_RESERVATION_COMPACT_LIST + `/${productId}`,
  });
};

export const getPublicProductScheduleById = (
  productId: string,
  date: string
) => {
  return API<APIGetPublicProductScheduleDetailByIdResponse>({
    method: "GET",
    url: `${endpoints.GET_PUBLIC_PRODUCT_BY_ID}/${productId}/${date}`,
  });
};

export const getPublicProductScheduleByIdV2 = (
  productId: string,
  date: string
) => {
  return API<APIGetPublicProductScheduleDetailByIdResponse>({
    method: "GET",
    url: `${endpoints.GET_PUBLIC_PRODUCT_RESERVATION_BY_ID}/${productId}/${date}`,
  });
};
export const getPublicTeamMemberAvailability = (
  productId: string,
  data: APIGetTeamMemberAvailabilityByProductRequest
) => {
  return API<APIGetPublicTeamMemberAvailabilityResponse>({
    method: "POST",
    url: `${endpoints.GET_PUBLIC_PRODUCT_RESERVATION_BY_ID}/team-member/availability/${productId}`,
    data,
  });
};

export const getScheduleDetailsByProductId = (
  productId: string,
  date: string
) => {
  return API<APIGetScheduleDetailsByProductIdResponse>({
    method: "GET",
    url: `${endpoints.GET_SCHEDULE_DETAILS_BY_PRODUCT_ID}/${productId}/${date}`,
  });
};
export const getScheduleDetailsByProductIdV2 = (
  productId: string,
  date: string
) => {
  return API<APIGetScheduleDetailsByProductIdResponse>({
    method: "GET",
    url: `${endpoints.GET_SCHEDULE_DETAILS_BY_PRODUCT_ID_V2}/${productId}/${date}`,
  });
};

export const getProductCompactList = (companyId: string) => {
  return API<APIGetProductCompactListResponse>({
    method: "GET",
    url: `${endpoints.GET_PRODUCT_COMPACT_LIST}/${companyId}`,
  });
};

export const getProductReservationCompactList = (companyId: string) => {
  return API<APIGetProductCompactListResponse>({
    method: "GET",
    url: `${endpoints.GET_PRODUCT_RESERVATION_COMPACT_LIST}/${companyId}`,
  });
};
// {
//   "companyID": "da7b99fb-592c-4ffd-b104-d2a70579fa4f",
//   "date": "2024-08-30",
//   "productDetail": [
//       {
//           "productID": "9747a169-f94b-4750-8003-ea9b47eb270c",
//           "scheduleDetails": [
//               {
//                   "startTime": "08.00",
//                   "endTime": "09.00",
//                   "date": "2024-08-30",
//                   "qty": 2
//               },
//               {
//                   "startTime": "17.00",
//                   "endTime": "18.00",
//                   "date": "2024-08-30",
//                   "qty": 1
//               }
//           ]
//       }
//   ]
// }
export const getCalculateTransaction = (
  data: APICalculateTransactionPayload
) => {
  return API<APICalculateTransactionResponse>({
    method: "POST",
    url: endpoints.PUBLIC_CALCULATE_TRANSACTION,
    data,
  });
};

export const createNewTransaction = (data: APICreateNewTransactionPayload) => {
  return API<APICreateNewTransactionResponse>({
    method: "POST",
    url: endpoints.PUBLIC_CREATE_NEW_TRANSACTION,
    data,
  });
};

export const cancelTransaction = (transactionId: string) => {
  return API<APICancelTransactionResponse>({
    method: "POST",
    url: `${endpoints.PUBLIC_CANCEL_TRANSACTION}/${transactionId}`,
  });
};
export const confirmTransaction = (transactionId: string) => {
  return API<APIConfirmTransactionResponse>({
    method: "POST",
    url: `${endpoints.PUBLIC_CONFIRM_TRANSACTION}/${transactionId}`,
  });
};

export const refundTransaction = (data: APIRefundTransactionPayload) => {
  return API<APIRefundTransactionResponse>({
    method: "POST",
    url: `${endpoints.PUBLIC_REFUND_TRANSACTION}/${data.transactionID}`,
    data,
  });
};
export const getPublicTransactionId = (
  companyDomainName: string,
  transactionId: string
) => {
  return API<APIGetPublicTransactionByIdResponse>({
    method: "GET",
    url: `${endpoints.PUBLIC_GET_TRANSACTION_BY_ID}/${companyDomainName}/${transactionId}`,
  });
};

export const changeProductQuota = (
  productId: string,
  data: APIChangeProductQuotaPayload
) => {
  return API<APIChangeProductQuotaResponse>({
    method: "PUT",
    url: `${endpoints.PRODUCT}/${productId}/quota`,
    data,
  });
};

export const changeProductQuotaV2 = (
  productId: string,
  data: APIChangeProductQuotaPayload
) => {
  return API<APIChangeProductQuotaResponse>({
    method: "PUT",
    url: `${endpoints.PRODUCT_RESERVATION}/${productId}/quota`,
    data,
  });
};
export const getTransactionByQuery = (query: APIGetTransactionQueries) => {
  return API<APIGetTransactionResponse>({
    method: "GET",
    url: `${endpoints.TRANSACTION}`,
    params: query,
  });
};

export const getTransactionById = (transactionId: string) => {
  return API<APIGetTransactionByIdResponse>({
    method: "GET",
    url: `${endpoints.TRANSACTION}/${transactionId}`,
  });
};

export const updateProductById = (
  productId: string,
  data: APIUpdateProductByIdPayload
) => {
  return API<APIUpdateProductByIdResponse>({
    method: "PUT",
    url: `${endpoints.PRODUCT}/${productId}`,
    data,
  });
};
export const storageUpload = (payload: UploadStoragePayload) => {
  const formData = new FormData();
  formData.append("file", payload.image);
  return API<APIUploadStorageResponse>({
    method: "POST",
    url: endpoints.STORAGE_UPLOAD + `/${payload.usage}/${payload.companyId}`,
    data: formData,
  });
};

export const getCompanyByIdV2 = (companyId: string) => {
  return API<APIGetCompanyV2Response>({
    method: "GET",
    url: endpoints.COMPANY_V2 + `/${companyId}`,
  });
};

export const getPublicCompanyDomainV2 = (companyDomainName: string) => {
  return API<APIGetCompanyV2Response>({
    method: "GET",
    url: endpoints.PUBLIC_COMPANY_V2 + `/${companyDomainName}`,
  });
};
export const getPublicProductReservationByMonth = (
  productId: string,
  monthYear: string
) => {
  return API<string[]>({
    method: "GET",
    url:
      endpoints.GET_PUBLIC_PRODUCT_RESERVATION_BY_ID +
      `/month/${productId}/${monthYear}`,
  });
};

export const updateCompanyAPIV2 = (
  companyId: string,
  payload: APIEditCompanyBodyPayloadV2
) => {
  return API<APIUpdateCompanyV2Response>({
    method: "PUT",
    url: endpoints.COMPANY_V2 + `/${companyId}`,
    data: payload,
  });
};

export const createProductReservationV2 = (
  data: CreateProductReservationRequest
) => {
  return API<APICreateProductResponse>({
    method: "POST",
    url: endpoints.PRODUCT_RESERVATION,
    data,
  });
};

export const createProductLinkV2 = (data: CreateProductLinkRequest) => {
  return API<APICreateProductResponse>({
    method: "POST",
    url: endpoints.PRODUCT_LINK,
    data,
  });
};

export const upsertBankAccount = (
  companyId: string,
  data: UpsertBankAccountRequest
) => {
  return API<APIUpsertBankAccountResponse>({
    method: "POST",
    url: endpoints.COMPANY_ACCOUNT + `/${companyId}`,
    data,
  });
};
export const verifyBankAccount = (
  companyId: string,
  data: VerifyBankAccountRequest
) => {
  return API<APIVerifyBankAccountResponse>({
    method: "POST",
    url: endpoints.COMPANY_ACCOUNT + `/verify/${companyId}`,
    data,
  });
};
export const resendBankAccount = (companyId: string) => {
  return API<APIResendBankAccountResponse>({
    method: "POST",
    url: endpoints.COMPANY_ACCOUNT + `/resend/${companyId}`,
  });
};

export const createWithdraw = (
  companyId: string,
  data: CreateWithdrawRequest
) => {
  return API<APICreateWithdrawResponse>({
    method: "POST",
    url: endpoints.WITHDRAW + `/${companyId}`,
    data,
  });
};

export const verifyWithdraw = (
  companyId: string,
  data: VerifyWithdrawRequest
) => {
  return API<APIVerifyWithdrawResponse>({
    method: "POST",
    url: endpoints.WITHDRAW + `/verify/${companyId}`,
    data,
  });
};

export const resendWithdrawEmail = (companyId: string) => {
  return API<APIResendVerificationEmailWithdrawResponse>({
    method: "POST",
    url: endpoints.WITHDRAW + `/resend/${companyId}`,
  });
};

export const rescheduleProduct = (data: RescheduleProductRequest) => {
  return API<APIRescheduleProductResponse>({
    method: "PUT",
    url: endpoints.RESCHEDULE,
    data,
  });
};

export const createProductSectionV2 = (data: CreateProductSectionRequest) => {
  return API<APICreateProductResponse>({
    method: "POST",
    url: endpoints.PRODUCT_SECTION,
    data,
  });
};

export const updateProductReservationV2 = (
  data: Partial<UpdateProductReservationRequest>,
  productId: string
) => {
  return API<APICreateProductResponse>({
    method: "PUT",
    url: `${endpoints.PRODUCT_RESERVATION}/${productId}`,
    data,
  });
};

export const updateProductLinkV2 = (
  data: Partial<UpdateProductLinkRequest>,
  productId: string
) => {
  return API<APICreateProductResponse>({
    method: "PUT",
    url: `${endpoints.PRODUCT_LINK}/${productId}`,
    data,
  });
};

export const updateProductSectionV2 = (
  data: UpdateProductSectionRequest,
  productId: string
) => {
  return API<APICreateProductResponse>({
    method: "PUT",
    url: `${endpoints.PRODUCT_SECTION}/${productId}`,
    data,
  });
};

export const getProductReservationById = (productId: string) => {
  return API<APIGetProductReservationByIdResponse>({
    method: "GET",
    url: `${endpoints.PRODUCT_RESERVATION}/${productId}`,
  });
};

export const getPublicProductReservationById = (productId: string) => {
  return API<APIGetProductReservationByIdResponse>({
    method: "GET",
    url: `${endpoints.PRODUCT_RESERVATION}/public/${productId}`,
  });
};

export const getProductLinkById = (productId: string) => {
  return API<APIGetProductLinkByIdResponse>({
    method: "GET",
    url: `${endpoints.PRODUCT_LINK}/${productId}`,
  });
};

export const getCheckoutDetailByProductId = (productId: string) => {
  return API<APICheckoutDetailByProductIdResponse>({
    method: "GET",
    url: `${endpoints.PUBLIC_GET_PRODUCT_RESERVATION}/${productId}`,
  });
};

export const requestForgotPassword = (email: string) => {
  return API<APIRequestForgotPasswordResponse>({
    method: "POST",
    url: `${endpoints.REQUEST_FORGOT_PASSWORD}`,
    data: { email },
  });
};

export const resetPassword = (data: ResetPasswordRequest) => {
  return API<APIResetPasswordResponse>({
    method: "POST",
    url: `${endpoints.RESET_PASSWORD}`,
    data,
  });
};

export const changePassword = (data: ChangePasswordRequest) => {
  return API<APIChangePasswordResponse>({
    method: "POST",
    url: `${endpoints.CHANGE_PASSWORD}`,
    data,
  });
};

export const verifyEmail = (data: VerifyEmailRequest) => {
  return API<APIVerifyEmailResponse>({
    method: "POST",
    url: `${endpoints.VERIFY_EMAIL}`,
    data,
  });
};

export const resendVerificationEmail = (email: string) => {
  return API<APIResendVerificationEmailResponse>({
    method: "POST",
    url: `${endpoints.RESEND_VERIFICATION_EMAIL}`,
    data: { email },
  });
};
export const downloadTransactionCSV = (query: APIGetTransactionQueries) => {
  return API<APIDownloadTransactionCSVResponse>({
    method: "GET",
    url: `${endpoints.DOWNLOAD_TRANSACTION_CSV}`,
    params: query,
  });
};
export const downloadTransactionCSVV2 = (query: TransactionFilterV2) => {
  return API<APIDownloadTransactionCSVResponse>({
    method: "GET",
    url: `${endpoints.DOWNLOAD_TRANSACTION_CSV}/v2`,
    params: query,
  });
};

export const totalTransactionPerDate = (data: DashboardBaseQuery) => {
  return API<APITotalTransactionByDateResponse>({
    method: "POST",
    url: `${endpoints.TOTAL_TRANSACTION_PER_DATE}`,
    data,
  });
};

export const totalTransactionByStatus = (data: DashboardBaseQuery) => {
  return API<APITransactionByStatusResponse>({
    method: "POST",
    url: `${endpoints.TOTAL_TRANSACTION_BY_STATUS}`,
    data,
  });
};

export const totalProductByTransaction = (data: DashboardBaseQuery) => {
  return API<APIProductByTransactionResponse>({
    method: "POST",
    url: `${endpoints.PRODUCT_BY_TRANSACTION}`,
    data,
  });
};

export const transactionOverview = (data: DashboardBaseQuery) => {
  return API<APITransactionOverviewResponse>({
    method: "POST",
    url: `${endpoints.TRANSACTION_OVERVIEW}`,
    data,
  });
};

export const upcomingSchedules = (data: UpcomingScheduleQuery) => {
  return API<APIGetUpcomingSchedulesResponse>({
    method: "POST",
    url: `${endpoints.UPCOMING_SCHEDULES}`,
    data,
  });
};

export const getCompanyAccountByID = (companyId: string) => {
  return API<APIGetCompanyAccountResponse>({
    method: "GET",
    url: endpoints.COMPANY_ACCOUNT + `/${companyId}`,
  });
};
export const getWithdrawList = (companyId: string) => {
  return API<APIGetWithdrawListResponse>({
    method: "GET",
    url: endpoints.WITHDRAW + `/${companyId}`,
  });
};

// Get all team members
export const getTeamMembers = (data: GetTeamMemberRequest) => {
  return API<{ data: GetTeamMembers[] }>({
    method: "GET",
    url: endpoints.TEAM_MEMBER,
    params: data,
  });
};

// Get block time types
export const getBlockTimeTypes = (companyID: string) => {
  return API<{ data: BlockTime[] }>({
    method: "GET",
    url: endpoints.BLOCK_TIME + `/${companyID}`,
  });
};

// Get public team members compact
export const getPublicTeamMembersCompact = (data: GetTeamMemberRequest) => {
  return API<APIGetPublicTeamMembersCompactResponse>({
    method: "GET",
    url: endpoints.TEAM_MEMBER + "/public/compact",
    params: data,
  });
};

// Get Products Team Members
export const getProductTeamMembers = (data: {
  companyID: string;
  productIDs: string[];
}) => {
  const queryString = getQueryParams({ productIDs: data.productIDs });

  return API<{ data: GetTeamMembers[] }>({
    method: "GET",
    url: endpoints.PRODUCT_TEAM_MEMBERS + `/${data.companyID}?${queryString}`,
  });
};

// Get team member details by ID
export const getTeamMemberById = (teamMemberID: string) => {
  return API<GetTeamMemberDetails>({
    method: "GET",
    url: `${endpoints.TEAM_MEMBER}/${teamMemberID}`,
  });
};

// Create new team member
export const createTeamMember = (data: CreateTeamMemberRequest) => {
  return API<string>({
    method: "POST",
    url: endpoints.TEAM_MEMBER,
    data,
  });
};

// Update team member
export const updateTeamMember = (
  teamMemberID: string,
  data: UpdateTeamMemberRequest
) => {
  return API<string>({
    method: "PUT",
    url: `${endpoints.TEAM_MEMBER}/${teamMemberID}`,
    data,
  });
};

// Delete team member
export const deleteTeamMember = (teamMemberID: string) => {
  return API<string>({
    method: "DELETE",
    url: `${endpoints.TEAM_MEMBER}/${teamMemberID}`,
  });
};

// Regular Shifts
export const upsertTeamMemberRegularShift = (
  data: UpsertTeamMemberRegularShiftRequest
) => {
  return API<APIUpsertTeamMemberRegularShiftResponse>({
    method: "POST",
    url: `${endpoints.TEAM_MEMBER_SHIFT}/regular`,
    data,
  });
};

export const getTeamMemberRegularShift = (
  companyID: string,
  teamMemberID: string,
  params: GetTeamMemberRegularShiftRequest
) => {
  return API<GetTeamMemberShiftsResponse>({
    method: "GET",
    url: `${endpoints.TEAM_MEMBER_SHIFT}/regular/${companyID}/${teamMemberID}`,
    params,
  });
};

// Time Off
export const createTeamMemberTimeOff = (
  data: CreateTeamMemberTimeOffRequest
) => {
  return API<APICreateTeamMemberTimeOffResponse>({
    method: "POST",
    url: `${endpoints.TEAM_MEMBER_SHIFT}/timeoff`,
    data,
  });
};

export const updateTeamMemberTimeOff = (
  timeOffID: string,
  data: UpdateTeamMemberTimeOffRequest
) => {
  return API<APIUpdateTeamMemberTimeOffResponse>({
    method: "PUT",
    url: `${endpoints.TEAM_MEMBER_SHIFT}/timeoff/${timeOffID}`,
    data,
  });
};

export const deleteTeamMemberTimeOff = (timeOffID: string) => {
  return API<APIDeleteTeamMemberTimeOffResponse>({
    method: "DELETE",
    url: `${endpoints.TEAM_MEMBER_SHIFT}/timeoff/${timeOffID}`,
  });
};

// Shifts
export const updateTeamMemberShift = (
  teamMemberID: string,
  data: UpdateTeamMemberShiftRequest
) => {
  return API<APIUpdateTeamMemberShiftResponse>({
    method: "PUT",
    url: `${endpoints.TEAM_MEMBER_SHIFT}/${teamMemberID}`,
    data,
  });
};

export const deleteTeamMemberShift = (
  teamMemberID: string,
  data: DeleteTeamMemberShiftRequest
) => {
  return API<APIDeleteTeamMemberShiftResponse>({
    method: "DELETE",
    url: `${endpoints.TEAM_MEMBER_SHIFT}/${teamMemberID}`,
    data,
  });
};

export const deleteAllTeamMemberShifts = (
  teamMemberID: string,
  data: DeleteAllTeamMemberShiftsRequest
) => {
  return API<APIDeleteAllTeamMemberShiftsResponse>({
    method: "DELETE",
    url: `${endpoints.TEAM_MEMBER_SHIFT}/${teamMemberID}/all`,
    data,
  });
};
export const getTeamMemberShifts = (
  companyID: string,
  params: GetTeamMemberShiftsRequest
) => {
  return API<APIGetTeamMemberShiftsResponse>({
    method: "GET",
    url: `${endpoints.TEAM_MEMBER_SHIFT}/${companyID}`,
    params,
  });
};

// Schedule Event API
export const getScheduleEvents = (query: ScheduleEventQuery) => {
  // Construct the query string using URLSearchParams
  const queryString = getQueryParams(query);
  return API<APIGetScheduleEventsResponse>({
    method: "GET",
    url: `${endpoints.SCHEDULE_EVENT}?${queryString}`,
  });
};
export const getScheduleEventsV2 = (query: ScheduleEventQuery) => {
  // Construct the query string using URLSearchParams
  const queryString = getQueryParams(query);
  return API<APIGetScheduleEventsResponseV2>({
    method: "GET",
    url: `${endpoints.SCHEDULE_EVENT}/v2?${queryString}`,
  });
};

export const getResourceCompact = (companyID: string, productID?: string) => {
  const queryString = productID ? getQueryParams({ productID }) : "";
  return API<APIGetResourceCompactResponse>({
    method: "GET",
    url: `${endpoints.RESOURCE}/${companyID}?${queryString}`,
  });
};

export const getResourceByID = (companyID: string, resourceID: string) => {
  return API<APIGetResourceByIDResponse>({
    method: "GET",
    url: `${endpoints.RESOURCE}/${companyID}/${resourceID}`,
  });
};

export const createScheduleEvent = (data: CreateScheduleEventRequest) => {
  return API<APICreateScheduleEventResponse>({
    method: "POST",
    url: endpoints.SCHEDULE_EVENT,
    data,
  });
};
export const createBlockTime = (data: CreateBlockTime) => {
  return API<APICreateBlockTimeResponse>({
    method: "POST",
    url: endpoints.BLOCK_TIME,
    data,
  });
};

export const updateScheduleEvent = (data: Partial<UpdateBlockTime>) => {
  return API<APIUpdateBlockTimeResponse>({
    method: "PUT",
    url: `${endpoints.SCHEDULE_EVENT}/${data.eventID || ""}`,
    data,
  });
};

export const updateBlockTime = (data: Partial<UpdateBlockTime>) => {
  return API<APIUpdateBlockTimeResponse>({
    method: "PUT",
    url: `${endpoints.BLOCK_TIME}/${data.eventID || ""}`,
    data,
  });
};
export const createTransactionScheduleEvent = (
  eventID: string,
  data: APICreateNewTransactionScheduleEventPayload
) => {
  return API<APICreateTransactionScheduleEventResponse>({
    method: "POST",
    url: `${endpoints.TRANSACTION}/schedule-event/${eventID}`,
    data,
  });
};

export const confirmTransactionScheduleEvent = (
  eventID: string,
  transactionID: string,
  data: ConfirmTransactionScheduleEvenPayloadRequest
) => {
  return API<APIConfirmTransactionScheduleEventResponse>({
    method: "POST",
    url: `${endpoints.TRANSACTION}/schedule-event/${eventID}/${transactionID}/confirm`,
    data,
  });
};
export const updateTransactionScheduleEvent = (
  eventID: string,
  transactionID: string,
  data: APIUpdateTransactionRequest
) => {
  return API<APIUpdateTransactionResponse>({
    method: "PUT",
    url: `${endpoints.TRANSACTION}/schedule-event/${eventID}/${transactionID}`,
    data,
  });
};
export const createTransactionAdhoc = (
  data: APICreateNewTransactionAdhocPayload
) => {
  return API<APICreateTransactionAdhocResponse>({
    method: "POST",
    url: `${endpoints.TRANSACTION}/adhoc`,
    data,
  });
};

export const confirmTransactionAdhoc = (
  transactionID: string,
  data: ConfirmTransactionScheduleEvenPayloadRequest
) => {
  return API<APIConfirmTransactionScheduleEventResponse>({
    method: "POST",
    url: `${endpoints.TRANSACTION}/adhoc/${transactionID}/confirm`,
    data,
  });
};

export const updateTransactionAdhoc = (
  transactionID: string,
  data: APIUpdateTransactionRequest
) => {
  return API<APIUpdateTransactionResponse>({
    method: "PUT",
    url: `${endpoints.TRANSACTION}/adhoc/${transactionID}`,
    data,
  });
};

export const createNewResource = (
  companyID: string,
  data: APICreateNewResourceRequest
) => {
  return API<Resource>({
    method: "POST",
    url: `${endpoints.RESOURCE}/${companyID}`,
    data,
  });
};
export const updateResource = (
  companyID: string,
  resourceID: string,
  data: APIUpdateResourceRequest
) => {
  return API<Resource>({
    method: "PUT",
    url: `${endpoints.RESOURCE}/${companyID}/${resourceID}`,
    data,
  });
};
export const deleteResource = (companyID: string, resourceID: string) => {
  return API<string>({
    method: "DELETE",
    url: `${endpoints.RESOURCE}/${companyID}/${resourceID}`,
  });
};
export const getTeamMemberShiftsByDate = (
  companyID: string,
  date: string,
  data: TeamMemberShiftByDateQuery
) => {
  return API<APIGetTeamMemberShiftsByDateResponse>({
    method: "GET",
    url: `${endpoints.TEAM_MEMBER_SHIFT}/${companyID}/${date}`,
    params: data,
  });
};

export const getScheduleEventProducts = (companyID: string) => {
  return API<APIGetScheduleEventProductsResponse>({
    method: "GET",
    url: `${endpoints.SCHEDULE_EVENT}/products/${companyID}`,
  });
};
export const getTeamMemberByProductID = (
  companyID: string,
  productID: string
) => {
  return API<APIGetTeamMemberByProductID>({
    method: "GET",
    url: `${endpoints.SCHEDULE_EVENT}/products/${companyID}/${productID}`,
  });
};
export const getScheduleEventByID = (companyID: string, eventID: string) => {
  return API<APIGetScheduleEventByIDResponse>({
    method: "GET",
    url: `${endpoints.SCHEDULE_EVENT}/${companyID}/${eventID}`,
  });
};

export const getScheduleEventTransactionActivities = (eventID: string) => {
  return API<APIGetScheduleEventTransactionActivitiesResponse>({
    method: "GET",
    url: `${endpoints.SCHEDULE_EVENT}/transaction-activities/${eventID}`,
  });
};

export const getScheduleEventActivities = (eventID: string) => {
  return API<APIGetScheduleEventActivitiesResponse>({
    method: "GET",
    url: `${endpoints.SCHEDULE_EVENT}/schedule-activities/${eventID}`,
  });
};

export const getScheduleEventClientOptions = (companyID: string) => {
  return API<APIGetScheduleEventClientOptionsResponse>({
    method: "GET",
    url: `${endpoints.SCHEDULE_EVENT}/client-options/${companyID}`,
  });
};

// Get transaction by query v2
export const getTransactionByQueryV2 = (data: TransactionFilterV2) => {
  const queryString = getQueryParams(data);

  return API<{ data: PaginationResult<TransactionDataV2> }>({
    method: "GET",
    url: endpoints.TRANSACTION + "/v2?" + queryString,
  });
};
