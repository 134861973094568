import { Box, Typography } from "@mui/material";
import { COLOR } from "../../utils/color";
import ProfileInitial from "../ProfileInitial/ProfileInitial";
import OpacityButton from "../OpacityButton/OpacityButton";
import { FaChevronRight } from "react-icons/fa";
import {
  TeamShiftOff,
  TimeRange,
} from "../../pages/TeamScheduledShifts/TeamScheduledShifts";

interface TimeScheduleButtonProps {
  name: string;
  timeRanges: TimeRange[];
  timeOff?: TeamShiftOff[];
  showNameInitial?: boolean;
  onClick?: () => void;
}

const TimeScheduleButton = ({
  name,
  timeRanges,
  timeOff,
  showNameInitial = false,
  onClick,
}: TimeScheduleButtonProps) => {
  return (
    <OpacityButton
      onClick={onClick}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
        px: 2,
        py: 1,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
          width: "50%",
        }}
      >
        {showNameInitial && (
          <ProfileInitial name={name} sx={{ minWidth: 32 }} />
        )}
        <Typography variant="body2" fontWeight="bold">
          {name}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "flex-end",
          gap: 1,
        }}
      >
        {timeRanges.length === 0 ? (
          <Typography variant="caption" color={COLOR.neutral500}>
            Tidak Bekerja
          </Typography>
        ) : (
          timeRanges.map((time, index) => (
            <Typography key={index} fontFamily="monospace">
              {time.startTime} - {time.endTime}
            </Typography>
          ))
        )}
        {timeOff?.map((time, index) => (
          <Typography
            key={index}
            fontFamily="monospace"
            color={COLOR.danger500}
          >
            {time.startTime} - {time.endTime}
          </Typography>
        ))}
      </Box>
      <FaChevronRight size={12} />
    </OpacityButton>
  );
};

export default TimeScheduleButton;
