import { Typography, TypographyProps } from "@mui/material";
import { COLOR } from "../../../utils/color";

interface HelperTextPropsI {
  text?: string;
  color?: string;
  otherProps?: TypographyProps;
}

/* use "" to fill space */
const HelperText: React.FC<HelperTextPropsI> = (props) => {
  if (props?.text === undefined) return null;
  return (
    <Typography
      variant='caption'
      fontWeight="normal"
      color={props?.color || COLOR.neutral500}
      display={"inline-block"}
      {...props?.otherProps}
    >
      {props?.text}
    </Typography>
  )
};

export default HelperText;
