import React, { useEffect, useMemo, useState } from "react";
import ModalTemplate, { ModalTemplateProps } from "./ModalTemplate";
import NumberKeyboard, {
  KeyboardKeyType,
} from "../NumberKeyboard/NumberKeyboard";
import { Box, Typography } from "@mui/material";
import OpacityButton from "../OpacityButton/OpacityButton";
import { formatCurrency } from "../../utils/helper";
import { COLOR } from "../../utils/color";
import CustomButton from "../CustomButton/CustomButton";
import {
  CART_PAYMENT_METHOD,
  DOT_SEPARATOR,
  PAYMENT_METHOD_TITLE,
} from "../../utils/constant";
import ChooseTeamMemberModal from "./ChooseTeamMemberModal";
import { GetTeamMembers } from "../../api/request.types";

export type PaymentAmountData = {
  method: CART_PAYMENT_METHOD;
  amount: number;
  receivedBy: {
    id: string;
    name: string;
  };
};
export interface InputAmountModalProps {
  modalProps: Omit<ModalTemplateProps, "children">;
  data: PaymentAmountData;
  totalAmountToBePaid: number;
  onClickPay: (paymentData: PaymentAmountData) => void;
}

const InputAmountModal: React.FC<InputAmountModalProps> = ({
  modalProps,
  data,
  onClickPay,
  totalAmountToBePaid,
}) => {
  const [amountData, setAmountData] = useState<PaymentAmountData>(data);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (modalProps.open) {
      setAmountData((prev) => ({
        ...prev,
        ...data,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalProps.open]);

  const handleKeyPress = (value: KeyboardKeyType | "clear") => {
    switch (value) {
      case "del":
        setAmountData((prev) => ({
          ...prev,
          amount: Math.floor(prev.amount / 10) || 0,
        }));
        break;
      case "000":
        setAmountData((prev) => ({
          ...prev,
          amount: prev.amount * 1000,
        }));
        break;
      default:
        setAmountData((prev) => ({
          ...prev,
          amount: parseInt(prev.amount.toString() + value) || 0,
        }));
        break;
    }
  };

  const remainder = totalAmountToBePaid - amountData.amount;
  const _renderRemainderText = () => {
    if (remainder < 0) {
      return `Total Kembalian: ${formatCurrency(Math.abs(remainder), "IDR")}`;
    } else {
      return `Tersisa untuk dibayar: ${formatCurrency(
        Math.abs(remainder),
        "IDR"
      )}`;
    }
  };

  return (
    <ModalTemplate
      desktopWidth="500px"
      desktopHeight="70vh"
      {...modalProps}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...modalProps.sx,
      }}
      header={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
            flex: 1,
            borderBottom: `1px solid ${COLOR.neutral300}`,
            gap: 1,
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Add Cash Amount
          </Typography>
        </Box>
      }
      footer={
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          px={2}
          pb={2}
          pt={1}
          borderTop={`1px solid ${COLOR.neutral300}`}
        >
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            <Typography
              flex={1}
              variant="body1"
              fontWeight={600}
              color={COLOR.neutral500}
              onClick={() => {}}
            >
              {PAYMENT_METHOD_TITLE[data.method]} {DOT_SEPARATOR}
              <OpacityButton onClick={() => setOpen(true)}>
                <Typography
                  variant="body1"
                  fontWeight={600}
                  color={COLOR.primary500}
                >
                  {amountData.receivedBy.name}
                </Typography>
              </OpacityButton>
            </Typography>
          </Box>

          <Typography variant="body2" fontWeight="bold">
            {_renderRemainderText()}
          </Typography>
          <CustomButton
            onClick={() => {
              onClickPay(amountData);
            }}
          >
            Bayar
          </CustomButton>
        </Box>
      }
    >
      <Box px={2} pb={5} pt={3}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <OpacityButton
            sx={{
              py: 1,
              minWidth: 80,
              borderRadius: "8px",
              border: `1px solid ${COLOR.neutral300}`,
              visibility: amountData.amount > 0 ? "visible" : "hidden",
            }}
            onClick={() => setAmountData((prev) => ({ ...prev, amount: 0 }))}
          >
            Clear
          </OpacityButton>
        </Box>

        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            wordBreak: "break-all",
            minHeight: "100px",
          }}
        >
          {formatCurrency(amountData.amount, "IDR")}
        </Typography>

        <NumberKeyboard onKeyPress={handleKeyPress} />
        <ChooseTeamMemberModal
          modalProps={{
            open,
            onClose: () => setOpen(false),
            onDismiss: () => setOpen(false),
          }}
          selectedTeamMemberIds={[
            amountData.receivedBy.id as GetTeamMembers["teamMemberID"],
          ]}
          onClickMember={(teamMember: GetTeamMembers) => {
            setAmountData((prev) => ({
              ...prev,
              receivedBy: {
                ...prev.receivedBy,
                id: teamMember.teamMemberID,
                name: teamMember.name,
              },
            }));
            setOpen(false);
          }}
        />
      </Box>
    </ModalTemplate>
  );
};

export default InputAmountModal;
