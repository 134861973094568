import React, { useEffect, useState } from "react";
import { COLOR } from "../../utils/color";
import ModalTemplate, { ModalTemplateProps } from "./ModalTemplate";
import {
  CART_PAYMENT_METHOD,
  DOT_SEPARATOR,
  PAYMENT_METHOD_ICON,
} from "../../utils/constant";
import PaymentMethod, {
  PaymentMethodPropsI,
} from "../PaymentMethod/PaymentMethod";
import { Box, Typography } from "@mui/material";
import InputAmountModal, {
  InputAmountModalProps,
  PaymentAmountData,
} from "./InputAmountModal";
import CustomButton from "../CustomButton/CustomButton";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import { formatCurrency } from "../../utils/helper";

interface ChoosePaymentMethodProps {
  modalProps: Omit<ModalTemplateProps, "children">;
  allowedMethod: PaymentMethodPropsI["allowedMethod"];
  totalAmountToBePaid: number;
  data: PaymentAmountData;
  onFullyPaid?: (paymentDetails: Array<PaymentAmountData>) => void;
  onPartlyPaid?: (paymentDetails: Array<PaymentAmountData>) => void;
}

const ChoosePaymentMethod: React.FC<ChoosePaymentMethodProps> = ({
  modalProps,
  allowedMethod = Object.values(CART_PAYMENT_METHOD) as CART_PAYMENT_METHOD[],
  totalAmountToBePaid,
  data,
  onFullyPaid,
  onPartlyPaid,
}) => {
  const [paymentDetails, setPaymentDetails] = useState<
    Array<PaymentAmountData>
  >([]);
  const [modalState, setModalState] = useState<{
    inputAmountModal: InputAmountModalProps["data"] | null;
    choosePaymentMethod: boolean;
  }>({
    inputAmountModal: null,
    choosePaymentMethod: false,
  });
  const totalSplitPaymentAmount = paymentDetails.reduce((prev, curr) => {
    return prev + curr.amount;
  }, 0);
  const isFullyPaid = totalSplitPaymentAmount >= totalAmountToBePaid;
  const remainder = totalAmountToBePaid - totalSplitPaymentAmount;

  const handleOnClickPaymentMethod = (method: CART_PAYMENT_METHOD) => {
    const paymentMethodData = {
      method,
      amount: remainder,
      receivedBy: {
        id: data.receivedBy.id,
        name: data.receivedBy.name,
      },
    };
    setModalState((prev) => ({ ...prev, inputAmountModal: paymentMethodData }));
  };

  const _renderRemainderText = () => {
    if (remainder < 0) {
      return `Total Kembalian: ${formatCurrency(Math.abs(remainder), "IDR")}`;
    } else if (remainder > 0) {
      return `Tersisa untuk dibayar: ${formatCurrency(
        Math.abs(remainder),
        "IDR"
      )}`;
    } else {
      return `Terbayar semua`;
    }
  };

  useEffect(() => {
    if (modalProps.open) {
      setPaymentDetails([]);
    }
  }, [modalProps.open]);

  return (
    <ModalTemplate
      desktopWidth="500px"
      desktopHeight="70vh"
      {...modalProps}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...modalProps.sx,
      }}
      header={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
            flex: 1,
            borderBottom: `1px solid ${COLOR.neutral300}`,
            gap: 1,
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Pilih Metode Pembayaran
          </Typography>
        </Box>
      }
      footer={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            gap: 1,
            p: 2,
            borderTop: `1px solid ${COLOR.neutral300}`,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="body2"
              fontWeight={600}
              color={
                remainder < 0
                  ? COLOR.success500
                  : remainder > 0
                  ? COLOR.danger500
                  : COLOR.neutral900
              }
            >
              {_renderRemainderText()}
            </Typography>
          </Box>
          <CustomButton
            onClick={() => {
              if (isFullyPaid) {
                onFullyPaid?.(paymentDetails);
              } else {
                onPartlyPaid?.(paymentDetails);
              }
            }}
          >
            {isFullyPaid ? "Lanjut Bayar Penuh" : "Lanjut Bayar Sebagian"}
          </CustomButton>
        </Box>
      }
    >
      <Box px={2} pb={5} pt={3}>
        {paymentDetails.length === 0 ? (
          <PaymentMethod
            allowedMethod={allowedMethod}
            onClick={handleOnClickPaymentMethod}
          />
        ) : (
          <Box display="flex" flexDirection="column" gap={1}>
            {paymentDetails.map((detail, index) => {
              const Icon = PAYMENT_METHOD_ICON[detail.method];
              const ICON_COLOR = {
                [CART_PAYMENT_METHOD.CASH]: COLOR.success500,
                [CART_PAYMENT_METHOD.OTHER]: COLOR.warning500,
              };
              const onClickDelete = (index: number) => {
                setPaymentDetails((prevData) => {
                  const newData = [...prevData];
                  newData.splice(index, 1);
                  return newData;
                });
              };
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: `1px solid ${COLOR.neutral300}`,
                    borderRadius: 2,
                    p: 1,
                    gap: 1,
                  }}
                >
                  <Icon
                    size={28}
                    color={ICON_COLOR?.[detail.method] || COLOR.neutral500}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flex: 1,
                      wordWrap: "break-word",
                      wordBreak: "break-word",
                    }}
                  >
                    <Typography>
                      {[detail.method, detail.receivedBy.name].join(
                        DOT_SEPARATOR
                      )}
                    </Typography>

                    <Typography>
                      {formatCurrency(detail.amount, "IDR")}
                    </Typography>
                  </Box>
                  <CustomButton
                    variant="icon"
                    sx={{
                      backgroundColor: COLOR.danger50,
                      color: COLOR.danger500,
                      p: 0,
                      minWidth: "42px",
                    }}
                    onClick={() => {
                      onClickDelete(index);
                    }}
                  >
                    <FaTrash size={18} />
                  </CustomButton>
                </Box>
              );
            })}
          </Box>
        )}
        {!isFullyPaid && paymentDetails.length > 0 && (
          <CustomButton
            variant="dashed"
            sx={{ px: 2, py: 1, borderRadius: 2, gap: 1, mt: 2 }}
            onClick={() => {
              setModalState((prev) => ({ ...prev, choosePaymentMethod: true }));
            }}
          >
            <FaPlusCircle size={18} />
            <Typography variant="body2">Tambah Jenis Pembayaran</Typography>
          </CustomButton>
        )}
      </Box>

      <InputAmountModal
        modalProps={{
          open: modalState.inputAmountModal !== null,
          onClose: () =>
            setModalState((prev) => ({ ...prev, inputAmountModal: null })),
        }}
        totalAmountToBePaid={remainder}
        data={{
          method:
            modalState.inputAmountModal?.method || CART_PAYMENT_METHOD.CASH,
          amount: modalState.inputAmountModal?.amount || 0,
          receivedBy: modalState.inputAmountModal?.receivedBy || {
            id: "",
            name: "",
          },
        }}
        onClickPay={(paymentData) => {
          setPaymentDetails((prev) => [...prev, paymentData]);
          setModalState((prev) => ({ ...prev, inputAmountModal: null }));
        }}
      />

      <ModalTemplate
        desktopWidth="500px"
        desktopHeight="70vh"
        withCloseButton
        header={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 2,
              flex: 1,
              borderBottom: `1px solid ${COLOR.neutral300}`,
              gap: 1,
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              Pilih Tipe Pembayaran
            </Typography>
          </Box>
        }
        open={modalState.choosePaymentMethod}
        onClose={() =>
          setModalState((prev) => ({ ...prev, choosePaymentMethod: false }))
        }
      >
        <Box sx={{ px: 2, py: 2, pb: 5 }}>
          <PaymentMethod
            allowedMethod={[
              CART_PAYMENT_METHOD.CASH,
              CART_PAYMENT_METHOD.OTHER,
            ]}
            onClick={(method) => {
              handleOnClickPaymentMethod(method);
              setModalState((prev) => ({
                ...prev,
                choosePaymentMethod: false,
              }));
            }}
          />
        </Box>
      </ModalTemplate>
    </ModalTemplate>
  );
};

export default ChoosePaymentMethod;
