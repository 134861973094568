import { Fragment, useEffect, useMemo, useState } from "react";
import ModalTemplate, { ModalTemplateProps } from "./ModalTemplate";
import { Box, Divider, Typography } from "@mui/material";
import TextInput from "../Forms/TextInput/TextInput";
import { FiSearch } from "react-icons/fi";
import { COLOR } from "../../utils/color";
import { ServiceExtraType } from "../../types/globalTypes";
import ServiceItem from "../ServiceItem/ServiceItem";
import { generateServiceDurationsOptions } from "../../utils/helper";
import {
  GetScheduleEventProductDetail,
  ScheduleEventProductDetail,
} from "../../api/request.types";
import { useGetScheduleEventProducts } from "../../query/queries";

export type SelectServiceOption = Pick<
  ScheduleEventProductDetail,
  | "productID"
  | "productName"
  | "duration"
  | "teamMemberName"
  | "price"
  | "teamMemberID"
  | "resourceID"
  | "resourceName"
  | "isResourceActive"
>;

interface SelectServiceModalProps {
  modalProps: Omit<ModalTemplateProps, "children">;
  onClickService: (service: SelectServiceOption) => void;
  eventID?: string;
  teamMemberIDs?: string[];
  resourceIDs?: string[];
}

const SelectServiceModal = ({
  modalProps,
  onClickService,
  teamMemberIDs = [],
  resourceIDs = [],
}: SelectServiceModalProps) => {
  const [search, setSearch] = useState("");
  const [services, setServices] = useState<SelectServiceOption[]>([]);

  const { data, isFetching } = useGetScheduleEventProducts();
  const stableTeamMemberIDs = useMemo(() => teamMemberIDs, [teamMemberIDs]);
  const stableResourceIDs = useMemo(() => resourceIDs, [resourceIDs]);
  useEffect(() => {
    if (!data?.data?.data || isFetching) return;
    const serviceData = data.data.data || [];
    const mappedServices = serviceData.map((service) => {
      let teamMemberService = service.teamMembers.find((tm) =>
        stableTeamMemberIDs.includes(tm.teamMemberID)
      );
      if (!teamMemberService) teamMemberService = service.teamMembers[0];

      let isActive = true;
      let resourceService = service.resources?.find((res) =>
        stableResourceIDs.includes(res.resourceID)
      );
      if (!resourceService) {
        resourceService = service.resources?.[0];
      }

      const resourceServiceID = resourceService?.resourceID || "";
      if (
        !service.resources
          ?.map((res) => res.resourceID)
          .includes(resourceServiceID)
      ) {
        isActive = false;
      }
      return {
        productID: service.productID,
        productName: service.productName,
        duration: service.duration,
        teamMemberID: teamMemberService?.teamMemberID || "",
        teamMemberName: teamMemberService?.name || "",
        price: service.price,
        resourceID: resourceServiceID,
        resourceName: resourceService?.name || "",
        isResourceActive: isActive,
      };
    });
    setServices(mappedServices);
  }, [data?.data.data, isFetching, stableTeamMemberIDs, stableResourceIDs]);

  const _renderServices = () => {
    const filteredServices = services.filter((service) =>
      service.productName.toLowerCase().includes(search.toLowerCase())
    );
    return (
      <Box>
        {filteredServices.map((service, index) => (
          <Fragment key={service.productID}>
            <ServiceItem
              service={service}
              onClick={() => {
                onClickService(service);
              }}
            />
            {index !== filteredServices.length - 1 && <Divider />}
          </Fragment>
        ))}
      </Box>
    );
  };
  return (
    <ModalTemplate
      {...modalProps}
      withCloseButton
      header={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
            flex: 1,
            borderBottom: `1px solid ${COLOR.neutral300}`,
            gap: 1,
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Pilih Service
          </Typography>
          <TextInput
            startEndorment={<FiSearch size={20} color={COLOR.neutral500} />}
            textInputProps={{
              placeholder: "Cari Service",
              value: search,
              onChange: (e) => setSearch(e.target.value),
            }}
          />
        </Box>
      }
    >
      <Box sx={{ px: 2, pb: 5 }}>{_renderServices()}</Box>
    </ModalTemplate>
  );
};

export default SelectServiceModal;
