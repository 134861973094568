import React from 'react';
import { useLocation } from 'react-router-dom';

interface DebugRouterProps {
  children: React.ReactNode;
}

const DebugRouter: React.FC<DebugRouterProps> = ({ children }) => {
  const location = useLocation();

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log(
        `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location.state)}`,
      );
    }
  }, [location]);

  return <>{children}</>;
};

export default DebugRouter;
