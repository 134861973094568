import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { COLOR } from "../../../utils/color";
import { MAX_PAGE_WIDTH, ZINDEX } from "../../../utils/constant";

export const HEADER_HEIGHT = 70;
export const SIDEBAR_WIDTH = 50;

export const NavContainer: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pb: '16px',
  pt: '8px',
  px: 2,
  backgroundColor: 'white',
  zIndex: ZINDEX.BOTTOM_SHEET,
};

export const actionButtonStyles: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  aspectRatio: 1,
  borderRadius: '50%',
  backgroundColor: COLOR.primary500,
  color: COLOR.neutral50,
  "&:hover": {
    backgroundColor: COLOR.primary700,
    cursor: 'pointer',
  },
  "&:active": {
    backgroundColor: COLOR.primary700,
  },
};
export const NavItemContainer: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  height: '36px',
  maxWidth: MAX_PAGE_WIDTH,
  alignSelf: 'center',
  flex: 1,
}

export const HeaderContainer: SxProps<Theme> = {
  p: 2,
  height: `${HEADER_HEIGHT}px`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'white',
  borderBottom: '1px solid #e2e2e2',
  boxShadow: 3,
  position: 'relative',
  zIndex: 1,
};

export const SideBarContainer: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  p: '12px',
  backgroundColor: COLOR.neutral900,
  gap: 2,
};
export const SidebarItem = (isActive: boolean): SxProps<Theme> => ({
  display: 'flex',
  flexDirection:'column',
  justifyContent: 'center',
  alignItems: 'center',
  aspectRatio: 1,
  borderRadius: '50%',
  color: isActive ? COLOR.primary500 : COLOR.neutral900,
  '&:hover': {
    backgroundColor: '#e5e5e5',
    color: COLOR.neutral900,
  }
});
export const OutletContainer: SxProps<Theme> = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  backgroundColor: 'white',
};
