import React, { useEffect, useMemo, useState } from "react";
import { COLOR } from "../../utils/color";
import ModalTemplate, { ModalTemplateProps } from "./ModalTemplate";
import { Alert, Box, Divider, Typography } from "@mui/material";
import { formatCurrency, formatDurationBySecond } from "../../utils/helper";
import {
  APIGetTransactionById,
  GetProductTeamMembersResponse,
  GetTeamMembers,
  TeamMemberStatus,
} from "../../api/request.types";
import { useSelector } from "react-redux";
import { RootReducerState } from "../../redux/reducers";
import {
  useGetProductTeamMembers,
  useGetTeamMembers,
} from "../../query/queries";
import SingleSelectRawOption from "../Forms/SingleSelect/SingleSelectRawOption";
import CustomButton from "../CustomButton/CustomButton";
import dayjs from "dayjs";
import { DOT_SEPARATOR } from "../../utils/constant";

interface EditSaleDetailModalProps {
  modalProps: Omit<ModalTemplateProps, "children">;
  initialData: APIGetTransactionById;
  onSave: (data: APIGetTransactionById) => void;
}

const EditSaleDetailModal: React.FC<EditSaleDetailModalProps> = ({
  modalProps,
  initialData,
  onSave,
}) => {
  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );

  const [saleDetails, setSaleDetails] =
    useState<APIGetTransactionById>(initialData);

  const getTeamMemberQuery = useGetTeamMembers({
    status: TeamMemberStatus.ACTIVE,
  });
  const getProductTeamMembersQuery = useGetProductTeamMembers(
    initialData.productDetail.map((product) => product.productID)
  );

  useEffect(() => {
    if (modalProps.open) {
      setSaleDetails(initialData);
    }
  }, [initialData, modalProps.open]);

  const activeTeamMembers: GetTeamMembers[] = useMemo(() => {
    if (
      !getTeamMemberQuery.isLoading &&
      getTeamMemberQuery.isFetched &&
      getTeamMemberQuery.data
    ) {
      const activeMembers = getTeamMemberQuery.data?.data;
      return activeMembers;
    }
    return [];
  }, [
    getTeamMemberQuery.data,
    getTeamMemberQuery.isFetched,
    getTeamMemberQuery.isLoading,
  ]);

  const productTeamMembersMap: GetProductTeamMembersResponse = useMemo(() => {
    if (
      !getProductTeamMembersQuery.isLoading &&
      getProductTeamMembersQuery.isFetched &&
      getProductTeamMembersQuery.data
    ) {
      const productTeamMembers = getProductTeamMembersQuery.data?.data;
      return productTeamMembers;
    }
    return {};
  }, [
    getProductTeamMembersQuery.data,
    getProductTeamMembersQuery.isFetched,
    getProductTeamMembersQuery.isLoading,
  ]);

  const activeTeamMemberOptions = useMemo(() => {
    return [
      ...activeTeamMembers.map((member) => ({
        label: member.name,
        value: member.teamMemberID,
      })),
      {
        label: userReducer.data.name + " (Me)" || "",
        value: userReducer.data.userId || "",
      },
    ];
  }, [activeTeamMembers, userReducer.data.name, userReducer.data.userId]);

  const handleTeamMembersChange =
    (targetProductIdx: number, targetScheduleIdx: number) =>
    (teamMemberId: string) => {
      setSaleDetails({
        ...saleDetails,
        productDetail: saleDetails.productDetail.map(
          (productDetail, productIdx) => {
            if (productIdx === targetProductIdx) {
              return {
                ...productDetail,
                teamMemberID: teamMemberId,
              };
            }
            return productDetail;
          }
        ),
      });
    };

  return (
    <ModalTemplate
      {...modalProps}
      header={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
            flex: 1,
            gap: 1,
            borderBottom: `1px solid #e5e5e5`,
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Ubah Detail Sale
          </Typography>
        </Box>
      }
      footer={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            gap: 1,
            p: 2,
            borderTop: `1px solid ${COLOR.neutral300}`,
          }}
        >
          <CustomButton onClick={() => onSave(saleDetails)}>
            Simpan
          </CustomButton>
        </Box>
      }
    >
      <Box px={2} pt={2} pb={5}>
        <Alert severity="info">
          Perubahan akan tercermin dalam semua laporan.
        </Alert>
        <Typography variant="h6" pt={2}>
          Produk Terjual
        </Typography>
        <Typography variant="caption" color={COLOR.neutral500} pb={2}>
          {[
            `Sale #${saleDetails._id}`,
            dayjs(saleDetails.date).format("ddd, DD MMM YYYY"),
            userReducer.data.companyName || "",
          ].join(DOT_SEPARATOR)}
        </Typography>

        {saleDetails.productDetail?.map((product, idx) => {
          const totalPriceOfProduct = product.scheduleDetails.reduce(
            (curr, schedule) => {
              return curr + schedule.price;
            },
            0
          );
          return (
            <Box key={`${product._id}${idx}`}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={1}
              >
                <Typography
                  variant="body1"
                  flex="1"
                  sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}
                >
                  {product.productName}
                </Typography>
                <Typography variant="body1" whiteSpace="nowrap">
                  {" "}
                  {formatCurrency(totalPriceOfProduct || 0, "IDR")}
                </Typography>
              </Box>

              {product.scheduleDetails.map((schedule, index) => {
                const productMemberOptions = activeTeamMemberOptions.filter(
                  (option) =>
                    productTeamMembersMap[product.productID]?.includes(
                      option.value
                    )
                );
                return (
                  <Box key={`${schedule._id}${index}`}>
                    <Typography color={COLOR.neutral500}>
                      {schedule.startTime} -{" "}
                      {formatDurationBySecond(schedule.duration * 60)} (
                      {schedule.qty}x) dengan
                    </Typography>
                    <SingleSelectRawOption
                      title="Anggota Tim"
                      sx={{ my: 2 }}
                      optionProps={{
                        value: product.teamMemberID,
                        options: productMemberOptions,
                        onChange: handleTeamMembersChange(idx, index),
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
          );
        })}

        <Divider sx={{ my: 3 }} />
        <Typography variant="h6" pb={2}>
          Pembayaran Diterima
        </Typography>
        <SingleSelectRawOption
          title={formatCurrency(saleDetails.total || 0, "IDR")}
          optionProps={{
            value:
              saleDetails.paymentDetail?.[0]?.receivedBy ||
              userReducer.data.userId,
            options: activeTeamMemberOptions,
            onChange: (teamMemberId) => {
              setSaleDetails({
                ...saleDetails,
                paymentDetail: (saleDetails.paymentDetail || []).map(
                  (paymentDetail) => ({
                    ...paymentDetail,
                    receivedBy: teamMemberId,
                  })
                ),
                receivedBy: teamMemberId,
              });
            },
          }}
        />
      </Box>
    </ModalTemplate>
  );
};

export default EditSaleDetailModal;
