import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { COLOR } from '../../utils/color';
import ModalTemplate, { ModalTemplateProps } from './ModalTemplate';
import { Box, Typography } from '@mui/material';
import CustomButton from '../CustomButton/CustomButton';
import SingleSelectRawOption from '../Forms/SingleSelect/SingleSelectRawOption';
import InputTitle from '../Forms/InputTitle/InputTitle';
import CalendarRangePicker from '../CalendarRangePicker/CalendarRangePicker';
import { DateRangeLabel, TIME_FORMAT } from '../../utils/constant';
import TextInput from '../Forms/TextInput/TextInput';
import { DateRangeType, RangeTypeLabelKey } from '../../types/globalTypes';
import { getDateRangeByRangeType, getRangeType } from '../../utils/helper';

interface DateRangePickerModalProps {
  modalProps: Omit<ModalTemplateProps, 'children'>;
  onApply: (date: { startDate: Dayjs, endDate: Dayjs, range: DateRangeType }) => void;
  startDate: Dayjs;
  endDate: Dayjs;
}

const DateRangePickerModal: React.FC<DateRangePickerModalProps> = ({
  modalProps,
  startDate,
  endDate,
  onApply
}) => {

  const [date, setDate] = useState<{
    startDate: Dayjs,
    endDate: Dayjs,
    range: DateRangeType,
    textStartDate: string,
    textEndDate: string,
  }>({
    startDate,
    endDate,
    range: 'today',
    textStartDate: startDate.format(TIME_FORMAT.YYYYMMDD),
    textEndDate: endDate.format(TIME_FORMAT.YYYYMMDD),
  });


  useEffect(() => {
    if (modalProps.open) {
      const startDateDayjs = startDate;
      const endDateDayjs = endDate;
      let dateType: DateRangeType = getRangeType(startDateDayjs, endDateDayjs);
      setDate(prev => ({
        ...prev,
        type: dateType,
        startDate: startDateDayjs,
        endDate: endDateDayjs,
        textStartDate: startDate.format(TIME_FORMAT.YYYYMMDD),
        textEndDate: endDate.format(TIME_FORMAT.YYYYMMDD),
      }));
    }
  }, [modalProps.open, startDate, endDate]);


  const handleDateChange = (startDate: Dayjs, endDate: Dayjs) => {
    setDate((prev) => {
      if (!prev.startDate || (prev.startDate && prev.endDate)) {
        return {
          ...prev,
          startDate,
          endDate,
          textStartDate: startDate.format(TIME_FORMAT.YYYYMMDD),
          textEndDate: endDate.format(TIME_FORMAT.YYYYMMDD),
          range: 'custom',
        };
      }
      return prev;
    });
  };

  const handleOnApply = () => {
    onApply(date);
  }

  return (
    <ModalTemplate
      desktopWidth='900px'
      desktopHeight='90vh'
      {...modalProps}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...modalProps.sx,
      }}
      header={
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, flex: 1, borderBottom: `1px solid ${COLOR.neutral300}`, gap: 1 }}>
          <Typography variant='h6' fontWeight="bold">Atur Tanggal Pencarian</Typography>
        </Box>
      }
      footer={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
            p: 2,
            borderTop: `1px solid ${COLOR.neutral300}`,
          }}
        >
          <CustomButton onClick={handleOnApply}>Terapkan</CustomButton>
        </Box>}
    >
      <Box px={2} pt={2} py={3} display="flex" flexDirection="column" gap={1}>
        <Box>
          <InputTitle title='Jangka Waktu' />
          <SingleSelectRawOption
            optionProps={{
              value: date.range,
              onChange: (newType) => {
                setDate(prev => {
                  const { startDate, endDate } = getDateRangeByRangeType(newType as DateRangeType, prev.startDate, prev.endDate);
                  return ({
                    ...prev,
                    startDate,
                    endDate,
                    range: newType as DateRangeType,
                  })
                })
              },
              options: Object.keys(DateRangeLabel)
                .filter((rangeType) => rangeType !== 'custom')
                .map((rangeType) => ({
                  label: DateRangeLabel[rangeType as RangeTypeLabelKey],
                  value: rangeType,
                }))
                .concat(date.range === 'custom' ? [{ label: 'Custom', value: 'custom' }] : []),
            }}
          />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          <TextInput
            title={"Dari"}
            textInputProps={{
              placeholder: "Dari" + dayjs(date.textStartDate, TIME_FORMAT.YYYYMMDD).format(TIME_FORMAT.DDMMMYYYY),
              value: date.textStartDate,
              onChange: (e) => setDate(date => ({ ...date, textStartDate: e.target.value })),
            }}
            error={!dayjs(date.textStartDate, TIME_FORMAT.YYYYMMDD, true).isValid()}
            helper={{
              text: dayjs(date.textStartDate, TIME_FORMAT.YYYYMMDD, true).isValid() ? undefined : "Invalid Date Format",
              color: COLOR.danger500,
            }}
          />
          <TextInput
            title={"Hingga"}
            textInputProps={{
              value: date.textEndDate,
              onChange: (e) => setDate(date => ({ ...date, textEndDate: e.target.value })),
            }}
            error={!dayjs(date.textEndDate, TIME_FORMAT.YYYYMMDD, true).isValid()}
            helper={{
              text: dayjs(date.textEndDate, TIME_FORMAT.YYYYMMDD, true).isValid() ? undefined : "Invalid Date Format",
              color: COLOR.danger500,
            }}
          />
        </Box>
        <CalendarRangePicker
          startDate={date.startDate}
          endDate={date.endDate}
          onDateChange={handleDateChange}
        />
      </Box>
    </ModalTemplate>
  );
};

export default DateRangePickerModal;
