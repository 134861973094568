import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_NAME, RouteLabel, RoutePath } from '../../utils/constant';
import PermissionTable from '../../components/PermissionTable/PermissionTable';
import SubMenuHeader from '../../components/Layout/SubMenuHeader/SubMenuHeader';
import { FaArrowLeft } from 'react-icons/fa';

const PermissionPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ backgroundColor: 'white', flexGrow: 1, }}>
      <SubMenuHeader
        leftNav={{
          icon: <FaArrowLeft />,
          onClick: () => {
            navigate(RoutePath[ROUTE_NAME.MORE], { replace: true });
          },
        }}
        text={RouteLabel[ROUTE_NAME.PERMISSION]}
      />
      <Box sx={{ p: 2, pt: 3, pb: 5, }}>
        <PermissionTable />
      </Box>
    </Box>
  );
};

export default PermissionPage;
