import React, { useEffect, useState } from 'react';
import { Button, Box, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { generateIntervalOptions } from '../../../utils/helper';
import InputTitle from '../InputTitle/InputTitle';
import SingleSelectRawOption from '../SingleSelect/SingleSelectRawOption';
import { TIME_FORMAT } from '../../../utils/constant';

interface TimeRangePickerProps {
  initialStartTime?: string;
  initialEndTime?: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onClose: (startTime: Dayjs, endTime: Dayjs) => void;
  intervalDuration: number;
  disabledIntervals: Array<string>;
}

const TimeRangePicker: React.FC<TimeRangePickerProps> = ({
  initialStartTime = '08.00',
  initialEndTime = '09.00',
  intervalDuration = 30,
  open,
  onOpenChange,
  onClose,
  disabledIntervals,
}) => {
  const intervalOptions = generateIntervalOptions(intervalDuration);
  const [startTime, setStartTime] = useState<Dayjs>(dayjs(intervalOptions[0].value, TIME_FORMAT.HHmm));
  const [endTime, setEndTime] = useState<Dayjs>(dayjs(intervalOptions[1].value, TIME_FORMAT.HHmm));
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setStartTime(dayjs(initialStartTime, TIME_FORMAT.HHmm));
    setEndTime(dayjs(initialEndTime, TIME_FORMAT.HHmm));
  }, [initialStartTime, initialEndTime]);


  const handleClose = () => {
    if (endTime.isBefore(startTime)) {
      setError('End time must be after start time.');
      return;
    }
    if (endTime.diff(startTime, 'minute') < intervalDuration) {
      setError(`Time Range at least must have ${intervalDuration} minute difference`);
      return;
    }
    setError('');
    onClose(startTime, endTime);
    onOpenChange(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => onOpenChange(false)}
      fullWidth
      maxWidth="sm"
      PaperProps={{ style: { minHeight: '500px' } }}
    >
      <DialogTitle>
        Tentukan Waktu
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <InputTitle title='Mulai Dari' />
          <SingleSelectRawOption
            optionProps={{
              value: startTime.format(TIME_FORMAT.HHmm),
              onChange: (newValue) => {
                setStartTime(dayjs(newValue, TIME_FORMAT.HHmm));
                setEndTime(dayjs(newValue, TIME_FORMAT.HHmm).add(intervalDuration, 'minute'));
              },
              options: intervalOptions,
              disabledValues: disabledIntervals,
            }}
          />
          <InputTitle title='Berakhir Hingga' textProps={{ sx: { mt: 2 } }} />
          <SingleSelectRawOption
            optionProps={{
              value: endTime.format(TIME_FORMAT.HHmm),
              onChange: (newValue) => {
                setEndTime(dayjs(newValue, TIME_FORMAT.HHmm));
              },
              options: intervalOptions,
              disabledValues: disabledIntervals,
            }}
          />
          {error && <Typography color="error">{error}</Typography>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => {
          e.preventDefault();
          onOpenChange(false);
        }}>Batalkan</Button>
        <Button onClick={handleClose}>Lanjutkan</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimeRangePicker;
