import { forwardRef, useCallback, useEffect, useState } from "react";
import {
  BottomSheet,
  BottomSheetProps,
  BottomSheetRef,
} from "react-spring-bottom-sheet";
import { Box, Typography } from "@mui/material";
import { COLOR } from "../../utils/color";
import SearchIcon from "../../assets/svg/SearchIcon";
import TextInput from "../Forms/TextInput/TextInput";
import CustomButton from "../CustomButton/CustomButton";

interface SectionBottomSheetProps {
  bottomSheetProps?: Omit<BottomSheetProps, "children" | "open" | "ref">;
  open: boolean;
  isLoading?: boolean;
  onClick: (name: string) => void;
  isEditSection: boolean;
  color?: string;
  initialName?: string;
}
const SectionBottomSheet = forwardRef<BottomSheetRef, SectionBottomSheetProps>(
  ({ bottomSheetProps, ...props }, ref) => {
    const [sectionName, setSectionName] = useState<string>(
      props.initialName || ""
    );

    useEffect(() => {
      setSectionName(props.initialName || "");
    }, [props.initialName]);
    const handleChangeSectionName = useCallback(
      (name: string) => {
        setSectionName(name);
      },
      [setSectionName]
    );

    return (
      <BottomSheet ref={ref} open={props.open} {...bottomSheetProps}>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          height="100%"
          pb={12}
        >
          <Typography px={2} mb={2} fontWeight={600} fontSize={18}>
            {props.isEditSection ? "Ubah Nama Bagian" : "Masukkan Nama Bagian"}
          </Typography>
          <Box display="flex" flexDirection="column" pb={3} pr={3} pl={3}>
            <TextInput
              textInputProps={{
                placeholder: "New Section",
                value: sectionName,
                onChange: (e) => handleChangeSectionName(e.target.value),
                style: {
                  border: "none", // or just omit this if the library supports it
                  backgroundColor: "white", // Set your desired background color
                  padding: "5px", // Optional: Add some padding for better appearance
                  borderRadius: "0px", // Optional: Add rounded corners
                },
              }}
              disabled={props.isLoading}
            />
          </Box>
          <Box display="flex" flexDirection="column" pr={3} pl={3}>
            <CustomButton
              variant="contained"
              onClick={() => {
                props.onClick(sectionName);
                setSectionName("");
              }}
              sx={{
                backgroundColor: COLOR.primary500,
                color: "white",
                "&:hover": {
                  backgroundColor: COLOR.primary500,
                  color: "white",
                },
              }}
            >
              {props.isEditSection ? "Ubah Nama Bagian" : "Tambahkan Bagian"}
            </CustomButton>
          </Box>
        </Box>
      </BottomSheet>
    );
  }
);

export default SectionBottomSheet;
