import React, { useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import AddResourceModal from "../Modal/AddResourceModal";
import CustomButton from "../CustomButton/CustomButton";
import { FaPlus, FaTrash } from "react-icons/fa";
import { CheckboxButton } from "../ChecboxButton/CheckboxButton";
import { useGetResourceCompact } from "../../query/queries";
import OpacityButton from "../OpacityButton/OpacityButton";
import { COLOR } from "../../utils/color";

interface ServiceResourcesCheckboxProps {
  selectedResources: string[];
  onChange: (newSelected: string[]) => void;
  isDisabled?: boolean;
  onDelete: (resource: { resourceID: string; resourceName: string }) => void;
}

const ServiceResourcesCheckbox: React.FC<ServiceResourcesCheckboxProps> = ({
  selectedResources,
  onChange,
  isDisabled,
  onDelete,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCompanyToggle = (resources: { resourceID: string }[]) => {
    const resourceIDs = resources.map((r) => r.resourceID);
    const isAllSelected = resourceIDs.every((id) =>
      selectedResources.includes(id)
    );

    const newSelected = isAllSelected
      ? selectedResources.filter((id) => !resourceIDs.includes(id))
      : [
          ...selectedResources,
          ...resourceIDs.filter((id) => !selectedResources.includes(id)),
        ];

    onChange(newSelected);
  };

  const handleResourceToggle = (resourceID: string) => {
    const newSelected = selectedResources.includes(resourceID)
      ? selectedResources.filter((id) => id !== resourceID)
      : [...selectedResources, resourceID];

    onChange(newSelected);
  };

  const getResourceCompactQuery = useGetResourceCompact();
  const resources = useMemo(() => {
    const data = getResourceCompactQuery.data?.data?.data;
    return data ? [data] : [];
  }, [getResourceCompactQuery.data]);

  return (
    <Box>
      {resources.map((company) => {
        const allResourcesChecked = company.resources.every((r) =>
          selectedResources.includes(r.resourceID)
        );

        return (
          <Box key={company.companyID}>
            <CheckboxButton
              key={company.companyID}
              checked={allResourcesChecked}
              disabled={isDisabled}
              onToggle={() => handleCompanyToggle(company.resources)}
              buttonSx={{ px: 2, py: 1, width: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 0.5,
                  alignItems: "flex-start",
                  textAlign: "left",
                }}
              >
                <Typography variant="body1" fontWeight="bold">
                  {company.companyName}
                </Typography>
              </Box>
            </CheckboxButton>

            {company.resources.map((resource) => (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={1}
              >
                <CheckboxButton
                  key={resource.resourceID}
                  checked={selectedResources.includes(resource.resourceID)}
                  onToggle={() => handleResourceToggle(resource.resourceID)}
                  disabled={isDisabled}
                  buttonSx={{ px: 2, py: 1, pl: 4, width: "100%" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 0.5,
                      alignItems: "flex-start",
                      textAlign: "left",
                    }}
                  >
                    <Typography variant="body2">
                      {resource.resourceName}
                    </Typography>
                  </Box>
                </CheckboxButton>
                <OpacityButton
                  disabled={isDisabled}
                  onClick={() => {
                    onDelete({
                      resourceID: resource.resourceID,
                      resourceName: resource.resourceName,
                    });
                  }}
                >
                  <FaTrash size={18} color={COLOR.neutral500} />
                </OpacityButton>
              </Box>
            ))}
          </Box>
        );
      })}

      <CustomButton
        variant="dashed"
        startEndorment={<FaPlus />}
        sx={{ mt: 2 }}
        onClick={() => setIsModalOpen(true)}
      >
        Tambah Sumber Daya
      </CustomButton>
      <AddResourceModal
        modalProps={{
          open: isModalOpen,
          onClose: () => setIsModalOpen(false),
        }}
        onSaveFinish={() => {
          setIsModalOpen(false);
          getResourceCompactQuery.refetch();
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      />
    </Box>
  );
};

export default ServiceResourcesCheckbox;
