import { Theme } from '@emotion/react';
import { Box, BoxProps, SxProps, Typography } from '@mui/material';
import React from 'react';
import { COLOR } from '../../utils/color';
import isEmpty from 'lodash.isempty';

interface RowKeyValuePropsI extends BoxProps {
  title: string;
  value?: React.ReactNode;
  renderValue?: React.ReactNode;
  titleProps?: {
    sx?: SxProps<Theme>;
  };
  valueProps?: {
    sx?: SxProps<Theme>;
  };
}

const RowKeyValue: React.FC<RowKeyValuePropsI> = (props) => {
  const { title, value, renderValue, titleProps, valueProps, ...rest } = props;

  return (
    <Box
      {...rest}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        my: 1,
        columnGap: 2,
        ...((rest?.sx as SxProps<Theme>) || {}),
      }}
    >
      {!isEmpty(title) && <Typography
        sx={{
          fontSize: 14,
          fontWeight: 500,
          color: COLOR.neutral400,
          ...(titleProps?.sx || {}),
        }}
      >
        {title}
      </Typography>}
      {renderValue ? (
        renderValue
      ) : (
        !isEmpty(value) && <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
            color: '#343434',
            ...(valueProps?.sx || {}),
          }}
        >
          {value}
        </Typography>
      )}
    </Box>
  );
};

export default RowKeyValue;
