import dayjs, { Dayjs } from "dayjs";
import {
  CurrencyCodeType,
  DateRangeType,
  GoogleMapSrcOptions,
  PAYMENT_STATUS,
  ProductData,
  SchedulePriceType,
  TRANSACTION_STATUS,
} from "../types/globalTypes";
import { COLOR } from "./color";
import { DEFAULT_GEOLOCATION, DURATION_FORMAT } from "./constant";
import { ManageSchedulesType } from "../context/ScheduleContext";
import { ScheduleItem } from "../components/Tables/ScheduleTables";
import {
  APICreateProductBodyPayload,
  GetProductByIdResponse,
  ScheduleDetailWithQty,
} from "../api/request.types";
import { StatusTextPropsI } from "../components/StatusText/StatusText";
import { initialState } from "../redux/reducers/product";
import { TimeRange } from "../pages/TeamScheduledShifts/TeamScheduledShifts";
import { ServiceItemWarning } from "../components/ServiceItem/ServiceItem";
import { ServiceItemWarningType } from "../components/ServiceItem/ServiceItem";

export const dashedBorderBgImage = (
  bgColor: string = COLOR.neutral50,
  borderColor: string = COLOR.neutral300,
  spaceBetweenDot: string = "8px",
  borderRadius: string = "8px"
) => {
  const svgString = `
  <svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'>
      <rect width='100%' height='100%' fill='${bgColor}' rx='${borderRadius}' ry='${borderRadius}' 
      stroke='${borderColor}' stroke-width='5' 
      stroke-dasharray='${spaceBetweenDot}' stroke-dashoffset='30' stroke-linecap='square'/>
  </svg>`;
  return `url("data:image/svg+xml,${encodeURIComponent(svgString)}")`;
};

export const formatNumber = (
  number: number,
  locale = "id-ID",
  options?: Intl.NumberFormatOptions
) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options,
  });
  return formatter.format(number);
};

export const formatCurrency = (
  number: number,
  currency: CurrencyCodeType,
  options?: Intl.NumberFormatOptions
): string => {
  const currencySymbolMap: Record<CurrencyCodeType, string> = {
    IDR: "Rp",
    USD: "$",
    EUR: "€",
    GBP: "£",
    JPY: "¥",
    AUD: "A$",
  };

  const symbol = currencySymbolMap[currency] || "";

  return `${symbol} ${formatNumber(number, "id-ID", options)}`;
};

export const parseValidNumber = (value: string): number | undefined => {
  const numberRegex = /^-?(0|[1-9]\d*)(\.\d+)?$/;
  if (numberRegex.test(value)) {
    return parseFloat(value);
  } else {
    return undefined;
  }
};
export const isValidNumber = (value: string) => {
  const numberRegex = /^-?\d*(\.\d+)?$/;
  return numberRegex.test(value);
};

export const isValidPhoneNumber = (value: string) => {
  if (value.length > 10 && value.length < 12) return true;
  return false;
};
export const stripFormatting = (value: string) => value.replace(/\D/g, "");
export const formatPhoneNumber = (phoneNumber: string) =>
  phoneNumber.replace(/\D/g, "").replace(/(\d{4})(?=\d)/g, "$1-");

export const localStorageSize = () => {
  let _lsTotal = 0,
    _xLen,
    _x;
  for (_x in localStorage) {
    if (!localStorage.hasOwnProperty(_x)) continue;
    _xLen = (localStorage[_x].length + _x.length) * 2;
    _lsTotal += _xLen;
  }
  return (_lsTotal / 1024).toFixed(2);
};

export const generateGoogleMapSrc = ({
  address,
  zipCode,
  latitude,
  longitude,
}: GoogleMapSrcOptions) => {
  const baseUrl = "https://www.google.com/maps?q=";
  let query = "";

  if (latitude !== undefined && longitude !== undefined) {
    query = `${latitude},${longitude}`;
  } else if (address) {
    query = zipCode ? `${address} ${zipCode}` : address;
  } else {
    throw new Error(
      "Either address or latitude and longitude must be provided."
    );
  }

  const encodedQuery = encodeURIComponent(query);
  return `${baseUrl}${encodedQuery}&output=embed`;
};

export const selectedLocationStr = (location: {
  search: string;
  lat: number;
  long: number;
}) => {
  const { search, lat, long } = location;
  const locationBySearch = search;
  const locationByGeolocation = [lat, long].join(",");
  const defaultGeolocationStr = [
    DEFAULT_GEOLOCATION.lat,
    DEFAULT_GEOLOCATION.long,
  ].join(",");

  if (locationBySearch.length !== 0) return locationBySearch;
  if (locationByGeolocation !== defaultGeolocationStr)
    return locationByGeolocation;
  return null;
};

export const hasErrors = (
  errors: Record<string, string | undefined>
): boolean => {
  return Object.values(errors).some((error) => error !== undefined);
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      } else {
        reject(new Error("Failed to convert file to base64"));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const generateTimeIntervals = (data: {
  startTime?: Dayjs;
  duration: number;
  durationUnit?: "minute" | "hour";
}) => {
  const intervals = [];
  const startOfDay = data.startTime || dayjs().startOf("day");
  const endOfDay = dayjs().endOf("day");

  for (
    let time = startOfDay;
    time <= endOfDay;
    time = time.add(data.duration, data.durationUnit || "minute")
  ) {
    intervals.push(time.format("HH:mm"));
  }

  return intervals;
};

export const generateIntervalOptions = (intervalDuration: number) =>
  generateTimeIntervals({
    duration: intervalDuration,
    durationUnit: "minute",
  }).map((time) => ({
    label: time,
    value: time,
  }));

export const generateDisabledTimeIntervals = (
  schedules: SchedulePriceType[],
  intervalDuration: number,
  currentSchedule?: SchedulePriceType
): string[] => {
  const disabledIntervals: string[] = [];

  schedules.forEach(({ time }) => {
    const { startTime, endTime } = time;
    if (
      currentSchedule &&
      dayjs(startTime).isSame(dayjs(currentSchedule.time.startTime)) &&
      dayjs(endTime).isSame(dayjs(currentSchedule.time.endTime))
    ) {
      return;
    }
    let currentTime = dayjs(startTime);
    const end = dayjs(endTime);

    while (currentTime.isBefore(end)) {
      disabledIntervals.push(currentTime.format("HH.mm"));
      currentTime = currentTime.add(intervalDuration, "minute");
    }
  });

  return Array.from(new Set(disabledIntervals));
};

export const convertSchedulesToList = (
  scheduleObject: ProductData["schedules"]
): ScheduleItem[] => {
  const scheduleList: ScheduleItem[] = [];
  for (const day in scheduleObject) {
    if (scheduleObject.hasOwnProperty(day)) {
      const schedules = scheduleObject[day];
      schedules.forEach((schedule) => {
        scheduleList.push({
          day: day,
          startTime: dayjs(schedule.startTime, "HH.mm").toDate(),
          endTime: dayjs(schedule.endTime, "HH.mm").toDate(),
          price: schedule.price,
        });
      });
    }
  }

  return scheduleList;
};

export const convertProductScheduleToManagedSchedule = (
  scheduleObject: ProductData["schedules"]
) => {
  const managedSchedules: ManageSchedulesType = {};
  for (const day in scheduleObject) {
    const schedules = scheduleObject[day];
    managedSchedules[day] = schedules.map((schedule) => ({
      price: schedule.price,
      time: {
        startTime: dayjs(schedule.startTime, "HH.mm").format("HH.mm"),
        endTime: dayjs(schedule.endTime, "HH.mm").format("HH.mm"),
        disabled: false,
      },
    }));
  }
  return managedSchedules;
};

export const parseProductScheduleToAPISchedule = (
  scheduleObject: ProductData["schedules"]
): APICreateProductBodyPayload["scheduleDetails"] => {
  const scheduleDetails: APICreateProductBodyPayload["scheduleDetails"] = {};
  for (const day in scheduleObject) {
    const schedules = scheduleObject[day];
    scheduleDetails[day] = schedules.map((schedule) => ({
      startTime: schedule.startTime,
      endTime: schedule.endTime,
      price: schedule.price,
    }));
  }
  return scheduleDetails;
};

export const convertProductScheduleToStateSchedule = (
  productSchedules: GetProductByIdResponse["scheduleDetails"]
): ProductData["schedules"] => {
  const scheduleData: ProductData["schedules"] = {
    ...initialState.edit.schedules,
  };
  for (const day in productSchedules) {
    const schedules = productSchedules[day];
    scheduleData[day] = schedules.map((schedule) => ({
      startTime: dayjs(schedule.startTime, "HH.mm").format("HH.mm"),
      endTime: dayjs(schedule.endTime, "HH.mm").format("HH.mm"),
      price: schedule.price,
    }));
  }
  return scheduleData;
};
export const isBase64 = (str: string): boolean => {
  if (str.length % 4 !== 0) {
    return false;
  }
  const base64Pattern = /^[A-Za-z0-9+/=]+$/;
  return base64Pattern.test(str);
};

export const isBase64Image = (str: string): boolean => {
  const imagePattern = /^data:image\/[a-zA-Z]+;base64,/;
  return imagePattern.test(str) && isBase64(str.split(",")[1]);
};

export const base64ToFile = (base64String: string, fileName: string): File => {
  const [header, data] = base64String.split(",");
  const mime = header.match(/:(.*?);/)?.[1] || "";

  const byteString = atob(data);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([arrayBuffer], { type: mime });

  let extension = "";
  if (mime) {
    extension = mime.split("/")[1];
  }

  const fileNameWithExtension = `${fileName}.${extension}`;

  return new File([blob], fileNameWithExtension, { type: mime });
};

export const getStatusTextPropsByStatus = (
  status: PAYMENT_STATUS | TRANSACTION_STATUS
): StatusTextPropsI => {
  switch (status) {
    case PAYMENT_STATUS.UNPAID:
      return {
        children: "Belum Dibayar",
        backgroundColor: COLOR.warning500,
        color: COLOR.warning50,
      };
    case PAYMENT_STATUS.PAID:
      return {
        children: "Sudah Dibayar",
        backgroundColor: COLOR.success500,
        color: COLOR.success50,
      };
    case PAYMENT_STATUS.CANCELLED:
      return {
        children: "Dibatalkan",
        backgroundColor: COLOR.danger500,
        color: COLOR.danger50,
      };
    case PAYMENT_STATUS.REFUNDED:
      return {
        children: "Dikembalikan",
        backgroundColor: COLOR.danger500,
        color: COLOR.danger50,
      };
    case PAYMENT_STATUS.PARTIAL_PAID:
      return {
        children: "Dibayar Sebagian",
        backgroundColor: COLOR.warning500,
        color: COLOR.warning50,
      };
    case PAYMENT_STATUS.REFUND_CANCELLED:
      return {
        children: "Refund Dibatalkan",
        backgroundColor: COLOR.danger500,
        color: COLOR.danger50,
      };
    case PAYMENT_STATUS.REQUEST_REFUND:
      return {
        children: "Pengajuan Pengembalian Dana",
        backgroundColor: COLOR.warning500,
        color: COLOR.warning50,
      };
    case PAYMENT_STATUS.PENDING:
      return {
        children: "Proses Pembayaran",
        backgroundColor: COLOR.warning500,
        color: COLOR.warning50,
      };
    case PAYMENT_STATUS.PARTIAL_REFUND:
      return {
        children: "Dikembalikan Sebagian",
        backgroundColor: COLOR.warning500,
        color: COLOR.warning50,
      };
    case TRANSACTION_STATUS.COMPLETE:
      return {
        children: "Selesai",
        backgroundColor: COLOR.success500,
        color: COLOR.success50,
      };
    case TRANSACTION_STATUS.ON_PROGRESS:
      return {
        children: "Sedang Berjalan",
        backgroundColor: COLOR.primary500,
        color: COLOR.primary50,
      };
    case TRANSACTION_STATUS.COMING_SOON:
      return {
        children: "Akan Datang",
        backgroundColor: COLOR.neutral500,
        color: COLOR.neutral50,
      };
    case TRANSACTION_STATUS.CANCELED:
      return {
        children: "Batal",
        backgroundColor: COLOR.danger500,
        color: COLOR.danger50,
      };
    case TRANSACTION_STATUS.CREATED:
      return {
        children: "Baru Dibuat",
        backgroundColor: COLOR.warning500,
        color: COLOR.warning50,
      };
    default:
      return {
        children: "Status Tidak Diketahui",
        backgroundColor: COLOR.neutral500,
        color: COLOR.neutral50,
      };
  }
};

export const hexToRgba = (hex: string, alpha: number) => {
  hex = hex.replace(/^#/, "");

  let r = parseInt(hex.slice(0, 2), 16);
  let g = parseInt(hex.slice(2, 4), 16);
  let b = parseInt(hex.slice(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const checkRefundAllowability = (
  scheduleDetails: ScheduleDetailWithQty[]
) => {
  if (!scheduleDetails.length) return false;
  const sortedSchedule = scheduleDetails.sort((scheduleA, scheduleB) =>
    scheduleB.date > scheduleA.date
      ? 1
      : scheduleA.date === scheduleB.date
        ? scheduleA.startTime > scheduleB.startTime
          ? 1
          : -1
        : -1
  );
  return dayjs(
    `${sortedSchedule[0]?.date} ${sortedSchedule[0]?.startTime}`,
    "YYYY-MM-DD HH.mm"
  )
    .subtract(1, "hours")
    .isAfter(dayjs());
};

export const formatCoordinate = (input: string) => {
  let sanitized = input.replace(/[^0-9]/g, "");

  if (sanitized.length > 3) {
    sanitized = sanitized.slice(0, 3) + "." + sanitized.slice(3);
  }
  if (input.startsWith("-")) {
    sanitized = "-" + sanitized;
  }

  return sanitized;
};

export function generateTimeOptions(minutesList: number[]) {
  return minutesList.map((minutes) => {
    const timeDuration = dayjs.duration(minutes, "minutes");
    const hours = timeDuration.hours();
    const minutesLeft = timeDuration.minutes();

    let label = "";

    if (hours > 0) {
      label = `${hours} jam`;
      if (minutesLeft > 0) {
        label += ` ${minutesLeft} mnt`;
      }
    } else {
      label = `${minutesLeft} mnt`;
    }

    return { label, value: minutes.toString() };
  });
}

export const calculateTimePosition = (
  time: Date,
  scrollHeight: number,
  isEndingHour: boolean = false
) => {
  const END_TIME = 24;
  const now = dayjs(time);

  const hours = now.hour();
  const minutes = now.minute();

  const totalMinutes = hours * 60 + minutes;
  const heightPerMinute = scrollHeight / (END_TIME * 60);
  const position = totalMinutes * heightPerMinute;
  if (isEndingHour && position === 0) {
    return scrollHeight;
  }
  return position;
};
export const overlapLeftPos = (overlapOrder: number = 0) => {
  let res = 0;
  if (overlapOrder === 0) return res;
  res = (1 / (overlapOrder + 1)) * 100;
  return res;
};

export const convertTimeStringToMinutes = (timeString: string) => {
  const time = dayjs(timeString, "HH.mm");
  const minutes = time.hour() * 60 + time.minute();

  return minutes;
};

export const calculatePosition = (
  datetime: Date,
  currDatetime: Date,
  scrollHeight: number,
  isEndingHour: boolean = false
) => {
  const MINUTES_IN_A_DAY = 24 * 60;
  const comparedDate = dayjs(datetime);
  const now = dayjs(currDatetime);

  const dayDifference = comparedDate.diff(now, "day");
  const heightPerMinute = scrollHeight / MINUTES_IN_A_DAY;

  const calculateSameDayPosition = () => {
    const eventMinutes = now.hour() * 60 + now.minute();
    return eventMinutes * heightPerMinute;
  };

  if (dayDifference > 0) {
    return isEndingHour ? scrollHeight : 0;
  }

  if (dayDifference < 0) {
    return 0;
  }

  if (dayDifference === 0) {
    const position = calculateSameDayPosition();

    if (isEndingHour && position === 0) {
      return scrollHeight;
    }

    return position;
  }

  return 0;
};

export const calculateEventPosition = (
  currentDate: Date,
  event: {
    overlapOrder: number;
    overlapCount: number;
    start: Date;
    end: Date;
  },
  scrollHeight: number,
  gap: number = 8
) => {
  const { overlapCount, overlapOrder } = event;
  const startPos = calculatePosition(
    dayjs(currentDate).toDate(),
    dayjs(event.start).toDate(),
    scrollHeight
  );
  const endPos = calculatePosition(
    dayjs(currentDate).toDate(),
    dayjs(event.end).toDate(),
    scrollHeight,
    true
  );

  const widthPerItem = `calc(100% / ${overlapCount + 1})`;
  const finalWidth = `calc(${widthPerItem} - ${gap}px)`;

  let itemHeight = endPos - startPos;
  const isNextDay = endPos < startPos;
  if (isNextDay) {
    itemHeight = scrollHeight - startPos;
  }

  if (overlapCount <= 0)
    return {
      left: 0,
      width: finalWidth,
      top: `${startPos}px`,
      height: `${itemHeight}px`,
    };

  const leftPos = `calc(${overlapOrder} * ${widthPerItem})`;

  return {
    left: `calc(min(${leftPos}, 100%))`,
    width: finalWidth,
    top: `${startPos}px`,
    height: `${itemHeight}px`,
  };
};

export const getInitials = (name: string, maxInitials: number = 3) => {
  if (!name) return "";

  const nameParts = name.trim().split(/\s+/).filter(Boolean);
  if (nameParts.length === 1) {
    return nameParts[0].slice(0, maxInitials).toUpperCase();
  }

  const initials = nameParts.map((part) => part[0].toUpperCase());
  return initials.slice(0, maxInitials).join("");
};

export const formatDuration = (
  hours: number,
  isShort: boolean = true
): string => {
  if (hours < 0) return "0" + DURATION_FORMAT.short.minute;

  const fullHours = Math.floor(hours);
  const minutes = Math.round((hours - fullHours) * 60);
  const format = isShort ? DURATION_FORMAT.short : DURATION_FORMAT.long;

  try {
    if (fullHours === 0) {
      return `${minutes}${format.minute}`;
    }

    if (minutes === 0) {
      return `${fullHours}${format.hour}`;
    }

    return `${fullHours}${format.hour} ${minutes}${format.minute}`;
  } catch (error) {
    console.error("Error formatting duration:", error);
    return "0" + format.minute;
  }
};

export const calculateDiffInHours = (start: string, end: string): number => {
  const startDate = dayjs(`2024-01-01 ${start}`);
  const endDate = dayjs(`2024-01-01 ${end}`);
  return endDate.diff(startDate, "hour", true);
};

export const calculateTotalHours = (schedules: Array<TimeRange>): number => {
  return schedules.reduce((total, shift) => {
    const diffInHours = calculateDiffInHours(shift.startTime, shift.endTime);
    return total + diffInHours;
  }, 0);
};

export const generateServiceDurations = (): number[] => {
  // this code is based on: Tiered Interval Progression
  const durations: number[] = [];

  for (let i = 1; i <= 24; i++) {
    // First 24 durations increase by 5 minutes (300s)
    durations.push(i * 300);
  }

  for (let i = 1; i <= 18; i++) {
    // After 24, increase by 15 minutes (900s)
    durations.push(i * 900 + 7200);
  }

  return durations;
};

export const formatDurationBySecond = (
  second: number,
  isShort: boolean = true,
  showSeconds: boolean = true
) => {
  if (second <= 0)
    return `0${isShort ? DURATION_FORMAT.short.second : DURATION_FORMAT.long.second
      }`;

  const fullHours = Math.floor(second / 3600);
  const remainingMinutes = Math.floor((second % 3600) / 60);
  const remainingSeconds = second % 60;
  const format = isShort ? DURATION_FORMAT.short : DURATION_FORMAT.long;

  try {
    if (fullHours === 0 && remainingMinutes === 0) {
      return showSeconds
        ? `${remainingSeconds}${format.second}`
        : `0${format.minute}`;
    }

    if (fullHours === 0) {
      return remainingSeconds === 0 || !showSeconds
        ? `${remainingMinutes}${format.minute}`
        : `${remainingMinutes}${format.minute} ${remainingSeconds}${format.second}`;
    }

    if (remainingMinutes === 0 && (remainingSeconds === 0 || !showSeconds)) {
      return `${fullHours}${format.hour}`;
    }

    if (remainingSeconds === 0 || !showSeconds) {
      return `${fullHours}${format.hour} ${remainingMinutes}${format.minute}`;
    }

    return `${fullHours}${format.hour} ${remainingMinutes}${format.minute} ${remainingSeconds}${format.second}`;
  } catch (error) {
    console.error("Error formatting duration:", error);
    return `0${format.second}`;
  }
};

export const generateServiceDurationsOptions = (
  isShort: boolean = true,
  showSeconds: boolean = true
): { label: string; value: string }[] => {
  const durations = generateServiceDurations();
  return durations.map((duration) => ({
    label: formatDurationBySecond(duration, isShort, showSeconds),
    value: duration.toString(),
  }));
};

export const SERVICE_DURATION_OPTIONS = generateServiceDurationsOptions();

export const isQuarterToDate = (startDateDayjs: Dayjs, endDateDayjs: Dayjs) => {
  const today = dayjs();

  const getStartOfQuarter = () => {
    const month = today.month();
    if (month >= 0 && month <= 2) return today.startOf("year"); // Q1: Jan-Mar
    if (month >= 3 && month <= 5) return today.startOf("year").add(3, "month"); // Q2: Apr-Jun
    if (month >= 6 && month <= 8) return today.startOf("year").add(6, "month"); // Q3: Jul-Sep
    return today.startOf("year").add(9, "month"); // Q4: Oct-Dec
  };

  const startOfQuarter = getStartOfQuarter();

  return startDateDayjs.isSame(startOfQuarter) && endDateDayjs.isSame(today);
};

export const getRangeType = (startDateDayjs: Dayjs, endDateDayjs: Dayjs) => {
  const dayDiff = endDateDayjs.diff(startDateDayjs, "day");
  let dateType: DateRangeType = "custom";

  if (startDateDayjs.isSame(endDateDayjs, "date")) {
    dateType = "today";
  } else if (dayDiff === -1) {
    dateType = "yesterday";
  } else if (dayDiff === -7) {
    dateType = "last_7_days";
  } else if (dayDiff === -30) {
    dateType = "last_30_days";
  } else if (dayDiff === -90) {
    dateType = "last_90_days";
  } else if (
    startDateDayjs.isSame(dayjs().startOf("month")) &&
    endDateDayjs.isSame(dayjs())
  ) {
    dateType = "month_to_date";
  } else if (isQuarterToDate(startDateDayjs, endDateDayjs)) {
    dateType = "quarter_to_date";
  } else if (
    startDateDayjs.isSame(dayjs().startOf("year")) &&
    endDateDayjs.isSame(dayjs())
  ) {
    dateType = "year_to_date";
  }
  return dateType;
};

export const getDateRangeByRangeType = (
  rangeType: DateRangeType,
  customStartDate: Dayjs,
  customEndDate: Dayjs
) => {
  const today = dayjs();
  let startDate: Dayjs = customStartDate;
  let endDate: Dayjs = customEndDate;
  switch (rangeType) {
    case "today":
      break;
    case "yesterday":
      startDate = today.subtract(1, "day");
      endDate = today.subtract(1, "day");
      break;
    case "last_7_days":
      startDate = today.subtract(6, "day");
      break;
    case "last_30_days":
      startDate = today.subtract(29, "day");
      break;
    case "last_90_days":
      startDate = today.subtract(89, "day");
      break;
    case "last_month":
      startDate = today.subtract(1, "month").startOf("month");
      endDate = today.subtract(1, "month").endOf("month");
      break;
    case "week_to_date":
      startDate = today.startOf("week");
      break;
    case "month_to_date":
      startDate = today.startOf("month");
      break;
    case "quarter_to_date":
      startDate = today.subtract(today.month() % 3, "month").startOf("month");
      break;
    case "year_to_date":
      startDate = today.startOf("year");
      break;
    case "custom":
      return { startDate, endDate };
  }
  return { startDate, endDate };
};

export const generateWarningMessage = (warning: ServiceItemWarning) => {
  switch (warning.type) {
    case ServiceItemWarningType.OUTSIDE_SHIFT:
      return "Di Luar Jam Shift Tim Member";
    case ServiceItemWarningType.BLOCK_TIME:
      return `${warning.name} memiliki block waktu pada jam ${warning.time}`;
    case ServiceItemWarningType.EXISTING_BOOKING:
      return `${warning.name} memiliki booking pada jam ${warning.time}`;
    case ServiceItemWarningType.RESOURCE_UNAVAILABLE:
      return `${warning.name} telah terpakai pada jam ${warning.time}`;
    case ServiceItemWarningType.RESOURCE_NOT_SUITABLE:
      return `${warning.name} tidak sesuai dengan produk yang dipilih`;
  }
};

export const sendWhatsAppMessage = (phoneNumber: string, message: string) => {
  try {
    if (!phoneNumber) throw new Error("Phone number is required.");
    if (!message) throw new Error("Message content is required.");

    const cleanedPhone = phoneNumber.replace(/\D/g, "");
    if (!cleanedPhone) throw new Error("Invalid phone number.");

    const encodedMessage = encodeURIComponent(message);

    const whatsappUrl = `https://wa.me/${cleanedPhone}?text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank");

  } catch (error) {
    console.error("sendWhatsAppMessage Error:", error);
  }
};

export const generateTransactionMessage = (transactionID: string, invoiceUrl: string): string => {
  return `Hi Kak, Berikut kami kirim bukti transaksi reservasi melalui e-receipt untuk pesanan ${transactionID}. 
Anda dapat mengakses e-receipt melalui tautan berikut: ${invoiceUrl} 
Terima kasih telah melakukan reservasi di tempat kami.`;
};
