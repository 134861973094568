import React, { useCallback, useEffect, useMemo } from 'react';
import TeamProfileMember, { ProfileDataType } from './TeamProfileMember';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateTeamMember } from '../../query/mutations';
import { ROUTE_NAME, RoutePath } from '../../utils/constant';
import { useGetTeamMemberDetails } from '../../query/queries';
import { CreateTeamMemberPayload, EmploymentType, TeamMemberStatus } from '../../api/request.types';

const TeamProfileMemberEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { data } = useGetTeamMemberDetails(id);
  const updateTeamMember = useUpdateTeamMember();

  const intialData: ProfileDataType | undefined = useMemo(() => {
    if (!data) return undefined;

    return {
      customTeamMemberID: data?.customTeamMemberID,
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      jobTitle: data?.jobTitle,
      phoneNumber: data?.phoneNumber,
      startDate: data?.startDate,
      endDate: data?.endDate,
      employmentType: data?.employmentType as EmploymentType,
      notes: data.notes,
      status: data?.status as TeamMemberStatus,
    };
  }, [data]);

  const handleSubmit = async (data: CreateTeamMemberPayload) => {
    if (!id) return;

    await updateTeamMember.mutateAsync({ teamMemberID: id, ...data });
  };

  const onEditHasNoId = useCallback(() => {
    if (!Boolean(id)) {
      navigate(RoutePath[ROUTE_NAME.TEAM_MEMBER], { replace: true });
    }
  }, [id, navigate]);

  useEffect(onEditHasNoId, [onEditHasNoId]);

  return (
    <Box>
      <TeamProfileMember mode='edit' onSubmit={handleSubmit} initialData={intialData} />
    </Box>
  );
};

export default TeamProfileMemberEdit;
