import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { INITIAL_ROUTE_PATH_ON_AUTH, ROUTE_NAME, RoutePath, USER_ROLE } from "../utils/constant";

import Home from "../pages/Home/Home";
import Business from "../pages/Business/Business";
import NotFound from "../pages/NotFound";
import RequireAuthRoute from "./RequireAuth";
import Login from "./UnAuthorizedPage/LoginPage/Login";
// import Register from "./UnAuthorizedPage/RegisterPage/Register";
import NavigateOnAuth from "./NavigateOnAuth";
import CreateBusiness from "./Business/CreateBusiness/CreateBusiness";
import { BottomNavProvider } from "../context/BottomNavContext";
import LocationBusiness from "./Business/LocationBusiness/LocationBusiness";
// import PageEditor from "../components/PageEditor/PageEditor";
import PageTemplate from "./Business/PageTemplate/PageTemplate";
import Product from "./Business/Product/Product";
import StatusInfo from "./StatusInfo/StatusInfo";
import ManageSchedule from "./Business/ManageSchedule/ManageSchedule";
import { LocationProvider } from "../context/LocationPageContext";
import { StatusInfoProvider } from "../context/StatusInfoContext";
import { ManageScheduleProvider } from "../context/ScheduleContext";
import Transaction from "./Transaction/Transaction";
import DetailProduct from "./ProductDetail/ProductDetail";
import TransactionDetail from "./TransactionDetail/TransactionDetail";
import EditBusiness from "./Business/EditBusiness/EditBusiness";
import NavigateOnValidCompanyNameRoute from "./NavigateOnValidCompanyName";
import D2Home from "./DynamicPage/Template2/D2Home";
import D2Checkout from "./DynamicPage/Template2/D2Checkout";
import DProduct from "./DynamicPage/Template1/DProduct";
import DInvoice from "./DynamicPage/Template1/DInvoice";
import DSchedule from "./DynamicPage/Template1/DSchedule";
import DPaymentDetail from "./DynamicPage/Template1/DPaymentDetail";
import ChooseCompany from "./ChooseCompany/ChooseCompany";
import ResetPassword from "./ResetPassword/ResetPassword";
import VerifyEmail from "./VerifyEmail";
import ForgotPassword from "./ForgotPassword";
import RequestForgotPassword from "./RequestForgotPassword";
import TopProduct from "./TopProduct/TopProduct";
import BankAccount from "./BankAccount/BankAccount";
import BankAccountOTP from "./BankAccount/OTP/OTP";
import Withdraw from "./Withdraw/Withdraw";
import WithdrawOTP from "./Withdraw/OTP/OTP";
import MainDashboard from "./Dashboard/MainDashboard";
import Team from "./Team/Team";
import Schedule from "./Schedule/Schedule";
import DReschedule from "./DynamicPage/Template1/DReschedule";
import DRescheduleDetail from "./DynamicPage/Template1/DRescheduleDetail";
import MorePage from "./MorePage/MorePage";
import PageContainer from "../components/Layout/PageContainer/PageContainer";
import TeamScheduledShifts from "./TeamScheduledShifts/TeamScheduledShifts";
import TeamMember from "./TeamMember/TeamMember";
import TeamProfileMemberAdd from "./TeamProfileMember/TeamProfileMemberAdd";
import TeamProfileMemberEdit from "./TeamProfileMember/TeamProfileMemberEdit";
import TeamScheduledWorkingHour from "./TeamScheduledWorkingHour/TeamScheduledWorkingHour";
import SalePage from './SalePage/SalePage';
import TeamScheduledTimeOffAdd from "./TeamScheduledTimeOff/TeamScheduledTimeOffAdd";
import TeamScheduledTimeOffEdit from "./TeamScheduledTimeOff/TeamScheduledTimeOffEdit";
import SaleReportPage from "./SaleReportPage/SaleReportPage";
import SaleListPage from "./SaleListPage/SaleListPage";
import DebugRouter from "../components/DebugRouter/DebugRouter";
import PermissionPage from "./PermissionPage/PermissionPage";

const RouteList = () => {
  return (
    <BrowserRouter>
      <DebugRouter>
        <BottomNavProvider>
          <StatusInfoProvider>
            <ManageScheduleProvider>
              <LocationProvider>
                <Routes>
                  <>
                    <Route element={<NavigateOnValidCompanyNameRoute />}>
                      <Route
                        path={RoutePath[ROUTE_NAME.DYNAMIC_HOME]}
                        element={<D2Home />}
                      />
                      <Route
                        path={
                          RoutePath[ROUTE_NAME.DYNAMIC_CHECKOUT] + "/:productId"
                        }
                        element={<D2Checkout />}
                      />
                      {/* Default component for subdomain */}
                      <Route
                        path={`${RoutePath[ROUTE_NAME.DYNAMIC_PRODUCT_DETAIL]
                          }/:productId`}
                        element={<DProduct />}
                      />
                      <Route
                        path={`${RoutePath[ROUTE_NAME.DYNAMIC_INVOICE]
                          }/:invoiceId`}
                        element={<DInvoice />}
                      />
                      <Route
                        path={`${RoutePath[ROUTE_NAME.DYNAMIC_RESCHEDULE]
                          }/:invoiceId/reschedule`}
                        element={<DReschedule />}
                      />
                      <Route
                        path={`${RoutePath[ROUTE_NAME.DYNAMIC_RESCHEDULE]
                          }/:invoiceId/reschedule/detail`}
                        element={<DRescheduleDetail />}
                      />
                      <Route
                        path={`${RoutePath[ROUTE_NAME.DYNAMIC_SCHEDULE]
                          }/:productId`}
                        element={<DSchedule />}
                      />
                      <Route
                        path={RoutePath[ROUTE_NAME.DYNAMIC_PAYMENT_DETAIL]}
                        element={<DPaymentDetail />}
                      />
                      <Route
                        path={RoutePath[ROUTE_NAME.DYNAMIC_STATUS_INFO]}
                        element={<StatusInfo />}
                      />
                      <Route
                        path="*"
                        element={
                          <Navigate to={RoutePath[ROUTE_NAME.DYNAMIC_HOME]} />
                        }
                      />
                    </Route>
                    <Route
                      path={RoutePath[ROUTE_NAME.DYNAMIC_NOT_FOUND]}
                      element={<NotFound />}
                    />

                    <Route element={<NavigateOnAuth to={INITIAL_ROUTE_PATH_ON_AUTH} />}>
                      <Route path={RoutePath.LOGIN} element={<Login />} />
                      {/* <Route path={RoutePath.LOGIN} element={<MainDashboard />} /> */}
                      {/* <Route path={RoutePath.REGISTER} element={<Register />} /> */}
                    </Route>
                    <Route
                      element={
                        <RequireAuthRoute
                          allowedRoles={[
                            USER_ROLE.ADMIN,
                            USER_ROLE.STAFF,
                            USER_ROLE.GUEST,
                          ]}
                        />
                      }
                    >
                      <Route path="/admin" element={<PageContainer />}>
                        <Route path={RoutePath[ROUTE_NAME.DASHBOARD]} element={<Home />} />
                        <Route path={RoutePath[ROUTE_NAME.MORE]} element={<MorePage />} />
                        <Route path={RoutePath[ROUTE_NAME.SCHEDULE_V2]} element={<MainDashboard />} />
                        <Route path={`${RoutePath[ROUTE_NAME.SCHEDULE_V2]}/:mode`} element={<MainDashboard />} />
                        <Route path={RoutePath[ROUTE_NAME.BUSINESS]} element={<Business />} />
                        <Route path={RoutePath[ROUTE_NAME.SCHEDULE]} element={<Schedule />} />
                        <Route path={RoutePath[ROUTE_NAME.TEAM]} element={<Team />} />
                        <Route path={RoutePath[ROUTE_NAME.TEAM_MEMBER]} element={<TeamMember />} />
                        <Route path={RoutePath[ROUTE_NAME.SALES]} element={<SalePage />} />
                        <Route path={RoutePath[ROUTE_NAME.SALE_REPORT]} element={<SaleReportPage />} />
                        <Route path={RoutePath[ROUTE_NAME.SALE_LIST]} element={<SaleListPage />} />
                        <Route path={RoutePath[ROUTE_NAME.PERMISSION]} element={<PermissionPage />} />
                        <Route path={`${RoutePath[ROUTE_NAME.SALE_LIST]}/:transactionId?`} element={<SaleListPage />} />
                      </Route>
                      <Route
                        path={RoutePath[ROUTE_NAME.TEAM_SCHEDULED_SHIFT]}
                        element={<TeamScheduledShifts />}
                      />
                      <Route
                        path={RoutePath[ROUTE_NAME.TEAM_ADD_MEMBER]}
                        element={<TeamProfileMemberAdd />}
                      />
                      <Route
                        path={RoutePath[ROUTE_NAME.TEAM_EDIT_MEMBER] + "/:id"}
                        element={<TeamProfileMemberEdit />}
                      />
                      <Route
                        path={
                          RoutePath[ROUTE_NAME.TEAM_SCHEDULED_WORKING_HOUR] +
                          "/:userId"
                        }
                        element={<TeamScheduledWorkingHour />}
                      />
                      <Route
                        path={RoutePath[ROUTE_NAME.TEAM_SCHEDULED_TIME_OFF]}
                        element={<TeamScheduledTimeOffAdd />}
                      />
                      <Route
                        path={
                          RoutePath[ROUTE_NAME.TEAM_SCHEDULED_TIME_OFF] +
                          "/:timeOffID"
                        }
                        element={<TeamScheduledTimeOffEdit />}
                      />
                      <Route
                        path={RoutePath[ROUTE_NAME.CHOOSE_COMPANY]}
                        element={<ChooseCompany />}
                      />
                      <Route
                        path={RoutePath[ROUTE_NAME.RESET_PASSWORD]}
                        element={<ResetPassword />}
                      />
                      <Route
                        path={RoutePath[ROUTE_NAME.CREATE_BUSINESS]}
                        element={<CreateBusiness />}
                      />
                      <Route
                        path={RoutePath[ROUTE_NAME.TRANSACTION]}
                        element={<Transaction />}
                      />
                      <Route
                        path={RoutePath[ROUTE_NAME.TOP_PRODUCT]}
                        element={<TopProduct />}
                      />
                      <Route
                        path={
                          RoutePath[ROUTE_NAME.EDIT_BUSINESS] +
                          "/:businessId/edit"
                        }
                        element={<EditBusiness />}
                      />
                      {/* <Route path={RoutePath.PAGE_EDITOR} element={<PageEditor />} /> */}
                      <Route
                        path={RoutePath[ROUTE_NAME.PAGE_TEMPLATE]}
                        element={<PageTemplate />}
                      />
                      <Route
                        path={RoutePath[ROUTE_NAME.ADD_PRODUCT] + "/:productId"}
                        element={<Product />}
                      />
                      <Route
                        path={
                          RoutePath[ROUTE_NAME.EDIT_PRODUCT] + "/:productId/edit"
                        }
                        element={<Product />}
                      />
                      <Route
                        path={RoutePath[ROUTE_NAME.BANK_ACCOUNT]}
                        element={<BankAccount />}
                      />
                      <Route
                        path={RoutePath[ROUTE_NAME.BANK_ACCOUNT_OTP]}
                        element={<BankAccountOTP />}
                      />
                      <Route
                        path={RoutePath[ROUTE_NAME.WITHDRAW]}
                        element={<Withdraw />}
                      />
                      <Route
                        path={RoutePath[ROUTE_NAME.WITHDRAW_OTP]}
                        element={<WithdrawOTP />}
                      />
                      <Route
                        path={RoutePath[ROUTE_NAME.LOCATION_BUSINESS]}
                        element={<LocationBusiness />}
                      />
                      <Route
                        path={RoutePath[ROUTE_NAME.STATUS_INFO]}
                        element={<StatusInfo />}
                      />
                      <Route
                        path={RoutePath[ROUTE_NAME.MANAGE_SCHEDULE]}
                        element={<ManageSchedule />}
                      />
                      <Route
                        path={
                          RoutePath[ROUTE_NAME.TRANSACTION] + "/:transactionId"
                        }
                        element={<TransactionDetail />}
                      />
                      <Route
                        path={RoutePath[ROUTE_NAME.PRODUCT] + "/:productId/:mode"}
                        element={<DetailProduct />}
                      />
                    </Route>
                    <Route
                      path={RoutePath[ROUTE_NAME.REQUEST_FORGOT_PASSWORD]}
                      element={<RequestForgotPassword />}
                    />
                    <Route
                      path={RoutePath[ROUTE_NAME.VERIFY_EMAIL]}
                      element={<VerifyEmail />}
                    />
                    <Route
                      path={RoutePath[ROUTE_NAME.FORGOT_PASSWORD]}
                      element={<ForgotPassword />}
                    />
                    <Route path="*" element={<NotFound />} />
                    <Route path="/" element={<Navigate to={RoutePath.LOGIN} />} />
                  </>
                </Routes>
              </LocationProvider>
            </ManageScheduleProvider>
          </StatusInfoProvider>
        </BottomNavProvider>
      </DebugRouter>
    </BrowserRouter>
  );
};

export default RouteList;
