import React, { useEffect, useState } from "react";
import ModalTemplate, { ModalTemplateProps } from "./ModalTemplate";
import { Box, Typography } from "@mui/material";
import { COLOR } from "../../utils/color";
import TextInput from "../Forms/TextInput/TextInput";
import CustomButton from "../CustomButton/CustomButton";
import TextArea from "../Forms/TextArea/TextArea";
import { errorLogger } from "../../utils/logger";
import useDebouncedFunction from "../../hooks/useDebouncedFunction";
import { enqueueSnackbar } from "notistack";
import { DEFAULT_SNACKBAR_PROPS } from "../../utils/constant";
import { useCreateNewResource } from "../../query/mutations";
import { useSelector } from "react-redux";
import { RootReducerState } from "../../redux/reducers";

type ResourceType = {
  name: string;
  description: string;
};

interface AddResourceModalPropsI {
  modalProps: Omit<ModalTemplateProps, "children">;
  initialData?: ResourceType;
  onSaveFinish: () => void;
  onCancel: () => void;
}

const AddResourceModal: React.FC<AddResourceModalPropsI> = ({
  modalProps,
  initialData,
  onSaveFinish,
  onCancel,
}) => {
  const [resourceData, setResourceData] = useState<ResourceType>({
    name: "",
    description: "",
    ...initialData,
  });
  const isLoading = false;
  const [errors, setErrors] = useState<{ name: string }>({ name: "" });

  const addResourceMutation = useCreateNewResource();

  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );
  const companyId = userReducer.data.companyId;

  useEffect(() => {
    if (!modalProps.open) return;
    setResourceData({
      name: "",
      description: "",
      ...initialData,
    });
    setErrors({ name: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalProps.open]);

  const validateName = () => {
    if (!resourceData.name.trim()) {
      setErrors((prev) => ({ ...prev, name: "Nama tidak boleh kosong" }));
      return false;
    }
    setErrors((prev) => ({ ...prev, name: "" }));
    return true;
  };

  const handleSave = async () => {
    if (!validateName()) return;

    try {
      await addResourceMutation.mutateAsync({
        companyID: companyId || "",
        payload: {
          resourceName: resourceData.name,
          description: resourceData.description,
          productIDs: [],
          companyID: companyId || "",
        },
      });
      enqueueSnackbar({
        ...DEFAULT_SNACKBAR_PROPS,
        variant: "success",
        message: "Sumber daya berhasil ditambahkan",
      });
    } catch (error) {
      errorLogger(error);
    } finally {
      onSaveFinish();
    }
  };

  const handleResourceDataChange = (
    field: keyof ResourceType,
    value: string
  ) => {
    setResourceData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const debouncedSave = useDebouncedFunction(handleSave, 500);
  return (
    <ModalTemplate
      {...modalProps}
      desktopHeight="60vh"
      desktopWidth="40vw"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...modalProps.sx,
      }}
      header={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
            flex: 1,
            borderBottom: `1px solid ${COLOR.neutral300}`,
            gap: 1,
          }}
        >
          <Typography variant="h6">Tambah Sumber Daya</Typography>
        </Box>
      }
      footer={
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: 1,
            p: 2,
            borderTop: `1px solid ${COLOR.neutral300}`,
          }}
        >
          <CustomButton
            disabled={isLoading}
            variant="outlined"
            onClick={() => {
              onCancel();
            }}
          >
            Batal
          </CustomButton>
          <CustomButton
            disabled={errors.name.length > 0 || isLoading}
            onClick={() => {
              debouncedSave();
            }}
          >
            Simpan
          </CustomButton>
        </Box>
      }
    >
      <Box pt={2} pb={5}>
        <Box px={2} pt={1} pb={2} display="flex" flexDirection="column" gap={1}>
          <TextInput
            title="Nama"
            required
            sx={{ flex: 1 }}
            textInputProps={{
              placeholder: "cth. Meeting (optional)",
              value: resourceData.name,
              onChange: (e) => handleResourceDataChange("name", e.target.value),
              onBlur: validateName,
            }}
            helper={
              errors.name
                ? { text: errors.name, color: COLOR.danger500 }
                : undefined
            }
          />

          <TextArea
            title="Deskripsi"
            additionalPrefix={
              <Typography
                variant="caption"
                fontWeight={400}
                color={COLOR.neutral400}
              >
                (Optional)
              </Typography>
            }
            sx={{ flex: 1 }}
            textAreaProps={{
              value: resourceData.description,
              placeholder: "Tambahkan deskripsi atau catatan",
              onChange: (e) =>
                handleResourceDataChange("description", e.target.value),
              onBlur: validateName,
            }}
          />
        </Box>
      </Box>
    </ModalTemplate>
  );
};

export default AddResourceModal;
