import RouteList from "./pages/RouteList";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClientProvider } from "react-query";
import { store, persistor } from "./redux";
import queryClient from "./query/queryClient";
import ThemeWrapper from "./theme/ThemeWrapper";
import { useEffect } from "react";
import ENV from "./utils/env";
import { localStorageSize } from "./utils/helper";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ConfirmationDialog from "./components/ConfirmationDialog/ConfirmationDialog";
import { SnackbarProvider } from "notistack";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeWrapper>
          <GoogleOAuthProvider clientId={ENV.GOOGLE_AUTH_CLIENT_ID}>
            <QueryClientProvider client={queryClient}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <SnackbarProvider maxSnack={3}>
                  <RouteList />
                </SnackbarProvider>
                <ConfirmationDialog />
              </LocalizationProvider>
            </QueryClientProvider>
          </GoogleOAuthProvider>
        </ThemeWrapper>
      </PersistGate>
    </Provider>
  );
};

export default App;
