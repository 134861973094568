import React from 'react';
import OpacityButton from '../OpacityButton/OpacityButton';
import { buttonContainer, buttonItemContainer, buttonText } from './MenuNextButton.styles';
import { Box, Typography } from '@mui/material';

export interface MenuNextButtonPropsI {
  Icon: React.ElementType;
  title: string;
  onClick?: () => void;
}
const MenuNextButton: React.FC<MenuNextButtonPropsI> = (props) => {
  return (
    <OpacityButton sx={buttonContainer} onClick={props.onClick}>
      <Box sx={buttonItemContainer}>
        <props.Icon size="24px" />
        <Typography sx={buttonText}>{props.title}</Typography>
      </Box>
      <span className='arrow-right' />
    </OpacityButton>
  )
}

export default MenuNextButton;