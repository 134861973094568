import Joi from "joi";
import { PHONE_LENGTH } from "../utils/constant";

export const customerDataSchema = Joi.object({
  name: Joi.string().min(3).allow(""),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .allow(""),
  phoneNumber: Joi.string().min(PHONE_LENGTH.MIN).max(PHONE_LENGTH.MAX).allow(""),
});
