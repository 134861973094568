import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Fade, Popper, TextField, SxProps } from '@mui/material';
import { HexColorPicker } from 'react-colorful';
import debounce from 'lodash/debounce'; // Import debounce from lodash
import { COLOR } from '../../utils/color';

const isValidHex = (hex: string) => /^#([0-9A-F]{3}|[0-9A-F]{6})$/i.test(hex);

interface CustomColorPickerProps {
  color: string;
  onChangeColor: (color: string) => void;
  sx?: SxProps;
}

const CustomColorPicker: React.FC<CustomColorPickerProps> = ({
  color,
  onChangeColor,
  sx,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [currentColor, setCurrentColor] = useState<string>(color);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const pickerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setCurrentColor(color);
  }, [color]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleHexInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newHexColor = event.target.value;
    let hexColor = !newHexColor.startsWith('#') ? `#${newHexColor}` : newHexColor;
    const validHex = isValidHex(hexColor);
    if (validHex) {
      onChangeColor(hexColor);
      setCurrentColor(hexColor)
    } else {
      setCurrentColor(newHexColor)
    }
  };

  // Debounce the color picker change handler
  const debouncedColorPickerChange = useCallback(
    debounce((newColor: string) => {
      onChangeColor(newColor);
    }, 300), // Adjust the debounce delay as needed
    [onChangeColor]
  );

  const handleColorPickerChange = (newColor: string) => {
    setCurrentColor(newColor);
    debouncedColorPickerChange(newColor); // Use the debounced function
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target as Node) && anchorEl && !anchorEl.contains(event.target as Node)) {
      setOpen(false);
    }
  }, [anchorEl]);

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, anchorEl, handleClickOutside]);

  return (
    <>
      <Box
        component="button"
        onClick={handleClick}
        sx={{
          backgroundColor: color,
          width: '80px',
          aspectRatio: '16 / 9',
          cursor: 'pointer',
          borderRadius: '4px',
          border: `1px solid ${COLOR.neutral500}`,
          ...sx,
        }}
      />

      <Popper open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              ref={pickerRef}
              sx={{
                border: 1,
                p: 2,
                bgcolor: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: 3,
              }}
            >
              <HexColorPicker color={currentColor} onChange={handleColorPickerChange} />
              <TextField
                fullWidth
                variant="outlined"
                label="HEX"
                value={currentColor}
                onChange={handleHexInputChange}
                sx={{ mt: 2 }}
              />
            </Box>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default CustomColorPicker;
