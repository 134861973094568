import { Dayjs } from "dayjs";

export type Day = 'SUNDAY' | 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY';

export const DAY: { [key in Day]: Day } = {
  SUNDAY: 'SUNDAY',
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
} as const;

export const generateDaysInRange = (start: Dayjs, end: Dayjs): Dayjs[] => {
  const days: Dayjs[] = [];
  let currentDay = start;

  while (currentDay.isBefore(end, "day")) {
    days.push(currentDay);
    currentDay = currentDay.add(1, "day");
  }

  return days;
};
