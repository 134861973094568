import { Box, Typography, Alert, Divider } from "@mui/material";
import React, { useState, useCallback, Fragment, useEffect } from "react";
import { Day, DAY } from "../../utils/date";
import dayjs, { Dayjs } from "dayjs";
import SingleSelect from "../../components/Forms/SingleSelect/SingleSelect";
import { MobileDatePicker } from "@mui/x-date-pickers";
import TimeScheduleButton from "../../components/TimeScheduleButton/TimeScheduleButton";
import CustomButton from "../../components/CustomButton/CustomButton";
import FormButton from "../../components/Forms/FormButton/FormButton";
import SubMenuHeader from "../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft } from "react-icons/fa";
import {
  DEFAULT_SNACKBAR_PROPS,
  ROUTE_NAME,
  RoutePath,
} from "../../utils/constant";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { COLOR } from "../../utils/color";
import ShiftScheduleBottomSheet, {
  ShiftSchedule,
  ShiftScheduleBottomSheetPropsI,
} from "../../components/BottomSheets/ShiftScheduleBottomSheet";
import { cloneDeep, isEmpty } from "lodash";
import { errorLogger } from "../../utils/logger";
import { TimeRange } from "../TeamScheduledShifts/TeamScheduledShifts";
import { useGetTeamMemberRegularShift } from "../../query/queries";
import { useUpsertTeamMemberRegularShift } from "../../query/mutations";
import { enqueueSnackbar } from "notistack";

interface RegularShiftSchedule {
  teamMemberID?: string;
  teamMemberName: string;
  shiftStartDate: Date | null;
  shiftEndDate: Date | null;
  scheduleType?: "1" | "2" | "3" | "4";
  shifts: {
    [key: string]: TimeRange[];
  };
}

enum PickerType {
  START_DATE = "startDate",
  END_DATE = "endDate",
}

const ERROR_MESSAGES = {
  END_DATE_BEFORE_START: "End date cannot be before start date",
} as const;

interface BottomSheetState {
  shiftSchedule:
    | ({
        name: string;
        day: string;
      } & ShiftScheduleBottomSheetPropsI["data"])
    | null;
}

const TeamScheduledWorkingHour: React.FC = () => {
  const navigate = useNavigate();
  const DATE_FORMAT = "ddd, DD MMMM YYYY";
  const { userId } = useParams();
  const [params] = useSearchParams();
  const selectedDate = params.get("date");
  const [isLoading, setIsLoading] = useState(false);
  const [openPicker, setOpenPicker] = useState<PickerType | null>(null);
  const [error, setError] = useState<string>("");
  const [bottomSheetState, setBottomSheetState] = useState<BottomSheetState>({
    shiftSchedule: null,
  });
  const { data } = useGetTeamMemberRegularShift(userId!, {
    startDate: selectedDate ?? dayjs().format("YYYY-MM-DD"),
  });
  const upsertTeamMemberRegularShift = useUpsertTeamMemberRegularShift();

  const [shiftSchedule, setShiftSchedule] = useState<RegularShiftSchedule>({
    teamMemberID: userId,
    teamMemberName: "UUIAA",
    shiftStartDate: dayjs(selectedDate).add(1, "day").toDate(),
    shiftEndDate: null,
    scheduleType: "1",
    shifts: {
      [DAY.MONDAY]: [{ startTime: "09:00", endTime: "17:00", duration: 480 }],
      [DAY.TUESDAY]: [{ startTime: "09:00", endTime: "17:00", duration: 480 }],
      [DAY.WEDNESDAY]: [
        { startTime: "09:00", endTime: "17:00", duration: 480 },
      ],
      [DAY.THURSDAY]: [{ startTime: "09:00", endTime: "17:00", duration: 480 }],
      [DAY.FRIDAY]: [{ startTime: "09:00", endTime: "17:00", duration: 480 }],
      [DAY.SATURDAY]: [],
      [DAY.SUNDAY]: [],
    },
  });

  useEffect(() => {
    if (data) {
      const shiftData: RegularShiftSchedule = {
        ...data,
        shiftStartDate: data.shiftStartDate
          ? dayjs(data.shiftStartDate).toDate()
          : null,
        shiftEndDate: data?.shiftEndDate
          ? dayjs(data?.shiftEndDate).toDate()
          : null,
      };
      setShiftSchedule(shiftData);
    }
  }, [data]);

  const validateEndDate = (endDate: Dayjs): boolean => {
    if (endDate.isBefore(dayjs(shiftSchedule.shiftStartDate))) {
      setError(ERROR_MESSAGES.END_DATE_BEFORE_START);
      return false;
    }
    return true;
  };

  const clearError = () => setError("");

  const handleDateChange = (newDate: Dayjs | null, type: PickerType) => {
    if (!newDate) {
      if (type === PickerType.END_DATE) {
        setShiftSchedule((prev) => ({ ...prev, shiftEndDate: null }));
        clearError();
      }
      setOpenPicker(null);
      return;
    }

    if (type === PickerType.END_DATE && !validateEndDate(newDate)) {
      return;
    }

    setShiftSchedule((prev) => ({
      ...prev,
      [type === PickerType.START_DATE ? "shiftStartDate" : "shiftEndDate"]:
        newDate.toDate(),
    }));
    clearError();
    setOpenPicker(null);
  };

  const handleEndsChange = (value: string | undefined) => {
    if (value === "custom") {
      setOpenPicker(PickerType.END_DATE);
    } else if (value === "never") {
      setShiftSchedule((prev) => ({ ...prev, shiftEndDate: null }));
      clearError();
    }
  };

  const handleBottomSheetDismiss = useCallback(() => {
    setBottomSheetState((prev) => ({ ...prev, shiftSchedule: null }));
  }, []);

  const handleShiftButtonClick = useCallback(
    (day: Day, shifts: TimeRange[]) => {
      setBottomSheetState({
        shiftSchedule: {
          name: shiftSchedule.teamMemberName,
          date: dayjs(shiftSchedule.shiftStartDate).toDate(),
          id: day,
          day,
          shifts,
        },
      });
    },
    [shiftSchedule.teamMemberName]
  );

  const handleApplySchedule = useCallback(
    (schedules: ShiftSchedule) => {
      if (!bottomSheetState.shiftSchedule) return;

      const shifts = cloneDeep(shiftSchedule.shifts);
      shifts[bottomSheetState.shiftSchedule.day] = schedules.shifts;

      setShiftSchedule((prev) => ({ ...prev, shifts }));

      handleBottomSheetDismiss();
    },
    [bottomSheetState.shiftSchedule, handleBottomSheetDismiss]
  );

  const handleDeleteSchedule = useCallback(() => {
    if (!bottomSheetState.shiftSchedule) return;

    const shifts = cloneDeep(shiftSchedule.shifts);
    shifts[bottomSheetState.shiftSchedule.day] = [];

    setShiftSchedule((prev) => ({ ...prev, shifts }));

    handleBottomSheetDismiss();
  }, [bottomSheetState.shiftSchedule, handleBottomSheetDismiss]);

  const handleSave = async () => {
    try {
      if (!userId) {
        throw new Error("User ID is required");
      }

      await upsertTeamMemberRegularShift.mutateAsync({
        shiftStartDate: dayjs(shiftSchedule.shiftStartDate).format(
          "YYYY-MM-DD"
        ),
        shiftEndDate: shiftSchedule.shiftEndDate
          ? dayjs(shiftSchedule.shiftEndDate).format("YYYY-MM-DD")
          : "",
        shifts: shiftSchedule.shifts,
        teamMemberID: userId,
      });

      enqueueSnackbar({
        ...DEFAULT_SNACKBAR_PROPS,
        variant: "success",
        message: "Berhasil menyimpan jadwal shift",
      });

      navigate(RoutePath[ROUTE_NAME.TEAM_SCHEDULED_SHIFT], {
        replace: true,
      });
    } catch (error) {
      errorLogger(error);
      enqueueSnackbar({
        ...DEFAULT_SNACKBAR_PROPS,
        variant: "error",
        message: "Gagal menyimpan jadwal shift",
      });
    }
  };

  const renderShiftScheduleBottomSheet = () => (
    <ShiftScheduleBottomSheet
      isLoading={isLoading}
      open={!isEmpty(bottomSheetState.shiftSchedule)}
      onDismiss={handleBottomSheetDismiss}
      header={
        <Box sx={{ px: 2 }}>
          <Typography variant="h6">
            Jadwal Shift {bottomSheetState.shiftSchedule?.name} di hari (
            {bottomSheetState.shiftSchedule?.day})"
          </Typography>
        </Box>
      }
      onApply={handleApplySchedule}
      onDelete={handleDeleteSchedule}
      data={bottomSheetState.shiftSchedule}
    />
  );

  return (
    <Box>
      <Box
        sx={{
          height: "calc(100vh - 72px)",
          overflowY: "auto",
          pb: 5,
        }}
      >
        <SubMenuHeader
          leftNav={{
            icon: <FaArrowLeft />,
            onClick: () => {
              navigate(-1);
            },
          }}
          text={`Set ${shiftSchedule?.teamMemberName}'s regular shifts`}
        />
        <Box sx={{ px: 2, pt: 2, pb: 5 }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pb: 2 }}>
            {/* <SingleSelect
              title="Schedule Type"
              optionProps={{
                value: shiftSchedule.scheduleType ?? "1",
                onChange: (value) =>
                  setShiftSchedule((prev) => ({
                    ...prev,
                    scheduleType: (value as "1" | "2" | "3" | "4") || "1",
                  })),
                options: [
                  { label: "Every Week", value: "1" },
                  { label: "Every 2 Week", value: "2" },
                  { label: "Every 3 Week", value: "3" },
                  { label: "Every 4 Week", value: "4" },
                ],
              }}
            /> */}
            <FormButton
              title="Start date"
              endEndorment={<span className="arrow-down" />}
              FormButtonProps={{
                onClick: () => setOpenPicker(PickerType.START_DATE),
              }}
            >
              {dayjs(shiftSchedule.shiftStartDate).format(DATE_FORMAT)}
            </FormButton>
            <SingleSelect
              title="Ends"
              optionProps={{
                value: shiftSchedule.shiftEndDate
                  ? dayjs(shiftSchedule.shiftEndDate).format(DATE_FORMAT)
                  : "never",
                onChange: (value) => handleEndsChange(value),
                options: [
                  { label: "Never", value: "never" },
                  { label: "Specific Date", value: "custom" },
                  ...(shiftSchedule.shiftEndDate
                    ? [
                        {
                          label: dayjs(shiftSchedule.shiftEndDate).format(
                            DATE_FORMAT
                          ),
                          value: dayjs(shiftSchedule.shiftEndDate).format(
                            DATE_FORMAT
                          ),
                        },
                      ]
                    : []),
                ],
              }}
              error={!!error}
              helper={{
                color: COLOR.danger500,
                text: error,
              }}
            />
            <Alert severity="info">
              Team members will not be scheduled on business closed periods.
            </Alert>
          </Box>
          <Divider />
        </Box>

        {Object.entries(shiftSchedule.shifts).map(
          ([day, dayShifts], index, array) => (
            <Fragment key={day}>
              <TimeScheduleButton
                name={day}
                timeRanges={dayShifts}
                onClick={() => handleShiftButtonClick(day as Day, dayShifts)}
              />
              {index !== array.length - 1 && <Divider sx={{ mx: 2 }} />}
            </Fragment>
          )
        )}
      </Box>

      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          p: 2,
          backgroundColor: "white",
          borderTop: "1px solid #e0e0e0",
        }}
      >
        <CustomButton fullWidth onClick={handleSave}>
          Save
        </CustomButton>
      </Box>
      <MobileDatePicker
        sx={{ display: "none" }}
        open={openPicker === PickerType.START_DATE}
        onClose={() => setOpenPicker(null)}
        label="Start date"
        value={dayjs(shiftSchedule.shiftStartDate)}
        onChange={(date) => handleDateChange(date, PickerType.START_DATE)}
        minDate={dayjs().add(1, "day")}
        slotProps={{
          dialog: {
            sx: { zIndex: 1300 },
          },
        }}
      />
      <MobileDatePicker
        sx={{ display: "none" }}
        open={openPicker === PickerType.END_DATE}
        onClose={() => {
          setOpenPicker(null);
          if (!shiftSchedule.shiftEndDate) {
            setShiftSchedule((prev) => ({ ...prev, shiftEndDate: null }));
          }
        }}
        label="End date"
        value={
          shiftSchedule.shiftEndDate ? dayjs(shiftSchedule.shiftEndDate) : null
        }
        onChange={(date) => handleDateChange(date, PickerType.END_DATE)}
        minDate={dayjs(shiftSchedule.shiftStartDate)}
      />

      {renderShiftScheduleBottomSheet()}
    </Box>
  );
};

export default TeamScheduledWorkingHour;
