import { Theme } from "@emotion/react";
import { Box, SxProps, Typography } from "@mui/material";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { COLOR } from "../../../utils/color";
import OpacityButton from "../../OpacityButton/OpacityButton";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useElementHeight } from "../../../hooks/useElementHeight";
import { ZINDEX } from "../../../utils/constant";

type NavPropType = {
  icon?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  disableOpacity?: boolean;
}
interface SubMenuHeaderPropsI {
  leftNav: NavPropType;
  rightNav?: NavPropType;
  text?: string;
  children?: React.ReactNode;
}
const SubMenuHeader = forwardRef<HTMLElement, SubMenuHeaderPropsI>((props, ref) => {
  const { leftNav, rightNav } = props;
  const iconStyle: SxProps<Theme> = {
    width: 50,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  }

  const handleOnLeftNavClick = leftNav.onClick || (() => {
    navigate(-1);
  })

  const leftIcon: SxProps<Theme> = {
    left: 5,
  }
  const rightIcon: SxProps<Theme> = {
    right: 0,
    pointerEvents: rightNav?.onClick ? 'auto' : 'none',
  }
  const leftIconRef = useRef<HTMLButtonElement | null>(null);
  const rightIconRef = useRef<HTMLButtonElement | null>(null);
  const iconWidth = Math.max(
    (leftIconRef?.current?.clientWidth) || 0,
    (rightIconRef?.current?.clientWidth) || 0,
    50
  )
  const navigate = useNavigate();
  const internalRef = useRef<HTMLElement | null>(null);
  const height = useElementHeight(internalRef);
  useImperativeHandle(ref, () => internalRef.current as HTMLElement);
  return (
    <>
      <Box sx={{ display: 'flex', height: `${height}px`, width: '100%' }}></Box>
      <Box ref={internalRef} sx={{ position: 'fixed', zIndex: ZINDEX.HEADER, top: 0, left: 0, width: '100%', backgroundColor: 'white', display: 'flex', alignItems: 'center', borderBottom: `1px solid ${COLOR.neutral200}`, minHeight: '65px' }}>
        <OpacityButton
          onClick={handleOnLeftNavClick}
          sx={{
            ...iconStyle,
            ...leftIcon,
            ...leftNav.sx,
            ...(leftNav?.icon ? {} : { display: 'none' })
          }}
          disabled={leftNav.disabled}
          disableOpacity={leftNav.disableOpacity}
          ref={leftIconRef}
        >
          {leftNav.icon || <FaArrowLeft />}
        </OpacityButton>
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', mx: `${iconWidth}px` }}>
          {props.text ? <Typography variant="body1" fontWeight={600} textTransform="capitalize" sx={{ textAlign: 'center' }}>
            {props.text}
          </Typography> : props.children}
        </Box>
        <OpacityButton
          onClick={rightNav?.onClick}
          sx={{
            ...iconStyle,
            ...rightIcon,
            ...rightNav?.sx,
            ...(rightNav?.icon ? {} : { display: 'none' })
          }}
          disabled={rightNav?.disabled}
          disableOpacity={rightNav?.disableOpacity}
          ref={rightIconRef}
        >
          {rightNav?.icon}
        </OpacityButton>
      </Box>
    </>
  )
});

export default SubMenuHeader;
