import { FiHome } from "react-icons/fi";
import { IoPeopleOutline, IoStorefrontOutline } from "react-icons/io5";
import { MdCancel, MdOutlineMail, MdOutlineSchedule } from "react-icons/md";
import { FiCalendar } from "react-icons/fi";
import { BottomNavItem } from "../components/BottomNav";
import { OptionsObject } from "notistack";
import {
  GetProductByIdResponse,
  PageTemplateType,
  ScheduleEventPaymentStatus,
  ScheduleEventStatus,
  ScheduleEventType,
} from "../api/request.types";
import {
  FaCheckCircle,
  FaDiscord,
  FaEtsy,
  FaFacebook,
  FaFlag,
  FaInstagram,
  FaLink,
  FaLinkedin,
  FaPinterest,
  FaPlay,
  FaSnapchat,
  FaThumbsUp,
  FaTiktok,
  FaTwitch,
  FaTwitter,
  FaVimeo,
  FaYoutube,
} from "react-icons/fa";
import { DurationFormat, FrequencyDetail, RangeTypeLabelKey, SideNavItem } from "../types/globalTypes";
import { FaLocationDot } from "react-icons/fa6";
import { RiCalendarScheduleLine } from "react-icons/ri";
import { LuLayoutGrid, LuTag } from "react-icons/lu";
import { GrSchedule } from "react-icons/gr";
import { TbCash, TbFileText } from "react-icons/tb";
import { PiCurrencyCircleDollarLight } from "react-icons/pi";
import { BsGraphUpArrow } from "react-icons/bs";
import { DAY } from "./date";
import { IconType } from "react-icons";
import { GoPasskeyFill } from "react-icons/go";

export enum ROUTE_NAME {
  DASHBOARD = "DASHBOARD",
  SALES = "SALES",
  MORE = "MORE",
  SCHEDULE = "SCHEDULE",
  SCHEDULE_V2 = "SCHEDULE_V2",
  BUSINESS = "BUSINESS",
  TEAM = "TEAM",
  TEAM_MEMBER = "TEAM_MEMBER",
  TEAM_ADD_MEMBER = "TEAM_ADD_MEMBER",
  TEAM_EDIT_MEMBER = "TEAM_EDIT_MEMBER",
  TEAM_SCHEDULED_SHIFT = "TEAM_SCHEDULED_SHIFT",
  TEAM_SCHEDULED_WORKING_HOUR = "TEAM_SCHEDULED_WORKING_HOUR",
  TEAM_SCHEDULED_TIME_OFF = "TEAM_SCHEDULED_TIME_OFF",
  CREATE_BUSINESS = "CREATE_BUSINESS",
  BANK_ACCOUNT = "BANK_ACCOUNT",
  BANK_ACCOUNT_OTP = "BANK_ACCOUNT_OTP",
  WITHDRAW = "WITHDRAW",
  WITHDRAW_OTP = "WITHDRAW_OTP",
  UNAUTHORIZED = "UNAUTHORIZED",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  LOCATION_BUSINESS = "LOCATION_BUSINESS",
  PAGE_EDITOR = "PAGE_EDITOR",
  PAGE_PREVIEW = "PAGE_PREVIEW",
  PAGE_TEMPLATE = "PAGE_TEMPLATE",
  ADD_PRODUCT = "ADD_PRODUCT",
  EDIT_PRODUCT = "EDIT_PRODUCT",
  STATUS_INFO = "STATUS_INFO",
  MANAGE_SCHEDULE = "MANAGE_SCHEDULE",
  DYNAMIC_PRODUCT_DETAIL = "DYNAMIC_PRODUCT_DETAIL",
  DYNAMIC_SCHEDULE = "DYNAMIC_SCHEDULE",
  DYNAMIC_HOME = "DYNAMIC_HOME",
  DYNAMIC_NOT_FOUND = "DYNAMIC_NOT_FOUND",
  DYNAMIC_INVOICE = "DYNAMIC_INVOICE",
  DYNAMIC_RESCHEDULE = "DYNAMIC_RESCHEDULE",
  DYNAMIC_RESCHEDULE_DETAIL = "DYNAMIC_RESCHEDULE_DETAIL",
  DYNAMIC_PAYMENT_DETAIL = "DYNAMIC_PAYMENT_DETAIL",
  DYNAMIC_STATUS_INFO = "DYNAMIC_STATUS_INFO",
  DYNAMIC_CHECKOUT = "DYNAMIC_CHECKOUT",
  TRANSACTION = "TRANSACTION",
  TOP_PRODUCT = "TOP_PRODUCT",
  PRODUCT = "PRODUCT",
  EDIT_BUSINESS = "EDIT_BUSINESS",
  CHOOSE_COMPANY = "CHOOSE_COMPANY",
  RESET_PASSWORD = "RESET_PASSWORD",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  REQUEST_FORGOT_PASSWORD = "REQUEST_FORGOT_PASSWORD",
  SALE_REPORT = "SALE_REPORT",
  SALE_LIST = "SALE_LIST",
  PERMISSION = "PERMISSION",
}

export const FONT_COLOR = "black";
export type RoutePathType = { [key in ROUTE_NAME]: string };

export const RoutePath: RoutePathType = {
  // Static admin routes
  [ROUTE_NAME.SALES]: "/admin/sales",
  [ROUTE_NAME.SALE_REPORT]: "/admin/sales/report",
  [ROUTE_NAME.SALE_LIST]: "/admin/sales/list",
  [ROUTE_NAME.DASHBOARD]: "/admin/dashboard",
  [ROUTE_NAME.TEAM]: "/admin/team",
  [ROUTE_NAME.TEAM_ADD_MEMBER]: "/admin/team/add",
  [ROUTE_NAME.TEAM_EDIT_MEMBER]: "/admin/team/edit",
  [ROUTE_NAME.TEAM_SCHEDULED_SHIFT]: "/admin/team/scheduled-shifts",
  [ROUTE_NAME.TEAM_SCHEDULED_WORKING_HOUR]:
    "/admin/team/scheduled-shifts/working-hours",
  [ROUTE_NAME.TEAM_SCHEDULED_TIME_OFF]: "/admin/team/scheduled-shifts/time-off",
  [ROUTE_NAME.TEAM_MEMBER]: "/admin/team/members",
  [ROUTE_NAME.MORE]: "/admin/more",
  [ROUTE_NAME.CHOOSE_COMPANY]: "/admin/company",
  [ROUTE_NAME.RESET_PASSWORD]: "/admin/reset-password",
  [ROUTE_NAME.REQUEST_FORGOT_PASSWORD]: "/admin/request-forgot-password",
  [ROUTE_NAME.SCHEDULE]: "/admin/schedule",
  [ROUTE_NAME.SCHEDULE_V2]: "/admin/schedule_v2",
  [ROUTE_NAME.BUSINESS]: "/admin/business",
  [ROUTE_NAME.EDIT_BUSINESS]: "/admin/business",
  [ROUTE_NAME.CREATE_BUSINESS]: "/admin/business/create",
  [ROUTE_NAME.LOCATION_BUSINESS]: "/admin/business/location",
  [ROUTE_NAME.PAGE_PREVIEW]: "/admin/business/preview",
  [ROUTE_NAME.PAGE_TEMPLATE]: "/admin/business/template",
  [ROUTE_NAME.ADD_PRODUCT]: "/admin/product/add-product",
  [ROUTE_NAME.EDIT_PRODUCT]: "/admin/product",
  [ROUTE_NAME.BANK_ACCOUNT]: "/admin/bankAccount",
  [ROUTE_NAME.BANK_ACCOUNT_OTP]: "/admin/bankAccount/otp",
  [ROUTE_NAME.WITHDRAW]: "/admin/withdraw",
  [ROUTE_NAME.WITHDRAW_OTP]: "/admin/withdraw/otp",
  [ROUTE_NAME.UNAUTHORIZED]: "/admin/unauthorized",
  [ROUTE_NAME.PAGE_EDITOR]: "/admin/page-editor",
  [ROUTE_NAME.STATUS_INFO]: "/admin/status-info",
  [ROUTE_NAME.TRANSACTION]: "/admin/transaction",
  [ROUTE_NAME.TOP_PRODUCT]: "/admin/top-product",
  [ROUTE_NAME.PRODUCT]: "/admin/product",
  [ROUTE_NAME.PERMISSION]: "/admin/permission",

  // Static Unauthorized
  [ROUTE_NAME.LOGIN]: "/login",
  [ROUTE_NAME.REGISTER]: "/register",
  [ROUTE_NAME.VERIFY_EMAIL]: "/verify-email",
  [ROUTE_NAME.FORGOT_PASSWORD]: "/forgot-password",

  // Dynamic company domain routes
  [ROUTE_NAME.DYNAMIC_HOME]: "/:companyDomainName",
  [ROUTE_NAME.DYNAMIC_RESCHEDULE_DETAIL]: "/:companyDomainName/inv",
  [ROUTE_NAME.DYNAMIC_PRODUCT_DETAIL]: "/:companyDomainName/detail",
  [ROUTE_NAME.DYNAMIC_SCHEDULE]: "/:companyDomainName/schedule",
  [ROUTE_NAME.MANAGE_SCHEDULE]: "/:companyDomainName/manage-schedule",
  [ROUTE_NAME.DYNAMIC_INVOICE]: "/:companyDomainName/inv",
  [ROUTE_NAME.DYNAMIC_RESCHEDULE]: "/:companyDomainName/inv",
  [ROUTE_NAME.DYNAMIC_PAYMENT_DETAIL]: "/:companyDomainName/payment",
  [ROUTE_NAME.DYNAMIC_STATUS_INFO]: "/:companyDomainName/info",
  [ROUTE_NAME.DYNAMIC_CHECKOUT]: "/:companyDomainName/checkout",

  // Catch-all
  [ROUTE_NAME.DYNAMIC_NOT_FOUND]: "/not-found",
};

export const INITIAL_ROUTE_PATH_ON_AUTH = RoutePath[ROUTE_NAME.SCHEDULE_V2];

export const COOKIE = {
  TOKEN: "TOKEN",
  USER_UUID: "USER_UUID",
};

export const USER_ROLE = {
  GUEST: "guest",
  ADMIN: "admin",
  STAFF: "staff",
  USER: "user",
};

export const DEFAULT_BOTTOM_NAVIGATION: Array<BottomNavItem> = [
  {
    routeName: ROUTE_NAME.SALES,
    routePath: RoutePath[ROUTE_NAME.SALES],
    label: "Beranda",
    icon: <FiHome size={"30px"} />,
  },
  {
    routeName: ROUTE_NAME.SCHEDULE,
    routePath: RoutePath[ROUTE_NAME.SCHEDULE],
    label: "Jadwal",
    icon: <FiCalendar size={"30px"} />,
  },
  {
    routeName: ROUTE_NAME.BUSINESS,
    routePath: RoutePath[ROUTE_NAME.BUSINESS],
    label: "Bisnis",
    icon: <IoStorefrontOutline size={"30px"} />,
  },
];

export const DEFAULT_SIDE_NAVIGATION: Array<SideNavItem> = [
  {
    routeName: ROUTE_NAME.SALES,
    routePath: RoutePath[ROUTE_NAME.SALES],
    label: "Beranda",
    icon: FiHome,
  },
  {
    routeName: ROUTE_NAME.SCHEDULE_V2,
    routePath: RoutePath[ROUTE_NAME.SCHEDULE_V2],
    label: "Jadwal",
    icon: FiCalendar,
  },
  {
    routeName: ROUTE_NAME.SCHEDULE,
    routePath: RoutePath[ROUTE_NAME.SCHEDULE],
    label: "Jadwal [Lama]",
    icon: MdOutlineSchedule,
  },
  {
    routeName: ROUTE_NAME.BUSINESS,
    routePath: RoutePath[ROUTE_NAME.BUSINESS],
    label: "Bisnis",
    icon: IoStorefrontOutline,
  },
];

// Remember change the value on index.css too (since it impact bottomsheet)
export const MAX_PAGE_WIDTH = 800;
export const MIN_FONT_SIZE_FORM = 16;
export const MIN_FONT_SIZE_BUTTON_ICON = 24;

export const SX_P_M_SIZE = 3;

export enum STORAGE_KEY {
  COLOR_THEME = "color-theme",
  CREATE_BUSINESS_IMAGE_PREVIEW = "create-business-image-preview",
  BUSINESS_DATA_ERROR = "business-data-error",
  TEMPLATE_DATA = "template-data",
  LOCATION_DATA = "location-data",
  PRODUCT_IMAGES = "product-images",
  ORDER_ID = "order-id",
}

export const DEFAULT_GEOLOCATION = {
  lat: 0,
  long: 0,
};

export const TIMEZONES = ["Asia/Jakarta", "Asia/Makassar"];

export const TIMEZONE_SHORTLABEL = {
  [TIMEZONES[0]]: "WIB",
  [TIMEZONES[1]]: "WITA",
};

export const TIMEZONE_LABEL = {
  [TIMEZONES[0]]: "Waktu Indonesia Barat (WIB), UTC+07:00",
  [TIMEZONES[1]]: "Waktu Indonesia Tengah (WITA), UTC+08:00",
};

export const DAY_INDONESIA_LABEL = {
  [DAY.SUNDAY]: "Minggu",
  [DAY.MONDAY]: "Senin",
  [DAY.TUESDAY]: "Selasa",
  [DAY.WEDNESDAY]: "Rabu",
  [DAY.THURSDAY]: "Kamis",
  [DAY.FRIDAY]: "Jumat",
};

// Secondary Color based on
// https://www.canva.com/colors/color-wheel/
export const TEMPLATE_HEX_COLOR = [
  {
    primary: "#3B82F6",
    secondary: "#93C5FD",
  },
  {
    primary: "#EF4444",
    secondary: "#EB1515",
  },
  {
    primary: "#F59E0B",
    secondary: "#C57F08",
  },
  {
    primary: "#22C55E",
    secondary: "#3DDD78",
  },
  {
    primary: "#8B5CF6",
    secondary: "#692CF3",
  },
];

export const TEMPLATE_PAGE: Array<{ id: PageTemplateType; content: string }> = [
  {
    id: "Type1",
    content: "template1",
  },
];

export enum DURATION_PERIOD {
  MINUTES = "minutes",
  HOURS = "hours",
  DAYS = "days",
  WEEKS = "weeks",
}

export const DEFAULT_SNACKBAR_PROPS: OptionsObject<"default"> = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  autoHideDuration: 2000,
};

export const DEFAULT_ERR_MSG = `Terjadi kesalahan! Mohon coba beberapa saat lagi!`;

export const UNIT_TEXT = {
  [DURATION_PERIOD.DAYS]: "Hari",
  [DURATION_PERIOD.HOURS]: "Jam",
  [DURATION_PERIOD.MINUTES]: "Menit",
  [DURATION_PERIOD.WEEKS]: "Minggu",
};

export const NEEMA_URL = window.location.origin;
export const getBusinessPreviewUrl = (companyUrl: string): string => {
  return `${NEEMA_URL}/${companyUrl}`;
};

export const DEFAULT_PRODUCT_DATA: GetProductByIdResponse = {
  productID: "",
  companyID: "",
  name: "",
  description: "",
  useSinglePrice: false,
  allowRefund: false,
  disabled: false,
  duration: 0,
  durationPeriod: DURATION_PERIOD.MINUTES,
  quota: 0,
  photos: [],
  photosSignedURL: [],
  location: {
    mapLocation: "",
    completeAddress: "",
    useManualLocation: false,
    longitude: 0,
    latitude: 0,
    timezone: "",
  },
  scheduleDetails: {},
  addOn: [],
  createdBy: "",
  updatedBy: "",
  createdAt: "",
  updatedAt: "",
};

export enum PRODUCT_TYPE {
  RESERVATION = "reservation",
  LINK = "link",
  SECTION = "section",
}

export type MAIN_PRODUCT_TYPE = PRODUCT_TYPE.RESERVATION | PRODUCT_TYPE.LINK;

export enum TAB_ENUM {
  THUMB = "thumb",
  CHECKOUT = "checkout",
  AVAILABILITY = "availability",
}

export enum THUMB_STYLE {
  BUTTON = "button",
  CALLOUT = "callout",
  PREVIEW = "preview",
}

export const SOCIAL_MEDIA = {
  tiktok: FaTiktok,
  instagram: FaInstagram,
  youtube: FaYoutube,
  email: MdOutlineMail,
  discord: FaDiscord,
  twitter: FaTwitter,
  twitch: FaTwitch,
  facebook: FaFacebook,
  snapchat: FaSnapchat,
  linkedin: FaLinkedin,
  pinterest: FaPinterest,
  vimeo: FaVimeo,
  etsy: FaEtsy,
  link: FaLink,
};

export const RouteIcon = {
  [ROUTE_NAME.SCHEDULE]: RiCalendarScheduleLine,
  [ROUTE_NAME.SCHEDULE_V2]: GrSchedule,
  [ROUTE_NAME.SALES]: LuTag,
  [ROUTE_NAME.SALE_LIST]: LuTag,
  [ROUTE_NAME.TEAM]: IoPeopleOutline,
  [ROUTE_NAME.BUSINESS]: IoStorefrontOutline,
  [ROUTE_NAME.MORE]: LuLayoutGrid,
  [ROUTE_NAME.TEAM_SCHEDULED_SHIFT]: RiCalendarScheduleLine,
  [ROUTE_NAME.TEAM_MEMBER]: IoPeopleOutline,
  [ROUTE_NAME.DASHBOARD]: BsGraphUpArrow,
  [ROUTE_NAME.SALE_REPORT]: TbFileText,
  [ROUTE_NAME.PERMISSION]: GoPasskeyFill,
};
export const RouteLabel = {
  [ROUTE_NAME.DASHBOARD]: 'Dasbor',
  [ROUTE_NAME.SCHEDULE]: 'Jadwal berdasarkan Servis',
  [ROUTE_NAME.SALE_LIST]: 'List Penjualan',
  [ROUTE_NAME.SALE_REPORT]: 'Laporan Penjualan',
  [ROUTE_NAME.BUSINESS]: 'Online Booking',
  [ROUTE_NAME.SCHEDULE_V2]: "Manajemen Penjadwalan",
  [ROUTE_NAME.SALES]: "Penjualan",
  [ROUTE_NAME.TEAM]: "Tim",
  [ROUTE_NAME.MORE]: "Lebih Banyak",
  [ROUTE_NAME.TEAM_SCHEDULED_SHIFT]: "Jadwal Shift",
  [ROUTE_NAME.TEAM_MEMBER]: "Anggota Tim",
  [ROUTE_NAME.TEAM_ADD_MEMBER]: "Tambah Anggota Tim",
  [ROUTE_NAME.TEAM_EDIT_MEMBER]: "Ubah Detail Anggota",
  [ROUTE_NAME.PERMISSION]: "Hak Akses",
};

export const NEEMA_LOGO_SIGNED_URL =
  "https://storage.googleapis.com/neema-b7c3b.appspot.com/public-web-assets/logo-white.png";

export const MAX_SIZE_IMAGE = 2 * 1024 * 1024; // 2MB

export const DURATION_FORMAT: DurationFormat = {
  short: {
    hour: "j",
    minute: "m",
    second: "d",
  },
  long: {
    hour: " jam",
    minute: " menit",
    second: " detik",
  },
} as const;

export const ZINDEX = {
  BOTTOM_SHEET: 15,
  HEADER: 15,
  TABLE_HEADER_LOW: 12,
  TABLE_HEADER_MID: 13,
  TABLE_HEADER_HIGH: 14,
}

export enum APPOINTMENT_FREQUENCY {
  NO_REPEAT = "no-repeat",
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  CUSTOM = "custom",
}

export const AppointmentFrequencyDetail: Record<string, FrequencyDetail> = {
  [APPOINTMENT_FREQUENCY.NO_REPEAT]: {
    qty: 0,
    unit: "day",
    ends: "never",
  },
  [APPOINTMENT_FREQUENCY.DAILY]: {
    qty: 1,
    unit: "day",
    ends: "never",
  },
  [APPOINTMENT_FREQUENCY.WEEKLY]: {
    qty: 1,
    unit: "week",
    ends: "never",
  },
  [APPOINTMENT_FREQUENCY.MONTHLY]: {
    qty: 1,
    unit: "month",
    ends: "never",
  },
};

export enum CART_PAYMENT_METHOD {
  CASH = "cash",
  OTHER = "other",
}

export const PAYMENT_METHOD_ICON = {
  [CART_PAYMENT_METHOD.CASH]: TbCash,
  [CART_PAYMENT_METHOD.OTHER]: PiCurrencyCircleDollarLight,
};

export const PAYMENT_METHOD_TITLE = {
  [CART_PAYMENT_METHOD.CASH]: "Cash",
  [CART_PAYMENT_METHOD.OTHER]: "Lainnya",
};

export const DOT_SEPARATOR = " • ";

export enum APPOINTMENT_STATUS {
  BOOKED = "booked",
  CONFIRMED = "confirmed",
  ARRIVED = "arrived",
  STARTED = "started",
  COMPLETED = "completed",
  NO_SHOW = "no_show",
}

export const backgroundByStatus: Record<string, string> = {
  [APPOINTMENT_STATUS.BOOKED]: "#3093E8",
  [APPOINTMENT_STATUS.CONFIRMED]: "#6950F3",
  [APPOINTMENT_STATUS.ARRIVED]: "#F19101",
  [APPOINTMENT_STATUS.COMPLETED]: "#00A36D",
  [APPOINTMENT_STATUS.STARTED]: "#00A36D",
  [APPOINTMENT_STATUS.NO_SHOW]: "#DA2346",
};

export const backgroundByScheduleStatus: Record<ScheduleEventStatus, string> = {
  [ScheduleEventStatus.BOOKED]: "#3093E8",
  [ScheduleEventStatus.CONFIRMED]: "#6950F3",
  [ScheduleEventStatus.ARRIVED]: "#F19101",
  [ScheduleEventStatus.COMPLETED]: "#00A36D",
  [ScheduleEventStatus.STARTED]: "#00A36D",
  [ScheduleEventStatus.NO_SHOW]: "#DA2346",
  [ScheduleEventStatus.CANCELLED]: "#DA2346",
};

export const colorByScheduleStatus: Record<ScheduleEventStatus, string> = {
  [ScheduleEventStatus.BOOKED]: "white",
  [ScheduleEventStatus.CONFIRMED]: "white",
  [ScheduleEventStatus.ARRIVED]: "white",
  [ScheduleEventStatus.COMPLETED]: "white",
  [ScheduleEventStatus.STARTED]: "white",
  [ScheduleEventStatus.NO_SHOW]: "white",
  [ScheduleEventStatus.CANCELLED]: "white",
};

export const backgroundByPaymentStatus: Record<
  ScheduleEventPaymentStatus,
  string
> = {
  [ScheduleEventPaymentStatus.PARTLY_PAID]: "#F19101",
  [ScheduleEventPaymentStatus.PAID]: "#00A36D",
  [ScheduleEventPaymentStatus.UNPAID]: "#DA2346",
};

export const backgroundByEventType: Record<ScheduleEventType, string> = {
  [ScheduleEventType.BLOCKED_TIME]: "#AAAAAA",
  [ScheduleEventType.SINGLE_RESERVATION]: "#00A36D",
};

export const IconByScheduleStatus: Record<
  ScheduleEventStatus,
  IconType | null
> = {
  [ScheduleEventStatus.BOOKED]: null,
  [ScheduleEventStatus.CONFIRMED]: FaThumbsUp,
  [ScheduleEventStatus.ARRIVED]: FaLocationDot,
  [ScheduleEventStatus.COMPLETED]: FaCheckCircle,
  [ScheduleEventStatus.STARTED]: FaPlay,
  [ScheduleEventStatus.NO_SHOW]: FaFlag,
  [ScheduleEventStatus.CANCELLED]: MdCancel,
};

export enum APPOINTMENT_TYPE {
  SINGLE_APPOINTMENT = "single_appointment",
  BLOCKED_TIME = "blocked_time",
}
export enum APPOINTMENT_CHANNEL {
  OFFLINE = "offline",
  ONLINE = "online",
}
export enum CART_PAYMENT_STATUS {
  UNPAID = "unpaid",
  PARTIALLY_PAID = "partially_paid",
  PAID = "paid",
}
export enum APPOINTMENT_CREATION_DATE {
  ANYTIME = "anytime",
  TODAY = "today",
  LAST_1_HOUR = "last_1_hour",
  LAST_2_HOUR = "last_2_hour",
  LAST_12_HOUR = "last_12_hour",
  LAST_24_HOUR = "last_24_hour",
  LAST_2_DAYS = "last_2_days",
  LAST_3_DAYS = "last_3_days",
  MORE_THAN_3_DAYS = "more_than_3_days",
}
export enum REQUESTED_TEAM_MEMBER {
  REQUESTED = "requested",
  NO_PREFERENCE = "no_preference",
}

export enum TIME_FORMAT {
  dot_HHmm = "HH.mm",
  slash_DDMMYYYY = "DD/MM/YYYY",
  HHmm = 'HH:mm',
  YYYYMMDD = 'YYYY-MM-DD',
  DDMMMYYYY = 'DD MMM YYYY',
  DDMMYYYY = 'DD-MM-YYYY',
  dddDDMMMYYYY = 'ddd, DD MMM YYYY',
  DDMMMYYYYHHmm = 'DD MMM YYYY, HH:mm',
}


export const DateRangeLabel: Record<RangeTypeLabelKey, string> = {
  'today': 'Today',
  'yesterday': 'Yesterday',
  'last_7_days': 'Last 7 Days',
  'last_30_days': 'Last 30 Days',
  'last_90_days': 'Last 90 Days',
  'last_month': 'Last Month',
  'week_to_date': 'Week to Date',
  'month_to_date': 'Month to Date',
  'quarter_to_date': 'Quarter to Date',
  'year_to_date': 'Year to Date'
}

export const PHONE_LENGTH = {
  MIN: 10,
  MAX: 14,
};