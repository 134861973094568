import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

export const ScheduledTimeOffFooter: SxProps<Theme> = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  p: 2,
  backgroundColor: "white",
  borderTop: "1px solid #e0e0e0",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 1,
}