import React, { useMemo, useState } from "react";
import { Box, CircularProgress, Skeleton, Typography } from "@mui/material";
import { COLOR } from "../../utils/color";
import { FaWallet } from "react-icons/fa";
import { formatCurrency } from "../../utils/helper";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts";
import {
  useGetCompanyByIdV2,
  useGetProductByTransaction,
  useGetTotalTransactionPerDate,
  useGetTransactionByStatus,
  useGetTransactionOverview,
  useGetUpcomingSchedules,
} from "../../query/queries";
import {
  DashboardBaseQuery,
  UpcomingScheduleQuery,
  UpcomingScheduleStatus,
} from "../../api/request.types";
import { RootReducerState } from "../../redux/reducers";
import { useSelector } from "react-redux";
import DateRangePicker from "../../components/Forms/DateRangePicker/DateRangePicker";
import dayjs, { Dayjs } from "dayjs";
import EventItem from "../../components/EventItem/EventItem";
import OpacityButton from "../../components/OpacityButton/OpacityButton";
import {
  ROUTE_NAME,
  RoutePath,
  TIMEZONE_SHORTLABEL,
} from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import MenuButton from "../../components/MenuButton/MenuButton";
import TransactionIcon from "../../assets/svg/TransactionIcon";
import ProductIcon from "../../assets/svg/ProductIcon";

const Home: React.FC = () => {
  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );
  const companyId = userReducer.data.companyId || "";
  const navigate = useNavigate();
  const today = dayjs();
  const [dateRange, setDateRange] = useState<{
    startDate: Dayjs;
    endDate: Dayjs;
  }>({ startDate: dayjs().startOf("weeks"), endDate: dayjs().endOf("weeks") });

  const dashboardQuery: DashboardBaseQuery = useMemo(
    () => ({
      startDate: dateRange.startDate.format("YYYY-MM-DD"),
      endDate: dateRange.endDate.format("YYYY-MM-DD"),
      companyID: companyId,
      sort: "desc",
    }),
    [companyId, dateRange.endDate, dateRange.startDate]
  );

  const upcomingSchedulesQuery: UpcomingScheduleQuery = useMemo(
    () => ({
      startDate: today.format("YYYY-MM-DD"),
      companyID: companyId,
      limit: 5,
      isUpcomingScheduleOnly: true,
    }),
    [companyId, today]
  );
  const totalTransactionPerDateQuery =
    useGetTotalTransactionPerDate(dashboardQuery);
  const totalTransactionPerDate = useMemo(() => {
    return totalTransactionPerDateQuery.data?.data?.data;
  }, [totalTransactionPerDateQuery.data?.data?.data]);

  const totalTransactionByStatusQuery =
    useGetTransactionByStatus(dashboardQuery);
  const totalTransactionByStatus = useMemo(() => {
    return totalTransactionByStatusQuery.data?.data?.data;
  }, [totalTransactionByStatusQuery.data?.data?.data]);

  const getUpcomingSchedulesQuery = useGetUpcomingSchedules(
    upcomingSchedulesQuery
  );
  const upcomingSchedules = useMemo(() => {
    return getUpcomingSchedulesQuery.data?.data?.data;
  }, [getUpcomingSchedulesQuery.data?.data?.data]);

  const dataset = () => {
    const dateData = [];
    for (
      let startDate = dayjs(dashboardQuery.startDate);
      startDate.isSame(dashboardQuery.endDate) ||
      startDate.isBefore(dashboardQuery.endDate);
      startDate = startDate.add(1, "days")
    ) {
      dateData.push({
        day: startDate.format("YYYY-MM-DD"),
        transactionCount: 0,
      });
    }
    return dateData;
  };

  const datasetPie = [
    { label: "Berhasil (0)", value: 0, color: COLOR.success500 },
    { label: "Gagal (0)", value: 0, color: COLOR.danger500 },
    { label: "Pending (0)", value: 0, color: COLOR.neutral500 },
  ];

  const _renderLoading = (size: number) =>
    Array.from({ length: size }).map((_, idx) => (
      <Skeleton key={idx} height="68px" sx={{ transform: "none" }} />
    ));

  const _renderSuccessTransaction = () => {
    return (
      <BarChart
        sx={{
          width: "100%",
          ".MuiBarLabel-root": {
            fill: "white",
          },
        }}
        height={300}
        dataset={
          totalTransactionPerDateQuery.isLoading || !totalTransactionPerDate
            ? dataset()
            : totalTransactionPerDate.map((data) => ({
              day: data.date,
              transactionCount: data.total,
            }))
        }
        xAxis={[{ scaleType: "band", dataKey: "day" }]}
        borderRadius={10}
        series={[
          {
            dataKey: "transactionCount",
            color: COLOR.primary500,
          },
        ]}
        barLabel="value"
      />
    )
  }
  const _renderTransactionByStatus = () => {
    return (
      <PieChart
        series={[
          {
            data: totalTransactionByStatus?.[0]
              ? [
                {
                  label: `Berhasil (${totalTransactionByStatus[0]?.success || 0
                    })`,
                  value: totalTransactionByStatus[0]?.success || 0,
                  color: COLOR.success500,
                },
                {
                  label: `Pending (${totalTransactionByStatus[0]?.pending || 0
                    })`,
                  value: totalTransactionByStatus[0]?.pending || 0,
                  color: COLOR.neutral500,
                },
                {
                  label: `Gagal (${totalTransactionByStatus[0]?.failed || 0
                    })`,
                  value: totalTransactionByStatus[0]?.failed || 0,
                  color: COLOR.danger500,
                },
              ]
              : datasetPie,
            highlightScope: { fade: "global", highlight: "item" },
            faded: {
              innerRadius: 30,
              additionalRadius: -30,
              color: "gray",
            },
            innerRadius: 45,
          },
        ]}
        slotProps={{
          legend: {
            itemMarkHeight: 10,
            itemMarkWidth: 10,
            labelStyle: {
              fontSize: 14,
            },
          },
        }}
        height={200}
      />
    )
  }
  const _renderUpcommingEvents = () => {
    return (
      <Box display="flex" flexDirection="column" px={2} pt={2} pb={5}>
        {getUpcomingSchedulesQuery.isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            minHeight="50vh"
          >
            <CircularProgress />
          </Box>
        ) : !upcomingSchedules?.length ? (
          <Typography>Belum ada jadwal</Typography>
        ) : (
          (upcomingSchedules || []).map((schedule) => {
            return (
              <EventItem
                key={schedule.transactionID}
                status={
                  schedule.status === UpcomingScheduleStatus.ENDED
                    ? "done"
                    : schedule.status
                }
                date={dayjs(schedule.date)}
                productName={schedule.productName}
                startTime={schedule.startTime}
                endTime={schedule.endTime}
                customerName={schedule.fullName}
                timezone={TIMEZONE_SHORTLABEL[schedule.timezone]}
                qty={schedule.qty}
                onClick={() => {
                  navigate(
                    `${RoutePath[ROUTE_NAME.TRANSACTION]}/${schedule.transactionID
                    }`
                  );
                }}
              />
            );
          })
        )}
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      px={2}
      pt={3}
      pb={10}
      sx={{ backgroundColor: "white" }}
    >
      <Box mt={2} mb={1}>
        <Typography variant="h6" fontWeight={600} mb={1}>
          Analisa Bisnis
        </Typography>
        <Box my={1}>
          <DateRangePicker
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            onChangeStartDate={(date) => {
              setDateRange((prev) => ({
                ...prev,
                startDate: date,
              }));
            }}
            onChangeEndDate={(date) => {
              setDateRange((prev) => ({
                ...prev,
                endDate: date,
              }));
            }}
          />
        </Box>
      </Box>

      <Box sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
        justifyContent: "center",
      }}>
        <Box
          sx={{
            flex: {
              xs: "1 1 100%",
              sm: "1 1 calc(50% - 8px)",
            },
            border: `1px solid ${COLOR.neutral200}`,
            borderRadius: 2
          }}
        >
          <Typography my={2} fontWeight={600} textAlign="center">
            Jumlah Transaksi Berhasil
          </Typography>
          {_renderSuccessTransaction()}
        </Box>
        <Box
          sx={{
            flex: {
              xs: "1 1 100%",
              sm: "1 1 calc(50% - 8px)",
            },
            border: `1px solid ${COLOR.neutral200}`,
            borderRadius: 2
          }}
        >
          <Typography my={2} fontWeight={600} textAlign="center">
            Jumlah Transaksi Berdasarkan Status
          </Typography>
          {_renderTransactionByStatus()}
        </Box>
        <Box
          sx={{
            flex: {
              xs: "1 1 100%",
              sm: "1 1 calc(50% - 8px)",
            },
            border: `1px solid ${COLOR.neutral200}`,
            borderRadius: 2,
            maxHeight: '400px',
            overflow:'auto',
          }}
        >
          <Typography fontWeight={600} textAlign="center" position="sticky" top={0} py={2} sx={{ backgroundColor: 'white', boxShadow: 1 }}>
            Jadwal yang Akan Datang
          </Typography>
          {_renderUpcommingEvents()}
        </Box>
      </Box>

    </Box>
  );
};

export default Home;
