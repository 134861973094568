import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { COLOR } from "../../utils/color";

export const AccordionContainer: SxProps<Theme> = {
  width: '100%',
  border: `1px solid ${COLOR.neutral200}`,
  borderRadius: 2,
  overflow: 'hidden',
};

export const AccordionHeader: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 2,
  backgroundColor: COLOR.neutral100,
  cursor: 'pointer',
  gap: 1,
  '&:hover': {
    backgroundColor: COLOR.neutral200,
  },
};

export const HeaderContent: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
};

