/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { LeafDirective } from "mdast-util-directive";
import {
  AdmonitionDirectiveDescriptor,
  DirectiveDescriptor,
  directivesPlugin,
  headingsPlugin,
  imagePlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  SandpackConfig,
  BoldItalicUnderlineToggles,
  ListsToggle,
  InsertImage,
  ConditionalContents,
  Separator,
  CodeToggle,
  StrikeThroughSupSubToggles,
  EditorInFocus,
  DirectiveNode,
} from "@mdxeditor/editor";
import { storageUpload } from "../../api/request";
import { enqueueSnackbar } from "notistack";
import { DEFAULT_SNACKBAR_PROPS } from "../../utils/constant";
import { MAX_FILE_SIZE_IN_MB, compressImage } from "../../utils/image";

const defaultSnippetContent = `
export default function App() {
  return (
    <div className="App">
      <h1>Hello CodeSandbox</h1>
      <h2>Start editing to see some magic happen!</h2>
    </div>
  );
}
`.trim();

export const virtuosoSampleSandpackConfig: SandpackConfig = {
  defaultPreset: "react",
  presets: [
    {
      label: "React",
      name: "react",
      meta: "live react",
      sandpackTemplate: "react",
      sandpackTheme: "light",
      snippetFileName: "/App.js",
      snippetLanguage: "jsx",
      initialSnippetContent: defaultSnippetContent,
    },
    {
      label: "React",
      name: "react",
      meta: "live",
      sandpackTemplate: "react",
      sandpackTheme: "light",
      snippetFileName: "/App.js",
      snippetLanguage: "jsx",
      initialSnippetContent: defaultSnippetContent,
    },
    {
      label: "Virtuoso",
      name: "virtuoso",
      meta: "live virtuoso",
      sandpackTemplate: "react-ts",
      sandpackTheme: "light",
      snippetFileName: "/App.tsx",
      initialSnippetContent: defaultSnippetContent,
      dependencies: {
        "react-virtuoso": "latest",
        "@ngneat/falso": "latest",
      },
    },
  ],
};

export async function expressImageUploadHandler(image: File) {
  const formData = new FormData();
  formData.append("image", image);
  const response = await fetch("/uploads/new", {
    method: "POST",
    body: formData,
  });
  const json = (await response.json()) as { url: string };
  return json.url;
}

interface YoutubeDirectiveNode extends LeafDirective {
  name: "youtube";
  attributes: { id: string };
}

export const YoutubeDirectiveDescriptor: DirectiveDescriptor<YoutubeDirectiveNode> =
  {
    name: "youtube",
    type: "leafDirective",
    testNode(node) {
      return node.name === "youtube";
    },
    attributes: ["id"],
    hasChildren: false,
    Editor: ({ mdastNode, lexicalNode, parentEditor }) => {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <button
            onClick={() => {
              parentEditor.update(() => {
                lexicalNode.selectNext();
                lexicalNode.remove();
              });
            }}
          >
            delete
          </button>
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${mdastNode.attributes.id}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        </div>
      );
    },
  };

function whenInAdmonition(editorInFocus: EditorInFocus | null) {
  const node = editorInFocus?.rootNode;
  if (!node || node.getType() !== "directive") {
    return false;
  }

  return ["note", "tip", "danger", "info", "caution"].includes(
    (node as DirectiveNode).getMdastNode().name as any
  );
}

const uploadImage = async (image: File, companyId: string) => {
  if (image.size > MAX_FILE_SIZE_IN_MB) {
    enqueueSnackbar({
      ...DEFAULT_SNACKBAR_PROPS,
      variant: "error",
      message: "Maximum File Size is 5MB",
    });
    return "";
  }

  const compressedImage = await compressImage(image);

  // Send file to the backend API that returns a signed URL
  return storageUpload({ companyId, usage: "product", image: compressedImage })
    .then((response) => response.data.data?.[0])
    .catch((error) => {
      enqueueSnackbar({
        ...DEFAULT_SNACKBAR_PROPS,
        variant: "error",
        message: "Error on uploading the image",
      });
      return "";
    });
};
export const ALL_PLUGINS = (companyId: string) => [
  toolbarPlugin({
    toolbarContents: () => (
      <ConditionalContents
        options={[
          {
            fallback: () => (
              <>
                <BoldItalicUnderlineToggles />
                <CodeToggle />

                <Separator />

                <StrikeThroughSupSubToggles />

                <Separator />

                <ListsToggle options={["bullet", "number"]} />

                <Separator />

                {/* <CreateLink /> */}
                <InsertImage />
              </>
            ),
          },
        ]}
      />
    ),
  }),
  listsPlugin(),
  quotePlugin(),
  headingsPlugin({ allowedHeadingLevels: [1, 2, 3] }),
  linkPlugin(),
  linkDialogPlugin(),
  imagePlugin({
    imageAutocompleteSuggestions: [
      "https://via.placeholder.com/150",
      "https://via.placeholder.com/150",
    ],
    imageUploadHandler: async (image: File) =>
      await uploadImage(image, companyId),
  }),
  tablePlugin(),
  thematicBreakPlugin(),
  directivesPlugin({
    directiveDescriptors: [
      YoutubeDirectiveDescriptor,
      AdmonitionDirectiveDescriptor,
    ],
  }),
];
