import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

type SyncQueryParamOptions<T> = {
  allowedValues?: T[];
  onInvalidValue?: () => void;
  validateValue?: (value: T) => boolean;
  parseValue?: (value: string) => T; // Converts URL string to expected type
  stringifyValue?: (value: T) => string; // Converts value to URL string
  removeIfInvalid?: boolean; // Remove param if invalid instead of setting default
};

export const useSyncQueryParam = <T>(
  key: string,
  defaultValue: T,
  options?: SyncQueryParamOptions<T>
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(key);

  const parsedValue = paramValue
    ? options?.parseValue
      ? options.parseValue(paramValue)
      : (paramValue as unknown as T)
    : defaultValue;

  const valueIsValid =
    options?.validateValue?.(parsedValue) ??
    options?.allowedValues?.includes(parsedValue) ??
    true;

  const [value, setValue] = useState<T>(valueIsValid ? parsedValue : defaultValue);

  useEffect(() => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);

      if (!valueIsValid && options?.removeIfInvalid) {
        newParams.delete(key);
      } else {
        const stringifiedValue = options?.stringifyValue
          ? options.stringifyValue(value)
          : (value as unknown as string);
        newParams.set(key, stringifiedValue);
      }
      return newParams;
    }, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, key]);

  useEffect(() => {
    if (!valueIsValid && paramValue !== null) {
      options?.onInvalidValue?.();

      if (options?.removeIfInvalid) {
        setSearchParams((prevParams) => {
          const newParams = new URLSearchParams(prevParams);
          newParams.delete(key);
          return newParams;
        }, { replace : true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramValue, valueIsValid]);

  return [value, setValue] as const;
};

type SyncObjectQueryParamOptions<T> = {
  removeEmptyValues?: boolean;
  onInvalidValue?: () => void;
  validateValue?: (value: T) => boolean;
};

export const useSyncObjectQueryParam = <T extends Record<string, any>>(
  keyPrefix: string, // Prefix to group object fields (e.g., "filters")
  defaultValue: T,
  options?: SyncObjectQueryParamOptions<T>
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const prevParamsRef = useRef<string | null>(null);

  // Extract values from URL
  const extractParams = () => {
    const obj: Partial<T> = {};
    searchParams.forEach((paramValue, paramKey) => {
      if (paramKey.startsWith(`${keyPrefix}_`)) {
        const fieldKey = paramKey.replace(`${keyPrefix}_`, "");
        obj[fieldKey as keyof T] = smartParse(paramValue);
      }
    });
    return obj as T;
  };

  const paramValues = extractParams();
  const valueIsValid = options?.validateValue?.(paramValues) ?? true;
  const [value, setValue] = useState<T>(valueIsValid ? paramValues : defaultValue);

  // Ensure the URL is set correctly on mount
  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);
    let shouldUpdate = false;

    Object.entries(defaultValue).forEach(([fieldKey, fieldValue]) => {
      const paramKey = `${keyPrefix}_${fieldKey}`;
      if (!newParams.has(paramKey) && fieldValue !== undefined && fieldValue !== null) {
        newParams.set(paramKey, smartStringify(fieldValue));
        shouldUpdate = true;
      }
    });

    if (shouldUpdate) {
      setSearchParams(newParams, { replace: true });
    }
  }, [keyPrefix, defaultValue, setSearchParams]);

  // Keep URL in sync when `value` changes
  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);

    // Remove old keys related to the object
    for (const paramKey of Array.from(newParams.keys())) {
      if (paramKey.startsWith(`${keyPrefix}_`)) {
        newParams.delete(paramKey);
      }
    }

    // Add updated values, skipping undefined/null
    Object.entries(value).forEach(([fieldKey, fieldValue]) => {
      if (fieldValue !== undefined && fieldValue !== null) {
        newParams.set(`${keyPrefix}_${fieldKey}`, smartStringify(fieldValue));
      }
    });

    // Prevent redundant updates
    const newParamsString = newParams.toString();
    if (prevParamsRef.current !== newParamsString) {
      prevParamsRef.current = newParamsString;
      setSearchParams(newParams, { replace: true });
    }
  }, [value, keyPrefix, setSearchParams]);

  return [value, setValue] as const;
};

// Handle different data types
const smartParse = (value: string): any => {
  if (value === "true") return true;
  if (value === "false") return false;
  if (!isNaN(Number(value))) return Number(value);
  if (value.includes(",")) return value.split(","); // Handle arrays
  return value;
};

const smartStringify = (value: any): string => {
  if (Array.isArray(value)) return value.join(",");
  return String(value);
};