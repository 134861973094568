import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Typography, Grid, Box } from '@mui/material';
import OpacityButton from '../OpacityButton/OpacityButton';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { COLOR } from '../../utils/color';

interface CalendarRangePickerProps {
  startDate: Dayjs;
  endDate: Dayjs;
  onDateChange: (startDate: Dayjs, endDate: Dayjs) => void;
}

const CalendarRangePicker: React.FC<CalendarRangePickerProps> = ({
  startDate,
  endDate,
  onDateChange,
}) => {
  const [currentMonth, setCurrentMonth] = useState(dayjs());
  const [toggled, setToggled] = useState(false);
  const renderDays = (monthDate: Dayjs) => {
    const days = [];
    const startOfMonth = monthDate.startOf('month');
    const daysInMonth = monthDate.daysInMonth();
    const firstDayOfMonth = startOfMonth.day();
    const offset = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1; // Start from Monday

    for (let i = 0; i < offset; i++) {
      days.push(<Grid item xs={1.71} key={`empty-${i}`} />);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const currentDate = startOfMonth.date(i);
      days.push(renderDay(currentDate));
    }

    return days;
  };

  const isBetweenDates = (day: Dayjs) => {
    return startDate && endDate && day.isAfter(startDate, 'day') && day.isBefore(endDate, 'day');
  };

  const handleDateClick = (newDate: Dayjs) => {
    if (!toggled) {
      onDateChange(newDate, newDate);
      setToggled(toggled => !toggled);
    } else {
      if (newDate.isBefore(startDate, 'day') || newDate.isSame(startDate, 'day')) {
        onDateChange(newDate, newDate);
      } else {
        onDateChange(startDate, newDate);
        setToggled(toggled => !toggled);
      }
    }
  };

  const renderDay = (day: Dayjs) => {
    const isToday = day.isSame(dayjs(), 'day');
    const isStart = day.isSame(startDate, 'day');
    const isEnd = day.isSame(endDate, 'day');
    const isBetweenDate = isBetweenDates(day);

    return (
      <Box
        key={day.format('YYYY-MM-DD')}
        onClick={() => handleDateClick(day)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          aspectRatio: '1',
          width: '100%',
          cursor: 'pointer',
          border: `1px solid transparent`,
          position: 'relative',
          borderRadius: '50%',
          '&:hover': { backgroundColor: '#e0e0e0', color: 'black' },
          ...(isToday && {
            border: `1px solid black`,
          }),
          ...(isBetweenDate && {
            backgroundColor: COLOR.neutral200,
          }),
          ...(isStart || isEnd ? {
            backgroundColor: 'black !important',
            color: 'white !important',
            fontWeight: 'bold',
          } : {}),
        }}
      >
        {day.date()}
      </Box>
    );
  };

  const handleMonthChange = (offset: number) => {
    setCurrentMonth(prev => prev.add(offset, 'month'));
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', flexWrap: 'wrap' }}>
      {[0, 1].map((offset) => {
        const monthDate = currentMonth.add(offset, 'month');
        return (
          <Box key={offset} display="flex" flexDirection="column" flexGrow={1}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                my: 2,
                position: 'relative',
                textAlign: 'center',
              }}
            >
              {offset === 0 && (
                <OpacityButton sx={{ position: 'absolute', left: '18px' }} onClick={() => handleMonthChange(-1)}>
                  <FaArrowLeft size={18} />
                </OpacityButton>
              )}
              <Typography variant="h6">{monthDate.format('MMMM YYYY')}</Typography>
              {offset === 1 && (
                <OpacityButton sx={{ position: 'absolute', right: '18px' }} onClick={() => handleMonthChange(1)}>
                  <FaArrowRight size={18} />
                </OpacityButton>
              )}
            </Box>
            <Box display="grid" gridTemplateColumns="repeat(7, 1fr)" sx={{ position: 'relative' }}>
              {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((label) => (
                <Grid item xs={1.71} key={label} sx={{ textAlign: 'center', fontWeight: 'bold', color: '#333' }}>
                  {label}
                </Grid>
              ))}
              {renderDays(monthDate)}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default CalendarRangePicker;