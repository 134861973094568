import React, { useMemo } from 'react';
import ModalTemplate, { ModalTemplateProps } from './ModalTemplate';
import { Box, Typography } from '@mui/material';
import OpacityButton from '../OpacityButton/OpacityButton';
import { COLOR } from '../../utils/color';
import { useGetTeamMembers } from '../../query/queries';
import { GetTeamMembers, TeamMemberStatus } from '../../api/request.types';
import { FaCheck } from 'react-icons/fa';
import { RootReducerState } from '../../redux/reducers';
import { useSelector } from 'react-redux';
import ProfileInitial from '../ProfileInitial/ProfileInitial';

export interface ChooseTeamMemberModalProps {
  modalProps: Omit<ModalTemplateProps, 'children'>;
  selectedTeamMemberIds: Array<GetTeamMembers['teamMemberID']>;
  onClickMember: (teamMember: GetTeamMembers) => void;
}

const ChooseTeamMemberModal: React.FC<ChooseTeamMemberModalProps> = ({
  modalProps,
  selectedTeamMemberIds,
  onClickMember,
}) => {
  const userReducer = useSelector((state: RootReducerState) => state.userReducer);
  const getActiveTeamMembers = useGetTeamMembers({
    status: TeamMemberStatus.ACTIVE,
  });
  const activeTeamMembers = useMemo(() => {
    let teamMembers: GetTeamMembers[] = [
      { email: '', jobTitle: '', name: userReducer.data.name || "", phoneNumber: '', status: '', teamMemberID: userReducer.data.userId || "" }
    ];
    if (getActiveTeamMembers.data && !getActiveTeamMembers.isLoading && getActiveTeamMembers.isFetched) {
      teamMembers = teamMembers.concat(getActiveTeamMembers.data.data);
      return teamMembers;
    }
    return teamMembers;
  }, [getActiveTeamMembers.data, getActiveTeamMembers.isFetched, getActiveTeamMembers.isLoading, userReducer.data.name, userReducer.data.userId]);

  return (
    <ModalTemplate
      desktopWidth='300px'
      desktopHeight='50vh'
      {...modalProps}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...modalProps.sx,
      }}
      header={
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, flex: 1, borderBottom: `1px solid ${COLOR.neutral300}`, gap: 1 }}>
          <Typography variant='h6' fontWeight="bold">Pilih Team Member</Typography>
        </Box>
      }
    >
      <Box px={2} pb={5} pt={3}>
        <Box display="flex" flexDirection="column">
          {activeTeamMembers.map(teamMember => {
            return (
              <OpacityButton key={teamMember.teamMemberID} onClick={() => onClickMember(teamMember)} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', px: 2, py: 1 }}>
                <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                  <ProfileInitial name={teamMember.name} size={32} />
                  <Typography fontWeight="bold">{teamMember.name}</Typography>
                </Box>
                {selectedTeamMemberIds.includes(teamMember.teamMemberID) && <Box component="span" color={COLOR.success500}><FaCheck /></Box>}
              </OpacityButton>
            )
          })}
        </Box>
      </Box>
    </ModalTemplate>
  );
};

export default ChooseTeamMemberModal;
