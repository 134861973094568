import { useMutation, UseMutationResult } from "react-query";
import {
  APICalculateTransactionPayload,
  APIChangeProductQuotaPayload,
  APICreateCompanyBodyPayload,
  APICreateCompanyBodyPayloadV2,
  APICreateNewTransactionPayload,
  APICreateProductBodyPayload,
  APIEditCompanyBodyPayload,
  APIEditCompanyBodyPayloadV2,
  APIGetTransactionQueries,
  APIRefundTransactionPayload,
  APIUpdateProductByIdPayload,
  APIUploadCompanyLogoPayload,
  ChangePasswordRequest,
  CreateProductLinkRequest,
  CreateScheduleEventRequest,
  UpdateScheduleEventRequest,
  CreateProductReservationRequest,
  CreateProductSectionRequest,
  CreateTeamMemberPayload,
  CreateWithdrawRequest,
  DeleteAllTeamMemberShiftsRequest,
  DeleteTeamMemberShiftRequest,
  LoginGooglePayload,
  LoginPayload,
  LoginResponse,
  PRODUCT_TYPE,
  ProductDetailCompact,
  RegisterPayload,
  RegisterResponse,
  RescheduleProductRequest,
  ResetPasswordRequest,
  UpdateCompanyV2Payload,
  UpdateProductLinkRequest,
  UpdateProductReservationRequest,
  UpdateProductSectionRequest,
  UpdateTeamMemberPayload,
  UpdateTeamMemberTimeOffRequest,
  UploadStoragePayload,
  UpsertBankAccountRequest,
  VerifyBankAccountRequest,
  VerifyEmailRequest,
  VerifyWithdrawRequest,
  UpdateTeamMemberShiftPayload,
  UpsertTeamMemberRegularShiftPayload,
  CreateTeamMemberTimeOffPayload,
  APICreateNewTransactionScheduleEventPayload,
  ConfirmTransactionScheduleEvenPayloadRequest,
  APIUpdateTransactionRequest,
  CreateBlockTime,
  UpdateBlockTime,
  APICreateNewResourceRequest,
  APIUpdateResourceRequest,
  APICreateNewTransactionAdhocPayload,
  TransactionFilterV2,
} from "../api/request.types";
import {
  cancelTransaction,
  changePassword,
  changeProductQuota,
  changeProductQuotaV2,
  confirmTransaction,
  createCompanyAPI,
  createCompanyAPIV2,
  createNewTransaction,
  createProductAPI,
  createProductLinkV2,
  createProductReservationV2,
  createProductSectionV2,
  createTeamMember,
  createTeamMemberTimeOff,
  createWithdraw,
  deleteAllTeamMemberShifts,
  deleteProductById,
  deleteTeamMember,
  deleteTeamMemberShift,
  deleteTeamMemberTimeOff,
  downloadTransactionCSV,
  getCalculateTransaction,
  getDataByDomainName,
  getPublicCompanyDomainV2,
  getUserCompanies,
  loginAPI,
  loginGoogleAPI,
  logoutAPI,
  refundTransaction,
  registerAPI,
  requestForgotPassword,
  requestTokenAPI,
  rescheduleProduct,
  resendBankAccount,
  resendVerificationEmail,
  resendWithdrawEmail,
  resetPassword,
  storageUpload,
  updateCompanyAPI,
  updateCompanyAPIV2,
  updateCompanyProductDetailAPI,
  updateProductById,
  updateProductLinkV2,
  updateProductReservationV2,
  updateProductSectionV2,
  updateTeamMember,
  updateTeamMemberShift,
  updateTeamMemberTimeOff,
  uploadCompanyLogo,
  uploadProductPhotos,
  upsertBankAccount,
  upsertTeamMemberRegularShift,
  verifyBankAccount,
  verifyEmail,
  verifyWithdraw,
  createScheduleEvent,
  updateScheduleEvent,
  getScheduleEventByID,
  confirmTransactionScheduleEvent,
  createTransactionScheduleEvent,
  updateTransactionScheduleEvent,
  createBlockTime,
  updateBlockTime,
  createNewResource,
  updateResource,
  deleteResource,
  createTransactionAdhoc,
  confirmTransactionAdhoc,
  updateTransactionAdhoc,
  downloadTransactionCSVV2,
} from "../api/request";
import { ErrorResponse } from "react-router-dom";
import { AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import { RootReducerState } from "../redux/reducers";

export type MutationResult<TData = any, TError = any> = UseMutationResult<
  AxiosResponse<TData>,
  TError,
  any
>;

export const useRegister = (): MutationResult<
  RegisterResponse,
  ErrorResponse
> => {
  return useMutation<
    AxiosResponse<RegisterResponse>,
    ErrorResponse,
    RegisterPayload
  >((data) => registerAPI(data), {
    onSuccess: (data) => {},
    onError: (error) => {},
  });
};
export const useLogin = (): MutationResult<LoginResponse, ErrorResponse> => {
  return useMutation<AxiosResponse<LoginResponse>, ErrorResponse, LoginPayload>(
    (data) => loginAPI(data),
    {
      onSuccess: (response) => {},
      onError: (error) => {},
    }
  );
};

export const useLoginGoogle = (): MutationResult<
  LoginResponse,
  ErrorResponse
> => {
  return useMutation<
    AxiosResponse<LoginResponse>,
    ErrorResponse,
    LoginGooglePayload
  >((data) => loginGoogleAPI(data), {
    onSuccess: (response) => {},
    onError: (error) => {},
  });
};
export const useRequestToken = () => {
  return useMutation(() => requestTokenAPI(), {
    onSuccess: (data) => {},
    onError: (error) => {},
  });
};

export const useLogout = () => {
  return useMutation(() => logoutAPI(), {
    onSuccess: () => {},
    onError: (error) => {},
  });
};

// export const useDownloadSignedUrl = (queryOpt?: Omit<UseMutationOptions<AxiosResponse<DownloadSignedUrlResponse, any>, unknown, string, unknown>, "mutationFn"> | undefined) => {
//   return useMutation((path: string) => downloadSignedUrl(path), {
//     ...queryOpt
//   });
// };

export const useGetDataByDomainNameMutation = () => {
  return useMutation((domainName: string) => getDataByDomainName(domainName));
};

export const useCreateCompany = () => {
  return useMutation((data: APICreateCompanyBodyPayload) =>
    createCompanyAPI(data)
  );
};

export const useCreateCompanyV2 = () => {
  return useMutation((data: APICreateCompanyBodyPayloadV2) =>
    createCompanyAPIV2(data)
  );
};
export const useEditCompany = () => {
  return useMutation(
    (data: { companyId: string; companyEdit: APIEditCompanyBodyPayload }) =>
      updateCompanyAPI(data.companyId, data.companyEdit)
  );
};

export const useEditCompanyV2 = () => {
  return useMutation(
    (data: { companyId: string; companyEdit: APIEditCompanyBodyPayloadV2 }) =>
      updateCompanyAPIV2(data.companyId, data.companyEdit)
  );
};
export const useUploadBusinessImage = () => {
  return useMutation((data: APIUploadCompanyLogoPayload) =>
    uploadCompanyLogo(data)
  );
};
export const useCreateProduct = () => {
  return useMutation((data: APICreateProductBodyPayload) =>
    createProductAPI(data)
  );
};
export const useUploadProductPhotos = () => {
  return useMutation(
    (data: {
      files: Array<File | string>;
      companyId: string;
      productId: string;
    }) => uploadProductPhotos(data.files, data.companyId, data.productId)
  );
};

export const useCalculateTransaction = () => {
  return useMutation((data: APICalculateTransactionPayload) =>
    getCalculateTransaction(data)
  );
};

export const useCreateNewTransaction = () => {
  return useMutation((data: APICreateNewTransactionPayload) =>
    createNewTransaction(data)
  );
};

export const useChangeProductQuota = () => {
  return useMutation(
    (data: APIChangeProductQuotaPayload & { productId: string }) => {
      const { productId, ...payloadData } = data;
      return changeProductQuota(data.productId, payloadData);
    }
  );
};
export const useChangeProductQuotaV2 = () => {
  return useMutation(
    (data: APIChangeProductQuotaPayload & { productId: string }) => {
      const { productId, ...payloadData } = data;
      return changeProductQuotaV2(data.productId, payloadData);
    }
  );
};
export const useDeleteProductById = () => {
  return useMutation(
    ({ productId, type }: { productId: string; type?: PRODUCT_TYPE }) =>
      deleteProductById(productId, type)
  );
};

export const useCancelTransaction = () => {
  return useMutation((transactionId: string) =>
    cancelTransaction(transactionId)
  );
};
export const useConfirmTransaction = () => {
  return useMutation((transactionId: string) =>
    confirmTransaction(transactionId)
  );
};

export const useRefundTransaction = () => {
  return useMutation((data: APIRefundTransactionPayload) =>
    refundTransaction(data)
  );
};

export const useUpdateProductById = () => {
  return useMutation(
    ({
      productId,
      ...data
    }: APIUpdateProductByIdPayload & { productId: string }) =>
      updateProductById(productId, data)
  );
};

export const useUploadToStorageMutation = () => {
  return useMutation((data: UploadStoragePayload) => storageUpload(data));
};

export const useUpdateCompanyProductDetailV2 = () => {
  return useMutation(
    ({
      companyId,
      ...payload
    }: {
      companyId: string;
      details: ProductDetailCompact[];
    }) => updateCompanyProductDetailAPI(companyId, payload)
  );
};

export const useUpdateCompanyV2 = () => {
  return useMutation(
    ({
      companyId,
      ...payload
    }: UpdateCompanyV2Payload & { companyId: string }) =>
      updateCompanyAPI(companyId, payload)
  );
};

export const useCreateProductReservation = () => {
  return useMutation((data: CreateProductReservationRequest) =>
    createProductReservationV2(data)
  );
};

export const useCreateProductLink = () => {
  return useMutation((data: CreateProductLinkRequest) =>
    createProductLinkV2(data)
  );
};

export const useCreateProductSection = () => {
  return useMutation((data: CreateProductSectionRequest) =>
    createProductSectionV2(data)
  );
};

export const useUpdateProductReservationV2 = () => {
  return useMutation(
    ({
      productId,
      ...payload
    }: Partial<UpdateProductReservationRequest> & { productId: string }) =>
      updateProductReservationV2(payload, productId)
  );
};

export const useUpdateProductLinkV2 = () => {
  return useMutation(
    ({
      productId,
      ...payload
    }: Partial<UpdateProductLinkRequest> & { productId: string }) =>
      updateProductLinkV2(payload, productId)
  );
};

export const useUpdateProductSectionV2 = () => {
  return useMutation(
    ({
      productId,
      ...payload
    }: UpdateProductSectionRequest & { productId: string }) =>
      updateProductSectionV2(payload, productId)
  );
};

export const useGetCompanies = () => {
  return useMutation(() => getUserCompanies());
};

export const useRequestForgotPassword = () => {
  return useMutation(({ email }: { email: string }) =>
    requestForgotPassword(email)
  );
};
export const useGetPublicCompanyDetail = () => {
  return useMutation(({ companyDomainName }: { companyDomainName: string }) =>
    getPublicCompanyDomainV2(companyDomainName)
  );
};

export const useChangePassword = () => {
  return useMutation((data: ChangePasswordRequest) => changePassword(data));
};

export const useResetPassword = () => {
  return useMutation((data: ResetPasswordRequest) => resetPassword(data));
};

export const useVerifyEmail = () => {
  return useMutation((data: VerifyEmailRequest) => verifyEmail(data));
};

export const useResendVerificationEmail = () => {
  return useMutation(({ email }: { email: string }) =>
    resendVerificationEmail(email)
  );
};

export const useDownloadCSVTransaction = () => {
  return useMutation(({ query }: { query: APIGetTransactionQueries }) =>
    downloadTransactionCSV(query)
  );
};
export const useDownloadCSVTransactionV2 = () => {
  const companyID = useSelector(
    (state: RootReducerState) => state.userReducer.data.companyId
  );
  return useMutation(({ query }: { query: TransactionFilterV2 }) =>
    downloadTransactionCSVV2({ ...query, companyID: companyID ?? "" })
  );
};

export const useUpsertBankAccount = () => {
  return useMutation(
    ({
      companyId,
      ...payload
    }: UpsertBankAccountRequest & { companyId: string }) =>
      upsertBankAccount(companyId, payload)
  );
};
export const useVerifyBankAccount = () => {
  return useMutation(
    ({
      companyId,
      ...payload
    }: VerifyBankAccountRequest & { companyId: string }) =>
      verifyBankAccount(companyId, payload)
  );
};
export const useResendBankAccount = () => {
  return useMutation(({ companyId, ...payload }: { companyId: string }) =>
    resendBankAccount(companyId)
  );
};

export const useCreateWithdraw = () => {
  return useMutation(
    ({
      companyId,
      ...payload
    }: CreateWithdrawRequest & { companyId: string }) =>
      createWithdraw(companyId, payload)
  );
};

export const useVerifyWithdraw = () => {
  return useMutation(
    ({
      companyId,
      ...payload
    }: VerifyWithdrawRequest & { companyId: string }) =>
      verifyWithdraw(companyId, payload)
  );
};

export const useResendWithdraw = () => {
  return useMutation(({ companyId }: { companyId: string }) =>
    resendWithdrawEmail(companyId)
  );
};

export const useReschedule = () => {
  return useMutation((data: RescheduleProductRequest) =>
    rescheduleProduct(data)
  );
};

export const useCreateTeamMember = () => {
  const companyID = useSelector(
    (state: RootReducerState) => state.userReducer.data.companyId
  );

  if (!companyID) {
    throw new Error("Company ID is not found");
  }

  return useMutation((data: CreateTeamMemberPayload) =>
    createTeamMember({ ...data, companyID })
  );
};

export const useUpdateTeamMember = () => {
  const companyID = useSelector(
    (state: RootReducerState) => state.userReducer.data.companyId
  );

  if (!companyID) {
    throw new Error("Company ID is not found");
  }

  return useMutation(
    ({
      teamMemberID,
      ...data
    }: UpdateTeamMemberPayload & { teamMemberID: string }) =>
      updateTeamMember(teamMemberID, { ...data, companyID })
  );
};

export const useDeleteTeamMember = () => {
  return useMutation((teamMemberID: string) => deleteTeamMember(teamMemberID));
};

// Team Member Shifts
export const useUpsertTeamMemberRegularShift = () => {
  const companyID = useSelector(
    (state: RootReducerState) => state.userReducer.data.companyId
  );

  if (!companyID) {
    throw new Error("Company ID is not found");
  }

  return useMutation((data: UpsertTeamMemberRegularShiftPayload) =>
    upsertTeamMemberRegularShift({ ...data, companyID })
  );
};

export const useCreateTeamMemberTimeOff = () => {
  const companyID = useSelector(
    (state: RootReducerState) => state.userReducer.data.companyId
  );

  if (!companyID) {
    throw new Error("Company ID is not found");
  }

  return useMutation((data: CreateTeamMemberTimeOffPayload) =>
    createTeamMemberTimeOff({ ...data, companyID })
  );
};

export const useUpdateTeamMemberTimeOff = () => {
  return useMutation((data: UpdateTeamMemberTimeOffRequest) =>
    updateTeamMemberTimeOff(data.timeOffID, data)
  );
};

export const useDeleteTeamMemberTimeOff = () => {
  return useMutation((timeOffID: string) => deleteTeamMemberTimeOff(timeOffID));
};

export const useUpdateTeamMemberShift = () => {
  const companyID = useSelector(
    (state: RootReducerState) => state.userReducer.data.companyId
  );

  if (!companyID) {
    throw new Error("Company ID is not found");
  }

  return useMutation(
    ({
      teamMemberID,
      ...data
    }: UpdateTeamMemberShiftPayload & { teamMemberID: string }) =>
      updateTeamMemberShift(teamMemberID, { ...data, companyID })
  );
};

export const useDeleteTeamMemberShift = () => {
  return useMutation(
    ({
      teamMemberID,
      ...data
    }: DeleteTeamMemberShiftRequest & { teamMemberID: string }) =>
      deleteTeamMemberShift(teamMemberID, data)
  );
};

export const useDeleteAllTeamMemberShifts = () => {
  return useMutation(
    ({
      teamMemberID,
      ...data
    }: DeleteAllTeamMemberShiftsRequest & { teamMemberID: string }) =>
      deleteAllTeamMemberShifts(teamMemberID, data)
  );
};

export const useCreateScheduleEvent = () => {
  return useMutation((data: CreateScheduleEventRequest) =>
    createScheduleEvent(data)
  );
};

export const useUpdateScheduleEvent = () => {
  return useMutation((data: Partial<UpdateScheduleEventRequest>) =>
    updateScheduleEvent(data)
  );
};

export const useCreateBlockTime = () => {
  return useMutation((data: CreateBlockTime) => createBlockTime(data));
};

export const useUpdateBlockTime = () => {
  return useMutation((data: Partial<UpdateBlockTime>) => updateBlockTime(data));
};

export const useCreateTransactionScheduleEvent = () => {
  return useMutation(
    (data: {
      eventID: string;
      payload: APICreateNewTransactionScheduleEventPayload;
    }) => createTransactionScheduleEvent(data.eventID, data.payload)
  );
};
export const useConfirmTransactionScheduleEvent = () => {
  return useMutation(
    (data: {
      eventID: string;
      transactionID: string;
      payload: ConfirmTransactionScheduleEvenPayloadRequest;
    }) =>
      confirmTransactionScheduleEvent(
        data.eventID,
        data.transactionID,
        data.payload
      )
  );
};

export const useUpdateTransactionScheduleEvent = () => {
  return useMutation(
    (data: {
      eventID: string;
      transactionID: string;
      payload: APIUpdateTransactionRequest;
    }) =>
      updateTransactionScheduleEvent(
        data.eventID,
        data.transactionID,
        data.payload
      )
  );
};

export const useCreateNewResource = () => {
  const companyID = useSelector(
    (state: RootReducerState) => state.userReducer.data.companyId
  );

  if (!companyID) {
    throw new Error("Company ID is not found");
  }
  return useMutation(
    (data: { companyID: string; payload: APICreateNewResourceRequest }) =>
      createNewResource(companyID, data.payload)
  );
};

export const useUpdateResource = () => {
  const companyID = useSelector(
    (state: RootReducerState) => state.userReducer.data.companyId
  );

  if (!companyID) {
    throw new Error("Company ID is not found");
  }

  return useMutation(
    (data: {
      companyID: string;
      resourceID: string;
      payload: APIUpdateResourceRequest;
    }) => updateResource(companyID, data.resourceID, data.payload)
  );
};

export const useDeleteResource = () => {
  const companyID = useSelector(
    (state: RootReducerState) => state.userReducer.data.companyId
  );

  if (!companyID) {
    throw new Error("Company ID is not found");
  }
  return useMutation((data: { companyID: string; resourceID: string }) =>
    deleteResource(companyID, data.resourceID)
  );
};

export const useScheduleEventByID = () => {
  return useMutation((data: { companyID: string; eventID: string }) =>
    getScheduleEventByID(data.companyID, data.eventID)
  );
};
// export const useCreateProduct = () => {
//   const uploadPhotosMutation = useMutation((photos) =>
//     Promise.all(photos.map(uploadPhotoAPI))
//   );

//   const createProductMutation = useMutation((data) =>
//     createProductAPI(data)
//   );

//   const createProduct = async (businessData: Omit<APICreateProductBodyPayload, 'photos'>) => {
//     try {
//       const uploadedPhotoPath: Array<string> = await uploadPhotosMutation.mutateAsync(businessData.photos);

//       await createProductMutation.mutateAsync({
//         ...businessData,
//         photos: uploadedPhotoPath,
//       });
//     } catch (error) {
//       console.error('Error creating product:', error);
//     }
//   };

//   return { createProduct, uploadPhotosMutation, createProductMutation };
// };

export const useCreateTransactionAdhoc = () => {
  return useMutation((data: { payload: APICreateNewTransactionAdhocPayload }) =>
    createTransactionAdhoc(data.payload)
  );
};
export const useConfirmTransactionAdhoc = () => {
  return useMutation(
    (data: {
      transactionID: string;
      payload: ConfirmTransactionScheduleEvenPayloadRequest;
    }) => confirmTransactionAdhoc(data.transactionID, data.payload)
  );
};

export const useUpdateTransactionAdhoc = () => {
  return useMutation(
    (data: { transactionID: string; payload: APIUpdateTransactionRequest }) =>
      updateTransactionAdhoc(data.transactionID, data.payload)
  );
};
