import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useUpdateTeamMemberTimeOff } from "../../query/mutations";
import { CreateTeamMemberTimeOffPayload } from "../../api/request.types";
import TeamScheduledTimeOff from "./TeamScheduledTimeOff";

const TeamScheduledTimeOffEdit = () => {
  const { state } = useLocation();
  const { timeOffID } = useParams();
  const teamMemberID: string = state?.teamMemberID;
  const timeOff = state?.timeOff;

  const updateTeamMemberTimeOff = useUpdateTeamMemberTimeOff();

  const onSubmit = async (payload: CreateTeamMemberTimeOffPayload) => {
    if (!timeOffID) return;

    await updateTeamMemberTimeOff.mutateAsync({
      ...payload,
      timeOffID: timeOffID,
    });
  };

  return (
    <TeamScheduledTimeOff
      onSubmit={onSubmit}
      initialValues={{ ...timeOff, teamMemberID }}
    />
  );
};

export default TeamScheduledTimeOffEdit;
