export const SLOT_HEIGHT = 96;
export const TIME_SLOT_WIDTH = 60;
export const HOURS = Array.from({ length: 24 }, (_, i) => `${i.toString().padStart(2, '0')}:00`);
export const CELL_MIN_WIDTH = 120;

export const Z_INDEX = {
  TIMELINE_INDICATOR: 6,
  TIMELINE_INDICATOR_TEXT: 7,
  BACKDROP: 1000,
  STICKY_HEADER: 10,
  TIMELINE: 5,
  EVENTS: 3,
  HOUR_CELLS: 2,
  ACTIVE_HOUR: 1,
  SCHEDULE_FLOATING_BUTTON: 13,
} as const;
