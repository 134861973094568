import { useCreateTeamMemberTimeOff } from "../../query/mutations";
import { CreateTeamMemberTimeOffPayload } from "../../api/request.types";
import TeamScheduledTimeOff from "./TeamScheduledTimeOff";

const TeamScheduledTimeOffAdd = () => {
  const createTeamMemberTimeOff = useCreateTeamMemberTimeOff();

  const onSubmit = async (payload: CreateTeamMemberTimeOffPayload) => {
    await createTeamMemberTimeOff.mutateAsync(payload);
  };

  return <TeamScheduledTimeOff onSubmit={onSubmit} />;
};

export default TeamScheduledTimeOffAdd;
