import Joi from "joi";
import { EmploymentType, TeamMemberStatus } from "../api/request.types";
import dayjs from "dayjs";
import { PHONE_LENGTH } from "../utils/constant";

export const addTeamMemberSchema = Joi.object({
  customTeamMemberID: Joi.string().allow(""),
  firstName: Joi.string().min(2).required(),
  lastName: Joi.string().allow(""),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  phoneNumber: Joi.string()
    .replace(/[^0-9]/g, "")
    .min(PHONE_LENGTH.MIN)
    .max(PHONE_LENGTH.MAX)
    .allow(""),
  jobTitle: Joi.string().min(2).required(),
  startDate: Joi.string()
    .custom((value, helpers) => {
      const endDate = dayjs(helpers.state.ancestors[0].endDate);
      if (endDate.isValid() && endDate.isBefore(dayjs(value))) {
        return helpers.message({
          custom: "Start date cannot be after end date",
        });
      }
    })
    .allow(""),
  endDate: Joi.string()
    .custom((value, helpers) => {
      const startDate = helpers.state.ancestors[0].startDate;

      if (!startDate) return true;

      if (dayjs(startDate).isAfter(dayjs(value))) {
        return helpers.message({
          custom: "End date cannot be before start date",
        });
      }
    })
    .allow(""),
  employmentType: Joi.string().valid(...Object.values(EmploymentType)),
  notes: Joi.string().allow(""),
  status: Joi.string().valid(...Object.values(TeamMemberStatus)),
});
