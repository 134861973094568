import React, { } from 'react';
import { Box } from '@mui/material';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { COLOR } from '../../utils/color';

export interface SearchBarPropsI {
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const SearchBar: React.FC<SearchBarPropsI> = ({ placeholder = 'Search...', value = '', onChange, disabled }) => {
  return (
    <Box
      sx={{
        borderRadius: 3,
        px: 2,
        py: '8px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
        height: 'fit-content',
        border: `1px solid ${COLOR.neutral500}`,
        gap: 1,
        ...(disabled && {
          cursor: 'not-allowed',
          border: `1px solid inherit`,
        }),
        '& .search-input': {
          outline: 'none',
          border: 'none',
          background: 'inherit',
          font: 'inherit',
          height: 'auto',
          color: COLOR.neutral500,
          ...(disabled && {
            cursor: 'not-allowed',
            color: COLOR.neutral200,
          }),
        },
      }}
    >
      <Box component="span" display="flex" flex={1} width="fit-content">
        <FaMagnifyingGlass size={16} />
      </Box>
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => {
          onChange?.(e);
        }}
        className="search-input"
      />
    </Box>
  );
};

export default SearchBar;
