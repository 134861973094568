import React, { useState } from 'react';
import { Box, Modal, useTheme, useMediaQuery, SxProps, Theme, ModalProps } from '@mui/material';
import OpacityButton from '../OpacityButton/OpacityButton';
import { IoMdClose } from 'react-icons/io';
import { useElementCurrentProperties } from '../../hooks/useElementHeight';

export interface ModalTemplateProps {
  open: boolean;
  onDismiss?: () => void;
  onClose?: () => void;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  withCloseButton?: boolean;
  title?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  desktopWidth?: string;
  desktopHeight?: string;
  closeButtonSx?: SxProps<Theme>;
  additionalProps?: Pick<ModalProps, 'keepMounted'>;
}

const ModalTemplate: React.FC<ModalTemplateProps> = ({
  open,
  onDismiss,
  onClose,
  children,
  sx,
  withCloseButton = true,
  header,
  footer,
  desktopWidth = 'max(600px, 60vw)',
  desktopHeight = '90vh',
  closeButtonSx,
  additionalProps,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showHeader, setShowHeader] = useState(false);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const headerProperties = useElementCurrentProperties(headerRef as React.RefObject<HTMLElement>);
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = e.currentTarget.scrollTop;
    const headerElement = headerRef.current;
    if (headerElement) {
      const titleHeight = headerProperties.offsetHeight;
      setShowHeader(scrollTop > titleHeight);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onDismiss}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
      {...additionalProps}
    >
      <Box
        sx={{
          bgcolor: 'background.paper',
          width: isMobile ? '100%' : desktopWidth,
          height: isMobile ? '100%' : desktopHeight,
          borderRadius: isMobile ? 0 : 2,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <Box sx={{ display: 'flex', width: '100%', minHeight: '56px', }}>
          {header}
        </Box>
        {withCloseButton && (
          <OpacityButton
            onClick={onClose}
            sx={{
              p: 1,
              borderRadius: 2,
              position: 'absolute',
              top: 5,
              right: 8,
              zIndex: 2,
              ...closeButtonSx
            }}
          >
            <IoMdClose size={24} />
          </OpacityButton>
        )}
        <Box
          onScroll={handleScroll}
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            overflowY: 'auto',
            '& .content-header': {
              opacity: showHeader ? 0 : 1,
              transition: 'opacity 0.3s ease-in-out',
              visibility: showHeader ? 'hidden' : 'visible',
            },
            '&::-webkit-scrollbar': {
              width: 8,
            },
            '&::-webkit-scrollbar-track': {
              bgcolor: 'grey.100',
            },
            '&::-webkit-scrollbar-thumb': {
              bgcolor: 'grey.400',
              borderRadius: 1,
            },
          }}
        >
          {children}
        </Box>
        {footer}
      </Box>
    </Modal>
  );
};

export default ModalTemplate;
