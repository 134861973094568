import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import SubMenuHeader from "../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_ERR_MSG,
  DEFAULT_SNACKBAR_PROPS,
  ROUTE_NAME,
  RouteLabel,
  RoutePath,
} from "../../utils/constant";
import MemberItem from "../../components/MemberItem/MemberItem";
import { COLOR } from "../../utils/color";
import TextInput from "../../components/Forms/TextInput/TextInput";
import { IoIosSearch, IoMdPersonAdd } from "react-icons/io";
import { GiSettingsKnobs } from "react-icons/gi";
import OpacityButton from "../../components/OpacityButton/OpacityButton";
import { FilterBtn } from "./TeamMember.styles";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useElementHeight } from "../../hooks/useElementHeight";
import ActionBottomSheet from "../../components/BottomSheets/ActionBottomSheet";
import { useGetTeamMembers } from "../../query/queries";
import { GetTeamMembers, TeamMemberStatus } from "../../api/request.types";
import { useUpdateTeamMember } from "../../query/mutations";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import { isAxiosError } from "axios";
import { useSyncObjectQueryParam } from "../../hooks/useSyncQueryParam";
import useDebouncedFunction from "../../hooks/useDebouncedFunction";
import TeamMemberFilterBottomSheet from "../../components/Modal/TeamMemberFilterModal";
import { errorLogger } from "../../utils/logger";

type TeamMemberBottomSheetType = {
  filter: boolean;
};
type TeamProfile = GetTeamMembers;

export type TeamMemberFilters = {
  status?: TeamMemberStatus;
  productID?: string;
  search?: string;
  sortFields?: string;
  sortDirections?: string;
};

const TeamMember = () => {
  const DEFAULT_SELECTED_FILTER: TeamMemberFilters = useMemo(
    () => ({
      status: TeamMemberStatus.ACTIVE,
      sortDirections: "asc",
      search: undefined,
      sortFields: undefined,
    }),
    []
  );
  const navigate = useNavigate();
  const headerRef = useRef<HTMLElement>(null);
  const headerHeight = useElementHeight(headerRef);
  const [filters, setFilters] = useSyncObjectQueryParam<TeamMemberFilters>(
    "q",
    { ...DEFAULT_SELECTED_FILTER }
  );
  const getTeamMembersQuery = useGetTeamMembers(filters);
  const updateTeamMember = useUpdateTeamMember();
  const isLoading = updateTeamMember.isLoading || getTeamMembersQuery.isLoading;

  const [bottomSheetState, setBottomSheetState] =
    useState<TeamMemberBottomSheetType>({
      filter: false,
    });
  const [viewMoreDetail, setViewMoreDetail] = useState<TeamProfile | null>(
    null
  );

  const activeTeamMemberCount = React.useMemo(() => {
    const count = getTeamMembersQuery.data?.data.reduce((count, teamMember) => {
      if (teamMember.status === TeamMemberStatus.ACTIVE) {
        return count + 1;
      }
      return count;
    }, 0);
    return count;
  }, [getTeamMembersQuery.data?.data]);

  const toggleBottomSheet = (
    name: keyof TeamMemberBottomSheetType,
    value?: boolean
  ) => {
    setBottomSheetState((state) => ({
      ...state,
      [name]: value === undefined ? !state?.[name] : value,
    }));
  };

  const onClickAddMember = () => {
    navigate(RoutePath[ROUTE_NAME.TEAM_ADD_MEMBER]);
  };

  const onClickFilter = () => {
    toggleBottomSheet("filter", true);
  };

  const onClickEdit = (id?: string) => {
    if (!id) return;
    navigate(`${RoutePath[ROUTE_NAME.TEAM_EDIT_MEMBER]}/${id}`);
  };

  const onChangeSearch = (searchText: string) => {
    setFilters((prevFilters) => ({ ...prevFilters, search: searchText }));
  };
  const debouncedSearch = useDebouncedFunction(onChangeSearch, 500);

  const _renderTeamMember = () => {
    const teamMembers: Array<TeamProfile> =
      getTeamMembersQuery.data?.data ?? [];
    if (isLoading) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    if (teamMembers.length === 0)
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>Belum ada anggota</Typography>
        </Box>
      );
    return teamMembers.map((member, index) => (
      <MemberItem
        key={index}
        name={member.name}
        email={member.email}
        status={member.status === TeamMemberStatus.ACTIVE ? "Aktif" : "Diarsip"}
        onClick={() => {
          onClickEdit(member.teamMemberID);
        }}
        onClickViewMore={() => {
          setViewMoreDetail(member);
        }}
      />
    ));
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <SubMenuHeader
        ref={headerRef}
        leftNav={{
          icon: <FaArrowLeft />,
          onClick: () => {
            navigate(-1);
          },
        }}
        text={RouteLabel[ROUTE_NAME.TEAM_MEMBER]}
      />
      <Box pb={8}>
        <Box
          sx={{
            pt: 2,
            pb: 2,
            position: "sticky",
            top: `${headerHeight}px`,
            backgroundColor: COLOR.bg1,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1, px: 2 }}>
            <TextInput
              sx={{ flex: 1 }}
              startEndorment={
                <IoIosSearch size="24px" color={COLOR.neutral400} />
              }
              textInputProps={{
                placeholder: "Nama Anggota",
                onChange: (e) => {
                  debouncedSearch(e.target.value);
                },
              }}
            />
            <OpacityButton sx={FilterBtn} onClick={onClickFilter}>
              <GiSettingsKnobs size="24px" />
            </OpacityButton>
            <OpacityButton sx={FilterBtn} onClick={onClickAddMember}>
              <IoMdPersonAdd size="24px" />
            </OpacityButton>
          </Box>
        </Box>
        <Box
          px={2}
          pt={1}
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          {_renderTeamMember()}
        </Box>
      </Box>

      <ActionBottomSheet
        open={viewMoreDetail !== null}
        bottomSheetProps={{
          onDismiss: () => {
            setViewMoreDetail(null);
          },
        }}
        onClose={() => {
          setViewMoreDetail(null);
        }}
      >
        <Box display="flex" flexDirection="column" gap={1} p={2}>
          <CustomButton
            variant="text-secondary"
            sx={{ justifyContent: "flex-start" }}
            disabled={!viewMoreDetail}
            onClick={() => onClickEdit(viewMoreDetail?.teamMemberID)}
          >
            Edit
          </CustomButton>
          <CustomButton
            variant="text-secondary"
            sx={{ justifyContent: "flex-start" }}
            onClick={() =>
              navigate(
                `${RoutePath[ROUTE_NAME.SCHEDULE_V2]}?member=${
                  viewMoreDetail?.teamMemberID
                }`
              )
            }
          >
            Lihat Kalender Jadwal
          </CustomButton>
          <CustomButton
            variant="text-secondary"
            sx={{ justifyContent: "flex-start" }}
            onClick={() =>
              navigate(
                `${RoutePath[ROUTE_NAME.TEAM_SCHEDULED_WORKING_HOUR]}/${
                  viewMoreDetail?.teamMemberID
                }?date=${dayjs().format("YYYY-MM-DD")}`
              )
            }
          >
            Lihat Jadwal Shift
          </CustomButton>
          <CustomButton
            variant="text-secondary"
            sx={{ justifyContent: "flex-start" }}
            onClick={() =>
              navigate(RoutePath[ROUTE_NAME.TEAM_SCHEDULED_TIME_OFF], {
                state: {
                  teamMemberID: viewMoreDetail?.teamMemberID,
                  teamMemberName: viewMoreDetail?.name,
                  startDate: dayjs().format("YYYY-MM-DD"),
                },
              })
            }
          >
            Tambah Waktu Kosong
          </CustomButton>
          <CustomButton
            variant="text-secondary"
            sx={{ justifyContent: "flex-start" }}
            onClick={async () => {
              try {
                if (!viewMoreDetail) return;
                if (
                  activeTeamMemberCount === 1 &&
                  viewMoreDetail.status !== TeamMemberStatus.INACTIVE
                ) {
                  enqueueSnackbar({
                    ...DEFAULT_SNACKBAR_PROPS,
                    variant: "error",
                    message: "Harus ada 1 team member yang Aktif!",
                  });
                  return;
                }
                await updateTeamMember.mutateAsync({
                  teamMemberID: viewMoreDetail.teamMemberID,
                  status:
                    viewMoreDetail.status === TeamMemberStatus.ACTIVE
                      ? TeamMemberStatus.INACTIVE
                      : TeamMemberStatus.ACTIVE,
                });
                setViewMoreDetail(null);
                await getTeamMembersQuery.refetch();
              } catch (error) {
                if (isAxiosError(error) && error.response?.status === 409) {
                  const msg = error.response?.data?.message || "";
                  let toastMsg = "Telah terjadi kesalahan. Silahkan coba lagi.";
                  switch (msg) {
                    case "future-schedule-exists":
                      toastMsg =
                        "Tim member ini memiliki jadwal yang akan datang. Silahkan membatalkan jadwal atau mengganti penugasan tim member terlebih dahulu";
                      break;
                    default:
                      break;
                  }
                  enqueueSnackbar(toastMsg, {
                    variant: "error",
                    ...DEFAULT_SNACKBAR_PROPS,
                    autoHideDuration: 3000,
                  });
                }
                console.error(error);
              }
            }}
          >
            {viewMoreDetail?.status === TeamMemberStatus.ACTIVE
              ? "Arsip"
              : "Aktifkan"}
          </CustomButton>
        </Box>
      </ActionBottomSheet>

      <TeamMemberFilterBottomSheet
        initialData={filters}
        modalProps={{
          open: bottomSheetState.filter,
          onClose: () =>
            setBottomSheetState((prev) => ({ ...prev, filter: false })),
          bottomSheetProps: {
            onDismiss: () =>
              setBottomSheetState((prev) => ({ ...prev, filter: false })),
          },
        }}
        onApply={async () => {
          try {
            await getTeamMembersQuery.refetch();
          } catch (error) {
            errorLogger(error);
            enqueueSnackbar({
              ...DEFAULT_SNACKBAR_PROPS,
              variant: "error",
              message: DEFAULT_ERR_MSG,
            });
          }
          setBottomSheetState((prev) => ({ ...prev, filter: false }));
        }}
      />
    </Box>
  );
};

export default TeamMember;
