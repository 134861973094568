export const getQueryParams = (query: object): string => {
  const params = new URLSearchParams();
  Object.entries(query).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => params.append(key, v));
    } else if (!!value) {
      params.append(key, value);
    }
  });
  return params.toString();
};
