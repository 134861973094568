import { Box, Typography } from '@mui/material';
import { ROUTE_NAME, RouteIcon, RouteLabel, RoutePath } from '../../utils/constant';
import { useNavigate } from 'react-router-dom';
import MenuListButton from '../../components/MenuListButton/MenuListButton';


const Team = () => {
  const navigate = useNavigate();
  const TeamMemberIcon = RouteIcon[ROUTE_NAME.TEAM_MEMBER];
  const TeamScheduledShiftIcon = RouteIcon[ROUTE_NAME.TEAM_SCHEDULED_SHIFT];

  return (
    <Box>
      <Box sx={{ p: 2, pt: 3, pb: 5, }}>
        <Typography variant='h6' pb={1}>Tim</Typography>
        <MenuListButton
          buttons={[
            {
              Icon: TeamMemberIcon,
              title: RouteLabel[ROUTE_NAME.TEAM_MEMBER],
              onClick: () => {
                navigate(RoutePath[ROUTE_NAME.TEAM_MEMBER]);
              }
            },
            {
              Icon: TeamScheduledShiftIcon,
              title: RouteLabel[ROUTE_NAME.TEAM_SCHEDULED_SHIFT],
              onClick: () => {
                navigate(RoutePath[ROUTE_NAME.TEAM_SCHEDULED_SHIFT]);
              }
            }
          ]}
        />
      </Box>
    </Box>
  )
};

export default Team;