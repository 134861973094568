import { Box, Divider, Typography } from "@mui/material";
import SubMenuHeader from "../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft, FaChevronDown } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DEFAULT_SNACKBAR_PROPS,
  ROUTE_NAME,
  RouteLabel,
  RoutePath,
} from "../../utils/constant";
import SwitchTabs from "../../components/SwitchTab/SwitchTab";
import { useSyncQueryParam } from "../../hooks/useSyncQueryParam";
import { TeamScheduleAccordion } from "../../components/TeamScheduleAccordion/TeamScheduleAccordion";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import React, { useState, Fragment, useEffect } from "react";
import { COLOR } from "../../utils/color";
import OpacityButton from "../../components/OpacityButton/OpacityButton";
import WeekScheduleAccordion from "../../components/WeekScheduleAccordion/WeekScheduleAccordion";
import WeeklyScheduleBottomSheet from "../../components/BottomSheets/WeeklyScheduleBottomSheet";
import TimeScheduleButton from "../../components/TimeScheduleButton/TimeScheduleButton";
import ShiftActionBottomSheet, {
  ShiftActionBottomSheetPropsI,
} from "../../components/BottomSheets/ShiftBottomSheet";
import ShiftScheduleBottomSheet, {
  ShiftSchedule,
  ShiftScheduleBottomSheetPropsI,
} from "../../components/BottomSheets/ShiftScheduleBottomSheet";
import isEmpty from "lodash.isempty";
import { useGetTeamMemberShifts } from "../../query/queries";
import {
  useDeleteTeamMemberShift,
  useUpdateTeamMemberShift,
} from "../../query/mutations";
import { enqueueSnackbar } from "notistack";
import { formatDuration } from "../../utils/helper";
import { TimeOffType } from "../../api/request.types";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

enum TAB_VALUES {
  TEAM = "team",
  WEEK = "week",
}

export interface TimeRange {
  startTime: string;
  endTime: string;
  duration: number; // in minutes
}

export interface Shift {
  date: Date;
  duration: number; // in minutes
  shifts: TimeRange[];
}

export interface TeamShift {
  teamMemberID: number;
  teamMemberName: string;
  totalDuration: number;
  durationUnit: string; // in minutes
  shifts: Shift[];
  timeOff: TeamShiftOff[];
}

export interface TeamShiftOff {
  endDate: string;
  endTime: string;
  startDate: string;
  startTime: string;
  timeOffApproval: boolean;
  timeOffID: string;
  timeOffType: TimeOffType;
}

interface GroupedSchedule {
  date: Date;
  members: Array<{
    id: number;
    name: string;
    timeRange: TimeRange[];
    timeOff: TeamShiftOff[];
  }>;
}

interface Member {
  id: number;
  name: string;
  timeRange: TimeRange[];
}

type ShiftScheduleBottomSheetState =
  | ({
      id: string;
      name: string;
      date: Date;
    } & ShiftScheduleBottomSheetPropsI["data"])
  | null;

const formatDateRange = (range: {
  start: Date | null;
  end: Date | null;
}): string => {
  if (!range.start || !range.end) return "";

  const startDate = dayjs(range.start);
  const endDate = dayjs(range.end);

  const isSameDay = startDate.isSame(endDate, "day");
  const isSameMonth = startDate.isSame(endDate, "month");
  const isSameYear = startDate.isSame(endDate, "year");

  if (isSameDay) {
    return startDate.format("D MMM, YYYY");
  }

  if (isSameMonth && isSameYear) {
    return `${startDate.format("D")} - ${endDate.format("D MMM, YYYY")}`;
  }

  if (isSameYear) {
    return `${startDate.format("D MMM")} - ${endDate.format("D MMM, YYYY")}`;
  }

  return `${startDate.format("D MMM, YYYY")} - ${endDate.format(
    "D MMM, YYYY"
  )}`;
};

const groupSchedulesByDate = (
  teams: TeamShift[] | undefined
): GroupedSchedule[] => {
  const schedulesByDate: Record<string, any[]> = {};

  teams?.forEach((team) => {
    team.shifts.forEach((schedule) => {
      const dateKey = dayjs(schedule.date).format("YYYY-MM-DD");

      if (!schedulesByDate[dateKey]) {
        schedulesByDate[dateKey] = [];
      }

      schedulesByDate[dateKey].push({
        id: team.teamMemberID,
        name: team.teamMemberName,
        timeRange: schedule.shifts,
        timeOff: team.timeOff,
      });
    });
  });

  return Object.entries(schedulesByDate).map(([date, members]) => ({
    date: new Date(date),
    members,
  }));
};

const useTeamShifts = (initialTeamShifts: TeamShift[] | undefined) => {
  const [teamShifts, setTeamShifts] = useState<TeamShift[]>(
    initialTeamShifts ?? []
  );
  const [expandedTeams, setExpandedTeams] = useState<number[]>([]);

  useEffect(() => {
    setTeamShifts(initialTeamShifts ?? []);
  }, [initialTeamShifts]);

  const handleToggleTeam = (teamId: number) => {
    setExpandedTeams((prev) =>
      prev.includes(teamId)
        ? prev.filter((id) => id !== teamId)
        : [...prev, teamId]
    );
  };

  return {
    teamShifts,
    setTeamShifts,
    expandedTeams,
    handleToggleTeam,
  };
};
const useWeekSchedules = (initialSchedules: TeamShift[] | undefined) => {
  const [expandedDates, setExpandedDates] = useState<string[]>([]);
  const groupedSchedules = groupSchedulesByDate(initialSchedules ?? []);

  const handleToggleDate = (date: Date) => {
    const dateKey = dayjs(date).format("YYYY-MM-DD");
    setExpandedDates((prev) =>
      prev.includes(dateKey)
        ? prev.filter((d) => d !== dateKey)
        : [...prev, dateKey]
    );
  };

  return {
    groupedSchedules,
    expandedDates,
    handleToggleDate,
  };
};

const isDateWithinTimeOff = (
  scheduleDate: string,
  timeOff: TeamShiftOff
): boolean => {
  const date = dayjs(scheduleDate);
  const timeOffStart = dayjs(timeOff.startDate);
  const timeOffEnd = dayjs(timeOff.endDate);

  return (
    date.isSameOrAfter(timeOffStart, "day") &&
    date.isSameOrBefore(timeOffEnd, "day")
  );
};

const TeamScheduledShifts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabValue, setTabValue] = useSyncQueryParam<TAB_VALUES>(
    "tab",
    TAB_VALUES.TEAM,
    {
      allowedValues: [TAB_VALUES.TEAM, TAB_VALUES.WEEK],
      onInvalidValue: () => console.warn("Invalid tab value!"),
    }
  );

  const [selectedDate, setSelectedDate] = useSyncQueryParam<string>(
    "date",
    dayjs().format("YYYY-MM-DD"),
    {
      validateValue: (value) => dayjs(value, "YYYY-MM-DD", true).isValid(),
      onInvalidValue: () => {
        console.warn("Invalid date format! Expected YYYY-MM-DD");
        return dayjs().format("YYYY-MM-DD");
      },
    }
  );

  const [isLoading, setIsLoading] = useState(false);
  const [selectedRange, setSelectedRange] = useState<{
    start: Date | null;
    end: Date | null;
  }>({
    start: dayjs(selectedDate, "YYYY-MM-DD").startOf("week").toDate(),
    end: dayjs(selectedDate, "YYYY-MM-DD").endOf("week").toDate(),
  });
  const [bottomSheetState, setBottomSheetState] = useState<{
    schedule: boolean;
    shift:
      | (ShiftActionBottomSheetPropsI["data"] & { timeOff: TeamShiftOff })
      | null;
    shiftSchedule: ShiftScheduleBottomSheetState;
  }>({
    schedule: false,
    shift: null,
    shiftSchedule: null,
  });

  const { data: teamMemberShifts, refetch } = useGetTeamMemberShifts({
    startDate: dayjs(selectedDate).startOf("week").format("YYYY-MM-DD"),
    endDate: dayjs(selectedDate).endOf("week").format("YYYY-MM-DD"),
  });
  const { teamShifts, expandedTeams, handleToggleTeam } =
    useTeamShifts(teamMemberShifts);
  const { groupedSchedules, expandedDates, handleToggleDate } =
    useWeekSchedules(teamMemberShifts);
  const updateTeamShifts = useUpdateTeamMemberShift();
  const deleteTeamShifts = useDeleteTeamMemberShift();

  const onApply = async (data: ShiftSchedule) => {
    try {
      setIsLoading(true);
      await updateTeamShifts.mutateAsync({
        teamMemberID: data.id,
        date: dayjs(data.date).format("YYYY-MM-DD"),
        shifts: data.shifts,
      });
      refetch();
      setBottomSheetState((e) => ({ ...e, shiftSchedule: null }));
      enqueueSnackbar({
        ...DEFAULT_SNACKBAR_PROPS,
        variant: "success",
        message: "Berhasil menyimpan jadwal shift",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar({
        ...DEFAULT_SNACKBAR_PROPS,
        variant: "error",
        message: "Gagal menyimpan jadwal shift",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = async (id?: string, date?: string) => {
    try {
      if (!id || !date) {
        throw new Error("Invalid shift data");
      }

      setIsLoading(true);
      await updateTeamShifts.mutateAsync({
        teamMemberID: id,
        date: dayjs(date).format("YYYY-MM-DD"),
        shifts: [],
      });
      refetch();
      setBottomSheetState((e) => ({ ...e, shiftSchedule: null, shift: null }));
      enqueueSnackbar({
        ...DEFAULT_SNACKBAR_PROPS,
        variant: "success",
        message: "Berhasil menghapus jadwal shift",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar({
        ...DEFAULT_SNACKBAR_PROPS,
        variant: "error",
        message: "Gagal menghapus jadwal shift",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  const _renderByTeam = () => {
    return (
      <Box>
        {teamShifts.map((memberShift, index) => {
          return (
            <Fragment key={memberShift.teamMemberID}>
              <TeamScheduleAccordion
                title={memberShift.teamMemberName}
                subtitle={formatDuration(memberShift.totalDuration / 60)}
                open={expandedTeams.includes(memberShift.teamMemberID)}
                onToggle={() => handleToggleTeam(memberShift.teamMemberID)}
              >
                <Box>
                  {memberShift.shifts.length === 0 ? (
                    <Box p={2} sx={{ backgroundColor: COLOR.neutral50 }}>
                      <Typography fontSize={12} textAlign="center">
                        Tidak Ada Jadwal
                      </Typography>
                    </Box>
                  ) : (
                    memberShift.shifts.map((schedule, scheduleIndex) => {
                      const dateKey = dayjs(schedule.date).format("YYYY-MM-DD");
                      return (
                        <React.Fragment key={scheduleIndex}>
                          <TimeScheduleButton
                            name={dayjs(schedule.date).format(
                              "ddd, DD MMM YYYY"
                            )}
                            timeRanges={schedule.shifts}
                            timeOff={memberShift.timeOff.filter((time) =>
                              isDateWithinTimeOff(dateKey, time)
                            )}
                            onClick={() => {
                              setBottomSheetState((e) => ({
                                ...e,
                                shift: {
                                  date: dateKey,
                                  id: memberShift.teamMemberID.toString() || "",
                                  name: memberShift.teamMemberName,
                                  timeRange: schedule.shifts,
                                  timeOff: memberShift.timeOff[0],
                                },
                              }));
                            }}
                          />
                          {scheduleIndex !== memberShift.shifts.length - 1 && (
                            <Divider />
                          )}
                        </React.Fragment>
                      );
                    })
                  )}
                </Box>
              </TeamScheduleAccordion>
              {index !== teamShifts.length - 1 && <Divider />}
            </Fragment>
          );
        })}
      </Box>
    );
  };

  const _renderByWeek = () => {
    return (
      <Box>
        {groupedSchedules.map((schedule) => {
          const dateKey = dayjs(schedule.date).format("YYYY-MM-DD");

          return (
            <WeekScheduleAccordion
              key={schedule.date.toString()}
              date={schedule.date}
              members={schedule.members as Member[]}
              open={expandedDates.includes(dateKey)}
              onToggle={() => handleToggleDate(schedule.date)}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {schedule.members.map((member, index) => (
                  <Fragment key={member.id}>
                    <TimeScheduleButton
                      showNameInitial
                      name={member.name}
                      timeRanges={member.timeRange}
                      timeOff={member.timeOff.filter((time) =>
                        isDateWithinTimeOff(dateKey, time)
                      )}
                      onClick={() => {
                        setBottomSheetState((e) => ({
                          ...e,
                          shift: {
                            date: dayjs(schedule.date).format("YYYY-MM-DD"),
                            id: member.id.toString(),
                            name: member.name,
                            timeRange: member.timeRange,
                            timeOff: member.timeOff[0],
                          },
                        }));
                      }}
                    />
                    {index !== schedule.members.length - 1 && <Divider />}
                  </Fragment>
                ))}
              </Box>
            </WeekScheduleAccordion>
          );
        })}
      </Box>
    );
  };
  const _renderByTab = (tab: TAB_VALUES) => {
    switch (tab) {
      case TAB_VALUES.TEAM:
        return _renderByTeam();

      case TAB_VALUES.WEEK:
        return _renderByWeek();
    }
  };

  return (
    <Box>
      <SubMenuHeader
        leftNav={{
          icon: <FaArrowLeft />,
          onClick: () => {
            navigate(RoutePath[ROUTE_NAME.TEAM]);
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body2"
            fontWeight={600}
            textTransform="capitalize"
          >
            {RouteLabel[ROUTE_NAME.TEAM_SCHEDULED_SHIFT]}
          </Typography>
          <OpacityButton
            component="span"
            sx={{ height: "fit-content" }}
            onClick={() => {
              setSelectedDate(dayjs(selectedRange.start).format("YYYY-MM-DD"));
              setBottomSheetState((e) => ({ ...e, schedule: true }));
            }}
          >
            <Typography
              variant="caption"
              lineHeight={1}
              textTransform="capitalize"
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              gap={1}
              pt="4px"
            >
              {formatDateRange(selectedRange)} <FaChevronDown />
            </Typography>
          </OpacityButton>
        </Box>
      </SubMenuHeader>
      <Box pt={3} pb={24}>
        <Box px={2} pb={2}>
          <Typography variant="h6" pb={1}>
            {RouteLabel[ROUTE_NAME.TEAM_SCHEDULED_SHIFT]}
          </Typography>
          <SwitchTabs
            options={[
              { label: "Tim", value: TAB_VALUES.TEAM },
              { label: "Mingguan", value: TAB_VALUES.WEEK },
            ]}
            value={tabValue}
            handleChange={(e) => setTabValue(e as TAB_VALUES)}
          />
        </Box>
        {_renderByTab(tabValue)}
      </Box>
      <WeeklyScheduleBottomSheet
        currentMonth={dayjs(selectedDate, "YYYY-MM-DD").toDate()}
        onMonthChange={(date) => {
          setSelectedDate(dayjs(date).format("YYYY-MM-DD"));
        }}
        open={bottomSheetState.schedule}
        onDismiss={() =>
          setBottomSheetState((e) => ({ ...e, schedule: false }))
        }
        selectedRange={selectedRange}
        onApply={(range) => {
          setSelectedRange({
            start: range.start,
            end: range.end,
          });
          setSelectedDate(dayjs(range.start).format("YYYY-MM-DD"));
        }}
      />
      <ShiftActionBottomSheet
        data={bottomSheetState.shift}
        onDismiss={() => setBottomSheetState((e) => ({ ...e, shift: null }))}
        onAddorEdit={(isAddMode) => {
          setBottomSheetState((state) => {
            const shiftScheduleData: ShiftScheduleBottomSheetState = {
              id: state.shift?.id || "",
              name: state.shift?.name || "",
              date: dayjs(state.shift?.date).toDate(),
              shifts: (state.shift?.timeRange || []).map((range) => ({
                startTime: range.startTime,
                endTime: range.endTime,
                duration: range.duration,
              })),
            };
            return {
              ...state,
              shiftSchedule: shiftScheduleData,
              shift: null,
            };
          });
        }}
        onDelete={() => {
          onDelete(bottomSheetState.shift?.id, bottomSheetState.shift?.date);
        }}
        onManageRegularShift={() => {
          navigate(
            `${RoutePath[ROUTE_NAME.TEAM_SCHEDULED_WORKING_HOUR]}/${
              bottomSheetState.shift?.id
            }?date=${dayjs(bottomSheetState.shift?.date).format("YYYY-MM-DD")}`
          );
        }}
        onAddEmptyShift={() => {
          navigate(
            `${RoutePath[ROUTE_NAME.TEAM_SCHEDULED_TIME_OFF]}/${
              bottomSheetState.shift?.timeOff?.timeOffID ?? ""
            }`,
            {
              state: {
                teamMemberID: bottomSheetState.shift?.id,
                teamMemberName: bottomSheetState.shift?.name,
                startDate: dayjs(bottomSheetState.shift?.date).format(
                  "YYYY-MM-DD"
                ),
                ...bottomSheetState.shift,
              },
            }
          );
        }}
      />
      <ShiftScheduleBottomSheet
        isLoading={isLoading}
        open={!isEmpty(bottomSheetState.shiftSchedule)}
        onDismiss={() =>
          setBottomSheetState((e) => ({ ...e, shiftSchedule: null }))
        }
        header={
          <Box sx={{ px: 2 }}>
            <Typography variant="h6">
              Jadwal Shift "{bottomSheetState.shiftSchedule?.name}"
            </Typography>
            <Typography variant="body1">
              {dayjs(bottomSheetState.shiftSchedule?.date).format(
                "ddd, DD MMM YYYY"
              )}
            </Typography>
          </Box>
        }
        additionalContent={
          <Typography variant="caption">
            Kamu sedang mengatur shift untuk hari ini saja. Untuk mengatur Shift
            Regular, klik tombol di bawah ini.
            <OpacityButton component="span" ml={"4px"} disabled={isLoading}>
              <Typography variant="caption" color={COLOR.primary500}>
                Atur Shift Regular "{bottomSheetState.shiftSchedule?.name}"
              </Typography>
            </OpacityButton>
          </Typography>
        }
        onApply={onApply}
        onDelete={onDelete}
        data={bottomSheetState.shiftSchedule}
      />
    </Box>
  );
};

export default TeamScheduledShifts;
