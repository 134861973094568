import React, { useEffect, useMemo, useState } from "react";
import { COLOR } from "../../utils/color";
import ModalTemplate, { ModalTemplateProps } from "./ModalTemplate";
import { Backdrop, Box, CircularProgress, Divider, Menu, Tab, Tabs, Typography } from "@mui/material";
import { DOT_SEPARATOR } from "../../utils/constant";
import CustomButton from "../CustomButton/CustomButton";
import { FaCheck, FaPrint, FaWhatsapp } from "react-icons/fa";
import dayjs from "dayjs";
import TicketSeparator from "../TicketSeparator/TicketSeparator";
import RowKeyValue from "../RowKeyValue/RowKeyValue";
import { formatCurrency, formatPhoneNumber, generateTransactionMessage, getStatusTextPropsByStatus, sendWhatsAppMessage } from "../../utils/helper";
import OpacityButton from "../OpacityButton/OpacityButton";
import { GrDocumentMissing } from "react-icons/gr";
import { IoMdMore } from "react-icons/io";
import { APIGetTransactionById } from "../../api/request.types";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "../../redux/reducers";
import { useGetTransactionById } from "../../query/queries";
import {
  useUpdateTransactionAdhoc,
  useUpdateTransactionScheduleEvent,
} from "../../query/mutations";
import { openDialog } from "../../redux/reducers/confirmationDialog";
import { useNavigate } from "react-router-dom";
import { PAYMENT_STATUS } from "../../types/globalTypes";
import { TbFilePencil } from "react-icons/tb";
import EditSaleDetailModal from "./EditSaleDetailModal";
import { useGetCompanyByIdV2 } from "../../query/queries";
import { handlePrint } from "../../helpers/printHelper/printHelper";
import { TRANSACTION_TYPE } from "../../types/globalTypes";
import { TIME_FORMAT } from "../../utils/constant";
import { Z_INDEX } from "../GroupSchedule/config";
import { LuScreenShare } from "react-icons/lu";
import { errorLogger } from "../../utils/logger";
import TextInput from "../Forms/TextInput/TextInput";
import PhoneIcon from "../../assets/svg/PhoneIcon";
import { phoneNumberJoi } from "../../joiSchema/general";
import isEmpty from "lodash.isempty";

interface SaleModalProps {
  modalProps: Omit<ModalTemplateProps, "children">;
  transactionID: string;
  onCloseAll: () => void;
}

enum SALE_TAB {
  DETAIL = "detail",
  // ACTIVITY = 'activity',
}

const titleSx = {
  fontWeight: 600,
  fontSize: 16,
};
const SaleModal: React.FC<SaleModalProps> = ({
  modalProps,
  transactionID,
  onCloseAll,
}) => {
  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );
  const companyId = userReducer.data.companyId;
  const companyV2Query = useGetCompanyByIdV2(companyId || "");
  const companyDetail = React.useMemo(() => {
    return companyV2Query.data?.data?.data;
  }, [companyV2Query.data?.data?.data]);
  const companyAddress = companyDetail?.location?.completeAddress;
  const companyName = companyDetail?.name;

  const companyDomainName = React.useMemo(() => {
    return companyV2Query.data?.data?.data?.companyDomainName;
  }, [companyV2Query.data?.data?.data]);

  const getTransaction = useGetTransactionById(transactionID);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<SALE_TAB>(SALE_TAB.DETAIL);

  const transaction: APIGetTransactionById = useMemo(() => {
    if (getTransaction.data && !getTransaction.isLoading) {
      return getTransaction.data.data.data;
    }
    return {
      _id: "",
      transactionID: "",
      companyID: "",
      date: "",
      fullName: "",
      email: "",
      phoneNumber: "",
      productDetail: [],
      location: "",
      total: 0,
      adminPrice: 0,
      productPrice: 0,
      notes: "",
      paymentStatus: PAYMENT_STATUS.PAID,
      paidWith: "",
      snapToken: "",
      snapRedirectURL: "",
      refundReason: "",
      isManuallyAdded: false,
      transactionTimestamp: {
        paidAt: "",
        requestRefundAt: null,
        refundedAt: null,
        refundCancelledAt: null,
        cancelledAt: null,
        _id: "",
      },
      paidAmount: 0,
      teamMemberIDs: [],
      transactionType: undefined,
      paymentDetail: undefined,
      isWalkIn: false,
      eventID: "",
      createdAt: "",
      updatedAt: "",
      companyLogo: "",
      timezone: "",
    };
  }, [getTransaction.data, getTransaction.isLoading]);

  const navigateToDInvoice = `/${companyDomainName}/inv/${transaction?.transactionID}`;

  const handlePrintButton = () => {
    handlePrint(`invoice-${transaction?.transactionID}`);
  };

  const [phone, setPhone] = useState<string>("");
  const [error, setError] = useState<string | undefined>();
  const validatePhone = (phoneNum: string) => {
    if (isEmpty(phoneNum)) {
      return setError(undefined);
    };
    const validation = phoneNumberJoi.validate(`62${phoneNum}`);
    setError(validation.error ? validation.error.message : undefined);
  }
  useEffect(() => {
    if (transaction.phoneNumber) {
      const rawPhone = transaction.phoneNumber.replace(/^\+62/, "");
      validatePhone(rawPhone);
      setPhone(rawPhone);
    } else {
      setPhone("")
    }
  }, [transaction.phoneNumber]);

  const handleSendInvoice = (phoneNumber: string) => {
    try {
      const fullPhoneNumber = `62${phoneNumber.replace(/\D/g, "")}`;
      const message = generateTransactionMessage(
        transaction.transactionID,
        `${window.location.origin}${navigateToDInvoice}`
      );

      sendWhatsAppMessage(fullPhoneNumber, message);
    } catch (error) {
      errorLogger(error);
    }
  };
  const isSendDisabled = getTransaction.isLoading || isEmpty(phone) || !!error;

  const updateTransactionMutation = useUpdateTransactionScheduleEvent();
  const updateTransactionAdhocMutation = useUpdateTransactionAdhoc();
  const [modalState, setModalState] = useState<{
    editSale: APIGetTransactionById | null;
  }>({
    editSale: null,
  });

  const handleChange = (event: React.SyntheticEvent, newValue: SALE_TAB) => {
    setActiveTab(newValue);
  };
  const Invoice = () => {
    const transactionTime = dayjs(transaction.transactionTimestamp?.paidAt).tz(transaction?.timezone || "Asia/Jakarta");
    return (
      <Box
        id={`invoice-${transaction?.transactionID}`}
        sx={{
          backgroundColor: "white",
          borderRadius: 2,
          py: 2,
          maxWidth: "350px",
          mx: "auto",
          px: 2,
        }}
      >
        {/* ===== HEADER ===== */}
        <Box sx={{ textAlign: "center", color: "black" }}>
          <Typography variant="h6" fontWeight={600}>
            {companyName}
          </Typography>
          <Typography variant="subtitle2" textAlign="center">
            Sale #{transaction?.transactionID || ""}
          </Typography>
          <Typography variant="subtitle2" textAlign="center">
            {transactionTime.format("ddd, DD MMM YYYY")} pukul {transactionTime.format("HH:mm")}
          </Typography>
        </Box>

        {/* ===== COMPANY ADDRESS ===== */}
        <Box mt={2}>
          <Typography fontWeight={600}>Alamat Perusahaan</Typography>
          <Typography variant="body2">{companyAddress}</Typography>
        </Box>

        {/* ===== PRODUCT INFORMATION ===== */}
        <Box mt={2}>
          <Typography fontWeight={600}>Informasi Produk</Typography>
          {transaction.productDetail.map((service) =>
            service.scheduleDetails.map((schedule) => (
              <Box key={schedule._id} sx={{ mt: 1, pb: 1, borderBottom: "1px solid lightgray" }}>
                <Typography fontWeight={500}>{service.productName}</Typography>
                <Typography variant="body2" color="gray">
                  {dayjs(schedule.date).format("DD MMM YYYY")}, {schedule.startTime} - {schedule.endTime}
                </Typography>
                <Typography variant="body2" color="gray" sx={{ fontStyle: "italic" }}>
                  {service.teamMemberName}
                </Typography>
                <Typography textAlign="right" fontWeight={500}>
                  {formatCurrency(schedule.price, "IDR")}
                </Typography>
              </Box>
            ))
          )}
        </Box>

        {/* ===== TOTALS ===== */}
        <Box mt={2}>
          <Box display="flex" justifyContent="space-between">
            <Typography fontWeight={600}>Subtotal</Typography>
            <Typography fontWeight={600}>{formatCurrency(transaction?.productPrice || 0, "IDR")}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography fontWeight={600}>Total</Typography>
            <Typography fontWeight={600}>{formatCurrency(transaction?.total || 0, "IDR")}</Typography>
          </Box>
        </Box>

        {/* ===== PAYMENT DETAILS ===== */}
        {transaction?.paymentDetail?.map((payment, idx) => {
          return (
            <Box key={idx} mt={2}>
              <Box display="flex" justifyContent="space-between">
                <Typography>Dibayar ({payment.paidWith.replace(/_/g, " ")})</Typography>
                <Typography fontWeight={600}>{formatCurrency(payment.paidAmount, "IDR")}</Typography>
              </Box>

            </Box>
          );
        })}

        {(transaction.paidAmount ?? transaction.total) !== transaction.total && (
          <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
            <Typography fontWeight={600}>
              {transaction.paidAmount! < transaction.total ? "Sisa Pembayaran" : "Kembalian"}
            </Typography>
            <Typography fontWeight={600}>
              {formatCurrency(Math.abs((transaction?.total || 0) - (transaction?.paidAmount || 0)), "IDR")}
            </Typography>
          </Box>
        )}

        {/* ===== FOOTER ===== */}
        <Box textAlign="center" mt={2}>
          <Typography variant="caption">
            Powered By <strong>Neema</strong>
          </Typography>
        </Box>
      </Box>
    );
  };

  const _renderDetailContent = () => {
    if (getTransaction.isLoading) {
      return (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          minHeight="60vh"
        >
          <CircularProgress color="inherit" />
        </Box>
      )
    }

    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
        {/* <Box
          id={`invoice-${transaction?.transactionID}`}
          sx={{ backgroundColor: "white", borderRadius: 2, py: 2, maxWidth: '500px' }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              color: "black",
              justifyContent: "center",
            }}
          >
            <Typography mt={1} variant="h6" fontWeight={600}>
              {companyName}
            </Typography>
            <Typography mt={1} variant="subtitle1" fontWeight={600}>
              Sale #{transaction?.transactionID || ""}
            </Typography>
            <Typography
              variant="subtitle1"
              color={COLOR.neutral500}
              fontWeight={600}
            >
              {dayjs(transaction.createdAt).format(TIME_FORMAT.DDMMMYYYYHHmm)}
            </Typography>
          </Box>

          <TicketSeparator
            hideCircle
            circleBorderColor={COLOR.bg1}
            circleProps={{ sx: { backgroundColor: COLOR.bg1 } }}
          />

          <Typography px={2} sx={titleSx} pb={1}>
            Alamat Perusahaan
          </Typography>

          <RowKeyValue px={2} title="" value={companyAddress} />

          <TicketSeparator
            hideCircle
            circleBorderColor={COLOR.bg1}
            circleProps={{ sx: { backgroundColor: COLOR.bg1 } }}
          />

          <Typography px={2} sx={titleSx} pb={1}>
            Informasi Produk
          </Typography>
          <Box px={2} display="flex" flexDirection="column" gap={2}>
            {transaction.productDetail.map((service) => (
              <React.Fragment key={service._id}>
                {service.scheduleDetails.map((schedule) => {
                  const productStartTime = service.scheduleDetails.map(
                    (schedule) => schedule.startTime
                  );
                  const productEndTime = service.scheduleDetails.map(
                    (schedule) => schedule.endTime
                  );
                  const teamMemberName = service.teamMemberName;
                  const formattedDate = dayjs(schedule.date).format(TIME_FORMAT.DDMMMYYYY);
                  const productInformation = `${formattedDate}, ${productStartTime} - ${productEndTime}`;
                  return (
                    <Box key={schedule._id}>
                      <RowKeyValue
                        title={`${service.productName}`}
                        value={formatCurrency(schedule.price, "IDR")}
                        titleProps={{
                          sx: {
                            fontWeight: 500,
                            color: COLOR.neutral900,
                          },
                        }}
                        sx={{ my: 0 }}
                      />
                      {transaction.transactionType !==
                        TRANSACTION_TYPE.SALE_ONLY && (
                          <RowKeyValue
                            title={productInformation}
                            sx={{ my: 0 }}
                            titleProps={{
                              sx: {
                                fontWeight: 500,
                                color: COLOR.neutral500,
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                maxWidth: "250px",
                              },
                            }}
                          />
                        )}
                      <RowKeyValue
                        title={teamMemberName}
                        sx={{ my: 0 }}
                        titleProps={{
                          sx: {
                            fontWeight: 500,
                            color: COLOR.neutral500,
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "250px",
                          },
                        }}
                      />
                    </Box>
                  );
                })}
              </React.Fragment>
            ))}
          </Box>
          <TicketSeparator
            hideCircle={false}
            circleBorderColor={COLOR.bg1}
            circleProps={{ sx: { backgroundColor: COLOR.bg1 } }}
          />

          <RowKeyValue
            px={2}
            title="Subtotal"
            value={formatCurrency(transaction?.productPrice || 0, "IDR")}
          />
          <RowKeyValue
            px={2}
            title="Total"
            value={formatCurrency(transaction?.total || 0, "IDR")}
          />
          <TicketSeparator
            hideCircle={false}
            circleBorderColor={COLOR.bg1}
            circleProps={{ sx: { backgroundColor: COLOR.bg1 } }}
          />
          {(transaction?.paymentDetail || []).map((payment, idx) => {
            const transactionTime = dayjs(transaction?.transactionTimestamp?.paidAt)
              .tz(transaction?.timezone || "Asia/Jakarta");
            const formattedTime = `${transactionTime.format(TIME_FORMAT.dddDDMMMYYYY)} pukul ${transactionTime.format(TIME_FORMAT.HHmm)}`;

            return (
              <Box key={idx}>
                <RowKeyValue
                  px={2}
                  title={`Dibayar dengan ${payment.paidWith
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase())}`}
                  titleProps={{
                    sx: {
                      color: COLOR.neutral900,
                    },
                  }}
                  value={formatCurrency(payment.paidAmount, "IDR")}
                />
                <RowKeyValue
                  px={2}
                  title={formattedTime}
                />
              </Box>
            );
          })}
          {(transaction?.paidAmount ?? transaction?.total) !==
            transaction?.total && (
              <RowKeyValue
                px={2}
                title={
                  transaction?.paidAmount! < transaction?.total
                    ? "Sisa Pembayaran"
                    : "Kembalian"
                }
                value={formatCurrency(
                  Math.abs(
                    (transaction?.total || 0) - (transaction?.paidAmount || 0)
                  ),
                  "IDR"
                )}
              />
            )}

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            pt={2}
          >
            <Typography
              textAlign="center"
              color={COLOR.neutral500}
              variant="caption"
            >
              Powered By{" "}
              <Typography component="span" fontWeight="bold">
                Neema
              </Typography>
            </Typography>
          </Box>
        </Box> */}
        <Invoice />
        <Box display="flex" flexDirection="column" gap="10px" mt={2} flex={1} sx={{
          width: '100%',
        }}>
          <Box display="flex" flexDirection="column" gap={1}>
            <CustomButton
              onClick={handlePrintButton}
              sx={{ gap: 1 }}
            >
              <FaPrint size={16} /> Print Invoice
            </CustomButton>
            <Divider sx={{ my: 2 }} />
            <TextInput
              title="Kirim ke Whatsapp"
              startEndorment={
                <>
                  <FaWhatsapp color={COLOR.neutral400} size={24} />
                  <Typography color={COLOR.neutral400} ml={"4px"}>
                    +62
                  </Typography>
                </>
              }
              textInputProps={{
                placeholder: formatPhoneNumber("12345678901"),
                value: formatPhoneNumber(phone),
                onChange: e => setPhone(e.target.value),
                onBlur: e => {
                  const value = e.target.value;
                  validatePhone(value);
                },
                pattern: "[0-9-]*",
                inputMode: "numeric",
              }}
              helper={{
                color: COLOR.danger500,
                text: error,
              }}
              error={Boolean(error)}
              disabled={getTransaction.isLoading}
            />
            <CustomButton
              variant="outlined"
              sx={{ gap: 1 }}
              onClick={() => handleSendInvoice(phone)}
              disabled={isSendDisabled}
            >
              <FaWhatsapp size={18} /> Kirim via WhatsApp
            </CustomButton>

            <CustomButton
              variant="outlined"
              onClick={() => window.open(navigateToDInvoice, "_blank")}
              sx={{ gap: 1 }}
            >
              <LuScreenShare size={16} /> Invoice Online
            </CustomButton>
          </Box>
        </Box>
      </Box>
    );
  };
  // const _renderActivityContent = () => {
  //   return <Box>Activity</Box>
  // }

  const _renderTabContent = () => {
    switch (activeTab) {
      // case SALE_TAB.ACTIVITY:
      //   return _renderActivityContent();
      case SALE_TAB.DETAIL:
      default:
        return _renderDetailContent();
    }
  };
  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };

  const handleCancalSale = () => {
    dispatch(
      openDialog({
        title: "Konfirmasi",
        message: "Apakah anda yakin ingin membatalkan/void penjualan?",
        primaryBtn: {
          text: "Tidak",
          onClick: () => {
            navigate(-1);
          },
        },
        secondaryBtn: {
          text: "Ya, Saya Yakin",
          onClick: async () => {
            transaction?.transactionType === TRANSACTION_TYPE.SALE_ONLY
              ? await updateTransactionAdhocMutation.mutateAsync({
                transactionID: transaction.transactionID || "",
                payload: {
                  companyID: companyId || "",
                  paymentStatus: PAYMENT_STATUS.CANCELLED,
                },
              })
              : await updateTransactionMutation.mutateAsync({
                eventID: transaction.eventID || "",
                transactionID: transaction.transactionID || "",
                payload: {
                  companyID: companyId || "",
                  paymentStatus: PAYMENT_STATUS.CANCELLED,
                },
              });
            getTransaction.refetch();
            handleCloseAnchor();
            onCloseAll();
          },
        },
      })
    );
  };
  const handleEditSale = () => {
    setModalState((prev) => ({ ...prev, editSale: transaction }));
    handleCloseAnchor();
  };

  const _renderAdditionalMenu = () => {
    return (
      <>
        <CustomButton
          sx={{
            minWidth: "40px",
            p: 0,
            border: `1px solid ${COLOR.primary500}`,
          }}
          variant="icon"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <IoMdMore size={24} />
        </CustomButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{
            "& .MuiPaper-root": {
              borderRadius: 2,
              "& .MuiMenu-list": {
                pt: 0,
                pb: 0,
                p: 1,
              },
              "& .MuiMenuItem-root": {
                p: 0,
              },
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              "& button": {
                px: 2,
                py: 1,
                textAlign: "left",
                borderRadius: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
              },
              "& button:hover": { backgroundColor: COLOR.neutral200 },
            }}
          >
            <OpacityButton onClick={handleEditSale}>
              <TbFilePencil size={18} />
              <Typography variant="body2" fontWeight={500}>
                Ubah Detail Penjualan
              </Typography>
            </OpacityButton>
            {transaction.transactionType !==
              TRANSACTION_TYPE.ONLINE_BOOKING && (
                <OpacityButton
                  onClick={handleCancalSale}
                  sx={{ color: COLOR.danger500 }}
                >
                  <GrDocumentMissing size={16} />
                  <Typography variant="body2" fontWeight={500}>
                    Batalkan Penjualan (Void)
                  </Typography>
                </OpacityButton>
              )}
            {
              activeTab === SALE_TAB.DETAIL && (
                <>
                  <Divider sx={{ my: 1 }} />
                  <OpacityButton
                    onClick={() => window.open(navigateToDInvoice, "_blank")}
                    sx={{ gap: 1 }}
                  >
                    <LuScreenShare size={18} />
                    <Typography variant="body2" fontWeight={500}>
                      Buka Invoice Online
                    </Typography>
                  </OpacityButton>
                  <OpacityButton
                    onClick={handlePrintButton}
                    sx={{ gap: 1 }}
                  >
                    <FaPrint size={18} />
                    <Typography variant="body2" fontWeight={500}>
                      Print Invoice
                    </Typography>
                  </OpacityButton>
                </>
              )
            }

          </Box>
        </Menu>
      </>
    );
  };
  return (
    <ModalTemplate
      {...modalProps}
      header={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
            flex: 1,
            gap: 1,
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Sale Detail
          </Typography>
        </Box>
      }
    >
      <Box>
        <Box px={2}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: getStatusTextPropsByStatus(
                  transaction?.paymentStatus
                ).backgroundColor,
                color: getStatusTextPropsByStatus(transaction?.paymentStatus)
                  .color,
                width: "fit-content",
                gap: 1,
                px: 2,
                py: 1,
                borderRadius: 5,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaCheck size={16} />
              <Typography lineHeight={1} fontWeight={600}>
                {
                  getStatusTextPropsByStatus(transaction?.paymentStatus)
                    .children
                }
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
              {/* <CustomButton sx={{ width: "fit-content", px: 2, py: 1 }}>
                <Typography fontWeight={600}>Rebook</Typography>
              </CustomButton> */}
              {_renderAdditionalMenu()}
            </Box>
          </Box>
          <Typography color={COLOR.neutral500}>{`${dayjs(
            transaction?.createdAt || new Date()
          )
            .tz(transaction?.timezone || "Asia/Jakarta")
            .format(TIME_FORMAT.dddDDMMMYYYY)} ${DOT_SEPARATOR} ${userReducer.data.companyName || ""
            }`}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            borderBottom: `1px solid ${COLOR.neutral300}`,
            mt: 3,
          }}
        >
          <Tabs
            value={activeTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {Object.values(SALE_TAB).map((tab) => {
              return <Tab key={tab} label={tab} value={tab} />;
            })}
          </Tabs>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: COLOR.bg1,
            pb: 5,
          }}
        >
          {_renderTabContent()}
        </Box>
      </Box>
      <EditSaleDetailModal
        modalProps={{
          open: modalState.editSale !== null,
          onClose: () => {
            setModalState((prev) => ({ ...prev, editSale: null }));
          },
        }}
        initialData={modalState.editSale || transaction}
        onSave={async (data) => {
          // save trs refetch
          transaction?.transactionType === TRANSACTION_TYPE.SALE_ONLY
            ? await updateTransactionAdhocMutation.mutateAsync({
              transactionID: modalState.editSale?.transactionID || "",
              payload: {
                companyID: userReducer.data.companyId || "",
                productDetail: data.productDetail,
                receivedBy: data.receivedBy,
              },
            })
            : await updateTransactionMutation.mutateAsync({
              eventID: modalState.editSale?.eventID || "",
              transactionID: modalState.editSale?.transactionID || "",
              payload: {
                companyID: userReducer.data.companyId || "",
                productDetail: data.productDetail,
                receivedBy: data.receivedBy,
              },
            });
          await getTransaction.refetch();
          setModalState((prev) => ({ ...prev, editSale: null }));
          onCloseAll();
        }}
      />
    </ModalTemplate>
  );
};

export default SaleModal;
