import React, { useMemo, useEffect, useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  DEFAULT_SNACKBAR_PROPS,
  ROUTE_NAME,
  RoutePath,
} from "../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "../../redux/reducers";
import { COLOR } from "../../utils/color";
import TextInput from "../../components/Forms/TextInput/TextInput";
import { useGetCompanyAccountByID } from "../../query/queries";
import { useUpsertBankAccount } from "../../query/mutations";
import { useNavigate } from "react-router-dom";
import { ContainerStyle, FieldContainer } from "../../styles/global.styles";
import AppContainer from "../../components/Layout/AppContainer/AppContainer";
import SubMenuHeader from "../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft } from "react-icons/fa";
import BoardIcon from "../../assets/svg/BoardIcon";
import SingleSelect from "../../components/Forms/SingleSelect/SingleSelect";
import {
  bankAccountColorMap,
  BankAccountStatus,
  bankAccountStatusMap,
  BankCode,
  bankCodeMap,
  bankCodeOptions,
} from "../../types/globalTypes";
import {
  setBankAccountData,
  initialState,
} from "../../redux/reducers/bankAccount";
import CustomButton from "../../components/CustomButton/CustomButton";
import { FooterContainer } from "./BankAccount.styles";
import { enqueueSnackbar } from "notistack";
import { openDialog } from "../../redux/reducers/confirmationDialog";
import HelperText from "../../components/Forms/HelperText/HelperText";

const BankAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [initialData, setInitialData] = useState(initialState.bankAccountData);
  const [hasChanges, setHasChanges] = useState(false);
  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );
  const { bankAccountData } = useSelector(
    (state: RootReducerState) => state.bankAccountReducer
  );
  const companyId = userReducer.data.companyId;
  const companyAccountQuery = useGetCompanyAccountByID(companyId || "");
  const companyAccount = useMemo(() => {
    return companyAccountQuery.data?.data?.data;
  }, [companyAccountQuery.data?.data?.data]);

  const upsertBankAccountMutation = useUpsertBankAccount();

  const handleUpsertCompanyAccount = async () => {
    if (
      initialData.accountNumber !== bankAccountData.accountNumber ||
      initialData.bankCode !== bankAccountData.bankCode
    ) {
      dispatch(
        openDialog({
          title: "Konfirmasi",
          message:
            "Anda yakin untuk mengubah nomor akun bank ? anda harus mengulang verifikasi akun bank anda",
          secondaryBtn: {
            onClick: async () => {
              await upsertBankAccountMutation.mutateAsync({
                companyId: companyId || "",
                accountNumber: bankAccountData.accountNumber,
                bankCode: bankAccountData.bankCode || BankCode.BCA,
              });
              navigate(`${RoutePath[ROUTE_NAME.BANK_ACCOUNT_OTP]}`);
            },
          },
        })
      );
    } else {
      if (
        initialData.bankInquiryStatus === BankAccountStatus.SUCCESS ||
        initialData.bankInquiryStatus === BankAccountStatus.PENDING
      ) {
        enqueueSnackbar(`Akun Bank sudah terverifikasi`, {
          variant: "success",
          ...DEFAULT_SNACKBAR_PROPS,
        });
        return;
      }
      navigate(`${RoutePath[ROUTE_NAME.BANK_ACCOUNT_OTP]}`);
    }
  };

  const initializeData = useCallback(() => {
    const latestData = companyAccount
      ? {
          accountNumber: companyAccount?.accountNumber,
          accountName: companyAccount?.accountName,
          bankCode: companyAccount?.bankCode,
          bankName: companyAccount?.bankName,
          bankInquiryStatus: companyAccount?.bankInquiryStatus,
        }
      : { ...initialState.bankAccountData };
    dispatch(setBankAccountData(latestData));
    setInitialData(latestData);
  }, [dispatch, companyAccount]);
  useEffect(initializeData, [initializeData]);

  return (
    <AppContainer>
      <Box sx={ContainerStyle}>
        <SubMenuHeader
          leftNav={{
            icon: <FaArrowLeft />,
            onClick: () => {
              navigate(RoutePath[ROUTE_NAME.MORE], {
                replace: true,
              });
            },
          }}
          text={"Status Akun Bank"}
        />
        <Box
          mt={2}
          sx={{
            backgroundColor: "white",
            p: 3,
            borderRadius: "20px",
            wordBreak: "break-all",
            // ...props.sx,
          }}
          boxShadow={2}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap={1}
          >
            <Typography color={COLOR.neutral500} fontWeight={600} fontSize={20}>
              Status:
            </Typography>
            <Typography
              fontWeight={800}
              color={
                companyAccount?.bankInquiryStatus
                  ? bankAccountColorMap[companyAccount?.bankInquiryStatus]
                  : COLOR.danger500
              }
              fontSize={20}
            >
              {companyAccount?.bankInquiryStatus
                ? bankAccountStatusMap[companyAccount?.bankInquiryStatus]
                : "Belum Diverifikasi"}
            </Typography>
          </Box>
        </Box>
        <Box py={2} width={"80%"} display="flex" flexDirection="column" gap={2}>
          <Box sx={FieldContainer}>
            <SingleSelect
              title="Nama Bank"
              disabled={
                companyAccount?.bankInquiryStatus === BankAccountStatus.SUCCESS
              }
              required
              // error={errors.timezone !== undefined}
              helper={
                {
                  // text: errors.timezone,
                }
              }
              optionProps={{
                value: bankAccountData.bankCode ? bankAccountData.bankCode : "",
                onChange: (selectedOption?: string | "") => {
                  dispatch(
                    setBankAccountData({
                      bankCode: (selectedOption as BankCode) || "",
                      bankName: selectedOption
                        ? bankCodeMap[selectedOption as BankCode]
                        : "",
                    })
                  );
                },
                options: bankCodeOptions,
                defaultValue: "",
                // placeholder: "Pilih Bank Anda",
              }}
            />
          </Box>
          <Box sx={FieldContainer}>
            <TextInput
              title="Nomor Rekening"
              disabled={
                companyAccount?.bankInquiryStatus === BankAccountStatus.SUCCESS
              }
              required
              startEndorment={<BoardIcon size={20} color={COLOR.neutral500} />}
              textInputProps={{
                placeholder: "Masukkan Nomor Rekening",
                value: bankAccountData.accountNumber,
                onChange: (e) => {
                  dispatch(
                    setBankAccountData({
                      accountNumber: e.target.value,
                    })
                  );
                }, // onBlur: () => handleBlur("name"),
              }}
              // error={errors.name !== undefined}
              helper={{
                color: COLOR.danger500,
                // text: errors.name,
              }}
            />
            <HelperText text="Pastikan nomor rekening yang Anda masukkan benar, laporkan ke tim Neema untuk jika ada perubahan." />
          </Box>
          <Box sx={FieldContainer}>
            <TextInput
              title="Nama Pemilik Rekening"
              disabled
              startEndorment={<BoardIcon size={20} color={COLOR.neutral500} />}
              textInputProps={{
                placeholder: "Rekening Belum Diverifikasi...",
                value: bankAccountData.accountName,
              }}
              // error={errors.name !== undefined}
              helper={{
                color: COLOR.danger500,
                // text: errors.name,
              }}
            />
          </Box>
        </Box>
        <Box sx={FieldContainer}></Box>
        <Box sx={FooterContainer}>
          <CustomButton
            disabled={
              upsertBankAccountMutation.isLoading ||
              companyAccount?.bankInquiryStatus === BankAccountStatus.SUCCESS
            }
            sx={{ width: "90%" }}
            onClick={handleUpsertCompanyAccount}
          >
            <Typography
              variant="body1"
              fontWeight={500}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              gap={2}
              sx={{ textWrap: "nowrap" }}
            >
              Simpan
            </Typography>
          </CustomButton>
        </Box>
      </Box>
    </AppContainer>
  );
};

export default BankAccount;
