import React from 'react';
import ActionBottomSheet from './ActionBottomSheet';
import CustomButton from '../CustomButton/CustomButton';
import dayjs from 'dayjs';
import { Box, Divider, Typography } from '@mui/material';
import ProfileInitial from '../ProfileInitial/ProfileInitial';
import { FaPencilAlt, FaRegCalendarTimes, FaRegClock, FaRegTrashAlt } from 'react-icons/fa';
import { BottomSheetProps } from 'react-spring-bottom-sheet';
import { COLOR } from '../../utils/color';
import { TimeRange } from '../../pages/TeamScheduledShifts/TeamScheduledShifts';

export interface ShiftActionBottomSheetPropsI {
  onDismiss: BottomSheetProps['onDismiss'];
  data: {
    id: string;
    name: string;
    timeRange: TimeRange[];
    date: string;
  } | null;
  onAddorEdit: (isAddMode: boolean) => void;
  onAddEmptyShift: () => void;
  onDelete: () => void;
  onManageRegularShift: () => void;
}

const ShiftActionBottomSheet: React.FC<ShiftActionBottomSheetPropsI> = ({ data, onDismiss, onAddorEdit, onAddEmptyShift, onDelete, onManageRegularShift }) => {
  const isEmptyTimeRange = data?.timeRange.length === 0;
  return (
    <ActionBottomSheet open={data !== null} bottomSheetProps={{ onDismiss }} onClose={onDismiss} sx={{ pb: 2 }}>
      <Box
        sx={{
          pb: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ProfileInitial name={data?.name || ''} />
        <Typography variant='h6' fontWeight='bold'>
          {data?.name || ''}
        </Typography>
        <Typography variant='body2'>{dayjs(data?.date).format('ddd, DD MMM YYYY')}</Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <CustomButton
          variant='text-secondary'
          startEndorment={<FaPencilAlt size={16} />}
          onClick={() => {
            onAddorEdit(isEmptyTimeRange);
          }}
        >
          <Typography variant='body2' fontWeight='600' textAlign='left' flex={1}>
            {isEmptyTimeRange ? 'Tambah' : 'Edit'} Shift Hari ini
          </Typography>
        </CustomButton>
        <CustomButton variant='text-secondary' startEndorment={<FaRegClock size={16} />} onClick={() => onManageRegularShift()}>
          <Typography variant='body2' fontWeight='600' textAlign='left' flex={1}>
            Atur Shift Reguler
          </Typography>
        </CustomButton>
        <CustomButton variant='text-secondary' startEndorment={<FaRegCalendarTimes size={16} />} onClick={() => onAddEmptyShift()}>
          <Typography variant='body2' fontWeight='600' textAlign='left' flex={1}>
            Tambah Waktu Kosong
          </Typography>
        </CustomButton>
        <CustomButton variant='text-secondary' startEndorment={<FaRegTrashAlt size={16} />} sx={{ color: COLOR.danger500 }} onClick={() => onDelete()}>
          <Typography variant='body2' fontWeight='600' textAlign='left' flex={1}>
            Hapus Shift ini
          </Typography>
        </CustomButton>
        <CustomButton onClick={onDismiss}>Tutup</CustomButton>
      </Box>
    </ActionBottomSheet>
  );
};

export default ShiftActionBottomSheet;
