import React, { Fragment, useEffect, useMemo, useState } from "react";
import { COLOR } from "../../utils/color";
import ModalTemplate, { ModalTemplateProps } from "./ModalTemplate";
import { Box, SxProps, Typography } from "@mui/material";
import {
  AppointmentData,
  ClientData,
  PAYMENT_STATUS,
  SaleData,
  SaleService,
} from "../../types/globalTypes";
import CustomButton from "../CustomButton/CustomButton";
import { FaPlusCircle } from "react-icons/fa";
import OpacityButton from "../OpacityButton/OpacityButton";
import SelectServiceModal from "./SelectServiceModal";
import { Theme } from "@emotion/react";
import {
  formatCurrency,
  formatPhoneNumber,
  stripFormatting,
} from "../../utils/helper";
import TextArea from "../Forms/TextArea/TextArea";
import TextInput from "../Forms/TextInput/TextInput";
import UserIcon from "../../assets/svg/UserIcon";
import MailIcon from "../../assets/svg/MailIcon";
import PhoneIcon from "../../assets/svg/PhoneIcon";
import Joi from "joi";
import { customerDataSchema } from "../../joiSchema/schedule";
import ServiceItem from "../ServiceItem/ServiceItem";
import EditSaleServiceModal from "./EditSaleServiceModal";
import { GetScheduleEventResponseType } from "../../api/request.types";
import { useGetTransactionById } from "../../query/queries";
import { useSelector } from "react-redux";
import { RootReducerState } from "../../redux/reducers";
import { ScheduleEventPaymentStatus } from "../../api/request.types";

interface CreateNewSaleModalProps {
  modalProps: Omit<ModalTemplateProps, "children">;
  initialData: SaleData;
  onContinueToPayment: (data: SaleData) => void;
  mode?: "create" | "edit";
  onViewSale: () => void;
}

const CreateNewSaleModal: React.FC<CreateNewSaleModalProps> = ({
  modalProps,
  initialData,
  onContinueToPayment,
  mode,
  onViewSale,
}) => {
  const getTransactionByID = useGetTransactionById(initialData.id);
  const [saleData, setSaleData] = useState<SaleData>({
    ...initialData,
  });

  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );

  const [clientError, setClientError] = useState<
    Record<keyof ClientData, string>
  >({
    name: "",
    email: "",
    phoneNumber: "",
  });

  const [modalState, setModalState] = useState<{
    selectService: boolean;
    editService: SaleService | null;
  }>({
    selectService: false,
    editService: null,
  });
  useEffect(() => {
    if (
      initialData.id &&
      getTransactionByID.isFetched &&
      !getTransactionByID.isLoading
    ) {
      const transaction = getTransactionByID.data?.data?.data;
      setSaleData((prev) => ({
        ...prev,
        id: transaction?.transactionID || prev.id,
        client: {
          name: transaction?.fullName || prev.client.name,
          email: transaction?.email || prev.client.email,
          phoneNumber: transaction?.phoneNumber || prev.client.phoneNumber,
        },
        services:
          transaction?.productDetail.flatMap((product) =>
            product.scheduleDetails.map((schedule) => ({
              id: product.productID,
              name: product.productName,
              price: schedule.price,
              durationInSeconds: schedule.duration,
              qty: schedule.qty,
              teamMember: {
                id: product.teamMemberID,
                name: product.teamMemberName,
                type: GetScheduleEventResponseType.TEAM_MEMBER,
                shifts: [],
              },
              resource: {
                id: product.resourceID,
                name: product.resourceName,
                type: GetScheduleEventResponseType.RESOURCE,
                shifts: [],
              },
            }))
          ) || [],
        paymentStatus: transaction?.paymentStatus || prev.paymentStatus,
        notes: transaction?.notes || prev.notes,
        receivedBy: {
          id: userReducer.data.userId || "",
          name: userReducer.data.name || "",
          type: GetScheduleEventResponseType.TEAM_MEMBER,
          shifts: [],
        },
        paidAmount: transaction?.paidAmount || prev.paidAmount || 0,
      }));
    }
  }, [
    getTransactionByID.data?.data?.data,
    getTransactionByID.isFetched,
    getTransactionByID.isLoading,
    initialData.id,
    userReducer.data.name,
    userReducer.data.userId,
  ]);

  const AddServiceButton = (props: {
    onClick: () => void;
    disabled?: boolean;
    sx?: SxProps<Theme>;
    text?: string;
  }) => {
    const { onClick, disabled, text = "Tambah Service" } = props;
    return (
      <OpacityButton
        disabled={disabled}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid black",
          borderRadius: "30px",
          px: 1.5,
          py: 1,
          gap: 1,
          ...(props.sx || {}),
        }}
        onClick={onClick}
      >
        <FaPlusCircle size={12} />
        <Typography lineHeight={1} variant="caption">
          {text}
        </Typography>
      </OpacityButton>
    );
  };

  useEffect(() => {
    if (modalProps.open && mode === "create") {
      setSaleData((prev) => ({
        ...prev,
        ...initialData,
        services: [...initialData.services],
      }));
    }

    if (modalProps.open && mode === "edit") {
      setClientError({
        name: "",
        email: "",
        phoneNumber: "",
      });
    }
  }, [modalProps.open, mode]);

  const totalPrice = saleData.services.reduce(
    (acc, service) => acc + service.price,
    0
  );

  const handleBlur = (field: keyof SaleData["client"]) => () => {
    if (!saleData.client) return;
    const { error } = Joi.object({
      [field]: customerDataSchema.extract(field),
    }).validate({ [field]: saleData.client[field] });
    if (error) {
      setClientError((prevErrors) => ({
        ...prevErrors,
        [field]: error.message,
      }));
    } else {
      setClientError((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };

  const handleOnChangeClientData =
    (key: keyof AppointmentData["client"]) => (e: any) => {
      if (!saleData.client) return;

      switch (key) {
        case "phoneNumber":
          let phoneNumber = e?.target?.value || "";
          phoneNumber = stripFormatting(phoneNumber);
          setSaleData((prevData) => ({
            ...prevData,
            client: {
              ...(prevData.client || { email: "", name: "", phoneNumber: "" }),
              [key]: phoneNumber,
            },
          }));
          return;
        case "email":
        case "name":
          setSaleData((prevData) => ({
            ...prevData,
            client: {
              ...(prevData.client || { email: "", name: "", phoneNumber: "" }),
              [key]: e.target.value,
            },
          }));
          return;
      }
    };

  return (
    <ModalTemplate
      {...modalProps}
      sx={{
        ...modalProps.sx,
      }}
      header={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
            flex: 1,
            borderBottom: `1px solid ${COLOR.neutral300}`,
            gap: 1,
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Penjualan Baru
          </Typography>
        </Box>
      }
      footer={
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          px={2}
          pb={2}
          pt={1}
          borderTop={`1px solid ${COLOR.neutral300}`}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              fontWeight={
                saleData.paymentStatus === PAYMENT_STATUS.PARTIAL_PAID
                  ? "normal"
                  : "bold"
              }
            >
              Total Penjualan
            </Typography>
            <Typography
              fontWeight={
                saleData.paymentStatus === PAYMENT_STATUS.PARTIAL_PAID
                  ? "normal"
                  : "bold"
              }
            >
              {formatCurrency(totalPrice, "IDR")}
            </Typography>
          </Box>
          {saleData.paymentStatus === PAYMENT_STATUS.PARTIAL_PAID && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography fontWeight="bold">Sisa Pembayaran</Typography>
              <Typography fontWeight="bold">
                {formatCurrency(
                  Math.abs(totalPrice - saleData.paidAmount),
                  "IDR"
                )}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 1,
            }}
          >
            {saleData.paymentStatus !== PAYMENT_STATUS.PAID && (
              <CustomButton
                disabled={saleData.services.length === 0}
                onClick={() => {
                  onContinueToPayment(saleData);
                }}
              >
                Lanjut ke Pembayaran
              </CustomButton>
            )}
            {mode === "edit" &&
              saleData.paymentStatus !== PAYMENT_STATUS.UNPAID && (
                <CustomButton
                  variant={"outlined"}
                  onClick={() => {
                    onViewSale();
                  }}
                >
                  Lihat Penjualan
                </CustomButton>
              )}
          </Box>
        </Box>
      }
    >
      <Box px={2} py={3} display="flex" flexDirection="column" gap={1}>
        <Typography pb={1} variant="h6" fontWeight="bold">
          Data Customer
        </Typography>
        <TextInput
          title="Nama Lengkap"
          startEndorment={<UserIcon color={COLOR.neutral400} />}
          disabled={mode === "edit"}
          textInputProps={{
            placeholder: "cth: John Doe",
            value: saleData.client.name,
            onChange: handleOnChangeClientData("name"),
            onBlur: handleBlur("name"),
          }}
          helper={{
            color: COLOR.danger500,
            text: clientError["name"],
          }}
        />
        <TextInput
          title="Alamat Email"
          startEndorment={<MailIcon color={COLOR.neutral400} />}
          disabled={mode === "edit"}
          textInputProps={{
            placeholder: "cth: johndoe@email.com",
            value: saleData.client.email,
            onChange: handleOnChangeClientData("email"),
            onBlur: handleBlur("email"),
          }}
          helper={{
            color: COLOR.danger500,
            text: clientError["email"],
          }}
        />
        <TextInput
          title="No Whatsapp"
          disabled={mode === "edit"}
          startEndorment={
            <>
              <PhoneIcon color={COLOR.neutral400} />
              <Typography color={COLOR.neutral400} ml={"4px"}>
                +62
              </Typography>
            </>
          }
          textInputProps={{
            placeholder: "cth: 818239183912",
            value: formatPhoneNumber(saleData.client.phoneNumber),
            onChange: handleOnChangeClientData("phoneNumber"),
            pattern: "[0-9]*",
            inputMode: "numeric",
            onBlur: handleBlur("phoneNumber"),
          }}
          helper={{
            color: COLOR.danger500,
            text: clientError["phoneNumber"],
          }}
        />
      </Box>
      <Box px={2} pb={3}>
        <Typography pt={3} pb={1} variant="h6" fontWeight="bold">
          Services
        </Typography>
        {saleData.services.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "60px",
              gap: 2,
              p: 2,
              py: 4,
              textAlign: "center",
              border: `1px solid ${COLOR.neutral200}`,
              borderRadius: 2,
            }}
          >
            <Typography fontSize={56}>🛒</Typography>
            <Typography variant="body1" color={COLOR.neutral900}>
              Keranjang kamu kosong.
            </Typography>
            <Typography variant="body2" color={COLOR.neutral500}>
              Tekan "Tambah Produk" untuk menambahkan produk/layanan ke
              keranjang
            </Typography>
            <AddServiceButton
              text="Tambah Produk"
              disabled={mode === "edit"}
              onClick={() => {
                setModalState((prev) => ({
                  ...prev,
                  selectService: true,
                }));
              }}
            />
          </Box>
        ) : (
          <Fragment>
            {saleData.services.map((service, index) => (
              <ServiceItem
                key={index}
                service={{
                  productName: service.name,
                  duration: service.durationInSeconds,
                  price: service.price,
                  teamMemberName: service.teamMember.name,
                  resourceName: service.resource.name,
                }}
                showDetail={false}
                onClick={() => {
                  setModalState((prev) => ({
                    ...prev,
                    editService: service,
                  }));
                }}
              />
            ))}
            <AddServiceButton
              sx={{ mt: 1 }}
              disabled={mode === "edit"}
              onClick={() => {
                setModalState((prev) => ({
                  ...prev,
                  selectService: true,
                }));
              }}
            />
          </Fragment>
        )}
      </Box>
      <Box px={2} pb={5}>
        <TextArea
          title="Catatan Tambahan"
          disabled={mode === "edit"}
          required={false}
          textAreaProps={{
            value: saleData.notes,
            onChange: (e) =>
              setSaleData((prev) => ({
                ...prev,
                notes: e.target.value,
              })),
          }}
          helper={{
            text: "Catatan ini hanya akan dilihat oleh team member anda.",
            color: COLOR.neutral500,
          }}
        />
      </Box>

      <SelectServiceModal
        modalProps={{
          open: modalState.selectService,
          onClose: () =>
            setModalState((prev) => ({
              ...prev,
              selectService: false,
            })),
        }}
        teamMemberIDs={[]}
        resourceIDs={[]}
        onClickService={(saleServiceOption) => {
          setSaleData((prev) => {
            const selectedService: SaleService = {
              id: saleServiceOption.productID,
              durationInSeconds: saleServiceOption.duration,
              name: saleServiceOption.productName,
              price: saleServiceOption.price,
              qty: 1,
              teamMember: {
                id: saleServiceOption.teamMemberID,
                name: saleServiceOption.teamMemberName,
                type: GetScheduleEventResponseType.TEAM_MEMBER,
                shifts: [],
              },
              resource: {
                id: saleServiceOption.resourceID,
                name: saleServiceOption.resourceName,
                type: GetScheduleEventResponseType.RESOURCE,
                shifts: [],
              },
            };
            const services = [...prev.services, selectedService];
            return {
              ...prev,
              services,
            };
          });
          setModalState((prev) => ({
            ...prev,
            selectService: false,
          }));
        }}
      />
      <EditSaleServiceModal
        modalProps={{
          open: modalState.editService !== null,
          onClose: () =>
            setModalState((prev) => ({
              ...prev,
              editService: null,
            })),
        }}
        onClickDelete={() => {
          setSaleData((prev) => ({
            ...prev,
            services: prev.services.filter(
              (s) => s.id !== modalState.editService?.id
            ),
          }));
          setModalState((prev) => ({
            ...prev,
            editService: null,
          }));
        }}
        onApply={(service) => {
          setSaleData((prev) => ({
            ...prev,
            services: prev.services.map((s) =>
              s.id === service.id ? service : s
            ),
          }));
          setModalState((prev) => ({
            ...prev,
            editService: null,
          }));
        }}
        initialService={modalState.editService}
        disabled={mode === "edit"}
      />
    </ModalTemplate>
  );
};

export default CreateNewSaleModal;
