import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Typography, SxProps, Skeleton } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDynamicPage } from "../../NavigateOnValidCompanyName";
import AppContainer from "../../../components/Layout/AppContainer/AppContainer";
import TicketSeparator from "../../../components/TicketSeparator/TicketSeparator";
import AvatarImg from "../../../components/AvatarImg/AvatarImg";
import { FaCopy } from "react-icons/fa";
import { COLOR } from "../../../utils/color";
import {
  RoutePath,
  ROUTE_NAME,
  TIMEZONE_SHORTLABEL,
  DEFAULT_SNACKBAR_PROPS,
  FONT_COLOR,
} from "../../../utils/constant";
import RowKeyValue from "../../../components/RowKeyValue/RowKeyValue";
import StatusText from "../../../components/StatusText/StatusText";
import {
  formatCurrency,
  getStatusTextPropsByStatus,
} from "../../../utils/helper";
import { PAYMENT_STATUS, TRANSACTION_TYPE } from "../../../types/globalTypes";
import { Theme } from "@emotion/react";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import TextArea from "../../../components/Forms/TextArea/TextArea";
import Joi from "joi";
import { useStatusInfo } from "../../../context/StatusInfoContext";
import {
  useGetPublicTeamMembersCompact,
  useGetPublicTransactionById,
} from "../../../query/queries";
import {
  GetPublicTransactionByIdResponse,
  TeamMemberStatus,
} from "../../../api/request.types";
import dayjs from "dayjs";
import {
  useCancelTransaction,
  useConfirmTransaction,
  useRefundTransaction,
} from "../../../query/mutations";
import NeemaLogoPNG from "../../../assets/images/neema-logo.png";
import { useDispatch } from "react-redux";
import { resetPublicTransaction } from "../../../redux/reducers/public";
import { enqueueSnackbar } from "notistack";
import useNavigateDynamicPage from "../../../hooks/useNavigateDynamicPage";
import TemplateButton from "../../../components/TemplateButton/TemplateButton";
import { useGetTeamMembers } from "../../../query/queries";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useGetPublicCompanyDomainV2 } from "../../../query/queries";
import {
  generateImageData,
  downloadPDFHelper,
  downloadJPGHelper,
} from "../../../helpers/downloadInvoiceHelper/downloadInvoiceHelper";
import { TIME_FORMAT } from "../../../utils/constant";
import isEmpty from "lodash.isempty";

const DEFAULT_TRANSACTION_DETAIL: GetPublicTransactionByIdResponse = {
  transactionID: "",
  companyID: "",
  timezone: "Asia/Jakarta",
  companyName: "",
  companyLogo: "",
  date: "",
  fullName: "",
  email: "",
  phoneNumber: "",
  productDetail: [],
  location: "",
  adminPrice: 0,
  productPrice: 0,
  total: 0,
  notes: "",
  paymentStatus: PAYMENT_STATUS.UNPAID,
  paidWith: "",
  snapToken: "",
  snapRedirectURL: "",
  refundReason: "",
  isManuallyAdded: false,
  transactionTimestamp: {
    paidAt: null,
    requestRefundAt: null,
    refundedAt: null,
    refundCancelledAt: null,
    cancelledAt: null,
  },
  linkID: 0,
  linkURL: "",
  allowRefund: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  teamMemberIDs: [],
  transactionType: TRANSACTION_TYPE.ONLINE_BOOKING,
};
const DInvoice: React.FC = () => {
  const { data } = useDynamicPage();
  const dispatch = useDispatch();
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const { subdomain } = useDynamicPage();
  const invoiceRef = useRef<HTMLDivElement>(null);

  const downloadPDF = async () => {
    try {
      const imgData = await generateImageData(invoiceRef);
      if (imgData) {
        await downloadPDFHelper(imgData, invoiceRef);
      } else {
        alert("Could not generate image data for PDF.");
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
      alert("Something went wrong while generating the PDF. Please try again.");
    }
  };

  const downloadJPG = async () => {
    try {
      const imgData = await generateImageData(invoiceRef);
      if (imgData) {
        downloadJPGHelper(imgData);
      } else {
        alert("Could not generate image data for JPG.");
      }
    } catch (error) {
      console.error("Error generating JPG:", error);
      alert("Something went wrong while generating the JPG. Please try again.");
    }
  };

  const companyV2Query = useGetPublicCompanyDomainV2(subdomain || "");

  const companyV2Detail = useMemo(() => {
    return companyV2Query.data?.data?.data;
  }, [companyV2Query.data?.data?.data]);

  const completeAddress = companyV2Detail?.location?.completeAddress;
  const companyName = companyV2Detail?.name;

  const navigate = useNavigateDynamicPage();
  const bottomSheetRef = useRef<BottomSheetRef>(null);
  const openBottomSheetRef = useRef<BottomSheetRef>(null);
  const [openBotSheet, setOpenBotSheet] = useState<boolean>(false);
  const [openCancelBotSheet, setOpenCancelBotSheet] = useState<boolean>(false);

  const [refundNote, setRefundNote] = useState<string>("");
  const [refundError, setRefundError] = useState<string>("");

  const transactionByIdQuery = useGetPublicTransactionById(
    subdomain,
    invoiceId || ""
  );
  const { setStatusInfoContent } = useStatusInfo();

  const transactionData: GetPublicTransactionByIdResponse = useMemo(() => {
    return transactionByIdQuery.data?.data.data || DEFAULT_TRANSACTION_DETAIL;
  }, [transactionByIdQuery.data]);

  const isInvalidTransaction = useMemo(() => {
    return transactionByIdQuery.isError;
  }, [transactionByIdQuery.isError]);
  const isLoading = useMemo(() => {
    return transactionByIdQuery.isLoading;
  }, [transactionByIdQuery.isLoading]);

  const refundTransactionMutation = useRefundTransaction();
  const cancelTransactionMutation = useCancelTransaction();
  const confirmTransactionMutation = useConfirmTransaction();

  useEffect(() => {
    if (!invoiceId) {
      navigate(RoutePath[ROUTE_NAME.DYNAMIC_NOT_FOUND], { replace: true });
    }
  }, [invoiceId, navigate]);

  useEffect(() => {
    if (!isLoading && transactionData.paymentStatus === PAYMENT_STATUS.PAID) {
      dispatch(resetPublicTransaction());
    }
  }, [isLoading]);

  if (!invoiceId) {
    return null;
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(invoiceId);
    enqueueSnackbar({
      ...DEFAULT_SNACKBAR_PROPS,
      variant: "success",
      message: `Link berhasil disalin: ${invoiceId}`,
    });
  };

  const titleSx: SxProps<Theme> = {
    fontWeight: 600,
    fontSize: 16,
  };

  const handleChange = (key: "refundNote") => (e: any) => {
    switch (key) {
      case "refundNote":
      default:
        setRefundNote(e.target.value);
    }
  };
  const handleBlur = (key: "refundNote") => (e: any) => {
    const { error } = Joi.string().required().min(5).validate(refundNote);
    if (error) {
      setRefundError(error.details[0].message);
    } else {
      setRefundError("");
    }
  };
  const handleRequestRefund = () => {
    setOpenBotSheet(true);
  };

  const handleSubmitRefund = async () => {
    const { error } = Joi.string().required().min(5).validate(refundNote);
    if (error?.details[0].message === undefined) {
      const data = {
        transactionID: invoiceId,
        refundReason: refundNote,
      };

      const apiResponse = await refundTransactionMutation.mutateAsync(data);
      const responseData = apiResponse.data.data;
      transactionByIdQuery.refetch();
      dispatch(resetPublicTransaction());
      setOpenBotSheet(false);
    }
  };

  const handleSubmitCancel = async () => {
    const apiResponse = await cancelTransactionMutation.mutateAsync(invoiceId);
    const responseData = apiResponse.data.data;
    transactionByIdQuery.refetch();
    dispatch(resetPublicTransaction());
    setOpenCancelBotSheet(false);
  };

  const setupStatusInfoPage = () => {
    setStatusInfoContent({
      title: "Pembayaran Berhasil!",
      description:
        "Selamat! Pembayaran telah selesai. Invoice sudah kami kirimkan ke alamat email Anda.",
      primaryBtn: {
        text: "Lihat Invoice Pembayaran",
        onClick: () => {
          navigate(`${RoutePath[ROUTE_NAME.DYNAMIC_INVOICE]}/invoiceId`, {
            replace: true,
          });
        },
      },
      secondaryBtn: {
        text: "Kembali ke Halaman Utama",
        onClick: () => {
          navigate(RoutePath[ROUTE_NAME.DYNAMIC_HOME], { replace: true });
        },
      },
    });
  };

  const handleConfirmPayment = async () => {
    const apiResponse = await confirmTransactionMutation.mutateAsync(invoiceId);
    const responseData = apiResponse.data.data;
    transactionByIdQuery.refetch();
    dispatch(resetPublicTransaction());
    setOpenCancelBotSheet(false);
  };

  const handleMakePayment = () => {
    window.open(`https://${transactionData.linkURL}`, "_self");
  };

  const _renderLoadingSkeleton = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          color: "black",
          justifyContent: "center",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <Skeleton
            variant="circular"
            width={80}
            height={80}
            sx={{ alignSelf: "center" }}
          />
          <Skeleton variant="rounded" width="20%" sx={{ mx: 2 }} />
          <Skeleton variant="rounded" width="35%" sx={{ mx: 2 }} />
          <Skeleton variant="rounded" width="15%" sx={{ mx: 2 }} />
        </Box>
        <TicketSeparator
          hideCircle={false}
          circleBorderColor={data.color}
          circleProps={{ sx: { backgroundColor: data.color } }}
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          px={2}
          gap={2}
        >
          <Skeleton variant="rounded" width="45%" />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap={2}
          >
            <Skeleton variant="rounded" width="35%" height={15} />
            <Skeleton variant="rounded" width="35%" height={15} />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap={2}
          >
            <Skeleton variant="rounded" width="35%" height={15} />
            <Skeleton variant="rounded" width="35%" height={15} />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap={2}
          >
            <Skeleton variant="rounded" width="35%" height={15} />
            <Skeleton variant="rounded" width="35%" height={15} />
          </Box>
        </Box>
        <TicketSeparator
          hideCircle={false}
          circleBorderColor={data.color}
          circleProps={{ sx: { backgroundColor: data.color } }}
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          px={2}
          gap={2}
        >
          <Skeleton variant="rounded" width="45%" />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap={2}
          >
            <Skeleton variant="rounded" width="35%" height={15} />
            <Skeleton variant="rounded" width="20%" height={15} />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap={2}
          >
            <Skeleton variant="rounded" width="20%" height={15} />
            <Skeleton variant="rounded" width="30%" height={15} />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap={2}
          >
            <Skeleton variant="rounded" width="25%" height={15} />
            <Skeleton variant="rounded" width="40%" height={15} />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap={2}
          >
            <Skeleton variant="rounded" width="35%" height={15} />
            <Skeleton variant="rounded" width="30%" height={15} />
          </Box>
        </Box>
        <TicketSeparator
          hideCircle={false}
          circleBorderColor={data.color}
          circleProps={{ sx: { backgroundColor: data.color } }}
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          px={2}
          gap={2}
        >
          <Skeleton variant="rounded" width="35%" />
          <Skeleton variant="rounded" width="35%" height={15} />
          <Skeleton variant="rounded" width="76%" height={15} />
          <Skeleton variant="rounded" width="35%" height={15} />
          <Skeleton variant="rounded" width="60%" height={30} />
          <Skeleton variant="rounded" width="35%" height={15} />
          <Skeleton variant="rounded" width="100%" height={15} />
          <Skeleton variant="rounded" width="100%" height={15} />
        </Box>
        <TicketSeparator hideCircle />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          px={2}
          gap={2}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap={2}
          >
            <Skeleton variant="rounded" width="25%" height={15} />
            <Skeleton variant="rounded" width="40%" height={15} />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap={2}
          >
            <Skeleton variant="rounded" width="35%" height={15} />
            <Skeleton variant="rounded" width="30%" height={15} />
          </Box>
        </Box>
        <TicketSeparator hideCircle />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          px={2}
          gap={2}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap={2}
          >
            <Skeleton variant="rounded" width="35%" height={15} />
            <Skeleton variant="rounded" width="30%" height={15} />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <AppContainer
      sx={{
        backgroundColor: data.color,
        padding: 2,
        height: "100%",
      }}
    >
      <>
        <div ref={invoiceRef}>
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: 4,
              py: 2,
            }}
          >
            {isLoading ? (
              _renderLoadingSkeleton()
            ) : isInvalidTransaction ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    color: "black",
                    justifyContent: "center",
                  }}
                >
                  <Typography mt={1} px={1} fontWeight={600}>
                    Transaksi Tidak Ditemukan
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    color: "black",
                    justifyContent: "center",
                  }}
                >
                  <Typography mt={1} variant="h6" fontWeight={600}>
                    {companyName}
                  </Typography>
                  <Typography
                    onClick={handleCopy}
                    variant="subtitle1"
                    color={COLOR.neutral500}
                    fontWeight={600}
                    sx={{ cursor: "pointer" }}
                  >
                    ID: {invoiceId}
                    <FaCopy style={{ marginLeft: "4px" }} />
                  </Typography>
                </Box>

                <TicketSeparator
                  hideCircle={false}
                  circleBorderColor={data.color}
                  circleProps={{ sx: { backgroundColor: data.color } }}
                />
                <Typography px={2} sx={titleSx}>
                  Alamat Perusahaan
                </Typography>

                <RowKeyValue
                  px={2}
                  title="Alamat Lengkap:"
                  value={completeAddress}
                />

                <TicketSeparator
                  hideCircle={false}
                  circleBorderColor={data.color}
                  circleProps={{ sx: { backgroundColor: data.color } }}
                />
                <Typography px={2} sx={titleSx}>
                  Informasi Pembeli
                </Typography>

                <RowKeyValue
                  px={2}
                  title="Nama:"
                  value={transactionData.fullName}
                />
                <RowKeyValue
                  px={2}
                  title="Email:"
                  value={transactionData.email}
                />
                <RowKeyValue
                  px={2}
                  title="No WA:"
                  value={transactionData.phoneNumber}
                />

                <TicketSeparator
                  hideCircle={false}
                  circleBorderColor={data.color}
                  circleProps={{ sx: { backgroundColor: data.color } }}
                />

                <Typography px={2} sx={titleSx}>
                  Detail Pembelian
                </Typography>
                <RowKeyValue
                  px={2}
                  title="Status:"
                  renderValue={
                    <StatusText
                      {...getStatusTextPropsByStatus(
                        transactionData.paymentStatus
                      )}
                    />
                  }
                />
                <RowKeyValue
                  px={2}
                  title="Metode:"
                  value={transactionData.paidWith || "-"}
                />
                <RowKeyValue
                  px={2}
                  title="Tanggal:"
                  value={dayjs(
                    transactionData.date,
                    TIME_FORMAT.YYYYMMDD
                  ).format(TIME_FORMAT.DDMMMYYYY)}
                />
                <RowKeyValue
                  px={2}
                  title="Waktu:"
                  value={dayjs(transactionData.createdAt).format(
                    TIME_FORMAT.HHmm
                  )}
                />

                <TicketSeparator
                  hideCircle={false}
                  circleBorderColor={data.color}
                  circleProps={{ sx: { backgroundColor: data.color } }}
                />
                <Typography px={2} sx={titleSx}>
                  Informasi Produk
                </Typography>
                <RowKeyValue
                  px={2}
                  title="Produk:"
                  value={transactionData.productDetail[0]?.productName || ""}
                />
                {transactionData.transactionType !==
                  TRANSACTION_TYPE.SALE_ONLY && (
                  <Box>
                    <RowKeyValue px={2} title="Jadwal:" value="" />
                    {transactionData.productDetail?.map(
                      (product, productIndex) => {
                        return (product.scheduleDetails || []).map(
                          (schedule, key) => {
                            const start = dayjs(
                              schedule.startTime,
                              TIME_FORMAT.HHmm
                            );
                            const end = dayjs(
                              schedule.endTime,
                              TIME_FORMAT.HHmm
                            );
                            const date = dayjs(
                              schedule.date,
                              TIME_FORMAT.YYYYMMDD
                            );
                            return (
                              <RowKeyValue
                                key={`${productIndex}-${key}`}
                                px={2}
                                title=""
                                value={`(${schedule.qty}x) ${date.format(
                                  TIME_FORMAT.slash_DDMMYYYY
                                )}, ${start.format(
                                  TIME_FORMAT.HHmm
                                )} - ${end.format(TIME_FORMAT.HHmm)} ${
                                  TIMEZONE_SHORTLABEL?.[
                                    transactionData.timezone || "Asia/Jakarta"
                                  ] || ""
                                } (${end.diff(start, "minute")} menit) ${
                                  isEmpty(product?.teamMemberName)
                                    ? ""
                                    : `- ${product.teamMemberName}`
                                }`}
                              />
                            );
                          }
                        );
                      }
                    )}
                  </Box>
                )}
                <TicketSeparator
                  hideCircle
                  circleBorderColor={data.color}
                  circleProps={{ sx: { backgroundColor: data.color } }}
                />
                <RowKeyValue
                  px={2}
                  titleProps={{
                    sx: { color: COLOR.neutral500, fontWeight: 600 },
                  }}
                  title="Harga Produk:"
                  value={formatCurrency(
                    transactionData.productPrice || 0,
                    "IDR"
                  )}
                />

                <RowKeyValue
                  px={2}
                  titleProps={{
                    sx: { color: COLOR.neutral500, fontWeight: 600 },
                  }}
                  title="Biaya Admin:"
                  value={formatCurrency(transactionData.adminPrice || 0, "IDR")}
                />

                <RowKeyValue
                  px={2}
                  titleProps={{
                    sx: {
                      color: COLOR.neutral900,
                      fontWeight: 600,
                      fontSize: 16,
                    },
                  }}
                  title="Total:"
                  value={formatCurrency(transactionData.total || 0, "IDR")}
                  valueProps={{
                    sx: {
                      color: COLOR.neutral900,
                      fontWeight: 600,
                      fontSize: 16,
                    },
                  }}
                />
              </>
            )}
          </Box>
        </div>
        <Box display="flex" flexDirection="column" gap={2} pt={2}>
          {/* {transactionData.allowRefund &&
            !isInvalidTransaction &&
            !isLoading &&
            transactionData.paymentStatus === PAYMENT_STATUS.PAID &&
            !!checkRefundAllowability(
              transactionData.productDetail[0]?.scheduleDetails || []
            ) && (
              <CustomButton
                startEndorment={<WarningIcon color={COLOR.danger500} />}
                variant="contained"
                onClick={handleRequestRefund}
                disabled={isInvalidTransaction}
                sx={{
                  backgroundColor: "white",
                  border: `1px solid white`,
                  color: COLOR.danger500,
                }}
              >
                Ajukan Pengembalian Dana
              </CustomButton>
            )} */}
          {(transactionData.paymentStatus === PAYMENT_STATUS.UNPAID ||
            transactionData.paymentStatus === PAYMENT_STATUS.PENDING) &&
            !isInvalidTransaction &&
            !isLoading && (
              <TemplateButton
                onClick={
                  transactionData.total
                    ? handleMakePayment
                    : handleConfirmPayment
                }
                backgroundColor={data.color}
                color={FONT_COLOR}
                borderColor="white"
                disabled={isInvalidTransaction}
                buttonText={
                  !transactionData.total
                    ? "Lanjutkan Pemesanan"
                    : transactionData.paymentStatus === PAYMENT_STATUS.UNPAID
                    ? "Bayar Sekarang"
                    : "Lanjutkan Pembayaran"
                }
              />
            )}
          {(transactionData.paymentStatus === PAYMENT_STATUS.UNPAID ||
            transactionData.paymentStatus === PAYMENT_STATUS.PENDING) &&
            !isInvalidTransaction &&
            !isLoading && (
              <TemplateButton
                onClick={() => setOpenCancelBotSheet(true)}
                disabled={isInvalidTransaction}
                backgroundColor={data.color}
                borderColor="white"
                color={FONT_COLOR}
                buttonText="Batalkan Pesanan"
              />
            )}

          {transactionData.paymentStatus === PAYMENT_STATUS.PAID &&
            transactionData.transactionType !== TRANSACTION_TYPE.SALE_ONLY &&
            !isInvalidTransaction &&
            !isLoading && (
              <>
                <div style={{ display: "flex", gap: "10px" }}>
                  <TemplateButton
                    onClick={downloadPDF}
                    backgroundColor={data.color}
                    borderColor="white"
                    color={FONT_COLOR}
                    buttonText="Unduh Sebagai PDF"
                  />
                  <TemplateButton
                    onClick={downloadJPG}
                    backgroundColor={data.color}
                    borderColor="white"
                    color={FONT_COLOR}
                    buttonText="Unduh Sebagai JPG"
                  />
                </div>
                <TemplateButton
                  onClick={() =>
                    navigate(
                      `${
                        RoutePath[ROUTE_NAME.DYNAMIC_RESCHEDULE]
                      }/${invoiceId}/reschedule`
                    )
                  }
                  disabled={isInvalidTransaction}
                  backgroundColor={data.color}
                  borderColor="white"
                  color={FONT_COLOR}
                  buttonText="Jadwalkan Ulang Pesanan"
                />
              </>
            )}

          {transactionData.paymentStatus === PAYMENT_STATUS.CANCELLED &&
            !isInvalidTransaction &&
            !isLoading && (
              <>
                <div style={{ display: "flex", gap: "10px" }}>
                  <TemplateButton
                    onClick={downloadPDF}
                    backgroundColor={data.color}
                    borderColor="white"
                    color={FONT_COLOR}
                    buttonText="Unduh Sebagai PDF"
                  />
                  <TemplateButton
                    onClick={downloadJPG}
                    backgroundColor={data.color}
                    borderColor="white"
                    color={FONT_COLOR}
                    buttonText="Unduh Sebagai JPG"
                  />
                </div>
              </>
            )}
          <TemplateButton
            onClick={() => {
              navigate(RoutePath[ROUTE_NAME.DYNAMIC_HOME]);
            }}
            backgroundColor="white"
            color={FONT_COLOR}
            buttonText="Kembali Ke Halaman Utama"
          />
        </Box>
        <BottomSheet
          ref={openBottomSheetRef}
          open={openCancelBotSheet}
          onDismiss={() => setOpenCancelBotSheet(false)}
        >
          <Box p={2} pb={4} display="flex" flexDirection="column" gap={2}>
            <Typography px={2} sx={titleSx}>
              Apakah kamu yakin untuk membatalkan pesanan?
            </Typography>
            <Box p={2} display="flex" flexDirection="row" gap={2}>
              <CustomButton
                variant="contained"
                onClick={() => setOpenCancelBotSheet(false)}
                sx={{
                  backgroundColor: "white",
                  border: `1px solid ${COLOR.danger500}`,
                  color: COLOR.danger500,
                  "&:hover": {
                    backgroundColor: "white",
                    border: `1px solid ${COLOR.danger500}`,
                  },
                }}
              >
                Kembali
              </CustomButton>
              <CustomButton
                variant="contained"
                onClick={handleSubmitCancel}
                sx={{
                  backgroundColor: COLOR.danger500,
                  border: `1px solid ${COLOR.danger500}`,
                  color: "white",
                  "&:hover": {
                    backgroundColor: COLOR.danger400,
                    border: `1px solid ${COLOR.danger500}`,
                  },
                }}
              >
                Batalkan
              </CustomButton>
            </Box>
          </Box>
        </BottomSheet>
        <BottomSheet
          ref={bottomSheetRef}
          open={openBotSheet}
          onDismiss={() => setOpenBotSheet(false)}
        >
          <Box p={2} pb={4} display="flex" flexDirection="column" gap={2}>
            <TextArea
              title="Alasan Pengembalian Dana"
              textAreaProps={{
                value: refundNote,
                onChange: handleChange("refundNote"),
                onBlur: handleBlur("refundNote"),
              }}
              helper={{ color: COLOR.danger500, text: refundError }}
            />
            <CustomButton
              variant="contained"
              onClick={handleSubmitRefund}
              sx={{
                backgroundColor: COLOR.danger500,
                border: `1px solid ${COLOR.danger500}`,
                color: "white",
                "&:hover": {
                  backgroundColor: COLOR.danger400,
                  border: `1px solid ${COLOR.danger500}`,
                },
              }}
            >
              Ajukan Sekarang
            </CustomButton>
          </Box>
        </BottomSheet>
      </>
    </AppContainer>
  );
};

export default DInvoice;
