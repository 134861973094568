import { Box, Typography } from '@mui/material';
import React from 'react';
import SubMenuHeader from '../../components/Layout/SubMenuHeader/SubMenuHeader';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ROUTE_NAME, RouteIcon, RouteLabel, RoutePath } from '../../utils/constant';
import MenuListButton from '../../components/MenuListButton/MenuListButton';
import { TbFileText } from 'react-icons/tb';


const SalePage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Box sx={{ p: 2, pt: 3, pb: 5, }}>
        <Typography variant='h6' pb={1}>{RouteLabel[ROUTE_NAME.SALES]}</Typography>
        <MenuListButton
          buttons={[
            {
              Icon: RouteIcon[ROUTE_NAME.SALE_REPORT],
              title: RouteLabel[ROUTE_NAME.SALE_REPORT],
              onClick: () => {
                navigate(RoutePath[ROUTE_NAME.SALE_REPORT]);
              }
            },
            {
              Icon: RouteIcon[ROUTE_NAME.SALE_LIST],
              title: RouteLabel[ROUTE_NAME.SALE_LIST],
              onClick: () => {
                navigate(RoutePath[ROUTE_NAME.SALE_LIST]);
              }
            }
          ]}
        />
      </Box>
    </Box>
  );
};

export default SalePage;
