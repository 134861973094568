import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { COLOR } from "../../../utils/color";
import { MAX_PAGE_WIDTH } from "../../../utils/constant";

export const PageBackground: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  backgroundColor: COLOR.neutral100,
  minHeight: '100%'
};

export const FormContainer: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  maxWidth: MAX_PAGE_WIDTH,
  backgroundColor: "white",
  width: '100%',
  minHeight: '600px',
}