import { FC, ReactNode } from "react";
import ProfileInitial from "../ProfileInitial/ProfileInitial";
import { Typography, Stack, Collapse } from "@mui/material";
import { Box } from "@mui/material";
import {
  AccordionContent,
  AccordionItem,
  AccordionTitle,
} from "../Accordion/AccordionItem";
import { KeyboardArrowUp } from "@mui/icons-material";
import { AccordionHeader, HeaderContent } from "./WeekScheduleAccordion.styles";
import dayjs from "dayjs";
import { COLOR } from "../../utils/color";
import { TimeRange } from "../../pages/TeamScheduledShifts/TeamScheduledShifts";

export interface Member {
  id: number;
  name: string;
  timeRange: TimeRange[];
}

interface WeekScheduleAccordionProps {
  date: Date;
  members: Member[];
  children: ReactNode;
  open: boolean;
  onToggle: () => void;
}

const WeekScheduleAccordion: FC<WeekScheduleAccordionProps> = ({
  date,
  members,
  children,
  open,
  onToggle,
}) => {
  const displayedMembers = members.slice(0, 5);
  const remainingCount = members.length - 5;

  return (
    <AccordionItem open={open} onToggle={onToggle}>
      <AccordionTitle sx={AccordionHeader}>
        <Box sx={HeaderContent}>
          <Typography variant="subtitle1" fontWeight="bold">
            {dayjs(date).format("ddd, DD MMMM YYYY")}
          </Typography>
          <Collapse in={!open} timeout={300}>
            <Stack direction="row" spacing={2} pt={2} alignItems="center">
              {displayedMembers.map((member) => (
                <ProfileInitial
                  key={member.id}
                  name={member.name}
                  sx={{ minWidth: 32 }}
                />
              ))}
              {remainingCount > 0 && (
                <ProfileInitial
                  name={`+${remainingCount}`}
                  sx={{
                    minWidth: 32,
                    outline: "none",
                    outlineOffset: 0,
                    backgroundColor: COLOR.neutral200,
                    color: COLOR.neutral900,
                  }}
                />
              )}
            </Stack>
          </Collapse>
        </Box>
        <KeyboardArrowUp
          style={{
            transform: open ? "initial" : "rotate(180deg)",
            transition: "transform 0.3s ease",
          }}
        />
      </AccordionTitle>
      <AccordionContent>{children}</AccordionContent>
    </AccordionItem>
  );
};

export default WeekScheduleAccordion;
