import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { AccordionHeader, HeaderContent } from './TeamScheduleAccordion.styles';
import ProfileInitial from '../ProfileInitial/ProfileInitial';
import { AccordionContent, AccordionItem, AccordionTitle } from '../Accordion/AccordionItem';
import { KeyboardArrowUp } from '@mui/icons-material';

interface TeamScheduleAccordionProps {
  title: string;
  subtitle?: string;
  children: ReactNode;
  open: boolean;
  onToggle: () => void;
}

export const TeamScheduleAccordion: FC<TeamScheduleAccordionProps> = ({
  title,
  subtitle,
  children,
  open,
  onToggle
}) => {
  return (
    <AccordionItem open={open} onToggle={onToggle}>
      <AccordionTitle sx={AccordionHeader} >
        <ProfileInitial name={title} />
        <Box sx={HeaderContent}>
          <Typography variant="subtitle1" fontWeight="bold">
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="body2" color="text.secondary">
              {subtitle}
            </Typography>
          )}
        </Box>
        <KeyboardArrowUp style={{
          transform: open ? 'initial' : 'rotate(180deg)',
          transition: 'transform 0.3s ease'
        }} />
      </AccordionTitle>
      <AccordionContent>
        {children}
      </AccordionContent>

    </AccordionItem>


  );
};
