import Joi from "joi";
import { PHONE_LENGTH } from "../utils/constant";

export const receipentSchema = Joi.object({
  name: Joi.string().min(3).required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  phone: Joi.string().min(PHONE_LENGTH.MIN).max(PHONE_LENGTH.MAX).allow(""),
  notes: Joi.string().allow(""),
});

export const receipentWARequiredSchema = Joi.object({
  name: Joi.string().min(3).required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  phone: Joi.string().min(PHONE_LENGTH.MIN).max(PHONE_LENGTH.MAX).required(),
  notes: Joi.string().allow(""),
});

export const changeQuotaSchema = Joi.object({
  fullName: Joi.string().min(3).required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  phoneNumber: Joi.string().min(PHONE_LENGTH.MIN).max(PHONE_LENGTH.MAX).allow(""),
});

