import { Theme } from "@emotion/react";
import {
  Box,
  BoxProps,
  SxProps,
  MenuItem,
  Checkbox,
  ListItemText,
  Select,
  OutlinedInput,
  SelectChangeEvent,
} from "@mui/material";
import InputTitle from "../InputTitle/InputTitle";
import HelperText from "../HelperText/HelperText";
import { FormBaseProps } from "../../../types/globalTypes";
import {
  ContainerStyle,
  DisabledContainerStyle,
  ErrorContainerStyle,
  InputStyle,
} from "../form.styles";

interface Option {
  label: string;
  value: string;
}

interface MultiCheckboxSelectPropsI extends BoxProps, FormBaseProps {
  startEndorment?: React.ReactNode;
  endEndorment?: React.ReactNode;
  disabled?: boolean;
  error?: boolean;
  options: Option[];
  selectInputProps?: {
    value?: string[];
    onChange?: (value: string[]) => void;
    placeholder?: string;
    style?: React.CSSProperties;
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiCheckboxSelect: React.FC<MultiCheckboxSelectPropsI> = (props) => {
  const {
    sx,
    startEndorment,
    endEndorment,
    disabled,
    error,
    options,
    selectInputProps,
  } = props;

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    if (!disabled && selectInputProps?.onChange) {
      selectInputProps.onChange(event.target.value as string[]);
    }
  };

  return (
    <Box
      sx={{
        ...sx,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <InputTitle
        title={props.title}
        required={props.required}
        textProps={{
          sx: {
            mb: 1,
          },
        }}
      />
      <Box
        sx={
          {
            ...ContainerStyle,
            pr: 1,
            ...(disabled ? DisabledContainerStyle : {}),
            ...(error ? ErrorContainerStyle : {}),
          } as SxProps<Theme>
        }
      >
        {startEndorment && (
          <Box component="span" sx={{ mr: 1 }}>
            {startEndorment}
          </Box>
        )}
        <Select
          multiple
          displayEmpty
          value={selectInputProps?.value || []}
          onChange={handleChange}
          input={<OutlinedInput notched={false} />}
          sx={{
            ...InputStyle,
            ...selectInputProps?.style,
            ".MuiInputBase-input": {
              padding: 0,
              border: "none",
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
          MenuProps={MenuProps}
          renderValue={(selected) => {
            return selected.length === 0
              ? selectInputProps?.placeholder || ""
              : options
                  .filter((option) =>
                    (selected as unknown as string[]).includes(option.value)
                  )
                  .map((option) => option.label)
                  .join(", ");
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value} sx={{ p: 0 }}>
              <Checkbox
                checked={selectInputProps?.value?.includes(option.value)}
              />
              <ListItemText
                primary={option.label}
                sx={{ wordBreak: "break-all" }}
              />
            </MenuItem>
          ))}
        </Select>
        {endEndorment && (
          <Box component="span" sx={{ ml: 1 }}>
            {endEndorment}
          </Box>
        )}
      </Box>
      <HelperText {...props.helper} />
    </Box>
  );
};

export default MultiCheckboxSelect;
