import { Theme } from "@emotion/react";
import {
  Box,
  BoxProps,
  SxProps,
} from "@mui/material";
import InputTitle from "../InputTitle/InputTitle";
import HelperText from "../HelperText/HelperText";
import { FormBaseProps } from "../../../types/globalTypes";
import {
  ContainerStyle,
  DisabledContainerStyle,
  ErrorContainerStyle,
} from "../form.styles";
import OpacityButton, { OpacityButtonPropsI } from "../../OpacityButton/OpacityButton";
import React from "react";

interface FormButtonPropsI extends BoxProps, FormBaseProps {
  startEndorment?: React.ReactNode;
  endEndorment?: React.ReactNode;
  disabled?: boolean;
  error?: boolean;
  children?: React.ReactNode;
  FormButtonProps?: Pick<OpacityButtonPropsI, 'sx' | 'onClick'>;
}

const FormButton = React.forwardRef<HTMLButtonElement, FormButtonPropsI>((props, ref) => {
  const {
    sx,
    startEndorment,
    endEndorment,
    disabled,
    error,
    children,
    FormButtonProps,
  } = props;

  return (
    <Box
      sx={
        {
          ...sx,
          display: "flex",
          flexDirection: "column",
        } as SxProps<Theme>
      }
    >
      <InputTitle
        title={props.title}
        required={props.required}
        textProps={{
          sx: {
            mb: 1,
          },
        }}
      />
      <OpacityButton
        {...FormButtonProps}
        disabled={disabled}
        sx={
          {
            ...ContainerStyle,
            ...(disabled ? DisabledContainerStyle : {}),
            ...(error ? ErrorContainerStyle : {}),
            ...FormButtonProps?.sx,
          } as SxProps<Theme>
        }
      >
        {startEndorment && (
          <Box component="span" sx={{ mr: 1 }}>
            {startEndorment}
          </Box>
        )}
        <Box sx={{ flex: 1, textAlign: "start" }}>
          {children}
        </Box>
        {endEndorment && (
          <Box component="span" sx={{ ml: 1, display: "flex", alignItems: "center" }}>
            {endEndorment}
          </Box>
        )}
      </OpacityButton>
      <HelperText {...props.helper} />
    </Box>
  );
});

export default FormButton;
