import ModalTemplate, { ModalTemplateProps } from "./ModalTemplate";
import { Box, Typography } from "@mui/material";
import CustomButton from "../CustomButton/CustomButton";
import { COLOR } from "../../utils/color";
import { SaleService, ServiceExtraType } from "../../types/globalTypes";
import { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import OpacityButton from "../OpacityButton/OpacityButton";
import NumberInput from "../Forms/NumberInput/NumberInput";
import {
  convertTimeStringToMinutes,
  formatDurationBySecond,
  formatNumber,
  generateIntervalOptions,
  generateServiceDurationsOptions,
} from "../../utils/helper";
import SingleSelect from "../Forms/SingleSelect/SingleSelect";
import { FaTrash } from "react-icons/fa";
import dayjs from "dayjs";
import {
  useGetResourceCompact,
  useGetTeamMemberByProductID,
} from "../../query/queries";
import { GetScheduleEventResponseType } from "../../api/request.types";

interface EditSaleServiceModalProps {
  modalProps: Omit<ModalTemplateProps, "children">;
  onApply: (selectedServices: SaleService) => void;
  initialService: SaleService | null;
  onClickDelete: () => void;
  disabled: boolean;
}
const durationOptions = generateServiceDurationsOptions();

const EditSaleServiceModal = ({
  modalProps,
  onApply,
  initialService,
  onClickDelete,
  disabled,
}: EditSaleServiceModalProps) => {
  const emptyService: SaleService = useMemo(
    () => ({
      id: "",
      name: "",
      price: 0,
      durationInSeconds: 0,
      qty: 1,
      teamMember: {
        id: "",
        name: "",
        type: GetScheduleEventResponseType.TEAM_MEMBER,
        shifts: [],
      },
      resource: {
        id: "",
        name: "",
        type: GetScheduleEventResponseType.RESOURCE,
        shifts: [],
      },
    }),
    []
  );

  const { data, refetch, isFetching } = useGetTeamMemberByProductID(
    initialService?.id || ""
  );
  const getResourceCompact = useGetResourceCompact(initialService?.id || "");

  const [teamMember, setTeamMember] = useState(
    data?.data?.data?.teamMembers || []
  );
  const [resource, setResource] = useState(
    getResourceCompact?.data?.data?.data?.resources || []
  );
  const [hasResource, setHasResource] = useState(false);

  const [service, setService] = useState<SaleService>(
    initialService || emptyService
  );
  const resetDefaultValues = useCallback(() => {
    if (modalProps.open) {
      setService(initialService || emptyService);
    }
  }, [emptyService, initialService, modalProps.open]);

  useEffect(() => {
    if (data?.data?.data && !isFetching) {
      setTeamMember(data?.data?.data?.teamMembers || []);
    }
  }, [data, isFetching]);

  useEffect(() => {
    if (getResourceCompact?.data?.data?.data && !isFetching) {
      const resourceData =
        getResourceCompact?.data?.data?.data?.resources || [];
      setResource(resourceData);
      setHasResource(!!resourceData.filter((res) => res.isActive)?.length);
    }
  }, [getResourceCompact?.data?.data?.data, isFetching]);

  useEffect(resetDefaultValues, [modalProps.open, resetDefaultValues]);

  return (
    <ModalTemplate
      {...modalProps}
      withCloseButton
      header={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
            flex: 1,
            borderBottom: `1px solid ${COLOR.neutral300}`,
            gap: 1,
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Edit Sale Service
          </Typography>
        </Box>
      }
      footer={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
            p: 2,
            borderTop: `1px solid ${COLOR.neutral300}`,
          }}
        >
          <CustomButton
            variant="icon"
            disabled={disabled}
            onClick={() => onClickDelete()}
          >
            <FaTrash />
          </CustomButton>
          <CustomButton onClick={() => onApply(service)}>Terapkan</CustomButton>
        </Box>
      }
    >
      <Box
        sx={{ px: 2, pb: 5, display: "flex", flexDirection: "column", gap: 1 }}
      >
        <OpacityButton
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            textAlign: "left",
            mt: 2,
            border: `1px solid ${COLOR.neutral300}`,
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <Box
            className="indicator"
            sx={{
              width: "4px",
              backgroundColor: COLOR.primary300,
              borderRadius: 1,
              flexShrink: 0,
              transition: "all 0.2s ease",
              height: "100%",
            }}
          />
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 1,
              py: 2.5,
              pr: 2,
            }}
          >
            <Typography fontWeight={400}>
              {[
                service.name,
                formatDurationBySecond(service.durationInSeconds),
              ].join(" • ")}
            </Typography>
          </Box>
        </OpacityButton>

        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          <NumberInput
            title="Service Price"
            sx={{ flex: 1 }}
            disabled={disabled}
            startEndorment={"Rp"}
            formatFunction={(value) => {
              return formatNumber(Number(value || 0));
            }}
            numberInputProps={{
              value: String(service.price),
              onChange: (e) => {
                setService({
                  ...service,
                  price: Number(e.target.value),
                });
              },
            }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          <SingleSelect
            title="Team Member"
            sx={{ flex: 1 }}
            disabled={disabled}
            optionProps={{
              options: teamMember.map((member) => ({
                label: member.name,
                value: member.teamMemberID,
              })),
              value: service.teamMember.id,
              onChange: (member) => {
                if (member) {
                  const targetMember = teamMember.find(
                    (m) => m.teamMemberID === member
                  );
                  setService({
                    ...service,
                    teamMember: {
                      id: member,
                      name: targetMember?.name || "",
                      type: GetScheduleEventResponseType.TEAM_MEMBER,
                      shifts: [],
                    },
                  });
                }
              },
            }}
          />
        </Box>
        {hasResource && (
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <SingleSelect
              title="Sumber Daya"
              sx={{ flex: 1 }}
              disabled={disabled}
              optionProps={{
                options: resource.map((res) => ({
                  label: res.isActive
                    ? res.resourceName
                    : `⚠️ ${res.resourceName}`,
                  value: res.resourceID,
                })),
                value: service.resource.id,
                onChange: (targetResource) => {
                  if (targetResource) {
                    const targetResourceData = resource.find(
                      (m) => m.resourceID === targetResource
                    );
                    setService({
                      ...service,
                      resource: {
                        id: targetResourceData?.resourceID || "",
                        name: targetResourceData?.resourceName || "",
                        type: GetScheduleEventResponseType.RESOURCE,
                        shifts: [],
                      },
                    });
                  }
                },
              }}
            />
          </Box>
        )}
      </Box>
    </ModalTemplate>
  );
};

export default EditSaleServiceModal;
